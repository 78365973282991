/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect, useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { faChevronDown, faRobot, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, Link } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/react-hooks'
// context
import { AuthContext } from '../../../Context/Auth'
// query
import { VIEW_BOT_USER, SELECT_BOT_USER } from '../../../Query/Auth'
import {GET_WIDGET_CONFIGURATION} from '../../../Query/HeaderTop'
import { CHANGE_STATE_USER} from '../../../Query/Agent'
// img
import logo from '../../../Asset/img/login/icon.png'
// style
import { headerTop } from '../../../Style/headerTop'



const HeaderTop = ({t, i18n}) => {
    // context
    const { 
        setAuth, 
        userAuth, setUserAuth, 
        setLang, 
        setTokenFirebase, 
        tokenSession,  
        botUser, setBotUser, 
        botsUser,
        viewOption, setViewOption,
        viewOptionLang, setViewOptionLang,
    } = useContext(AuthContext)
    
    // query
    const [showingWidget, setShowingWidget] = useState(false);
    const [hasWidget, setHasWidget] = useState(false)
    const [widgetToken, setWidgetToken] = useState("")
    const [autoDerivate, setAutoDerivate] = useState(false)
    const [autoDerivateText, setAutoDerivateText] = useState("")


    const { error: errorBotView, data: dataBotView, refetch: refetchBotView } = useQuery(VIEW_BOT_USER, { 
        variables: { token: tokenSession, id_user: userAuth.id_user }});
    const { loading: widgetLoading, error: widgetError, data: widgetData} = useQuery(GET_WIDGET_CONFIGURATION,{
        variables:{token: tokenSession, bot_id: (botUser)?botUser.bot_id:"null"}})        
    const [ selectBotUser, { data: dataSelectBotUser }] = useMutation(SELECT_BOT_USER)
    const [changeState , {loading: creatChangeStateLoading, error: changeStateError, data: changeStateData }] = useMutation(CHANGE_STATE_USER)
    // ref
    const selectBot = useRef(null)
    const selectLang = useRef(null)
    // router
    const history = useHistory();
    // function
    const handleClickOutside = (event) => {
        if (selectBot && !selectBot.current.contains(event.target)) {
            setViewOption(false);
        }
        if (selectLang && !selectLang.current.contains(event.target)) {
            setViewOptionLang(false);
        }
    }
    const logout = () => {       
        setUserAuth(null)
        setTokenFirebase(null)
        setAuth(false)
        if (userAuth.name_rol == "Agente"){
            const variables = {
                token: tokenSession,
                id_user: userAuth.id_user,
                state: 2
            }
            changeState({variables, errorPolicy: 'all'})                    
        }

    }

    /**
     * @description: funcion para validar el tiempo de inactividad del usuario
     * cada vez que se da un click se actualiza el tiempo de inactividad, 
     * si el tiempo de inactividad es mayor a 60 minutos se cierra la sesion
     */
    useEffect(() => {

        let handleClickOutside = (event) => {
            // Se refresca la fecha cada vez que se hace un click
            localStorage.setItem('inicioSesion', new Date());
        }

        document.querySelector('body').addEventListener('click', handleClickOutside);
        
        const intervalId = setInterval(() => {
            
            let horActual = new Date();
            let inicioSesion = new Date(localStorage.getItem("inicioSesion"));
            let diferencia = horActual.getTime() - inicioSesion.getTime();
            let tiempoSesionMinutos = (diferencia / 1000) / 60;

            if(tiempoSesionMinutos > 60){
                //console.log("cerrando sesion");  
                if(document.getElementById("logout")){
                    document.getElementById("logout").click();
                }                              
            }
            // console.log("validando sesion");
        }, 1000 * 300);

    }, [])
      
    const changeLang = lang => {
        i18n.changeLanguage(lang)
        setViewOptionLang(!viewOptionLang)
        setLang(lang)
    }
    
    useEffect(() => {
        if(dataBotView) {
            if(dataBotView.getBotViewed) {
                // console.log(dataBotView.getBotViewed.data)                
                if(dataBotView.getBotViewed.data) {
                    setBotUser(dataBotView.getBotViewed.data) 
                } else {
                    if(userAuth.bots.length > 0) {
                        selectBotUser({variables:{token: tokenSession, id_user: userAuth.id_user, id_bot: userAuth.bots.shift().id_bot}})
                    }
                }
            }
        }
    }, [dataBotView])
    
    useEffect(() => {
        if(dataSelectBotUser){
            if(dataSelectBotUser.setBotViewed){
                refetchBotView()
            }
        }
    }, [dataSelectBotUser])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    })

    useEffect(() => {
        if(errorBotView){
            if(botsUser){
                selectBotUser({variables:{token: tokenSession, id_user: userAuth.id_user, id_bot: botsUser.shift().bot_id}})
                refetchBotView()
            }
        }
    }, [errorBotView])

    const viewWidget = () => {
        if(showingWidget){
            (function(b, o, i)
            {
                var r = o.getElementsByTagName(i);
                
                for (let item of r) {
                    if(item.src.includes("widget")){
                        
                        item.remove()
                    }
                }
                var r = o.getElementsByTagName("pengibot-root")[0];
                r.remove();
            }(window, document, 'script'));
        } else {
            (function(b, o, i, to, ad, dt, ur)
            {
            b.wO = {t: to,
            autoDerivate: ad,
            derivationText: dt};
            var e = o.createElement(i);
            var r = o.getElementsByTagName(i)[0];
            e.src = ur

            r.parentNode.insertBefore(e, r);}(window, document, 'script', widgetToken, autoDerivate, autoDerivateText, process.env.REACT_APP_WIDGET_URL));
        }
        setShowingWidget(!showingWidget)
    }

    useEffect(() => {
        if(widgetData) {
            let code = widgetData.getConfigurationWidget.code
            if(code == 101){
                setHasWidget(true)
                setAutoDerivate(widgetData.getConfigurationWidget.data.auto_derivate)
                setAutoDerivateText(widgetData.getConfigurationWidget.data.auto_derivate_word)
                setWidgetToken(widgetData.getConfigurationWidget.data.token)
            }
        }
    }, [widgetData])
    
    return(
    <div className={headerTop.contHeaderTop}>
        <div className={headerTop.optionLeft}>
            <div className={headerTop.logo}>
                <img src={logo} />
                <label> {t('title')} </label> 
            </div>
            <div className={headerTop.linkSelect} ref={selectBot}>
                <div className="name" onClick={() => setViewOption(!viewOption)}><FontAwesomeIcon icon={faRobot} /> {t('headerTop.myBot')} <FontAwesomeIcon icon={faChevronDown} /> </div>
                <div className="option" style={viewOption?({display:'flex'}):({display:'none'})}>
                    <div className="title">{t('headerTop.restoreBot')}</div>
                    <Link to="/restore" onClick={() => setViewOption(false) } className="item"><FontAwesomeIcon icon={faRobot} className="icon" /> {t('headerTop.restore')}</Link>
                {userAuth ? errorBotView ? '' : botsUser ? (
                    <>
                        <div className="title">{t('headerTop.restoreBot')}</div>
                        {botsUser.map((bot, i)=><div key={i} className="item" 
                        onClick={()=> { 
                                setViewOption(false)                            
                                selectBotUser({variables:{token: tokenSession, id_user: userAuth.id_user, id_bot: bot.bot_id}})
                            }}><FontAwesomeIcon icon={faRobot} className="icon" /> {bot.name}</div>)}
                    </>
                    ):'': ''}
                </div>
            </div>
            <div className={headerTop.buttonBot} onClick={()=>history.push('/messages')}>
                {botUser ? botUser.bot_name : 'Test Bot'}
            </div>
        </div>
        <div className={headerTop.optionRight}>
        {hasWidget &&
            <div className={showingWidget?headerTop.buttonTestBotOn:headerTop.buttonTestBotOff} onClick={()=>viewWidget()}>
                <FontAwesomeIcon icon={faRobot} /> {t('headerTop.testBot')}
            </div>
        }
            <div className={`${headerTop.linkSelect} success`} ref={selectLang}>
                <div className="name" onClick={() => setViewOptionLang(!viewOptionLang)}><FontAwesomeIcon icon={faGlobeAmericas} /> <FontAwesomeIcon icon={faChevronDown} /></div>
                <div className="option" style={viewOptionLang?({display:'flex'}):({display:'none'})}>
                    <div className="item" onClick={()=>changeLang('en')}>{t('headerTop.lang.en')}</div>
                    <div className="item" onClick={()=>changeLang('es')}>{t('headerTop.lang.es')}</div>
                </div>
            </div>
            <div id='logout' className={headerTop.close} onClick={() => logout()}>
                {t('headerTop.close')}
            </div>
        </div>
    </div>
    )
}

export default  withTranslation()(HeaderTop)
