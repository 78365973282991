/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faTimes, faAngleDown, faSearch } from '@fortawesome/free-solid-svg-icons'

import { select } from './style'

const Select = ({value, options, onChange, placeholder, clearOption, multiSelect, className, search, disabled , heightOption, marginTopOption}) => {
    const Placeholder = placeholder||'Select...'
    const Option = options||[]
    const MultiSelect = multiSelect||false
    const ClearOption = clearOption||false
    const Search = search||false
    const Disabled = disabled||false
    const [Value, setValue] = useState(value||null)
    const [ViewOption, setViewOption] = useState(false)
    const [SearchInput, setSearchInput] = useState('')
    const [Top, setTop] = useState(36)
    const refSearch = useRef(null)
    const optionsRef = useRef(null)
    const selectRef = useRef(null)
    // function
    const handleClickOutside = (event) => {
        if (optionsRef && !optionsRef.current.contains(event.target)) {
            setViewOption(false);
        }
    }
    
    const selectItem = item => {
        const newValue = MultiSelect ? Value ? Value.find(e => e.value === item.value) ? [...Value] : [...Value, item] : [item] : item 
        if(onChange){
            onChange(newValue)
        } 
            setValue(newValue)
        setViewOption(false)
    }
    
    const clickSelect = ()=> { 
        if(Disabled !== true){
            setViewOption(!ViewOption);
            setTimeout(() => {
                refSearch.current.focus()
            }, 100);
        }
    }

    const deleteOptions = item => {
        const newValue = []
        Value.forEach(data =>{if(data!==item) newValue.push(data)})

        if(onChange){
            onChange(newValue.length>0 ? newValue : null)
        }
        setValue(newValue.length>0 ? newValue : null)
    }
    const deleteOption = () => {
        if(onChange){
            onChange(null)
        }
        setValue(null)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    })

    useEffect(() => {
        if(value!==Value){
            setValue(value)
        }
    }, [value])

    useEffect(() => {
        if(selectRef){
            if(selectRef.current){
                setTimeout(() => {
                    if(selectRef.current){
                        setTop(selectRef.current.offsetHeight)
                    }
                }, 100);
            }
        }
    }, [selectRef])

    return (
        <div className={`${select} ${className} ${Disabled ? 'disabled' : ''}`}>
            <div className='select' ref={selectRef} >
                {Value 
                    ? MultiSelect 
                        ? <div className='contSelects' onClick={clickSelect} >{Value.map((item,i)=><label key={i}>{item.label} <FontAwesomeIcon className='close' icon={faTimes} onClick={() => deleteOptions(item)} /></label>)}</div>
                        : <label className='item' onClick={clickSelect}>{Value.label}</label>
                    :<label className='item place' onClick={clickSelect}>{Placeholder} </label>
                }
                {Value && ClearOption ? <FontAwesomeIcon className='close' onClick={() => deleteOption()} icon={faTimes} /> : ''}
                <FontAwesomeIcon icon={faAngleDown} className='icon' onClick={clickSelect} />
            </div>
            <div className='contOptions' ref={optionsRef} style={{display: ViewOption ? 'block' : 'none', height: heightOption || '0px' , marginTop: marginTopOption || Top }}>
                <div className='options'>
                    <div 
                        style={{display:(Search && ViewOption) ?'flex':'none'}} 
                        className='contSearch'>
                        <input 
                            ref={refSearch} 
                            className='search' 
                            value={SearchInput} 
                            onChange={e => setSearchInput(e.target.value)}
                        />
                        <FontAwesomeIcon className='icon' icon={faSearch} /> 
                    </div>
                    {Option.map((item, i)=>(SearchInput!=='' 
                        ? item.label.indexOf(SearchInput)>-1 && <div key={i} className='option' onClick={() => selectItem(item)} >{item.label}</div> 
                        : <div key={i} className='option' onClick={() => selectItem(item)} >{item.img ? <img src={item.img} /> : ''}{item.label}</div>))}
                </div>
            </div>
        </div>
    )
}

export default Select