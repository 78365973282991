import {style} from "typestyle"

export const Color = {
    primary: '#0095ae',
    secon: '#3b638c',
    messageBot: '#46B59D',
    tree: '#0a182e',
    hoverPrimary: '#22a7cc',
    lint: '#d5e3fb',
    white: '#fff',
    gray: '#EDEDED',
    keos: '#0095ae', //'#2963ff',
    text: '#888',
    textDark: '#777',
    danger: '#ffafb0',
    dangertwo: '#b52b2b',
    border: '#ccc',
    dark: '#1b262c',
    black: '#000',
    whatsapp: '#00e676',
    asterisk: '#ff7802',
    skype: '#00afee',
    telegram: '#2ca5e0',
    messager: '#00a3ff',
    allhover: 'rgb(0,0,0,0.2)',
    green: 'rgb(42, 130, 15)',
    red: 'rgb(189, 20, 27)',
    messagesBox: '#e7e6e7',
    messagesBoxSelected: '#2e68fa',
    messageNew: '#f38f01',
    messageInfoColor: '#3b638c',
    messageButtonSelected: '#f38f01',
    messageButtonSelectedHover: '#E5A750'
};

export const Shadow = {
    default: `1px 1px 5px 0px ${Color.text}`
}

export const PanelDiv = {
    title: style({
        width: '100%',
        height: 50,
        padding: 15,
        fontSize: 25,
        marginBottom: 10
    }),
    graphicsDiv: style({
        width: '100%',
        height: 450,
        padding: 15,
        float: 'left',
    }),
    graphicsCard: style({
        float: 'left',
    }),
    subText: style({
        fontWeight: 'bold',
        color: '#0095ae',
    }),
    panel: style({
        width: '100%',
        height: 200,
        padding: 5,
    }),
    panelDiv: style({
        height: '50%',
        width: '40%',
        float: 'left',
    }),
    panelDiv2: style({
        height: '100%',
        width: '100%'
    }),
    graphicsPanel: style({
        height: 560
    }),
    atentionPanel: style({
        height: 620
    }),
    tablePanel: style({
        paddingLeft: 30
    }),
    divTMO: style({
        width: 250
    })
}

export const DashboardPanel = {
    headerCard: style({
        width: '60%',
        padding: 15,
        float: 'left',
        fontWeight: "bolder",
        fontSize: 35,
    }),
    divTitle: style({
        width: '100%',
        height:'100%',
    }),
    divContent: style({
        height: 70,
    }),
    iconoSize: style({
        width: 70,
        height: 70,
        padding: 10
    }),
    divLeft: style({
        float:'left',
        height: '100%',
    }),
    divValue: style({
        fontSize: 68,
    })
}

export const Cont = {
    login: style({
        backgroundColor: Color.secon,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat',
    }),
    panel: style({
        fontFamily: 'Montserrat',
        width: '100vw',
        height: '100vh',
        backgroundColor: Color.lint,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }),
    loader: style({
        backgroundColor: Color.lint,
        color: Color.primary,
        maxWidth: '100vw',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat',
        flex: '1 1 40%'

    }),

    infoPanel: style({
        flex: '1 1 auto',
        height: 'calc(100vh - 51px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
        $nest: {
            '& iframe':{
                width: '100%',
                height: '100%',
                margin: '0px',
                borderWidth: '0px',

                $nest:{
                    '&::-webkit-scrollbar':{
                        width: '3px',
                        height: '3px',
                        backgroundColor: Color.white
                    },
                    '&::-webkit-scrollbar-thumb':{
                        backgroundColor: Color.primary
                    }
                }
            }
            
        }
    })
};

export const Button = {
    primary: style({
        backgroundColor: Color.primary,
        color: Color.white,
        borderColor: Color.primary,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '10px',
        borderRadius: '3px',
        fontSize: '14px',
        cursor: 'pointer',
        marginLeft: '5px',

        $nest: {
            '&:hover': {
                backgroundColor: Color.hoverPrimary,
                borderColor: Color.hoverPrimary
            },
            '&:disabled': {
                backgroundColor: Color.hoverPrimary,
                borderColor: Color.hoverPrimary,
                cursor: 'no-drop'
            },
            '&.justify': {
                width: '100%'
            },
            '&.between': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',

                $nest: {
                    '& div':{
                        flex: '1 1 auto'
                    }
                }
            }
        }
    }),
    primarySmall: style({
        backgroundColor: Color.primary,
        color: Color.white,
        borderColor: Color.primary,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '4px',
        borderRadius: '3px',
        fontSize: '12px',
        cursor: 'pointer',
        width: '90%',

        $nest: {
            '&:hover': {
                backgroundColor: Color.hoverPrimary,
                borderColor: Color.hoverPrimary
            },
            '&:disabled': {
                backgroundColor: Color.hoverPrimary,
                borderColor: Color.hoverPrimary,
                cursor: 'no-drop'
            },
            '&.justify': {
                width: '100%'
            },
            '&.between': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',

                $nest: {
                    '& div':{
                        flex: '1 1 auto'
                    }
                }
            }
        }
    }),
    default: style({
        backgroundColor: Color.white,
        color: Color.border,
        borderColor: Color.border,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '10px',
        borderRadius: '3px',
        fontSize: '14px',
        cursor: 'pointer',

        $nest: {
            '&:disabled': {
                backgroundColor: Color.border,
                color: Color.textDark,
                cursor: 'no-drop'
            },
            '&:hover': {
                backgroundColor: Color.border,
                color: Color.textDark
            },
            '&.justify': {
                width: '100%'
            }
        }
    }),
    table_edit: style({
        backgroundColor: Color.green,
        color: Color.border,
        borderColor: Color.border,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '5px 4px 4px 5px',
        borderRadius: '3px',
        fontSize: '14px',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        $nest: {
            '&:disabled': {
                backgroundColor: Color.border,
                color: Color.textDark,
                cursor: 'no-drop'
            },
            '&:hover': {
                backgroundColor: Color.border,
                color: Color.textDark
            },
            '&.justify': {
                width: '100%'
            }
        }
    }),
    table_delete: style({
        backgroundColor: Color.red,
        color: Color.border,
        borderColor: Color.border,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '5px 4px 4px 4px',
        borderRadius: '3px',
        fontSize: '14px',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        $nest: {
            '&:disabled': {
                backgroundColor: Color.border,
                color: Color.textDark,
                cursor: 'no-drop'
            },
            '&:hover': {
                backgroundColor: Color.border,
                color: Color.textDark
            },
            '&.justify': {
                width: '100%'
            }
        }
    })
}

export const formElements = {
    formLabel: style({
        fontSize: '12px',
        fontWeight: 600,
        color: Color.textDark,
        marginBottom: '10px',
        marginTop: '10px'
    }),

    formSave: style({
        marginTop: '10px',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }),

    formInput: style({
        borderRadius: '3px',
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '7px',
        flex: '1 1 auto',
        backgroundColor: Color.white,
        borderColor: Color.border,
        color: Color.text,        
        marginBottom: '2px'
    }),
}

export const divScroll = {
    scrollable: style({
        overflowY: 'scroll',
        maxHeight:'350px',
        position:'relative'
    })
}
export const divFlex = {
    flex: style({
      maxInlineSize: '500px',
      overflowWrap: 'break-word'
    })
}

export const linkMaps = {
    link: style({
        color: Color.black + ' !important',
    })
}
