/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from 'react'
import { AES, enc, format, mode, pad } from 'crypto-js'
// context
import { AuthContext } from '../../../Context/Auth'
// style
import { Cont } from '../../../Style/custom'
const Admin = () => {
    const { autoAuth, lang, botUser  } = useContext(AuthContext)
   
    /*
    const { autoAuth, lang, botUser  } = useContext(AuthContext)
    const Key = process.env.REACT_APP_KEY_CRYPTO_PATH
    const IV = enc.Utf8.parse("0123456789ABCDEF")
    const  hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser, location: '/dashboard'}), 
    Key, {
        iv: IV,
        mode: mode.CBC,
        padding: pad.Pkcs7  
    }).toString(format.Base64)
    */
    const  hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser,location: '/dashboard'}), process.env.REACT_APP_KEY_CRYPTO_PATH).toString()
    console.log(`http://74.205.81.232/pedirectv/page/qa/DataStudio.php?token=${hast}`)
    return( <div className={Cont.infoPanel}>
        <iframe src={`http://74.205.81.232/pedirectv/page/qa/DataStudio.php?token=${hast}`} /> 
    </div>)

    
}
export default Admin