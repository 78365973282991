import React from 'react'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// style
import { conter } from '../../../Style/conter'
const Conter = ({title, icon, children, buttonLeft, buttonRight, styleHeader, styleBody}) => {
    return(
        <div className={conter.cont}>
            <div className={conter.header} style={styleHeader}>
                {buttonLeft && <div className='Left'>{buttonLeft}</div>}
                <div className={`title ${buttonLeft ? 'center' : ''}`} >
                    <FontAwesomeIcon className='icon' icon={icon||faExclamationTriangle} />
                    <div>{title||' '}</div>
                </div>
                {buttonRight && <div className='Right'>{buttonRight}</div>} 
            </div>
            <div className={conter.body} style={styleBody}>
                {children}
            </div>
        </div>
    )
}

export default Conter