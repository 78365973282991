import React from 'react'
import {
  Switch,
  Route
} from "react-router-dom"

import PrivateRoute from './PrivateRoute'
import LoginPage from '../Auth/LoginPage'

import Dashboard from '../Panel/Dashboard'
import Settings from '../Panel/Settings'
import Backups from '../Panel/Backups'
import Topics from '../Panel/Topics'
import Topic from '../Panel/Personalization'
import BotConfig from '../Panel/BotConfig'
import AssignBots from '../Panel/AssignBots'
import Bots from '../Panel/Bots'
import ConversationFlow from '../Panel/ConversationFlow'
import FlowTree from '../Panel/FlowTree'
import Integrations from '../Panel/Integrations'
import JarvisHooks from '../Panel/JarvisHooks'
import PhrasesRefactoring from '../Panel/PhrasesRefactoring'
import Entities from '../Panel/Entities'
import Agents from '../Panel/Agents'
import SchedulesBySkill from '../Panel/SchedulesBySkill'
import Templates from '../Panel/Templates'
import Messages from '../Panel/Messages'
import MessagesHistory from '../Panel/MessagesHistory'
import Monitoring from '../Panel/Monitoring'
import Report from '../Panel/Report'
import Client from '../Panel/Client'
import Restore from '../Panel/Restore'
import ReportCustomer from '../Panel/ReportCustomer'



const Routes = () => (
    <Switch>
        <Route path={`${process.env.REACT_APP_FATHER_PATH}/login`}> <LoginPage /> </Route>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/dashboard`}> <Dashboard /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/settings`}> <Settings /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/backups`}> <Backups /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/topics`}> <Topics /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/personalization`}> <Topic /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/botConfig`}> <BotConfig /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/assignBots`}> <AssignBots /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/bots`}> <Bots /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/conversationFlow`}> <ConversationFlow /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/flowTree`}> <FlowTree /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/integrations`}> <Integrations /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/jarvisHooks`}> <JarvisHooks /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/phrasesRefactoring`}> <PhrasesRefactoring /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/entities`}> <Entities /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/agents`}> <Agents /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/schedulesBySkill`}> <SchedulesBySkill /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/templates`}> <Templates /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/messages`}> <Messages /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/messages_history`}> <MessagesHistory /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/report`}> <Report /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/reportCustomer`}> <ReportCustomer /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/client`}> <Client /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/monitoring`}> <Monitoring /> </PrivateRoute>
        <PrivateRoute path={`${process.env.REACT_APP_FATHER_PATH}/restore`}> <Restore /> </PrivateRoute>
    </Switch>
)
export default Routes