/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react'
import { withTranslation } from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faEdit, faTrashAlt, faPlus, faFileAlt, faSave} from '@fortawesome/free-solid-svg-icons'

import { useQuery, useMutation  } from '@apollo/react-hooks'
// import Select from 'react-select'              
// context

import { TemplatesContext } from '../../../Context/Templates'
import { AuthContext } from '../../../Context/Auth'
// query and mutation
import { GET_TEMPLATES, CREATE_TEMPLATE, UPDATE_TEMPLATE, DELELE_TEMPLATE, GET_VARIABLES} from '../../../Query/TemplatesAdmin' 

// function
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
// components
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import Conter from '../../Recurent/Conter'

import paginationFactory from 'react-bootstrap-table2-paginator';  
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';  


///IMPORTS FOR DIALOGS

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CodeIcon from '@material-ui/icons/Code';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
//import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

//import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// style
import 'bootstrap/dist/css/bootstrap.min.css';
import {formElements , Button as ButtonSt} from '../../../Style/custom'

import {topic} from '../../../Style/topic'
const Templates = ({t}) => {
    const useStyles = makeStyles({
        avatar: {
          backgroundColor: blue[100],
          color: blue[600],
        },
      });
    const classes = useStyles();

    //context
    const {
        deleteTemplateId, setDeleteTemplateId,
        editTemplateId, setEditTemplateId,
        editing, setEditing,
        templates, setTemplates,
        templateName, setTemplateName,
        templateBody, setTemplateBody,
        templateType, setTemplateType,
        templateTypes, setTemplateTypes,
        typeDialog, setTypeDialog,
        dialog, setDialog,
        deleteDialog, setDeleteDialog,
        variable, setVariable,
        variables, setVariables
    } = useContext(TemplatesContext)

    
    const { tokenSession, botUser, userAuth } = useContext(AuthContext)    
    // query and mutation
    const { loading: templatesLoading, error: templatesError, data: templatesData, refetch: templatesRefetch} = useQuery(GET_TEMPLATES,{variables:{token: tokenSession, fk_company: userAuth.id_company}})
    const { loading: variablesLoading, error: variablesError, data: variablesData, refetch: variablesRefetch} = useQuery(GET_VARIABLES,{variables:{token: tokenSession, id_bot: botUser.bot_id}})
    const [saveTemplate, { loading: newTemplateLoading, error: newTemplateError, data: newTemplateData }] = useMutation(CREATE_TEMPLATE)
    const [updateTemplate, { loading: updateTemplateLoading, error: updateTemplateError, data: updateTemplateData }] = useMutation(UPDATE_TEMPLATE)
    const [delTemplate, { loading: delTemplateLoading, error: delTemplateError, data: delTemplateData }] = useMutation(DELELE_TEMPLATE)



    const editTemplate = (cell, row) => {
        setEditing(true)
        setTemplateName(row.name)
        setTemplateBody(row.template)
        setTemplateType(String(changeTypeToString(row.type)))
        setEditTemplateId(row.id)
    }


    const deleteTemplate = (cell, row) => {
        setDeleteTemplateId(row.id)
        setDeleteDialog(true)
    } 

    const GetActionFormat = (cell, row) => {
        return (
            <div>
                <button className={`${ButtonSt.table_edit} button`}  onClick={editTemplate.bind(this,cell,row)} >
                            <FontAwesomeIcon icon={faEdit} /> 
                </button>
                <button className={`${ButtonSt.table_delete} button`}  onClick={deleteTemplate.bind(this,cell,row)} >
                                <FontAwesomeIcon icon={faTrashAlt} /> 
                    </button>
            </div>
        );
    }

    // function to close variables and type dialog
    const handleClose = () => {
        setDialog(false);
        setTypeDialog(false);
        setVariable("");
      };

    // function to close variables dialog and delete entry
    const handleCloseDelete = () => {
    setDeleteDialog(false);
    setDeleteTemplateId(0);

    };

    // Function to delete an entry
    const handleConfirmDelete = () => {
        const variables = {
            token: tokenSession,
            id_agent_template: deleteTemplateId
        }

        const [validate] = validateGlobal({
            token: 'String|Require',
            id_agent_template: 'Int|Require'
        },variables,{
            message: {
                ErrorDefault: t('templates.error.default'),
                CorrectValidate: t('templates.error.correct')
            },
            position: 'bottom-right',
            correctValidate: false
        })
        if(validate) {
            delTemplate({ variables, errorPolicy: 'all'})
        }
        setDeleteDialog(false);
        setDeleteTemplateId(0);
    };

    // Function to select the types of templates for the select filter
    const selectOptionsTemplateTypes = {
        1: t("templates.template_type.push"),
        2: t("templates.template_type.response")
    };

    // Columns of the template data
    const columns = [
                        {
                          dataField: 'name',  
                          text: t('templates.name'),  
                          sort:true  ,
                          filter: textFilter(),
                          style:{'width' : '270px'}
                         
                        }, {  
                          dataField: 'template',  
                          text: t('templates.body'),
                          sort: true

                        }, {
                          dataField: 'type',
                          text: t('templates.type'),
                          sort: true,
                          formatter: cell => selectOptionsTemplateTypes[cell],
                          filter: selectFilter({
                              options: selectOptionsTemplateTypes
                          }),
                          style:{'width' : '120px' }
                        },
                        {
                            text: t('templates.action'),
                            dataField: "",
                            formatter: GetActionFormat,
                            classes: "p-1",
                            style:{'width' : '60px'}
                            
                        }
                        ]

    // Config of the columns
    const options = {  
        page: 2,   
        sizePerPageList: [ {  
                text: '5', value: 5 
            }, {  
                text: '10', value: 10  
            }, {  
                text: 'All', value: templates.length  
            } ],   
        sizePerPage: 5,   
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: 'Prev',  
        nextPage: 'Next', 
        firstPage: 'First',   
        lastPage: 'Last',   
        paginationPosition: 'top'    
    };

    // To add a new entry, verify variables and write with mutation gql. WRITE AND READ FROM DB.
    const addTopic = () => {

        if(editing){
            const variables = {
                token: tokenSession,
                id_agent_template: editTemplateId,
                template: templateBody,
                template_name: templateName,
                fk_company: userAuth.id_company,
                template_type: parseInt(changeTypeToInt(String(templateType)))
            }
    
            const [validate] = validateGlobal({
                token: 'String|Require',
                id_agent_template: 'Int|Require',
                template: 'String|Require',
                template_name: 'String|Require',
                fk_company: 'Int|Require',
                template_type: 'Int|Require'

            },variables,{
                message: {
                    template: t('templates.error.body'),
                    template_name: t('templates.error.name'),
                    ErrorDefault: t('templates.error.default'),
                    CorrectValidate: t('templates.error.correct'),
                    template_type: t("templates.error.type_missing")
                },
                position: 'bottom-right',
                correctValidate: false
            })
            if(validate) {
                updateTemplate({ variables, errorPolicy: 'all'})
            }
        } else {
            const variables = {
                token: tokenSession,
                template: templateBody,
                template_name: templateName,
                fk_company: userAuth.id_company,
                template_type: parseInt(changeTypeToInt(String(templateType)))
            }
    
            const [validate] = validateGlobal({
                token: 'String|Require',
                template: 'String|Require',
                template_name: 'String|Require',
                fk_company: 'Int|Require',
                template_type: 'Int|Require'
            },variables,{
                message: {
                    template: t('templates.error.body'),
                    template_name: t('templates.error.name'),
                    ErrorDefault: t('templates.error.default'),
                    CorrectValidate: t('templates.error.correct'),
                    template_type: t("templates.error.type_missing")
                },
                position: 'bottom-right',
                correctValidate: false
            })

            //if template input from the user is valid, then save template
            if(validate) {
                saveTemplate({ variables, errorPolicy: 'all'})
            }
        }
        

    }

    // Type of template comes from DB as integers 1 for push 2 for response
    const changeTypeToInt = (type) => {
        //if(type == t("templates.template_type.push")) {
        //    return "1"
        //}
        // else if(type == t("templates.template_type.response")){
           if(type == t("templates.template_type.response")){
             return "2"
            }
            else {
                return <Error />
            }
    };

    const changeTypeToString = (type) => {
        //if(type == 1) {
        //    return String(t("templates.template_type.push"))
        //}
        // else if(type == 2){
        if(type == 2){            
             return String(t("templates.template_type.response"))
        }
        else {
            return <Error />
        }
    }

    // In variable 'typeArr' will be stored all types of templates in the project

    useEffect(()=>{
        if(templatesData && templatesData.listTemplate.data){
            let tempArr = templatesData.listTemplate.data.templates.map((temp)=>{
                return {id: temp.id_agent_template, name: temp.template_name, template: temp.template, type: temp.template_type}
            })
            //let typeArr = [t("templates.template_type.push")]
            let typeArr = [t("templates.template_type.response")]
            setTemplateTypes(typeArr)
            setTemplates(tempArr)
        }
    },[templatesData])

    useEffect(()=>{
        if(variablesData){
            let varArr = ['#agent', '#time'];
            let tempArr = variablesData.getEntitiesBot.data.map((vari)=>{
                varArr.push(vari)
            })
            varArr = [...new Set(varArr)];
            setVariables(varArr);

        }
    },[variablesData])
    
    useEffect(() => {
        if(newTemplateData){
            if(newTemplateData.createTemplate){
                templatesRefetch()
                setEditing(false)
                setEditTemplateId(0)
                setTemplateName("")
                setTemplateBody("")
                setTemplateType("")
            }
        }
    }, [newTemplateData])

    useEffect(() => {
        if(updateTemplateData){
            if(updateTemplateData.updateTemplate){
                templatesRefetch()
                setEditing(false)
                setEditTemplateId(0)
                setTemplateName("")
                setTemplateBody("")
                setTemplateType("")
            }
        }
    }, [updateTemplateData])

    useEffect(() => {
        if(delTemplateData){
            if(delTemplateData.deleteTemplate){
                templatesRefetch()
                setEditing(false)
                setEditTemplateId(0)
                setTemplateName("")
                setTemplateBody("")
                setTemplateType("")
            }
        }
    }, [delTemplateData])

    if(templatesLoading) return <Loader height='100%' />

    if(templatesError) return <Error />

    if(newTemplateError) return <Error />

    const changeTemplate = (value) => {
        let lastChar = value.substr(value.length - 1); // => "1"
        if(lastChar == '#' && templateType != "Push"){
            setDialog(true)
        } else {
            setTemplateBody(value)
        }
    }

    const handleListItemClick = (value) => {
        setDialog(false);
        setTemplateBody(templateBody + value)
      };

    const handleTemplateTypeClick = (type) => {
        setTemplateType(type)
        setTypeDialog(false);

    };
    

    return (
        <Conter title={t('templates.title')} icon={faFileAlt}>
            {<>
                <p className="py-4">{t('templates.use')}</p>
                <div className={topic.contLabel}>
                    <div className={`${topic.label} small name`}>{t('templates.name')}</div>
                    <div className={`${topic.label} small name`}>{t('templates.type')}</div>
                    <div className={`${topic.label} small body`}>{t('templates.body')}</div>
                    <div className={`${topic.label} small`}>{t('topics.action')}</div>
                </div>
                <div className={topic.contInputButton}>

                    {/*Dialog for the variables window*/}
                    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog}>

                        <DialogTitle id="simple-dialog-title">{t('templates.select_variable')}</DialogTitle>
                        <List>
                            {(Array.isArray(variables) && variables.length != 0 )  ? variables.map((variable) => (
                            <ListItem button onClick={() => handleListItemClick(variable)} key={variable}>
                                <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <CodeIcon />
                                </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={variable} />
                            </ListItem>
                            )): ''}
                        </List>
                    </Dialog>

                    {/*Dialog for the template types window*/}
                    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={typeDialog}>

                        <DialogTitle id="simple-dialog-title">{t('templates.select_template_type')}</DialogTitle>
                        <List>
                            {templateTypes.map((type) => (
                                <ListItem button onClick={() => handleTemplateTypeClick(type)} key={type}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <BuildTwoToneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={type} />
                                </ListItem>
                            ))}
                        </List>
                    </Dialog>

                    <input className='input_name'  maxLength={20} value={templateName?templateName:''}
                        onChange={e => setTemplateName(e.target.value) } />

                    <input className="input_name" onClick={() => setTypeDialog(true)} maxLength={20} value={templateType?templateType:''}
                           onChange={e => setTemplateType(e.target.value)  }/>

                    <input className='input'  maxLength={500} value={templateBody?templateBody:''}
                        onChange={e => changeTemplate(e.target.value) }/>

                    <button className={`${ButtonSt.default} button`}  onClick={()=> addTopic()} >
                       <FontAwesomeIcon icon={!editing ? faPlus:faSave } /> 
                    </button>
                </div>
            </>}

            <Dialog
                open={deleteDialog}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('templates.confirm_delete')}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('templates.delete_message')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        {t('templates.disagree')}
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        {t('templates.agree')}
                    </Button>
                </DialogActions>
            </Dialog>
            
            <BootstrapTable   
                        striped  
                        hover  
                        keyField='id'   
                        data={ templates }   
                        columns={ columns }
                        pagination={ paginationFactory() }  
                        filter={ filterFactory() } >
                            
            </BootstrapTable>  
            
        </Conter>
    )
    
}

export default withTranslation()(Templates)