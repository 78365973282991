import {gql} from 'apollo-boost'

export const CHANGE_AGENT = gql`
mutation changeClientFromAgent(
  $token:String, 
  $roomId: String, 
  $agentId: Int,
  $oldAgentId: Int,
  $botId: Int
){
 changeClientFromAgent(inputAgent:{
    token:$token,
    roomId:$roomId,
    agentId:$agentId
    oldAgentId: $oldAgentId,
    botId: $botId
    
  }){
    code
    message
  }
}
`

export const GET_FILE_URL = gql`
mutation getUrlFromFile(
  $token: String!
  $file: Upload!
) {
  getUrlFromFile(
    inputFile: { token: $token, file: $file }
  ) {
    code    
    media_uri
    content_type
  }
}
`