import { gql } from "apollo-boost"

export const GET_WIDGET_CONFIGURATION = gql`
query getConfigurationWidget($token:String, $bot_id:Int){
  getConfigurationWidget(inputWidget:{

    token:$token,
    bot_id:$bot_id
  }){
	code
    message
    data{
        token
        auto_derivate
        auto_derivate_word
    }
  }
}
`


