import { gql } from 'apollo-boost'

export const SEARCH_CLIENT_USER_ID = gql`
query searchClientUserId(
    $token: String
    $user_id: Int
    $bot_id: Int
    $start_date: String
    $end_date: String
  ){
    searchClientByUserIdAndDate(inputClientByUserIdAndDate:{
      token: $token
      user_id: $user_id
      bot_id: $bot_id
      start_date: $start_date
      end_date: $end_date
    }){
      code
      message
      data{
        client_id
        client_name
        client_email
        client_phone
        tag_id
        tag_name
        user_name
      }
    }
  }
` 

export const SEARCH_CLIENT_BOT_ID = gql`
query searchClientBotId(
    $token: String
    $bot_id: Int
    $start_date: String
    $end_date: String
  ){
    searchClientByBotIdAndDate(inputClientByBotIdAndDate:{
      token: $token
      bot_id: $bot_id
      start_date: $start_date
      end_date: $end_date
    }){
      code
      message
      data{
        client_id
        client_name
        client_email
        client_phone
        tag_id
        tag_name
        user_name
      }
    }
  }
`
