import { gql } from "apollo-boost";

export const GET_CONTENT_TYPE = gql`

query getContentType(
  $token: String!
  $url: String!
) {
  getContentType(
    inputURL: { token: $token, url: $url }
  ) {
    code    
    message
    data
  }
}

`
