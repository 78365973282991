import React, {useState, useEffect, useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'

// style
import { messages } from '../../../../Style/messages'


// context
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
//Component

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SentimentIcon from '../../../Recurent/Graphics/SentimentIcon';

//Query
import { useQuery, useMutation } from '@apollo/react-hooks'
import { CHANGE_STATE_USER, GET_AGENT} from '../../../../Query/Agent'
import { GET_SENTIMENT_AGENT } from '../../../../Query/Messages'

const ListChat = ({children , t}) =>{

    const { botUser, userAuth, tokenSession } = useContext(AuthContext)    
    const [changeState , {loading: creatChangeStateLoading, error: changeStateError, data: changeStateData }] = useMutation(CHANGE_STATE_USER)
    const {loading: loadingShowUser, error: errorShowUser, data: dataShowUser} = useQuery(GET_AGENT, {variables: {token: tokenSession, id_user: userAuth.id_user},  errorPolicy: 'all', fetchPolicy: 'network-only'})   
    // const {loading: loadingAgentSentiment, error: errorAgentSentiment, data: dataAgentSentiment, refetch: refetchAgentSentiment} = useQuery(GET_SENTIMENT_AGENT, 
        // {variables:{token: tokenSession, agent_id: userAuth.id_user },  errorPolicy: 'all', pollInterval: 30000},{fetchPolicy: 'network-only'})
    const [checkState, setCheckState] = useState(false);
    const [nameUser, setNameUser] = useState("");
    const [initialsName, setinitialsName] = useState("");
    const { sound, setSound } = useContext(MessagesContext)   
    const [agentSentiment, setAgentSentiment] = useState(null)

    useEffect(() => {
        if (dataShowUser){
            let value = dataShowUser.showUser.data.state_id == 1 ? true : false
            setCheckState(value)            
            setNameUser(dataShowUser.showUser.data.name+' '+dataShowUser.showUser.data.last_name)
            setinitialsName(dataShowUser.showUser.data.name.charAt(0).toUpperCase()+dataShowUser.showUser.data.last_name.charAt(0).toUpperCase())        
        }        
    }, [dataShowUser])

    // useEffect(() => {
    //     if(dataAgentSentiment){
    //         if(dataAgentSentiment.getAvgSentimentAnalysisByAgent){
    //             let agentSent = dataAgentSentiment.getAvgSentimentAnalysisByAgent.data;
    //             if (!(agentSent === null)){
    //                 setAgentSentiment(agentSent.avg_sentiment_analysis)
    //             }
    //         }
    //     }        
    // }, [dataAgentSentiment])
    
    const handleChange = (event) => {
        setCheckState(event.target.checked)
        let stateCheck =  event.target.checked ? 1 : 2
        const variables = {
            token: tokenSession,
            id_user: userAuth.id_user,
            state: stateCheck
        }

        changeState({variables, errorPolicy: 'all'})
    }

    const soundChange = (event) => {
        setSound(event.target.checked)        
    }



    const IOSSwitch = withStyles((theme) => ({
        root: {
          width: 32,
          height: 16,
          padding: 0,
          margin: theme.spacing(1),
        },
        switchBase: {
          padding: 1,
          '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
              backgroundColor: '#52d869',
              opacity: 1,
              border: 'none',
            },
          },
          '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
          },
        },
        thumb: {
          width: 14,
          height: 14,
        },
        track: {
          borderRadius: 16 / 2,
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[50],
          opacity: 1,
          transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
      }))(({ classes, ...props }) => {
        return (
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        );
      });
console.log(agentSentiment);
    return (

        
        <div className={messages.listChat} >
            <div className='title' >
                <FontAwesomeIcon className='icon' icon={faComments} />
                <div style={{"flexGrow": 1}}>{`${process.env.REACT_APP_DOC_TITLE} / ${t('messages.title')}`.toUpperCase()}</div>
                {agentSentiment?<div style={{"flexDirection": "column", "alignItems": "flex-end"}}><SentimentIcon satisfactionLevel={agentSentiment} size={30} /></div>:''}
            </div>
            <Grid container spacing={1} >
                <Grid item xs={3} style={{textAlign:"center"}}>
                    <Avatar style={{backgroundColor:"#0095ae", display:"inline-grid"}}>{initialsName}</Avatar>
                </Grid>
                <Grid item xs={8}>
                    <FormGroup row>                
                        <FormLabel component="legend">{nameUser}</FormLabel>
                        <FormControlLabel
                            control={<IOSSwitch 
                                        checked={checkState} 
                                        onChange={handleChange} 
                                        name="checkedState" 
                                        id="checkedState"
                                        />}
                        label={(checkState) ? t('messages.active') : t('messages.disabled')} />
                        <FormControlLabel
                            control={<IOSSwitch 
                                        checked={sound} 
                                        onChange={soundChange} 
                                        name="checkedsound" 
                                        id="checkedsound"
                                        />}
                        label={(sound) ? <FontAwesomeIcon style={{color:"#0095ae"}} className='icon' icon={faVolumeUp} /> : <FontAwesomeIcon style={{color:"red"}} className='icon' icon={faVolumeMute} />} />

                    </FormGroup>    
                </Grid>
            </Grid>    
            
            <div className='list' style={{paddingLeft: '14px'}}>
                {children}
            </div>
        </div>
    )
}
export default withTranslation()(ListChat)