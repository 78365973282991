import {gql} from 'apollo-boost'

export const LIST_AGENTS = gql`
query getAllAgentsOcupation($token:String, $id_bot:Int,$id_user:Int){
  getAllAgentsOcupation(inputAgentOcupation:{
    token:$token,
    id_bot:$id_bot,
    id_user:$id_user
  }){
  code
    message
    data{
        id
        name
        picture
        ocupation
    }
  }
}`

export const CHANGE_STATE_USER = gql`
mutation changeUserState(
	$token: String 
  $id_user: Int 
  $state: Int 
) {
  changeUserState(
    inputChangeUserState: { inputTokIdUser:{token: $token, id_user: $id_user}, state: $state }
  ) {
    message    
  }
}
`

export const GET_AGENT = gql`
query showUser(
  $token: String!
  $id_user : Int! 
) {
  showUser(
    inputDefault: { token: $token,  id_user: $id_user}
  ) {
    code    
    message
    data{name,last_name,state_id}
  }
}
`