import React from 'react'
import { Bar } from 'react-chartjs-2'

const BarChart = ({t, graphicsData}) => {
  return (
    <div>
      <Bar
        data={{
          labels: graphicsData.labels,
          datasets: [
            {
              label: t('dashboard.labelConversationsGraphics'),
              data: graphicsData.data,
              backgroundColor: [
                'rgba(41, 223, 255, 1)',
                'rgba(41, 99, 255, 1)',
                'rgba(41, 255, 191, 1)',
                'rgba(166, 41, 255, 1)',
              ],
              borderColor: [
                'rgba(41, 223, 255, 1)',
                'rgba(41, 99, 255, 1)',
                'rgba(41, 255, 191, 1)',
                'rgba(166, 41, 255, 1)',
              ],
              borderWidth: 1,
            },
          ],
        }}
        height={350}
        width={100}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            labels: {
              fontSize: 25,
              family: "'Big Shoulders Stencil Display'",
            },
          },
        }}
      />
    </div>
  )
}

export default BarChart