/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, {useContext, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDatabase } from 'reactfire'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobot, faAsterisk, faCube, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { faTelegram, faFacebookMessenger, faWhatsapp, faWhatsappSquare, faSkype } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'
import emoji from 'react-easy-emoji'
import { getEmojiDataFromNative, Emoji } from 'emoji-mart'
import dataEmoji from 'emoji-mart/data/all.json'
// context
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
import { CustomBarContext } from '../../../../Context/CustomBar'
// style
import { messages } from '../../../../Style/messages'
import { Button, Color } from '../../../../Style/custom'
import clock from '../../../../Asset/img/profile/clock5.png'


const ItemList = ({t, data, showTapsBot, tapBotRefetch, userSelected, removeRoomById}) => {
    const { tokenSession, userAuth, botUser } = useContext(AuthContext)
    const { 
        setUser,
        ListTap,
        PataformList,
        setMessage,
        chatRef,
        setClient,
        setName,
        setMail,
        setPhone, phone,
        scrollRef,
        setMyTaps,
        setClientPhone,
        setTime,
        setCountry,
        setTypeSelect,
        setViewWhatsapp,
        setViewWhatsappRender,        
        setViewButtonsClient,
        setViewButtonsFrom,
        setRefechIdSearch,
        setRefechPhoneSearch,
        showClientId, showClientIdData, showClientIdRefetch,
        showClientPhone, showClientPhoneData, showClientPhoneRefetch
    } = useContext(MessagesContext)


    const {setViewShortCut } = useContext(CustomBarContext)  
    
    
    const db = useDatabase()
    // function
    const userSelect = (User, e) => {   
        if(e.target.type==='button'){
            console.log("userSelect clear user")
            setUser(null)
        } else {           
            setUser(User)
            ListTap ? tapBotRefetch({token: tokenSession, bot_id: botUser.bot_id, type_tag_id: 1 }) : showTapsBot({variables:{token: tokenSession,  bot_id: botUser.bot_id, type_tag_id: 1 }})
            setClient(null)
            setName('')
            setMail('')
            setPhone('')
            setMyTaps([])
            setTime(null)
            setCountry(null)
            setTypeSelect(null)
            setViewButtonsClient(true)
            setViewButtonsFrom(true)
            setViewWhatsapp(false)
            searchUserId(User)
            setClientPhone(null)
            setTimeout(() => {
                setTitle()
                if(chatRef) {
                    if(chatRef.current) {                                
                        //chatRef.current.scrollIntoView(false);
                        chatRef.current.scrollIntoView({block: 'end', behavior: "smooth" })                
                    }
                }
            }, 100);
        }                
    }

    function formatPhone(phone){
        let _phone = phone.trim()
        if (_phone.charAt(0) != "+"){
          _phone = `+${_phone}`  
        }         
        setClientPhone(_phone)
        setPhone(_phone)
        return _phone
    }

    const getChannel = (platform, metadata) => {
        const myChannel = PataformList ? PataformList.find(e => e.id === parseInt(platform)) ?  PataformList.find(e => e.id === parseInt(platform))  : { name: '' }: { name: '' }                    
        switch (myChannel.name) {
            case 'Widget':
                let widgetChannel = myChannel
                const Meta = metadata !== '' ? JSON.parse(metadata) : null                
                if ('widget_platform' in Meta){
                    widgetChannel = PataformList ? PataformList.find(e => e.id === parseInt(Meta.widget_platform)) ?  PataformList.find(e => e.id === parseInt(Meta.widget_platform))  : { name: '' }: { name: '' }             
                }
            return <>{(widgetChannel.icon!=='' && widgetChannel.icon!==' ') ? <img className='foto' src={widgetChannel.icon} /> : <FontAwesomeIcon className='iconchannel' icon={faRobot} />} <div className='name' >{widgetChannel.name}</div> </>                
            case 'Asterisk':
                return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel asterisk' icon={faAsterisk} />} <div className='name' >{myChannel.name}</div> </>
            case 'Skype':
                return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel skype' icon={faSkype} />} <div className='name' >{myChannel.name}</div> </>
            case 'Telegram':
                return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel telegram' icon={faTelegram} />} <div className='name' >{myChannel.name}</div> </>
            case 'Facebook':
                //return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel messager' icon={faFacebookMessenger} />} <div className='name' >{myChannel.name}</div> </>
                return <> <FontAwesomeIcon className='iconchannel messager' icon={faFacebookMessenger} /> <div className='name' >{myChannel.name}</div> </>
            case 'Whatsapp':
            case 'WhatsApp':
                //return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel whatsapp' icon={faWhatsappSquare} />} <div className='name' > {myChannel.name}</div> </>
                return <><FontAwesomeIcon className='iconchannel whatsapp' icon={faWhatsapp} /> <div className='name' > {myChannel.name}</div> </>
            case 'API':
        return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel' icon={faCube} />} <div className='name' >{myChannel.name}</div> </>
            default:
        return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel' icon={faRobot} />} <div className='name' >{myChannel.name}</div> </>
        }
    }
    
    const searchUserId = User => {
        setViewShortCut(false)

        if(User){

            db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({'newMessage': '0'})                       
            const Meta = User.metadata !== '' ? JSON.parse(User.metadata) : null      
            if(parseInt(User.client_id)!==0 && User.client_id) {
                setViewButtonsClient(false)
                setRefechIdSearch(true)
                if (User.platform == 4){
                    setViewWhatsappRender(true)
                    setViewWhatsapp(true)      
                } 
                showClientId({variables:{token: tokenSession, id_bot: botUser.bot_id, id_client: parseInt(User.client_id)}}, {fetchPolicy: 'network-only'})
            } else {

                if(parseInt(User.platform)===4) {
                    let _phone = ""                    
                    if (Meta.phone){                       
                      _phone = Meta.phone  
                      
                    }else{
                        const data = User.session_id.split('|')    
                        _phone = `+${data[1]}`
                    } 

                    _phone = formatPhone(_phone)                    
                    const variables = {
                        token: tokenSession,
                        client_phone: _phone,
                        id_bot: botUser.bot_id,
                        user_id: userAuth.id_user
                    }

                    setViewButtonsClient(false)
                    setViewWhatsapp(true)      
                    
                    if (User.client_id != 0){
                       
                        setViewWhatsappRender(true)
                        setRefechPhoneSearch(true)                         
                        if(showClientPhoneData){

                            showClientPhoneRefetch(variables)                        
                        }else{

                            showClientPhone({variables, errorPolicy: 'all', fetchPolicy: 'network-only'})
                        }                

                    }else{
                                     
                        if(Meta.phone) formatPhone(Meta.phone)

                        if (Meta.name){
                            if (Meta.name !== ''){
                                setName(Meta.name)
                            }else if(Meta.phone){
                                if (Meta.phone !== '') setName(Meta.phone.trim())
                            }
                        }else if(Meta.phone){
                            if (Meta.phone !== '') setName(Meta.phone.trim())
                        }
                        setViewWhatsappRender(true)                                 
                    }                   

                }else{
                    if (Meta.name) setName(Meta.name)
                    if (Meta.phone){
                        formatPhone(Meta.phone)                        
                    }else{
                        formatPhone("")                        
                        setViewWhatsappRender(false)   
                    }    
                    
                    
                }

                
                   
            }        
           
        }
    }


    const setTitleAlert = () => {
        document.title = t('messages.info.titleAlert')
        return(
            <div className='new' >{t('messages.info.new')}</div>

        )
    }

    const setTransferAlert = () => {        
        document.title = t('messages.info.titleAlert')
        return(
            <div className='transfer'><FontAwesomeIcon icon={faExchangeAlt} /></div>
        )
    }


    const setTitle = () => {        
        document.title = t('messages.info.title')
    }
    
    let Metadata = ''
    if (data){
        if (data.metadata){
            Metadata = JSON.parse(data.metadata)
        }       
    }    
    
    const client_name = (data.client_name !== '') ? data.client_name : Metadata.name ? Metadata.name : Metadata.phone ? Metadata.phone.trim() : ''
    const showItem = (data.chat) ? true : false
    
    return(        
       
        <>{ showItem && 
        
        <div id={data.lastMessage.time} name="chat-agent" onClick={(e)=>userSelect(data,e)} className={(userSelected && data.roomId==userSelected.roomId)?messages.userSelected:messages.itemList} style={{marginTop:'5px', paddingRight: '0px', borderColor: (userSelected && data.roomId==userSelected.roomId) ? Color.keos : 'lightgray'}}>
            
                <div className='contAlert'>
                    {JSON.parse(process.env.REACT_APP_CONTER_MESSAGE) 
                        ?  (parseInt(data.newMessage||0)>0) ? <div className='new' >{data.newMessage}</div>: '' 
                        : data.newMessage==='1' ?  setTitleAlert() : data.newMessage==='t' ? setTransferAlert() : '' }
                </div>
           

            <div className='contInfo'>


                <div className='contFoto' >
                    {data.img?<img className='foto' src={data.img} />: getChannel(data.platform, data.metadata)}
                </div>

                <div className='info'>
                    {data.alerted && 
                        <div className='contAgentAlert'>
                            <img className="iconAlert" src={clock} alt="icon_header_card" style={{size: 10}}/>
                        </div>
                     }
                     
                    {client_name && <div><b>{`${t('messages.info.clientName')}:`}&nbsp;</b> { `${client_name}` }</div> }
                    {data.lastMessage.text && <div><b>{`${t('messages.info.message')}:`}&nbsp;</b> 
                        {emoji(data.lastMessage.text.substr(0,15), (_, string, offset) => {
                            const emojiData = getEmojiDataFromNative(string, 'google', dataEmoji)
                            if (emojiData) {
                                return <Emoji key={offset} emoji={emojiData} set={'google'} skin={emojiData ? emojiData.skin || 1 : 1} size={28} />    
                            }else{
                                return string
                            }                             
                        })}
                        {` ${data.lastMessage.text.length >= 15 ? '...' : ''}`}</div>}
                    {data.lastMessage.time && <div><b>{`${t('messages.info.time')}:`}&nbsp;</b><small>{` ${moment(data.lastMessage.time).format('YY-MM-DD LT')}`}</small></div>}
                    <div>
                        {data.tag_name !== '' 
                        ? <div className='tag' style={{backgroundColor: '#e7e6e7', color:data.tag_color !== '' ? data.tag_color : Color.primary, borderColor:data.tag_color !== '' ? data.tag_color : Color.primary, borderStyle:'solid', borderWidth:'1px', fontSize:'9px' }} >{data.tag_name}</div> 
                        : ''}
                    </div>
                    
                </div>
            </div>
        </div>
        }
      </>
       
    )
}

export default withTranslation()(ItemList)