import { gql } from "apollo-boost"

export const GET_CLOSE_BOT = gql`
query getCloseIntentsByBot($token:String,$bot_id:Int){
    getCloseIntentsByBot(inputGetCloseIntent:{
        token:$token,
        bot_id:$bot_id
    }){
        code    
        message    
        data{    
            intent_id    
            intent_name
            }
        }
}
`
export const GET_BOT_SETTING = gql`
query getBotSetting($token:String,$bot_id:Int){
    getBotSetting(inputGetBotSetting:{
        token:$token,
        bot_id:$bot_id
    }){
        code    
        message    
        data{    
            bot_id    
            session_close_validation
            session_close_minutes
            session_close_message
            session_close_intent_id
            }
        }
}

`
export const SAVE_BOT_SETTING = gql`
mutation alterBotSetting($token:String,$bot_id:Int,$session_close_validity:Int,$session_close_minutes_inactivity:Int,$session_close_message:String,$session_close_intent_id:Int){
    alterBotSetting(inputAlterBotSetting:{
        token:$token,
        bot_id:$bot_id,
        session_close_validity:$session_close_validity,
        session_close_minutes_inactivity:$session_close_minutes_inactivity,
        session_close_message:$session_close_message,
        session_close_intent_id:$session_close_intent_id
    }){
        code
        message
        }
}

`
