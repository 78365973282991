/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
// import DateTimePicker from 'react-datetime-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTags } from '@fortawesome/free-solid-svg-icons'
import generatorId from 'random-id-generator'
import moment from 'moment'
// context
import { MessagesContext } from '../../../../Context/Messages'
import { AuthContext } from '../../../../Context/Auth'
// query
import { CREATE_DIATY_USER, CREATE_PROCTION,CREATE_CLIENT, EDIT_CLIENT, SHOP_COUTRY ,VALIDATE_PHONE} from '../../../../Query/Messages'
// function
import { validateGlobal, toastMessage } from '../../../Recurent/ValidateGlobal'
// componet
import Select from '../../../Recurent/Mylibrery/Select'
import Loader from '../../../Recurent/Loader'
import Error from '../../../Recurent/Error'
import NewCalendar from './NewCalendar'
// style 
import { Button } from '../../../../Style/custom'
// Firebase
import { useDatabase } from 'reactfire'

const PhoneDefault = "99999";


         
const FormDiary = ({t}) => {
    const [newPhone, setNewPhone] = useState("")   
    const { tokenSession, userAuth, botUser } = useContext(AuthContext)
    const {
        User, 
        typeSelect , setTypeSelect, 
        ListTap, 
        Client, setClient, 
        name, setName, 
        mail, setMail, 
        phone, setPhone,
        ClientPhone, setClientPhone,
        time, 
        ViewButtonsClient, setViewButtonsClient, 
        ViewButtonsFrom, setViewButtonsFrom, 
        setScheduling, 
        MyTaps, setMyTaps,
        isTransfer, setTransfer,
        // ViewCalendarFrom, setViewCalendarFrom, 
        // calendarPlay,
        ListCountry, setListCountry,
        Country, setCountry,
        RefechIdSearch, setRefechIdSearch,
        RefechPhoneSearch, setRefechPhoneSearch,
        RefechMailSearch, setRefechMailSearch,
        ViewWhatsapp, setViewWhatsapp,
        ViewWhatsappRender, setViewWhatsappRender,
        showClientId, showClientIdLoading, showClientIdError, showClientIdData, showClientIdRefetch,
        showClientPhone, showClientPhoneLoading, showClientPhoneError, showClientPhoneData, showClientPhoneRefetch,
        showClientMail, showClientMailLoading, showClientMailError, showClientMailData, showClientMailRefetch,
        //showValidatePhone, validatePhoneLoading, validatePhoneError, validatePhoneData, validatePhoneRefetch
        setViewFrom, viewFrom, formPlay
    } = useContext(MessagesContext)
    // query and mutation
    const [creatClient , {loading: creatClientLoading, error: creatClientError, data: creatClientData }] = useMutation(CREATE_CLIENT)
    const [editClient , {loading: editClientLoading, error: editClientError, data: editClientData }] = useMutation(EDIT_CLIENT)
    const [creatDiary , {loading: creatDiaryLoading, error: creatDiaryError, data: creatDiaryData }] = useMutation(CREATE_DIATY_USER)
    const [creatProction , {loading: creatProctionLoading, error: creatProctionError, data: creatProctionData }] = useMutation(CREATE_PROCTION)
    const {loading: loadingShowCoury, error: errorShowCoury, data: dataShowCoury} = useQuery(SHOP_COUTRY, {variables: {token: tokenSession}})    
    const {loading: validatePhoneLoading, error: validatePhoneError, data: validatePhoneData, refetch: validatePhoneRefetch} = useQuery(VALIDATE_PHONE, {variables:{token: tokenSession, phone: (phone == '') ? PhoneDefault : phone},  fetchPolicy: 'network-only' })
    // function

    const db = useDatabase()
    // eslint-disable-next-line 

    const setClientID = (client_id) => {
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({ 'client_id': client_id})                
        
    }

    const setTags = (data) => {
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({ 'tag_color': data.color})
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({ 'tag_name': data.tag_name})
    }       
    //
    const saveClient = () => {

        let _session_id = User.session_id
        let _platform = User.platform
        const platforms = [7,12,13,14,15,16,17,18,19,20];
        if (platforms.includes(parseInt(_platform))){
            _platform = "7";
            const Meta = User.metadata !== '' ? JSON.parse(User.metadata) : null                
            if ('unique_id' in Meta){
                if (Meta.unique_id !== ''){
                    _session_id = Meta.unique_id
                }
            }    
        } 
// solo cuando el client_id no existe, permitira crearlo
        if (Client && Client.id_client > 0) {
            const variables = {
                token: tokenSession,
                id_client: Client.id_client,
                client_name: name,
                client_email: mail,
                client_phone_indicative: Country.value,
                client_phone: Country ? `${Country.value}${newPhone}` : '',
                facebook_id: generatorId(10),
                fk_state: 1,
                platform_id: parseInt(_platform),
                session_id: _session_id,
                user_id: userAuth.id_user
            }
            const [validate] = validateGlobal({
                token: 'String|Require',
                id_client: 'Int|Require',
                client_name: 'String|Require',
                client_email: 'Email',
                client_phone: 'String|Require',
                client_phone_indicative: 'String|Require',
                facebook_id: 'String|Require',
                fk_state: 'Int|Require',
                platform_id: 'Int|Require',
                session_id: 'String|Require',
                user_id: 'Int|Require',
            }, 
            variables,
            {
                message:{
                    client_email: t('messages.error.client_email'),
                    client_name: t('messages.error.client_name'),
                    client_phone: t('messages.error.client_phone'),
                    client_phone_indicative: t('messages.error.client_phone'),
                    ErrorDefault: t('messages.error.default'),
                    CorrectValidate: t('messages.error.correct')
                },
                correctValidate: false,
                position: 'bottom-right'
            })

            if(validate){
                editClient({variables, errorPolicy: 'all'})
                db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({ 'client_name': name})
            }
        } else {
            const variables = {
                token: tokenSession,
                client_name: name,
                client_email: mail,
                client_phone_indicative: Country.value,
                client_phone: Country ? `${Country.value}${newPhone}` : '',
                facebook_id: generatorId(10),
                platform_id: parseInt(_platform),
                session_id: _session_id,
                user_id: userAuth.id_user
            }
            const [validate] = validateGlobal({
                token: 'String|Require',
                client_name: 'String|Require',
                client_email: 'Email',
                client_phone: 'String|Require',
                client_phone_indicative: 'String|Require',
                facebook_id: 'String|Require',
                platform_id: 'Int|Require',
                session_id: 'String|Require',
                user_id: 'Int|Require',
            }, 
            variables,
            {
                message:{
                    client_email: t('messages.error.client_email'),
                    client_name: t('messages.error.client_name'),
                    client_phone: t('messages.error.client_phone'),
                    client_phone_indicative: t('messages.error.client_phone'),
                    ErrorDefault: t('messages.error.default'),
                    CorrectValidate: t('messages.error.correct')
                },
                correctValidate: false,
                position: 'bottom-right'
            })


            if(validate){
                creatClient({variables, errorPolicy: 'all'})
                db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({ 'client_name': name})
            }
        }
    }
    const createDiary = () => {
        const variables = {
            token: tokenSession,
            id_agent: userAuth.id_user,
            id_client: Client.id_client,
            date_scheduling: time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '',
            scheduling_description: typeSelect ? typeSelect.label : 'null',
        }
        const [validate] = validateGlobal({
            token: 'String|Require',
            id_agent: 'Int|Require',
            id_client: 'Int|Require',
            scheduling_description: 'String',
            date_scheduling: 'String|Require',
        },variables,{
            message: {
                id_client: t('messages.error.id_client'),
                scheduling_description: t('messages.error.scheduling_description'),
                date_scheduling: t('messages.error.date_scheduling'),
                ErrorDefault: t('messages.error.default'),
                CorrectValidate: t('messages.error.correct')
            },
            correctValidate: false,
            position: 'bottom-right'
        })
        if(validate) {
            creatDiary({variables, errorPolicy: 'all', fetchPolicy: 'network-only'})
        }
    }
    const searchEmail = () => {

        const  variables = {
            token: tokenSession,
            client_email: mail,
            id_bot: botUser.bot_id,
            user_id: userAuth.id_user
        }
        const [ validate ] = validateGlobal({
            token: 'String|Require',
            client_email: 'String|Require',
            id_bot: 'Int|Require',
            user_id: 'Int|Require'
        },
        variables,
        {
            message: {
                client_email: t('messages.error.client_email'),
                ErrorDefault: t('messages.error.default'),
                CorrectValidate: t('messages.error.correct') 
            },
            correctValidate: false,
            position: 'bottom-right'
        })

        if(validate){
            setViewButtonsClient(false)
            setRefechMailSearch(true)
            showClientMail({variables, errorPolicy: 'all', fetchPolicy: 'network-only'})
        }
    }
    const searchPhone = () => {        
        setPhone(newPhone)
        const  variables = {
            token: tokenSession,
            client_phone: Country ? `${Country.value}${newPhone}` : '',
            id_bot: botUser.bot_id,
            user_id: userAuth.id_user
        }
        const [ validate ] = validateGlobal({
            token: 'String|Require',
            client_phone: 'String|Require',
            id_bot: 'Int|Require',
            user_id: 'Int|Require'
        },
        variables,
        {
            message: {
                client_phone: t('messages.error.client_phone'),
                ErrorDefault: t('messages.error.default'),
                CorrectValidate: t('messages.error.correct') 
            },
            correctValidate: false,
            position: 'bottom-right'
        })

        if(validate){
            setViewButtonsClient(false)
            setClient(null)
            setRefechPhoneSearch(true)
            showClientPhone({variables, errorPolicy: 'all', fetchPolicy: 'network-only'})
        }
    }

    useEffect(() => {
        if(creatDiaryData){
            if(creatDiaryData.createScheduling){
                toastMessage({message: t('messages.error.diarySuccess')})
                setScheduling(creatDiaryData.createScheduling)
            }        
        }
    }, [creatDiaryData])


    useEffect(() => {
        if(creatProctionData){
            if(creatProctionData.createProspecting){
                toastMessage({message: t('messages.error.tagSuccess')})
                setRefechIdSearch(true)      
                showClientId({variables:{token: tokenSession, id_bot: botUser.bot_id, id_client: Client ? Client.id_client ? parseInt(Client.id_client) : parseInt(User.client_id) : parseInt(User.client_id)}}, {fetchPolicy: 'network-only'})
                  
            }        
        }
    }, [creatProctionData])

    useEffect(() => {
        if(creatClientData){
            if(creatClientData.createClient){
                console.warn(creatClientData.createClient)
                if (creatClientData.createClient.code == 409){
                    if (User.platform == 4){
                        toastMessage({message: t('messages.error.client_email_duplicate'),error:true})        
                    }else{
                        toastMessage({message: t('messages.error.client_duplicate'),error:true})        
                    }                    
                }else{
                    setClient({ id_client: creatClientData.createClient.id_client})
                    setClientID(creatClientData.createClient.id_client)
                    toastMessage({message: t('messages.error.clientSuccess')})                       
                }
                
            }
        }
    }, [creatClientData])
    
    useEffect(() => {
        if(editClientData){
            if(editClientData.editClient){
                if (editClientData.editClient.code == 209){
                    toastMessage({message: t('messages.error.client_email_duplicate'),position:"bottom-right",error:true})
                }else{
                    toastMessage({message: t('messages.error.clientSuccess')})    
                }
                
            }
        }
    }, [editClientData])

    useEffect(() => {
        if(showClientMailData && RefechMailSearch) {
            if(showClientMailData.getClientDataByEmail){
                if(showClientMailData.getClientDataByEmail.data){

                    if (User.platform == 4){
                        toastMessage({message: t('messages.error.client_email_duplicate'),position:"bottom-right",error:true})  
                    }else{
                        setClient(showClientMailData.getClientDataByEmail.data.infoClient)
                        setMyTaps(showClientMailData.getClientDataByEmail.data.tagsClient)                        
                        setRefechMailSearch(false)
                    }                    

                } else {
                    setMyTaps([])
                    setTypeSelect(null)
                }                
            }
        }
    }, [showClientMailData, RefechMailSearch])

    useEffect(() => {
        if(showClientPhoneData && RefechPhoneSearch) {
            if(showClientPhoneData.getClientDataByPhone){
                if(showClientPhoneData.getClientDataByPhone.data){
                    setClient(showClientPhoneData.getClientDataByPhone.data.infoClient)
                    setMyTaps(showClientPhoneData.getClientDataByPhone.data.tagsClient)
                    //setPhone(showClientPhoneData.getClientDataByPhone.data.client_phone)
                } else {
                    setTypeSelect(null)
                    setMyTaps([])
                }
                setRefechPhoneSearch(false)
            }
        }
    }, [showClientPhoneData, RefechPhoneSearch])



    useEffect(() => {        
        if (phone !== '' && phone !== '+'){
            if(validatePhoneData && ViewWhatsappRender) {
                if(validatePhoneData.validCountryMobilePhone) {
                    if(validatePhoneData.validCountryMobilePhone.data) {
                        if (validatePhoneData.validCountryMobilePhone.data.phone_number){  
                            if (validatePhoneData.validCountryMobilePhone.data.code !== 'NAN' && !validatePhoneLoading){
                                setNewPhone(validatePhoneData.validCountryMobilePhone.data.phone_number)
                                setCountry({value: validatePhoneData.validCountryMobilePhone.data.dial_code, label: `${validatePhoneData.validCountryMobilePhone.data.flag} ${validatePhoneData.validCountryMobilePhone.data.dial_code}` })
                                if (User.platform == 4){
                                    setViewWhatsapp(true)
                                }else{
                                   setViewWhatsapp(false)  
                                }                                   
                                setViewWhatsappRender(false)
                                setViewButtonsClient(false)
                            } 
                        }
                    }
                }
            }            
        }else{
            if (!Client){
                setNewPhone('')                
            }
        }
    }, [validatePhoneData,ViewWhatsappRender,validatePhoneLoading])


    useEffect(() => {
        if (isTransfer){
            setName('')
            setMail('')
            setPhone('')
            setMyTaps([])
            setCountry(null)
            setTypeSelect(null)
            setRefechIdSearch(false)
            setTransfer(false)
            setViewFrom(true)
            formPlay(true)
        }
    },[isTransfer])    


    useEffect(() => {
            if(showClientIdData && RefechIdSearch) {
                if(showClientIdData.getClientData){
                    if(showClientIdData.getClientData.data){

                        if ( showClientIdData.getClientData.data.hasOwnProperty('tagsClient') ){
                            if ( showClientIdData.getClientData.data.tagsClient !== null){
                                setTags(showClientIdData.getClientData.data.tagsClient[0])                                   
                            }                          
                        }
                        setClient(showClientIdData.getClientData.data.infoClient)
                        setMyTaps(showClientIdData.getClientData.data.tagsClient)                                               
                        setPhone(showClientIdData.getClientData.data.infoClient.client_phone)

                        
                    } else {
                        setName('')
                        setMail('')
                        setNewPhone('')
                        setMyTaps([])
                        setCountry(null)
                        setTypeSelect(null)
                    }
                    setRefechIdSearch(false)
                }        
            }
    }, [showClientIdData, RefechIdSearch])

    useEffect(() => {
        if(Client){
            setViewButtonsFrom(false)
            if(Client.client_name) setName(Client.client_name)
            if(Client.client_email) setMail(Client.client_email)
            if(ViewWhatsapp===false) {
                if(Client.client_phone_indicative) setCountry({value: Client.client_phone_indicative, label: Client.client_phone_indicative})
                if(Client.client_phone) setNewPhone(Client.client_phone.replace(Client.client_phone_indicative,''))
            }

            if(ClientPhone && User.platform) setViewWhatsapp(true)
            if(Client.tags) {
                if(Client.tags.length > 0) {
                    const tag = Client.tags.shift()
                    if(tag) {                            
                        setTypeSelect({value: tag.tag_id, label: tag.tag_name}) 
                    }
                }
            }
        }
    }, [Client])

    useEffect(() => {
        if(dataShowCoury){
            if(dataShowCoury.getCountriesData){
                if(dataShowCoury.getCountriesData.data){
                    setListCountry(dataShowCoury.getCountriesData.data)
                }
            }
        }
    }, [dataShowCoury])


    if(creatDiaryLoading || creatClientLoading || showClientMailLoading || showClientPhoneLoading 
        || editClientLoading || creatProctionLoading ||loadingShowCoury || showClientIdLoading || validatePhoneLoading )  return <Loader height='50%' /> 
    if(creatDiaryError) return <Error error={t('messages.error.creatDiaryError') } />
    if(creatClientError) return <Error error={t('messages.error.creatClientError') } />
    if(editClientError) return <Error />
    if(showClientIdError) return <Error />
    if(showClientMailError) return <Error />
    if(showClientPhoneError) return <Error />
    if(creatProctionError) return <Error />
    if(errorShowCoury) return <Error />
    if(validatePhoneError) return <Error />


    return(
        <>
            <div className='items'>
                {<div className='contSelGrup'>
                    <label className='label' >{t('messages.from.userPhone')}</label>
                    <div className='grupItem'>
                        <Select 
                            placeholder='...'
                            className='selectFlag'
                            disabled={ViewWhatsapp}
                            value={Country}
                            heightOption='auto'
                            onChange={e => setCountry(e)}
                            options={(Array.isArray(ListCountry) && ListCountry.length != 0 )  ? ListCountry.map(country => ({value:country.code, label:`${country.flag} ${country.code}` })):[]}
                        />
                        <input className='input' placeholder={t('messages.from.userPhone')} value={newPhone} maxLength={20} disabled={ViewWhatsapp ? ViewWhatsapp : Country===null}
                        onChange={ e => setNewPhone(e.target.value)} onKeyPress={e =>(e.which===20) } />
                        <button className={`${Button.default}`} onClick={()=> searchPhone() } disabled={ViewWhatsapp} >
                            <FontAwesomeIcon icon={faSearch} /> 
                        </button>
                    </ div>
                </div>}
                {<div className='contSelGrup' >
                    <label className='label' >{t('messages.from.userName')}</label>
                        <input className='input' placeholder={t('messages.from.userName')} maxLength={40} value={name} 
                        onChange={ e => setName(e.target.value)} />
                </div>}
                {<div className='contSelGrup'>
                    <label className='label' >{t('messages.from.userMail')}</label>
                    <div className='grupItem'>
                        <input className='input' placeholder={t('messages.from.userMail')} value={mail} maxLength={70}
                        onChange={ e => setMail(e.target.value)} onKeyPress={e =>(e.which===13) } type='email' />
                        <button className={`${Button.default}`} onClick={()=> searchEmail() } >
                            <FontAwesomeIcon icon={faSearch} /> 
                        </button>
                    </div> 
                </div>}                
                <button className={`${Button.primarySmall} justify`} onClick={()=> saveClient() } disabled={ViewButtonsClient} >{t('messages.from.createOrUpdate')}</button>
            </div>
            <div className='items'>
                <div className='contSelGrup' style={{paddingTop: '10px'}}>
                    <NewCalendar createDiary={createDiary} creatDiaryData={creatDiaryData} />
                </div>
            </div>
            <div className='items'>
                <div className='conTag'>
                    {(Array.isArray(MyTaps) && MyTaps.length != 0 )  ? MyTaps.map((tag, i) => <div key={i} className='tag' style={{backgroundColor: tag.color}}>{tag.tag_name}</div>) : []}
                </div>
                <div className='contSelGrup'>
                    <Select placeholder={t('messages.from.tapDescription')} 
                            value={typeSelect} 
                            disabled={ViewButtonsFrom}
                            heightOption='auto'
                            onChange={(e) =>{
                                const variables = { token: tokenSession, bot_id: botUser.bot_id, client_id: Client.id_client, tag_id: e.value, agent_id: userAuth.id_user }
                                creatProction({variables})
                                setTypeSelect(e)}} 
                            options={ListTap?ListTap.map(option => ({value: option.tag_id, label:option.tag_name})):[]}
                    />
                </div>
            </div>
        </> 
    )
}
export default withTranslation()(FormDiary)