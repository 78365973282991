import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const PieChart = ({t, botServed, agentServed}) => {

  return (
    <div>
      <Doughnut
      data={{
      labels: [t('dashboard.automaticBotLabel'), t('dashboard.agentLabel')],
      datasets: [
        {
          label: '# of votes',
          data: [botServed, agentServed],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }}
      height={450}
      width={100}
      options={{
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontSize: 25,
          },
        },
        }}
      />
    </div>
  )
}

export default PieChart