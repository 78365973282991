import { gql } from "apollo-boost"

export const CREATE_TEMPLATE = gql`
mutation createTemplate(
  $token: String!
  $template: String!
  $template_name: String!
  $fk_company: Int!
  $template_type: Int!
) {
  createTemplate(
    inputTemplate: { token: $token, template: $template, template_name: $template_name, fk_company: $fk_company, template_type: $template_type}
  ) {
    code
    message
  }
}
`

export const DELELE_TEMPLATE = gql`
mutation deleteTemplate(
  $token: String!
  $id_agent_template: Int!
) {
  deleteTemplate(
    inputDelete: { token: $token, id_agent_template: $id_agent_template }
  ) {
    code
    message
  }
}
`

export const UPDATE_TEMPLATE = gql`
mutation updateTemplate(
  $token: String!
  $id_agent_template: Int!
  $fk_company: Int!
  $template: String!
  $template_name: String!
  $template_type: Int!
) {
  updateTemplate(
    inputUpdate: { token: $token, id_agent_template: $id_agent_template, fk_company: $fk_company, template: $template, template_name: $template_name, template_type: $template_type}
  ) {
    code
    message
  }
}
`

export const GET_TEMPLATES = gql`
query listTemplate(
  $token: String!
  $fk_company: Int!
) {
  listTemplate(
    inputList: { token: $token, fk_company: $fk_company}
  ) {
    code
    message
    data {templates{id_agent_template, template, template_name, fk_company, template_type}}
  }
}
`

export const GET_VARIABLES = gql`
query getEntitiesBot(
  $token: String!
  $id_bot: Int!
) {
  getEntitiesBot(
    inputEntitieBotId: { token: $token, id_bot: $id_bot }
  ) {
    code    
    message
    data
  }
}
`
