/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useEffect } from 'react'
import { useLocation, useHistory, Redirect } from "react-router-dom"
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
import { useFirebaseApp } from 'reactfire'
import 'firebase/auth'
// context
import { AuthContext } from '../Context/Auth'
import { TopicProvider } from '../Context/Topic'
import {PersonalizationProvider} from '../Context/Personalization'
import {TemplatesProvider} from '../Context/Templates'
import { MessagesProvider } from '../Context/Messages'
import { MessagesHistoryProvider } from '../Context/MessagesHistory'
import { ReportProvider } from '../Context/Repor'
import { ClientProvider } from '../Context/Client'
import { AgentProvider } from '../Context/Agent'
import { CustomBarProvider } from '../Context/CustomBar'
import { DashboardProvider } from '../Context/Dashboard'
import { SchedulesBySkillProvider } from '../Context/SchedulesBySkill'
// query
import { TOKEN_AUTH, FIREBASE_AUTH, CHECK_BOT_USER, ASINATE_BOT_USER } from '../Query/Auth'
// component
import Routers from './Routers'
import HeaderTop from './Panel/HeaderTop'
import MenuVertical from './Panel/MenuVertical'
import Loader from './Recurent/Loader'
import Error from './Recurent/Error'
import { toastMessage } from './Recurent/ValidateGlobal'
// style
import { Cont } from '../Style/custom'
import { BotConfigProvider } from '../Context/BotConfig'

const Aplication = ({t}) => {
    // context
    const { auth, tokenSession, tokenFirebase, ErrorBot, setErrorBot, setTokenFirebase, setTokenAuth, userAuth, setBotsUser, viewMenu, setviewMenu, play, playButton } = useContext(AuthContext)
    // firebase
    const firebase = useFirebaseApp()
    // query
    const { loading, error, data } = useQuery(TOKEN_AUTH);
    const [authFire, { loading: loadingFireBase, error: errorFireBase, data: dataFireBase }] = useLazyQuery(FIREBASE_AUTH);
    const [getCheckBot, { loading: loadingCheckBot, error: errorCheckBot, data: dataCheckBot, refetch: refetchCheckBot }] = useLazyQuery(CHECK_BOT_USER);
    const [autoAsinateBotUser, { loading: loadingAsinateBotUser, error: errorAsinateBotUser, data: dataAsinateBotUser }] = useMutation(ASINATE_BOT_USER);
    // router
    const location = useLocation();
    const history = useHistory();
    const loginFirebase = async () => await firebase.auth().signInWithCustomToken(tokenFirebase).then(()=>console.log('login firebase')).catch(e => console.log(e))
    // function
    const redirect = () => {
        switch (userAuth.name_rol) {
            case 'Administrador': {
                return '/dashboard'
            }
            default: {
                return '/messages'
            }
        }
    }

    useEffect(() => {
        const from = auth ? location.pathname !== '/login' && location.pathname !== '/' 
                        ? ({ from: location }) : ({ from: { pathname: redirect() } })
                        : ({ from: { pathname: '/login' } }) 
        history.push(from.from.pathname);
        // console.log(userAuth)
    }, [auth])

    useEffect(() => {     
        if(auth){
            toastMessage({ message: `${t('auth.welcome')} ${userAuth.user_name}` })
            getCheckBot({variables:{token: tokenSession, id_user: userAuth.id_user}})
        }
    }, [auth])

    useEffect(() => {       
        if(auth){
            if(userAuth.name_rol === 'Agente'){
                setviewMenu(!viewMenu)
                play(!viewMenu)
                playButton(!viewMenu)
            }
        }
    }, [auth])

    useEffect(() => {
        if(dataCheckBot){
            if(dataCheckBot.checkBotsState){
                if(dataCheckBot.checkBotsState.bots){
                    setBotsUser(dataCheckBot.checkBotsState.bots)
                } else {
                    if(userAuth){
                        if(userAuth.bots){
                            if(userAuth.bots.length > 0) {
                                autoAsinateBotUser({variables:{ 
                                    token: tokenSession, 
                                    id_user: userAuth.id_user, 
                                    id_company: userAuth.id_company, 
                                    id_bot: userAuth.bots.shift().id_bot,
                                    activate: true
                                }})
                                setErrorBot(false)
                            } else {
                                setErrorBot(true)
                            }                            
                        }
                    }
                }
            }
        }
    }, [dataCheckBot])

    useEffect(() => {
        if(dataAsinateBotUser){
            if(dataAsinateBotUser.asignbot){
                refetchCheckBot()
            }
        }
    }, [dataAsinateBotUser])

    useEffect(() => {          
        if(auth){
            authFire({variables:{token: tokenSession}})
        }
    }, [auth])

    useEffect(() => {
        if(tokenFirebase){
            loginFirebase()
        }
    }, [tokenFirebase])

    useEffect(() => {
        if(data){
            if(data.token_login){
                setTokenAuth(data.token_login.token)
            }
        }
    }, [data])

    useEffect(() => {
        if(dataFireBase){
            if(dataFireBase.loginFireBase){
                setTokenFirebase(dataFireBase.login_firebase.token)
            }
        }
    }, [dataFireBase])

    if(loading || loadingFireBase || loadingCheckBot || loadingAsinateBotUser) return <Loader />

    if(error || errorFireBase || errorCheckBot || errorAsinateBotUser ){ 
        localStorage.clear()
        sessionStorage.clear()
        // return <Error />
    }

    if(ErrorBot){ 
        localStorage.clear()
        sessionStorage.clear()
        return <Error error={t('errorBot')} />
    }
    if(data)
    
    /* Se agrega restriccion para que en pantalla de login no se carguen componentes innecesarios*/

    return location.pathname==='/'? (<div> <Redirect to="/dashboard" /> </div> ) :(
        <div className={auth? Cont.panel : Cont.login}>
            {auth ? <HeaderTop /> : ''}
            {auth ? <MenuVertical /> : ''}
            {auth ?
            <MessagesHistoryProvider>
                <MessagesProvider>
                    <CustomBarProvider>
                        <ReportProvider>
                            <TemplatesProvider>
                                <PersonalizationProvider>
                                    <BotConfigProvider>
                                        <TopicProvider>
                                            <ClientProvider>
                                                <AgentProvider>
                                                    <DashboardProvider>
                                                        <SchedulesBySkillProvider>
                                                            <Suspense fallback={<Loader height='100%' />}>
                                                                <Routers />
                                                            </Suspense>
                                                        </SchedulesBySkillProvider>
                                                    </DashboardProvider>
                                                </AgentProvider>
                                            </ClientProvider>
                                        </TopicProvider>
                                    </BotConfigProvider>
                                </PersonalizationProvider>
                            </TemplatesProvider>
                        </ReportProvider>
                    </CustomBarProvider>
                </MessagesProvider>
            </MessagesHistoryProvider>
            :
            <Routers></Routers>
            }
        </div>
    )
}

export default withTranslation()(Aplication)
