import React from 'react'

import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import ic_users from '../../../../Asset/img/profile/ic_users.png'
import ic_user_conversation from '../../../../Asset/img/profile/ic_user_conversation.png'
import ic_user_no_conversation from '../../../../Asset/img/profile/ic_user_no_conversation.png'


const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      height: 270,
      padding: 20,
    },
    title: {
        fontSize: 40,
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    cardIcon: {
        float: 'left',
        height: 40,
        width: 80,
        padding: 10,
    },
    panel: {
        margin: 5,
        fontSize: 20,
    },
    divActive:{
        float: "left",
        width:'33%',
        height: 200,
        textAlign: 'center',
    },
    divConversations:{
        float: "left",
        width:'33%',
        height: 200,
        textAlign: 'center',
    },
    divNoConversations:{
        float: "left",
        width:'33%',
        height: 200,
        textAlign: 'center',
    },
    iconSize: {
        width: 100,
        height: 70,
        paddingTop: 2,
      },
    value: {
        fontSize: 35,
        padding: 10
    },
    marginText:{
        marginBottom: 10
    }
  }));

const CardAgentAvailability = ({t,title, activeAgents, busyAgents, freeAgents})=> {

    const classes = useStyles();

    return (
        <Card className={classes.root} style={{margin:'10px'}}>
            <Typography className={classes.title} color="textPrimary" gutterBottom>
                {title || "Title"}
            </Typography>
            <div className={classes.panel}>
                <div className={classes.divActive}>
                    <div className={classes.text}>
                        <h3 className={classes.marginText}>{t('dashboard.totalActiveAgentsLabel')}</h3>
                    </div>
                    <div >
                        <img className={classes.iconSize} src={ic_users} alt="ic_users"/>
                    </div>
                    <div className={classes.value}>
                        <h3>{activeAgents || "0"}</h3>
                    </div>
                </div>
                <div className={classes.divConversations}>
                    <div className={classes.text}>
                        <h3 className={classes.marginText}>{t('dashboard.agentsInConversationlabel')}</h3>
                    </div>
                    <div >
                        <img className={classes.iconSize} src={ic_user_conversation} alt="ic_users"/>
                    </div>
                    <div className={classes.value}>
                        <h3>{busyAgents | "0"}</h3>
                    </div>
                </div>
                <div className={classes.divNoConversations}>
                <div className={classes.text}>
                        <h3 className={classes.marginText}>{t('dashboard.agentsWithoutConversationLabel')}</h3>
                    </div>
                    <div >
                        <img className={classes.iconSize} src={ic_user_no_conversation} alt="ic_users"/>
                    </div>
                    <div className={classes.value}>
                        <h3>{freeAgents | "0"}</h3>
                    </div>
                </div>
            </div>
        </Card>
      )
}

export default withTranslation()(CardAgentAvailability)