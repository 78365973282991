import React, {createContext, useState, useRef, useContext} from 'react'
import {useAnimate} from 'react-simple-animate'
import {useQuery, useLazyQuery} from '@apollo/react-hooks'
// context
import {AuthContext} from '../Auth'
// query
import { LIST_CLIENT, SHOW_CLIENT_ID } from '../../Query/Client'

const ClientContext = createContext()
const { Provider, Consumer } = ClientContext

const ClientProvider = ({children}) => {
    const initQuery = 50
    const {tokenSession, botUser, userAuth} = useContext(AuthContext)
    const {loading: loadingListClient, error: errorListClient, data: dataListClient, refetch: refetchListClient} = useQuery(LIST_CLIENT, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null , max_count: initQuery, min_count: 0, user_id: userAuth ? userAuth.id_user : 0 }}, {fetchPolicy: 'network-only'})
    const [showClientId, {loading: loadingShowClientId, error: errorShowClientId, data: dataShowClientId, refetch: refetchShowClientId}] = useLazyQuery(SHOW_CLIENT_ID)
    const [ViewFrom, setViewFrom] = useState(false)
    const [Clients, setClients] = useState([])
    const [Hash, setHash] = useState(null)
    const [HasMore, setHasMore] = useState(true)
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [Tag, setTag] = useState(null)
    const [Country, setCountry] = useState(null)
    const [Res, setRes] = useState(Clients ? Clients.length : 0)
    const [FocusCalendar, setFocusCalendar] = useState(true)
    const [FocusTimeLine, setFocusTimeLine] = useState(false)
    const [ViewClientTag, setViewClientTag] = useState(true)
    const [NextData, setNextData] = useState(false)
    const [AvailableData, setAvailableData] = useState(0)
    const [Client, setClient] = useState(null)
    const [Pataform, setPataform] = useState(null)
    const [StartDate, setStartDate] = useState(null)
    const [EndDate, setEndDate] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null) 
    const [TotalData, setTotalData] = useState(initQuery)
    const ScrollerRef = useRef(null)
    const [NewClient, setNewClient] = useState(false)

    const { play: formPlay , style: formStyle } = useAnimate({
        start: { width: '270px', marginRight: '15px', padding: '10px', borderWidth: '1px', opacity: 1 },
        end: { width: '0px', marginRight: '0px', padding: '0px', borderWidth: '0px', opacity: 0 },
        duration: 1
    });

    const { play: FocusCalendarPlay, style: FocusCalendarStyle } = useAnimate({
        start: {gridArea: '1 / 2 / 4 / 4', width: 'scale(1)'},
        end: {gridArea: 'auto', transform: 'scale(0.97)'},
        duration: 1
    })
    const { play: FocusUserPlay, style: FocusUserStyle } = useAnimate({
        start: {gridArea: 'auto', transform: 'scale(0.97)'},
        end: {gridArea: '1 / 2 / 4 / 4', transform: 'scale(1)'},
        duration: 1
    })
    const { play: FocusLineTimePlay, style: FocusLineTimeStyle } = useAnimate({
        start: {gridArea: 'auto', transform: 'scale(0.97)'},
        end: {gridArea: '1 / 2 / 4 / 4', transform: 'scale(1)'},
        duration: 1
    })

    return (<Provider
        value={{
            ViewFrom, setViewFrom,
            Clients, setClients,
            NextData, setNextData,
            formPlay, formStyle,
            HasMore, setHasMore,
            ScrollerRef,
            Res, setRes,
            Hash, setHash,
            FocusCalendar, setFocusCalendar,
            FocusTimeLine, setFocusTimeLine,
            FocusCalendarPlay, FocusCalendarStyle,
            FocusUserPlay, FocusUserStyle,
            FocusLineTimePlay, FocusLineTimeStyle,
            ViewClientTag, setViewClientTag,
            phone, setPhone,
            name, setName,
            mail, setMail,
            Tag, setTag,
            AvailableData, setAvailableData,
            Country, setCountry,
            Client, setClient,
            Pataform, setPataform,
            StartDate, setStartDate,
            EndDate, setEndDate,
            focusedInput, setFocusedInput,
            TotalData, setTotalData,
            loadingListClient, errorListClient, dataListClient, refetchListClient,
            NewClient, setNewClient,
            showClientId, loadingShowClientId, errorShowClientId, dataShowClientId, refetchShowClientId
        }}
    >
        {children}
    </Provider>)
}

export {ClientProvider, Consumer as ClientConsumer, ClientContext}