import {style} from "typestyle"

// color
import {Color} from './custom'

export const login = {
    cont: style({
        width: '450px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }),

    logo: style({
        width: '90px',
        height: '90px',
        marginBottom: '30px'
    }),

    form: style({
        width: '100%',
        backgroundColor: Color.white,
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }),

    title: style({
        fontSize: '23px',
        fontWeight: 500,
        color: Color.primary,
        marginBottom: '20px',
        marginTop: '5px'
    }),

    contInput: style({
        width: '100%',
        backgroundColor: Color.lint,
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 15px',
        marginBottom: '15px',
        borderRadius: '5px',
        justifyContent: 'space-between'
    }),

    icon: style({
        color: Color.primary
    }),

    input: style({
        flex: '1 1 85%',
        marginLeft: '10px',
        backgroundColor: Color.lint,
        borderWidth: '0px',

        $nest: {
            '&:focus':{
                outline: 'none'
            },
            '&::placeholder':{
                backgroundColor: Color.lint
            },
            '&:visited':{
                backgroundColor: Color.lint
            },
            '&:active':{
                backgroundColor: Color.lint
            },
            '&:-internal-autofill-selected':{
                backgroundColor: `${Color.lint} !important`
            }
        }
    }),

    button: style({
        marginTop: '10px',
        marginBottom: '20px'
    })
}
