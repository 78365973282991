import React from 'react'

import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';

import sa_happy from '../../../Asset/img/profile/sa_happy.png'
import sa_neutral from '../../../Asset/img/profile/sa_neutral.png'
import sa_sad from '../../../Asset/img/profile/sa_sad.png'

const useStyles = makeStyles((theme) => ({
    divValue:{
      fontSize: 80,
      height: 80,
      float: 'left'
    },
    sentimentIcon: props => ({
      width: props.size,
      height: props.size
      
    }),
  }));

const SentimentIcon = ({t, satisfactionLevel, size})=> {

    let iconSize = size ? size : 50;
    const classes = useStyles({size: iconSize});

    if (satisfactionLevel != null){
      if (satisfactionLevel < -0.3) {
        return (<img className={classes.sentimentIcon} src={sa_sad} alt="icon_header_card"/>)
      } else if (satisfactionLevel > 0.3) {
        return (<img className={classes.sentimentIcon} src={sa_happy} alt="icon_header_card"/>)
      } else {
        return (<img className={classes.sentimentIcon} src={sa_neutral} alt="icon_header_card"/>)
      }
    } else {
      return (<div className={classes.divValue}><h2>--</h2></div>)
    }
    
}

export default withTranslation()(SentimentIcon)