import {gql} from 'apollo-boost'

export const GET_TEMPLATES = gql`
query getTemplates(
  $token: String!
  $session_id: String!
  $template_type : Int! 
  $bot_id: Int!
  $agent_id: Int!
) {
  getTemplates(
    inputSession: { token: $token, session_id: $session_id, template_type: $template_type, bot_id: $bot_id, agent_id: $agent_id }
  ) {
    code    
    message
    data{templates{id,name,msj,template_type}}
  }
}
`