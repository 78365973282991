import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import En from './Translations/en.json';
import Es from './Translations/es.json';
import EsCO from './Translations/esCO.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    'en': {
        translation: {
            ...En
        }
    },
    'es': {
        translation: {
            ...Es
        }
    },
    "es-CO": {
        translation: {
            ...EsCO
        }
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang')!=='' ? JSON.parse(localStorage.getItem('lang')) : 'en',

    keySeparator: '.', // we do not use keys in form messages.welcome
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;