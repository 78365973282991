import React, { createContext, useState } from 'react'

const PersonalizationContext = createContext()
const { Consumer, Provider } = PersonalizationContext

const PersonalizationProvider = ({children}) =>{
    const [MaxBot, setMaxBot] = useState(null)
    const [MaxUser, setMaxUser] = useState(null)
    const [maxAgentConv, setMaxAgentConv] = useState(10)
    const [maxAgentValidity, setmaxAgentValidity] = useState(10)
    const [maxQueueValidity, setmaxQueueValidity] = useState(120)
    const [alertTime, setAlertTime] = useState(0)
    const [analizeSentiment, setAnalizeSentiment] = useState(false)
    const [monthlyTotal, setMonthlyTotal] = useState("0")
    const [samplePercentage, setSamplePercentage] = useState("0")
    const [isBotSessionValidity, setIsBotSessionValidity] = useState(false)
    const [botSessionValidity, setBotSessionValidity] = useState(0)
    const [botSessionMessage, setBotSessionMessage] = useState("")
    return(<Provider value={{
        maxAgentConv, setMaxAgentConv,
        maxAgentValidity, setmaxAgentValidity,
        maxQueueValidity, setmaxQueueValidity,
        MaxBot, setMaxBot,
        MaxUser, setMaxUser,
        alertTime, setAlertTime,
        analizeSentiment, setAnalizeSentiment,
        monthlyTotal, setMonthlyTotal,
        samplePercentage, setSamplePercentage,
        isBotSessionValidity, setIsBotSessionValidity,
        botSessionValidity, setBotSessionValidity,
        botSessionMessage, setBotSessionMessage
    }}>
        {children}
    </Provider>)
}

export { PersonalizationProvider, Consumer as PersonalizationConsumer, PersonalizationContext }