/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
// style
import { Color, Cont, Button } from '../../../Style/custom'
import { login } from '../../../Style/auth'
// img
import icon from '../../../Asset/img/login/icon.png'

const Loader = ({t, error, height, action}) => {
    return(
        <div className={Cont.loader} style={{height}}>
            <img className={login.logo} src={icon} style={{marginTop: '10px'}} />
            <h1 style={{color: Color.primary, marginBottom: 0}}>{t('title')}</h1>
            <h3 style={{color: Color.primary, marginTop: 0, textAlign: 'center' }}>{error || t('error')}</h3>
            <button className={Button.primary} 
                    style={{marginBottom: '10px'}}
                    onClick={() => action !== undefined ? action() : window.location.reload(false)} ><FontAwesomeIcon icon={faRedoAlt} /> {t('reload')}</button>
        </div>
    )
}
export default withTranslation()(Loader)