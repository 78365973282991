/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// context
import { ClientContext } from '../../../../Context/Client'
import { AuthContext } from '../../../../Context/Auth'
//  componeet
import Loader from '../../../Recurent/Loader'


//Prueba

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#0095ae',
  },
}));


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);


const List = ({t}) => {
    const classes = useStyles();
    const {tokenSession, botUser, userAuth} = useContext(AuthContext)
    const { 
        Clients, setClients,
        NextData, setNextData,
        HasMore, setHasMore,
        ScrollerRef,
        name, 
        mail, 
        Country,
        phone, 
        Tag, 
        StartDate,
        EndDate, 
        setFocusCalendar,
        FocusCalendarPlay,
        FocusUserPlay, 
        FocusLineTimePlay, 
        setFocusTimeLine,
        dataListClient,
        AvailableData, setAvailableData,
        TotalData, setTotalData,
        loadingListClient, refetchListClient,
        showClientId, dataShowClientId, refetchShowClientId,
        creatClientData,
        NewClient
    } = useContext(ClientContext)
    
    const callReferch = () => {
        if(TotalData >= AvailableData) {
            setHasMore(false)
        } else {
            const variables = {
                token: tokenSession, 
                max_count: TotalData + 10,
                min_count: TotalData,
                id_bot: botUser.bot_id, 
                client_name: name, 
                client_email: mail, 
                client_phone: Country ? `${Country.value}${phone}` : '', 
                id_tags: Tag ? `${Tag.value}` : '', 
                user_id: userAuth.id_user,
                start_date: StartDate ? StartDate.format('YYYY-MM-DD') : '' ,
                end_date: EndDate ? EndDate.format('YYYY-MM-DD') : ''
            }
            setNextData(true)
            refetchListClient(variables)
            setTotalData(TotalData + 10)
        }
    }
    
    const focusClient = Client => {
        focusCalendar()
        if(dataShowClientId) {
            refetchShowClientId({token: tokenSession, id_bot: botUser.bot_id, id_client: parseInt(Client.id_client)})
        } else {
            showClientId({variables:{token: tokenSession, id_bot: botUser.bot_id, id_client: parseInt(Client.id_client)}})
        }   
    }

    const focusCalendar = () => {
        setFocusCalendar(true)
        FocusCalendarPlay(false)
        FocusUserPlay(false)
        FocusLineTimePlay(false)
        setFocusTimeLine(false)
    }

    useEffect(() => {
        if(dataListClient) {
            if(dataListClient.getClientDataByLike) {
                if(dataListClient.getClientDataByLike.data){
                    if(NextData) {
                        setClients([...Clients, ...dataListClient.getClientDataByLike.data.dataClient])  
                    } else  {
                        setClients(dataListClient.getClientDataByLike.data.dataClient)
                    }
                    setAvailableData(dataListClient.getClientDataByLike.data.availableData)
                } else {
                    setClients([])
                }
            }
        }
    }, [dataListClient])

    useEffect(() => {
        if(TotalData >= AvailableData) {
            setHasMore(false)
        } else{
            setHasMore(true)
        } 
    }, [AvailableData, TotalData])

    useEffect(() => {
        if(ScrollerRef) {
            if(ScrollerRef.current) {
                ScrollerRef.current.addEventListener('scroll', (e)=> {
                    const { offsetHeight, scrollTop, scrollHeight } = e.target
                    if(offsetHeight + scrollTop === scrollHeight){
                        if(HasMore) {
                            callReferch()
                        }
                    }
                })
            }
        }
    }, [ScrollerRef, loadingListClient, AvailableData])

    return(
        <div ref={ScrollerRef} className='listItems'>
            {Clients.map((Client, i) => (             
             <Link to={`#${Client.id_client}`}  key={i} onClick={()=> focusClient(Client)} >   
                <Card className={classes.root} style={{margin:'10px'}}>
                      <CardHeader
                        avatar={
                     
                                        
                              <Avatar aria-label="recipe" className={classes.avatar}>
                                {(Client.client_name.indexOf(" ") > 1) ? `${Client.client_name.substr(0,1)}${Client.client_name.substr(Client.client_name.indexOf(" ")+1,1)}` : `${Client.client_name.substr(0,1)}`}                        
                               </Avatar>



                        }  

                        title={Client.client_name}
                        subheader={`${Client.client_phone.trim()} | ${Client.client_email.trim()}`}                        
                        />



                        </Card>
                    </Link>

            ))}
            {HasMore ? <div className='item loader'><Loader height='100%' size={30} styleImg={{width:'50px', height: '50px'}} /> </div> : ''}
        </div>
    )
}

export default withTranslation()(List)