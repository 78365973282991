/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faSearch } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DateRangePicker, isInclusivelyAfterDay  } from 'react-dates'
import defaultPhrases from 'react-dates/lib/defaultPhrases'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/es'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
// context
import { AuthContext } from '../../../Context/Auth'
import { ReportContext } from '../../../Context/Repor'
import { TopicContext } from '../../../Context/Topic'
// function
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
// componet
import Conter from '../../Recurent/Conter'
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import Minimap from '../../Recurent/Mylibrery/Minimap'
// style
import { report, form, contInfo } from '../../../Style/report'
import { Color, Button } from '../../../Style/custom'
const Report = ({t}) => {
    // eslint-disable-next-line no-unused-vars
    const { userAuth, tokenSession, botUser, lang } = useContext(AuthContext)
    const { 
        Boart,
        StartDate, setStartDate,
        EndDate, setEndDate,
        focusedInput, setFocusedInput,
        warpList, setWarpList,
        loadingShowTap, errorShowTap, dataShowTap, refetchShowTap,
        SearchClientBotId, loadingSearchClientBotId, errorSearchClientBotId, dataSearchClientBotId, refetchSearchClientBotId,
        creatProction, creatProctionLoading, creatProctionError, creatProctionData
    } = useContext(ReportContext)
    const {RefechKanban, setRefechKanban} = useContext(TopicContext)
    
    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const blockOrigin = warpList.find(e => e.id === source.droppableId)
        const blockDestini = warpList.find(e => e.id === destination.droppableId)
        if(blockOrigin.id===blockDestini.id){
            const listFinal = Array.from(blockOrigin.items)
            const [item] = listFinal.splice(source.index, 1)
            ItemChage(item,parseInt(blockOrigin.id))            
            listFinal.splice(destination.index, 0, item)
            const newWarpList = warpList.map(e => {
                if(e.id===blockOrigin.id) e.items=listFinal
                return e
            })
            setWarpList(newWarpList);
        }else{
            const listOrigin = Array.from(blockOrigin.items)        
            const [item] = listOrigin.splice(source.index, 1)
            ItemChage(item,parseInt(blockDestini.id))            
            const listDestini = Array.from(blockDestini.items)
            listDestini.splice(destination.index, 0,item)
            const newWarpList = warpList.map(e =>{
                if(e.id===blockOrigin.id) e.items=listOrigin
                if(e.id===blockDestini.id) e.items=listDestini
                return e
            })
            setWarpList(newWarpList);
        }
    }

    const ItemChage = (item, tag_id) => {
        // console.log(item, tag_id)
        creatProction({variables:{
            token: tokenSession,
            bot_id: botUser.bot_id,
            client_id: item.client_id,
            tag_id,
            agent_id: userAuth.id_user
        }})
    }
    
    const getListStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? Color.border : Color.lint
    })
    
    const getItemStyle = (isDragging, draggableStyle) => ({
        borderColor: isDragging ? Color.hoverPrimary : Color.lint,
        ...draggableStyle
    })

    const formatWarpList = items => {
        const newWarpList = items.map( item =>{ 
                if (warpList.length > 0){
                    return warpList.find(e => e.id ===item.tag_id.toString()) || ({...item, id: item.tag_id.toString(), title: item.tag_name, items: [] })
                } else {
                    return ({...item, id: item.tag_id.toString(), title: item.tag_name, items: [] })            
                }
            })
        setWarpList(newWarpList)
    }

    const Search = () => {
        const variables = {
            token: tokenSession,
            bot_id: botUser.bot_id,
            start_date: StartDate ? StartDate.format('YYYY-MM-DD') : '' ,
            end_date: EndDate ? EndDate.format('YYYY-MM-DD') : '',
        }
        const [validate] = validateGlobal({
            token: 'String|Require',
            bot_id: 'Int|Require',
            start_date: 'String|Require' ,
            end_date: 'String|Require',
        },
        variables,
        {
            message: {
                start_date: t('report.error.startDate'),
                end_date: t('report.error.endDate'),
                ErrorDefault: t('report.error.default'),
                CorrectValidate: t('report.error.correct') 
            },
            correctValidate: false,
            position: 'bottom-right'
        })

        if(validate){
            if (dataSearchClientBotId) {
                refetchSearchClientBotId(variables)
            }else {
                SearchClientBotId({variables})
            }
        }
    }

    const loaderItemswarpList = items => {
        const newWarpList = warpList.map( e => {
            const list = []
            items.forEach( item =>{ if(item.tag_id === parseInt(e.id))  list.push(item) })
            e.items = list 
            return e
        })
        setWarpList(newWarpList)

    }

    useEffect(() => {
        if(dataShowTap) {
            if(dataShowTap.showTagsByBotId) {
                if(dataShowTap.showTagsByBotId.data) {
                    formatWarpList(dataShowTap.showTagsByBotId.data)
                } else{
                    formatWarpList([])
                }
            }
        }            
    }, [dataShowTap])
    
    useEffect(() => {
        if(dataSearchClientBotId){
            if(dataSearchClientBotId.searchClientByBotIdAndDate){
                if(dataSearchClientBotId.searchClientByBotIdAndDate.data){
                    loaderItemswarpList(dataSearchClientBotId.searchClientByBotIdAndDate.data)
                } else {
                    loaderItemswarpList([])
                }
            }
        }            
    }, [dataSearchClientBotId])

    useEffect(() => {
        if(creatProctionData){
            toastMessage({message: t('messages.error.diarySuccess')})
            refetchSearchClientBotId()
        }
    }, [creatProctionData])

    useEffect(() => {
        if(RefechKanban){
            setRefechKanban(false)
            refetchShowTap({token: tokenSession, bot_id: botUser.bot_id, type_tag_id: 3})
            setTimeout(() => {
                refetchShowTap({token: tokenSession, bot_id: botUser.bot_id, type_tag_id: 1})
            }, 500);       
        }
    }, [RefechKanban])

    // console.log(lang)
    

    if(loadingShowTap || loadingSearchClientBotId || creatProctionLoading ) return <Loader height='100%' />

    // if(errorShowTap || errorSearchClientBotId || creatProctionError) return <Error />

    return(<Conter title={t('report.title')} icon={faClipboard} styleHeader={{padding: '18px 36px'}}>
        <div className={form}>
            <div className='contGrup'>
                <label className='label'>{t('report.rango')}</label>
                <div className='date' >
                    <DateRangePicker
                        startDate={StartDate} 
                        endDate={EndDate} 
                        onDatesChange={({startDate, endDate}) => {
                            if(startDate) setStartDate(startDate)
                            if(endDate) setEndDate(endDate)
                            }}
                        startDateId='startDateId'
                        endDateId='endDateId'
                        startDatePlaceholderText={t('selectDate.startDate')}
                        endDatePlaceholderText={t('selectDate.endDate')}
                        focusedInput={focusedInput}
                        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                        small={true}
                        isOutsideRange={() => {
                                day => {
                                    !isInclusivelyAfterDay(day, moment())
                                }
                            }}
                        renderMonthElement={({ month }) => moment(month).locale(lang).format('MMMM YYYY')}
                        monthFormat="MMMM YYYY" 
                        displayFormat="YYYY-MM-DD" 
                        phrases={{
                            ...defaultPhrases,
                            keyboardShortcuts: t('selectDate.keyboardShortcuts'),
                            selectFocusedDate: t('selectDate.selectFocusedDate'),
                            moveFocusByOneDay: t('selectDate.moveFocusByOneDay'),
                            moveFocusByOneWeek: t('selectDate.moveFocusByOneWeek'),
                            moveFocusByOneMonth: t('selectDate.moveFocusByOneMonth'),
                            moveFocustoStartAndEndOfWeek: t('selectDate.moveFocustoStartAndEndOfWeek'),
                            returnFocusToInput: t('selectDate.returnFocusToInput'),
                            openThisPanel: t('selectDate.openThisPanel')                            
                        }}
                    />
                </div>
            </div>
            <div className='contGrup'>
                <button 
                    onClick={() => Search()}
                    className={Button.primary} 
                ><FontAwesomeIcon icon={faSearch} /></button>
            </div>
        </div>
        <div className={contInfo}>
            <p className='info' >{t('report.info')}</p>
        </div>
        <div >
        <DragDropContext onDragEnd={onDragEnd} >
            <div className={report} ref={Boart}>
            <Minimap referent={Boart} />
            { warpList.map( (block, i) => 
            <Droppable key={i} droppableId={block.id}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{...getListStyle(snapshot.isDraggingOver), order: block.order ? block.order : 0 }}
                        className='droppable'
                    >
                        <label className='title' style={block.color ? { backgroundColor: block.color} : {}} >{block.title}</label>
                        <div className='cont'>
                        { block.items.map( (item, j) => 
                        <Draggable key={j} draggableId={`item-${i}-${j}`} index={j}>
                            {(provided, snapshot) => (
                                <div
                                    className='item'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <div>{`nombre: ${item.client_name}`}</div>
                                    <div>{`mail: ${item.client_email}`}</div>
                                    <div>{`phone: ${item.client_phone}`}</div>
                                </div>
                            )}
                        </Draggable>
                        )}
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            )}
            </div>
        </DragDropContext>
        </div>
    </Conter>)
}

export default withTranslation()(Report)
