import {style} from "typestyle"

// color
import {Color} from './custom'

export const menuVertical = {
    cont: style({
        width: '250px',
        height: 'calc(100vh - 51px)',
        backgroundColor: Color.white,
        borderRightColor: Color.border,
        borderRightStyle: 'solid',
        borderRightWidth: '5px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',

        $nest:{
            '&::-webkit-scrollbar':{
                width: '3px',
                backgroundColor: Color.white
            },
            '&::-webkit-scrollbar-thumb':{
                backgroundColor: Color.primary
            }
        }
    }),

    iconclose:  style({
        backgroundColor: Color.border,
        padding: '3px',
        borderRadius: '50%',
        fontSize: '22px',
        color: Color.primary,
        position: 'absolute',
        left: 'calc(250px - 14px)',
        top: '160px'
    }),

    title: style({
        padding: '10px',
        color: Color.text,
        fontWeight: 500,
        fontSize: '12px',

        $nest: {
            '&.noview':{
                display: 'none'
            }
        }
    }),

    contOption: style({
        display: 'flex',
        flexDirection: 'column'
    }),

    link: style({
        padding: '10px',
        color: Color.text,
        fontWeight: 500,
        fontSize: '14px',
        cursor: 'pointer',
        textDecoration: 'none',

        $nest: {
            '& .icon':{
                marginLeft: '10px',
                marginRight: '5px'
            },
            '& .label':{
                color: Color.text
            },
            '&:hover': {
                backgroundColor: Color.hoverPrimary,
                color: Color.white,

                $nest:{
                    '& .label':{
                        color: Color.white
                    },
                },
            },
            '&.active': {
                backgroundColor: Color.hoverPrimary,
                color: Color.white,

                $nest:{
                    '& .label':{
                        color: Color.white
                    },
                },
            },
            '&.noview': {
                display: 'flex',
                flexDirection: 'row',

                $nest:{
                    '& .icon':{
                        marginLeft: '0px',
                        marginRight: '0px',
                        order: 2
                    },
                    '& .label':{
                        position: 'absolute',
                        backgroundColor: Color.white,
                        marginLeft: '50px',
                        order: 1,
                        padding: '2px 5px',
                        borderRadius: '3px',
                        display: 'none'
                    },
                    '&:hover':{
                        $nest:{
                            '& .label':{
                                display: 'initial',
                                color: Color.text
                            }
                        }
                    }
                }
            }
        }
    })
}