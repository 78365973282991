/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faAd } from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { DateRangePicker, isInclusivelyAfterDay  } from 'react-dates'
import defaultPhrases from 'react-dates/lib/defaultPhrases'
import moment from 'moment'
import 'moment/locale/es'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
// context
import {AuthContext} from '../../../../Context/Auth'
import {ClientContext} from '../../../../Context/Client'
import {MessagesContext} from '../../../../Context/Messages'
// query
import { CREATE_CLIENT, SHOP_COUTRY, VALIDATE_PHONE } from '../../../../Query/Messages'
// Component
import Select from '../../../Recurent/Mylibrery/Select'
import Loader from '../../../Recurent/Loader'
import Error from '../../../Recurent/Error'
// function
import { validateGlobal, toastMessage } from '../../../Recurent/ValidateGlobal'

// style
import { Button } from '../../../../Style/custom'
import { dom } from '@fortawesome/fontawesome-svg-core'

const From = ({t}) => {
    const [isNewClient, setIsNewClient] = useState(false)
    const [newPhone, setNewPhone] = useState("")
    const [creatClient , {loading: creatClientLoading, error: creatClientError, data: creatClientData }] = useMutation(CREATE_CLIENT)          
    const {tokenSession, botUser, lang, userAuth} = useContext(AuthContext)    
    const {
        ViewFrom, setViewFrom,
        formPlay,
        phone, setPhone,
        name, setName,
        mail, setMail,
        Tag, setTag,
        StartDate, setStartDate,
        EndDate, setEndDate,
        focusedInput, setFocusedInput,
        Country, setCountry,
        ViewClientTag, setViewClientTag,
        setNextData,
        setTotalData,
        refetchListClient,
        NewClient, setNewClient
    } = useContext(ClientContext)
    const {        
        ListTap, setListTap,
        ListCountry, setListCountry,
        showTapsBot, tapBotData, tapBotRefetch 
    } = useContext(MessagesContext)

    const {loading: loadingShowCoury, error: errorShowCoury, data: dataShowCoury} = useQuery(SHOP_COUTRY, {variables: {token: tokenSession}})
    const {data: validatePhoneData, error: errorValidatePhone, loading: loadingValidatePhone} = useQuery(VALIDATE_PHONE, {variables: {token: tokenSession, phone: newPhone},  fetchPolicy: 'network-only'})      


    const saveClient = () => {        
            const variables = {
                token: tokenSession, 
                client_name: name, 
                client_email: mail, 
                client_phone: newPhone, 
                client_phone_indicative: Country.value,
                facebook_id: "{}",
                platform_id: 1,
                session_id: "12345",
                user_id: parseInt(userAuth.id_user)
            }


            const [validateNC] = validateGlobal({
                token: 'String|Require',
                client_name: 'String|Require', 
                client_email: 'Email|Require', 
                client_phone: 'Int|Require',
                client_phone_indicative: 'String|Require',
                facebook_id: 'String',
                platform_id: 'Int',
                session_id: 'String',
                user_id: 'Int'
            },
            variables,
            {
                message:{
                    client_email: t('messages.error.client_email'),
                    client_name: t('messages.error.client_name'),
                    client_phone: t('messages.error.client_phone'),
                    client_phone_indicative: t('messages.error.client_phone_indicative'),
                    ErrorDefault: t('messages.error.default'),                    
                },
                position: 'bottom-right'
            })
            if(validateNC) {
                creatClient({variables, errorPolicy: 'all'})
            }
    }
    
    const Search = () => {
        const variables = {
            token: tokenSession, 
            max_count:50,
            min_count: 0,
            id_bot: botUser.bot_id, 
            client_name: name, 
            client_email: mail, 
            client_phone: Country ? `${Country.value}${phone}` : '', 
            id_tags: Tag ? `${Tag.value}` : '', 
            user_id: userAuth.id_user,
            start_date: StartDate ? StartDate.format('YYYY-MM-DD') : '' ,
            end_date: EndDate ? EndDate.format('YYYY-MM-DD') : ''
        }
        const [validate] = validateGlobal({
            token: 'String|Require', 
            max_count: 'Int|Require', 
            min_count: 'Int|Require', 
            id_bot: 'Int|Require', 
            client_name: 'String', 
            client_email: 'String', 
            client_phone: 'String', 
            id_tags: 'String', 
            user_id: 'Int',
            start_date: 'String',
            end_date: 'String'
        },
        variables,
        {
            message: {
                ErrorDefault: t('messages.error.default'),
                CorrectValidate: t('messages.error.correct')
            },
            correctValidate: false,
            position: 'bottom-right'
        })
        if(validate) {
            setNextData(false)
            refetchListClient(variables)
            setTotalData(50)
        }
    }

    const NewClientFuntion = () => {
        
        if (isNewClient) {
            if (Country == '' || phone == ''){
                toastMessage({message: t('messages.error.client_phone'),error:true})
            }else{
                if(validatePhoneData) {
                    if(validatePhoneData.validCountryMobilePhone) {
                        if(validatePhoneData.validCountryMobilePhone.data) {
                            if ( validatePhoneData.validCountryMobilePhone.data.code !== 'NaN'){
                                saveClient()
                            }
                        }
                    }            
                }else{
                    setNewPhone(Country ? `${Country.value}${phone}` : '')        
                }                                
            }            

        } else {
            setIsNewClient(true)
            setMail("")
            setName("")
            setPhone("")
            setCountry("")
        }
    }

    const NewClientCancel = () => {
        setIsNewClient(false)
        setMail("")
        setName("")
        setPhone("")
        setCountry("")
    }

    useEffect(() => {
        if(ViewClientTag){
            ListTap ? tapBotRefetch({token: tokenSession, bot_id: botUser.bot_id, type_tag_id: 1 }) : showTapsBot({variables:{token: tokenSession,  bot_id: botUser.bot_id, type_tag_id: 1 }})
            setViewClientTag(false)
        }
    }, [ViewClientTag])

    useEffect(() => {
        if(tapBotData){
            if(tapBotData.showTagsByBotId){
                setListTap(tapBotData.showTagsByBotId.data)
            }
        }
    }, [tapBotData])

    useEffect(() => {
        if(dataShowCoury){
            if(dataShowCoury.getCountriesData){
                if(dataShowCoury.getCountriesData.data){
                    setListCountry(dataShowCoury.getCountriesData.data)
                }
            }
        }
    }, [dataShowCoury])

    useEffect(() => {
        if (creatClientData) {
            if (creatClientData.createClient){                
                if (creatClientData.createClient.code == 409){
                    toastMessage({message: t('messages.error.client_duplicate'),error:true})                    
                }else{
                    setIsNewClient(false)
                    toastMessage({message: t('messages.error.correct_save')})                    
                    refetchListClient()
                    setMail("")
                    setName("")
                    setPhone("")
                    //setNewPhone("")
                    setCountry("")
                }
            }
        }
    }, [creatClientData])


    useEffect(() => {
        if (phone !== ''){
            if(validatePhoneData) {
                if(validatePhoneData.validCountryMobilePhone) {
                    if(validatePhoneData.validCountryMobilePhone.data) {
                        if ( validatePhoneData.validCountryMobilePhone.data.code !== 'NaN'){
                            saveClient()
                        }else{
                          toastMessage({message: t('messages.error.client_phone'),error:true})
                        }
                    }else{
                        toastMessage({message: t('messages.error.client_phone'),error:true})
                    }
                }else{
                    toastMessage({message: t('messages.error.client_phone'),error:true})
                }
            }else{
                toastMessage({message: t('messages.error.client_phone'),error:true})
            }

        }else{
            if (isNewClient){
                toastMessage({message: t('messages.error.client_phone'),error:true})    
            }            
        }
    }, [validatePhoneData])



    if(loadingShowCoury) return <Loader height='100%' /> 

    if(errorShowCoury) return <Error /> 

    return(<div className='fromGrup' >
        <div className='buttonClose'>
            <FontAwesomeIcon icon={faTimes} className='close' onClick={()=>{setViewFrom(!ViewFrom); formPlay(!ViewFrom)}} />
        </div>
        { !isNewClient && <div className='grupItem'>
            <label className='label'>{t('clients.from.tag')}</label>
            <Select 
                placeholder={t('')}
                search={false}
                value={Tag}
                onChange={e => setTag(e)}
                className='select'
                marginTopOption='0px'
                clearOption={true}
                options={ListTap?ListTap.map(option => ({value: option.tag_id, label:option.tag_name})):[]}
                />
        </div> }
        { !isNewClient && <div className='grupItem'>
            <label className='label'>{t('clients.from.dateDiary')}</label>
            <div className='date' >
                <DateRangePicker
                        startDate={StartDate} 
                        withPortal={true}
                        block={true}
                        endDate={EndDate} 
                        onDatesChange={({startDate, endDate}) => {
                            if(startDate) setStartDate(startDate)
                            if(endDate) setEndDate(endDate)
                            }}
                        startDateId='startDateId'
                        endDateId='endDateId'
                        startDatePlaceholderText={t('selectDate.startDate')}
                        endDatePlaceholderText={t('selectDate.endDate')}
                        focusedInput={focusedInput}
                        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                        small={true}
                        isOutsideRange={() => {
                                day => {
                                    !isInclusivelyAfterDay(day, moment())
                                }
                            }}
                        renderMonthElement={({ month }) => moment(month).locale(lang).format('MMMM YYYY')}
                        phrases={{
                            ...defaultPhrases,
                            keyboardShortcuts: t('selectDate.keyboardShortcuts'),
                            selectFocusedDate: t('selectDate.selectFocusedDate'),
                            moveFocusByOneDay: t('selectDate.moveFocusByOneDay'),
                            moveFocusByOneWeek: t('selectDate.moveFocusByOneWeek'),
                            moveFocusByOneMonth: t('selectDate.moveFocusByOneMonth'),
                            moveFocustoStartAndEndOfWeek: t('selectDate.moveFocustoStartAndEndOfWeek'),
                            returnFocusToInput: t('selectDate.returnFocusToInput'),
                            openThisPanel: t('selectDate.openThisPanel')                            
                        }}
                        monthFormat="MMMM YYYY" 
                        displayFormat="YYYY-MM-DD"
                        showClearDates={true}
                        customCloseIcon={<FontAwesomeIcon icon={faTimes} className='close' onClick={()=> {setStartDate(null); setEndDate(null)}} />}
                    />
            </div>
        </div> }
        <div className='grupItem'>
            <label className='label'>{t('clients.from.name')}</label>
            <input className='input' value={name} maxLength={40} onChange={e => setName(e.target.value)} />
        </div>
        <div className='grupItem'>
            <label className='label'>{t('clients.from.mail')}</label>
            <input className='input' type='email' value={mail} onChange={e => setMail(e.target.value)} maxLength={70} />
        </div>
        <div className='grupItem'>
            <label className='label'>{t('clients.from.phone')}</label>
            <div className='grupItem'>
                <Select 
                    placeholder='...'
                    className='selectFlag'
                    value={Country}
                    heightOption='auto'
                    onChange={e => setCountry(e)}
                    options={ListCountry? ListCountry.map(country => ({value:country.code, label: `${country.flag} ${country.code}` })):[]}
                />
                    <input className='input' placeholder={t('messages.from.userPhone')} value={phone} maxLength={12} disabled={Country===null}
                    onChange={ e => setPhone(e.target.value)} type='number'/>
                </ div>
        </div>
        <div className='grupItem'>
            <button className={`${Button.primary} justify`} onClick={() => Search()} ><FontAwesomeIcon icon={faSearch} /> {t('clients.search')}</button>
        </div>    
        <div className='grupItem'>
            <button className={`${Button.primary} justify`} onClick={() => NewClientFuntion()} ><FontAwesomeIcon /> {isNewClient ? t('clients.save') : t('clients.new')} </button>
        </div>
        { isNewClient && <div className='grupItem'>
            <button className={`${Button.primary} justify`} onClick={() => NewClientCancel()} ><FontAwesomeIcon /> {t('customBar.cancel')} </button>
        </div>
        }
    </div>)
}

export default withTranslation()(From)


