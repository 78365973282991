import React, { Suspense } from 'react'
import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter as Router } from 'react-router-dom'
import { FirebaseAppProvider } from 'reactfire'
// Context
import { AuthProvider } from './Context/Auth'
// Component
import Aplication from './Components'
import Loader from './Components/Recurent/Loader'
// multi idioma
import './Lang'

const firebaseConfig = process.env.REACT_APP_FRONT_ENV === 'dev' ? {
  apiKey: "AIzaSyDoDvumKb6p4CaK4k-vPcceBjfJLstiJ5g",
  authDomain: "keos-sales-stg.firebaseapp.com",
  databaseURL: "https://keos-sales-stg.firebaseio.com",
  projectId: "keos-sales-stg",
  storageBucket: "keos-sales-stg.appspot.com",
  messagingSenderId: "942168318059",
  appId: "1:942168318059:web:41fc934fdd604693a7112e",
  measurementId: "G-96R567YXX3"
} :
process.env.REACT_APP_FRONT_ENV === 'qa' ? {
  apiKey: "AIzaSyA8uiz43s9i5Vd-M95HVBJ7FOu6Wto1fLM",
  authDomain: "keos-blocks-qa.firebaseapp.com",
  databaseURL: "https://keos-blocks-qa-default-rtdb.firebaseio.com",
  projectId: "keos-blocks-qa",
  storageBucket: "keos-blocks-qa.appspot.com",
  messagingSenderId: "124138728241",
  appId: "1:124138728241:web:b5ce550f3c9308b2885e10"
} : 
{
  apiKey: "AIzaSyDMGp8jmvMJ3n-JZh9tOHuG3af4NyMqiaE",
  authDomain: "keos-blocks-prod.firebaseapp.com",
  databaseURL: "https://keos-blocks-prod.firebaseio.com",
  projectId: "keos-blocks-prod",
  storageBucket: "keos-blocks-prod.appspot.com",
  messagingSenderId: "867407093153",
  appId: "1:867407093153:web:d511b7e1941c3a3459bfe0",
  measurementId: "G-WKSXP8HRE3"
}



const client = new ApolloClient({
  uri: process.env.REACT_APP_API_GRAPHQL,
  cache: new InMemoryCache({
    addTypename: false
  })
});

const App = () => {
  return (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Suspense fallback={<Loader />}>
      <ApolloProvider client={client}>
          <AuthProvider>
            <Router>
              <Aplication />
            </Router>
          </AuthProvider>
      </ApolloProvider>
    </Suspense>
  </FirebaseAppProvider>
  );
}

export default App;
