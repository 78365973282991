import { gql } from "apollo-boost"

export const SHOW_TAGS = gql`
query showTagsByBotId($token: String, $bot_id: Int, $type_tag_id: Int){  showTagsByBotId(inputByBotTypeId: {token: $token, bot_id: $bot_id, type_tag_id: $type_tag_id}) {
    code
    message
    data {
        tag_id
        tag_name
        order
        color
        }
    }
}
`

export const SHOW_TAG_SCHEDULES = gql`
query showTagSchedules($token:String,$bot_id:Int){
    showTagSchedules(inputByBotId:{
        token:$token,
        bot_id:$bot_id
    }){
        code
        message
        data{
            tag_id
            days
            hours{
                start_time
                end_time
            }
            message
            tag_name
        }
    }
}
`
export const CREATE_TAG_SCHEDULE = gql`
mutation newTagSchedule($token:String,$tag_id:Int,$days:[Int],$hours:[InputHour],$message:String){
    createTagSchedule(inputNewTagSchedule:{token:$token,tag_id:$tag_id,days:$days,hours:$hours,message:$message}){
        code
        message
        tag_schedule_ids
    }

}
`

export const DELETE_TAG_SCHEDULE = gql`
mutation deleteTagSchedule($token:String,$tag_id:Int,$days:[Int],$hours:[InputDeleteHour]){
    deleteTagSchedule(inputDeleteTagSchedule:{token:$token,tag_id:$tag_id,days:$days,hours:$hours}){
        code
        message
    }
}
`

