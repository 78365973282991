import React, {useRef} from 'react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

const EventCalendar = ({ event, t }) => {
    const eventRef = useRef(null)
    // console.log(event.extendedProps)    
    return(
        <div className='event' ref={eventRef}>
            <div className='titleEvente'>{event.title}</div>
            <div className='timeEvente'>{moment(event.start).format('LT')}</div>
        </div>
    );
}

export default withTranslation()(EventCalendar)