import { AuthContext } from '../../../../Context/Auth'
import React, {useState, useContext, useRef, useEffect} from 'react';
import { useQuery, useMutation  } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { MessagesHistoryContext } from '../../../../Context/MessagesHistory'
import { ADD_MESSAGES} from '../../../../Query/MessagesHistory' 
import moment from 'moment'
// style
import { messages } from '../../../../Style/messages'
const ListChat = ({children , t}) =>{
    const [filterChat, setFilterChat] = useState('')
    const [pageConversations, setPageConversations] = useState(0)
    
    const [bottom, setBottom] = useState(null)
    const [inBottom, setInBottom] = useState(false)
    const scrollRef = useRef();

    const { autoAuth, userAuth, lang, botUser, tokenSession } = useContext(AuthContext)   

    const {
        messagesList, setMessagesList, 
        filteredMessagesList, setFilteredMessagesList,
        selectedRoom, setSelectedRoom,
        loadingConversations, setLoadingConversations
    } = useContext(MessagesHistoryContext)

    const [addRooms , { loading: addRoomsLoading, error: addRoomsError, data: addRoomsData, refetch: addRoomsRefetch}] = useMutation(ADD_MESSAGES)

    useEffect(() => {
        scrollRef.current.addEventListener('scroll',function(e) {
            console.log("CALC CLIENT HEIGHT",Math.trunc(scrollRef.current.scrollHeight - scrollRef.current.clientHeight))
            console.log("CURRENT TOP",Math.trunc(scrollRef.current.scrollTop))
            if(Math.trunc(scrollRef.current.scrollHeight - scrollRef.current.clientHeight) - Math.trunc(scrollRef.current.scrollTop) < Math.abs(10)){
                console.log("BOTTOM REACHED")
                setBottom(scrollRef.current.scrollTop)
            }else {
                setInBottom(false)
            }
            
        })
    }, [])

    useEffect(() => {
        // console.log("BOTTOM REACHED EFFECT",pageConversations)
        if(bottom && !loadingConversations && !inBottom){
            setLoadingConversations(true)
            setInBottom(true)
            console.log("OBTAINING ROOMS")
            let variables = {
                token: tokenSession,
                bot_id: parseInt(botUser.bot_id),
                limit:  100,
                offset: pageConversations+1,
                user_filter: filterChat
            }
            
            addRooms({ variables, errorPolicy: 'all'}).then((payload) => {
                console.log("ROOMS OBTAINED",payload)
                setLoadingConversations(false)
                if(payload && payload.data.getMessagesListPaged.code == 200){
                    let messArray = [...messagesList]
                    payload.data.getMessagesListPaged.data.map((conv) =>{
                        let roomId = conv.room_id;
                        
                        if(findRoom(messArray, roomId)){
                            messArray.push(conv)
                        
                        }
                    })
                    
                    // console.log("MESSAGES ADDED", messArray)
                    setMessagesList(messArray)
                    setFilteredMessagesList(messArray)
                    setPageConversations(pageConversations+1)
                    }
            }).catch((e) => {
                setLoadingConversations(false)
                console.log("ERROR OBTAINING ROOMS", e)
            }) 
        }
    }, [bottom])
   
    const findRoom = (arr, roomId) => {
        
        var result  = arr.filter(function(o){return o.room_id == roomId;} );
        return !(result && result.length > 0)
      
    }

    const setFilter = (value) => {
        setFilterChat(value)
    }

    const searchFiltered = (filter) => {
        console.log("SEARCH REQUESTED", filter)
        console.log("OBTAINING ROOMS")
        setSelectedRoom({room_id:''})
        if(!loadingConversations){
            let variables = {
                token: tokenSession,
                bot_id: parseInt(botUser.bot_id),
                limit:  100,
                offset: 0,
                user_filter: filter
            }
            setLoadingConversations(true)
            
            addRooms({ variables, errorPolicy: 'all'}).then((payload) => {
                console.log("ROOMS OBTAINED",payload)
                
                if(payload && payload.data.getMessagesListPaged.code == 200){
                    let messArray = []
                    payload.data.getMessagesListPaged.data.map((conv) =>{
                        let roomId = conv.room_id;
                        if(findRoom(messArray, roomId)){
                            messArray.push(conv)
                        
                        }
                    })
                    // console.log("MESSAGES ADDED", messArray)
                    scrollRef.current.scroll({top: 0,
                        left: 0, 
                        behavior: 'smooth',})
                    setMessagesList(messArray)
                    setFilteredMessagesList(messArray)
                    setPageConversations(0)
                    }
                setLoadingConversations(false)
            }).catch((e) => {
                setLoadingConversations(false)
                console.log("ERROR OBTAINING ROOMS", e)
            }) 
        }
    }

    return (
        <div className={messages.listChat} >
            <div className='title' >
                <FontAwesomeIcon className='icon' icon={faComments} />
                <div>{`${t('messagesHistory.title')}`.toUpperCase()}</div>
            </div>
            <div className='subtitle'>{t('messagesHistory.subtitle')}</div>
            <div className='filter' >
                <div className='filterColumn' style={{opacity:loadingConversations?'1':'0'}} >
                    <FontAwesomeIcon className='icon fa-spin' icon={faSpinner} />
                </div>
                <div className='filterColumn'> 
                    <input className='input'  placeholder={t('messagesHistory.info.phoneFilter')} value={filterChat} maxLength={70}
                    onChange={ e => setFilter(e.target.value)} />
                </div>
                <div className='filterColumn' >
                    <FontAwesomeIcon className='icon' icon={faSearch} onClick={ filter => searchFiltered(filterChat)}/>
                </div>
                
            </div> 
            <div className='list' style={{paddingLeft: '14px', opacity: loadingConversations?'0.5':'1'}} ref={scrollRef}>
                {loadingConversations&&
                    <div className='message' style={{position:'absolute', marginTop:'15%', marginLeft:'9%', fontSize:'40px'}}><FontAwesomeIcon className='icon fa-spin' icon={faSpinner} /></div>
                }
                
                {children}
            </div>
        </div>
    )
}
export default withTranslation()(ListChat)