/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import { withTranslation } from 'react-i18next'
import {faCog} from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation  } from '@apollo/react-hooks'
// import Select from 'react-select'              
// context
import { PersonalizationContext } from '../../../Context/Personalization'
import { AuthContext } from '../../../Context/Auth'
// query and mutation
import { GET_PERSONALIZATION, SAVE_PERSONALIZATION} from '../../../Query/Personalization' 
// function
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
// componets
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import Conter from '../../Recurent/Conter'
// style
import {formElements , Button} from '../../../Style/custom'
import {topic} from '../../../Style/topic'
// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Color } from '../../../Style/custom'
import { FilterRounded } from '@material-ui/icons'

const Personalization = ({t}) => {
    // context
    const { 
        maxAgentConv, setMaxAgentConv,
        maxAgentValidity, setmaxAgentValidity,
        maxQueueValidity, setmaxQueueValidity,
        MaxBot, setMaxBot,
        MaxUser, setMaxUser,
        alertTime, setAlertTime,
        analizeSentiment, setAnalizeSentiment,
        monthlyTotal, setMonthlyTotal,
        samplePercentage, setSamplePercentage,
        isBotSessionValidity, setIsBotSessionValidity,
        botSessionValidity, setBotSessionValidity,
        botSessionMessage, setBotSessionMessage
    } = useContext(PersonalizationContext)
    const { tokenSession, botUser } = useContext(AuthContext)    
    // query and mutation
    const { loading: personalizationLoading, error: personalizationError, data: personalizationData, refetch: personalizationRefetch} = useQuery(GET_PERSONALIZATION,{variables:{token: tokenSession, bot_id: botUser.bot_id}})
    const [savePersonalization, { loading: newPersonalizationLoading, error: newPersonalizationError, data: newPersonalizationData }] = useMutation(SAVE_PERSONALIZATION)
    
    const [derivationsAdm, setDerivationsAdm] = useState(false)



    const savePers = () => {
        let monthTot = monthlyTotal && monthlyTotal.length > 0 ? parseInt(monthlyTotal) : 0;
        let sampPerc = samplePercentage && samplePercentage.length > 0 ? parseInt(samplePercentage) : 0;
        const variables = {
            token: tokenSession,
            bot_id: botUser.bot_id,
            max_bot: MaxBot,
            max_user: MaxUser,
            max_agent_occupation: parseInt(maxAgentConv),
            max_derivation_agent_validity: parseInt(maxAgentValidity),
            max_derivation_queue_validity: parseInt(maxQueueValidity),
            alert_time: parseInt(alertTime),
            derivations_adm: derivationsAdm,
            analize_sentiment: analizeSentiment,
            monthly_total: monthTot,
            sample_percentage: sampPerc,
            is_bot_session_validity: isBotSessionValidity,
            bot_session_validity: botSessionValidity,
            bot_session_message: botSessionMessage
        }
        
        
        const [validate] = validateGlobal({
            token: 'String|Require',
            bot_id: 'Int|Require',
            max_bot: 'Int|Require',
            max_user: 'Int|Require',
            max_agent_occupation: 'Int|Require',
            max_derivation_agent_validity: 'Int|Require',
            max_derivation_queue_validity: 'Int|Require',
            derivations_adm: 'Boolean|Require',
            alert_time: 'Int|Require',            
            analize_sentiment: 'Boolean|Require',
            monthly_total: 'Int|Require',
            sample_percentage: 'Int|Require',
            is_bot_session_validity: 'Boolean|Require',
            bot_session_validity: 'Int|Require',
            bot_session_message: 'String'
        },variables,{
            message: {
                max_agent_occupation: t('personalization.error.max_agent_occupation'),
                max_derivation_agent_validity: t('personalization.error.max_derivation_agent_validity'),
                max_derivation_queue_validity: t('personalization.error.max_derivation_queue_validity'),
                alert_time: t('personalization.error.alert_time'),
                ErrorDefault: t('topics.error.default'),
                CorrectValidate: t('personalization.error.correct')
            },
            position: 'bottom-right',
            correctValidate: false
        })

        if(validate) {
            savePersonalization({ variables, errorPolicy: 'all'})
        }
    }

    const changeDerivationAdm = (e) => {
        setDerivationsAdm(e.target.checked )
    }

    const changeAnalizeSentiment = (e) => {
        setAnalizeSentiment(e.target.checked)
        if (monthlyTotal && monthlyTotal.length === 0) setMonthlyTotal(0);
        if (samplePercentage && samplePercentage.length === 0) setMonthlyTotal(0);
    }
    useEffect(() => {
        if (maxQueueValidity && (+maxQueueValidity < 0 || +maxQueueValidity > 60)) setmaxQueueValidity(0)
    }, [maxQueueValidity])

    useEffect(() => {
        if(personalizationData) {
            if(personalizationData.getConfigurationByBotId) {
                if(personalizationData.getConfigurationByBotId.data) {
                    const { 
                        max_bot,
                        max_user,
                        max_agent_occupation, 
                        max_derivation_agent_validity, 
                        max_derivation_queue_validity,
                        derivations_adm,
                        alert_time,
                        analize_sentiment,
                        monthly_total,
                        sample_percentage ,
                        is_bot_session_validity,
                        bot_session_validity,
                        bot_session_message
                    } = personalizationData.getConfigurationByBotId.data.configuration
                    if(max_bot){
                        setMaxBot(max_agent_occupation)
                    }
                    if(max_user){
                        setMaxUser(max_agent_occupation)
                    }
                    if(max_agent_occupation){
                        setMaxAgentConv(max_agent_occupation)
                    }
                    if(max_derivation_agent_validity){
                        setmaxAgentValidity(max_derivation_agent_validity)
                    }
                    if(max_derivation_queue_validity){
                        if(max_derivation_queue_validity > 60) {
                            setmaxQueueValidity(60)
                        }
                        setmaxQueueValidity(max_derivation_queue_validity)
                    } 
                    if(derivations_adm){
                        setDerivationsAdm(derivations_adm)
                    } 
                    if(alert_time){
                        setAlertTime(alert_time)
                    } 
                    if(analize_sentiment){
                        setAnalizeSentiment(analize_sentiment)
                    }
                    if(monthly_total){
                        setMonthlyTotal(monthly_total)
                    }
                    if(sample_percentage){
                        setSamplePercentage(sample_percentage)
                    }
                    if(is_bot_session_validity){
                        setIsBotSessionValidity(is_bot_session_validity)
                    }
                    if(bot_session_validity){
                        setBotSessionValidity(bot_session_validity)
                    }
                    if(bot_session_message){
                        setBotSessionMessage(bot_session_message)
                    }

                }
            }
        }
    }, [personalizationData])

    useEffect(() => {
        if(newPersonalizationData){
            if(newPersonalizationData.setConfigurationByBotId){
                personalizationRefetch()
            }
        }
    }, [newPersonalizationData])

    if(personalizationLoading) return <Loader height='100%' />

    if(personalizationError) return <Error />

    if(newPersonalizationError) return <Error />
    
    /* funcion para validar que solo se ingresen numeros enteros en el tiempo maximo en cola */

    function validateInteger(event){
        var tecla = event.key;
        if (['.','e'].includes(tecla)){
            event.preventDefault();
        }
    }

    return (
        <Conter title={t('personalization.title')} icon={faCog}>
            <div className={topic.cont}>
                {newPersonalizationLoading ? <Loader height='100%' /> : <div className={topic.form}>
                    {/*SECCION DE DERIVACIONES*/}
                    <div className={topic.label}>{t('personalization.derivations')}</div>
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.max_cant_agent')}</label>
                        <div className="control">
                            <input 
                            type='number'
                            name="max_cant_agent" 
                            className={formElements.formInput} 
                            value={maxAgentConv?maxAgentConv:''} 
                            onChange = {(e) =>{
                                setMaxAgentConv(e.target.value)}}
                                />
                        </div>
                    </div>
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.max_agent_validity')}</label>
                        <div className="control">
                            <input 
                                type='number'
                                name="max_agent_validity" 
                                className={formElements.formInput} 
                                value={maxAgentValidity?maxAgentValidity:''} 
                                onChange = {(e) =>{
                                    setmaxAgentValidity(e.target.value)}}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.max_queue_validity')}</label>
                        <div className="control">
                            <input 
                                type='number'
                                name="max_queue_validity" 
                                step="1"
                                max="60"
                                min="1"
                                className={formElements.formInput} 
                                value={maxQueueValidity?maxQueueValidity:''} 
                                onKeyDown={validateInteger}
                                onChange = {(e) =>{
                                    setmaxQueueValidity(e.target.value)}}
                                    />
                        </div>
                    </div>
                    <div className="field">

                          <FormControlLabel
                            control={<Switch checked={derivationsAdm} onChange={ changeDerivationAdm } color="primary"  />}
                            label={t('personalization.derivationsAdm')}
                            //labelPlacement="start"                          
                          />                                                

                    </div>
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.max_queue_validity')}</label>
                            <div className="control">
                                <input type="file" />
                            </div>
                    </div>
                    <br/>
                    {/*SECCION DE ANALISIS DE SENTIMIENTOS*/}
                    {/* <div className={topic.label}>{t('personalization.sentiment_analysis')}</div>
                    <div className="field">

                          <FormControlLabel
                            control={<Switch checked={analizeSentiment} onChange={ changeAnalizeSentiment } color="primary"  />}
                            label={t('personalization.enable_sentiment_analysis')}
                            //labelPlacement="start"                          
                          />                                                

                    </div>                   
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.monthly_total')}</label>
                        <div className="control">
                            <input 
                                type='number'
                                name="monthly_total"
                                min="0"
                                className={formElements.formInput} 
                                value={monthlyTotal} 
                                onChange = {(e) =>{
                                    setMonthlyTotal(e.target.value)}}
                                    />
                        </div>
                    </div>
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.sample_percentage')}</label>
                        <div className="control">
                            <input 
                                type='number'
                                name="sample_percentage"
                                min="0"
                                max="100"
                                className={formElements.formInput} 
                                value={samplePercentage} 
                                onChange = {(e) =>{
                                    setSamplePercentage(e.target.value)}}
                                    />
                        </div>
                    </div> */}
                    <br/>
                    {/*SECCION DE ALERTAS*/}
                    <div className={topic.label}>{t('personalization.alerts')}</div>
                     
                    <div className="field">
                        <label className={formElements.formLabel}>{t('personalization.alert_time')}</label>
                        <div className="control">
                            <input 
                                type='number'
                                name="alert_time" 
                                className={formElements.formInput} 
                                value={alertTime} 
                                onChange = {(e) =>{
                                    setAlertTime(e.target.value)}}
                                    />
                        </div>
                    </div>
    

                    <div className={formElements.formSave}>
                        <button className={`${Button.primary}`} onClick={() => {savePers()}}>{t('save')}</button>
                    </div>

                </div>}
            </div>
        </Conter>
    )
}

export default withTranslation()(Personalization)
