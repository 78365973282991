/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faClipboard, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
// context
import {ClientContext} from '../../../Context/Client'
// Component
import Conter from '../../Recurent/Conter'
import From from './items/From'
import List from './items/List'
import ShowClient from './items/ShowClient'
import Error from '../../Recurent/Error'
import Loader from '../../Recurent/Loader'
// style
import { contList, floatButton } from '../../../Style/client'
import { Button } from '../../../Style/custom'

const Client = ({t}) => {
    const {
        ViewFrom, setViewFrom,
        formPlay, formStyle,
        Hash, setHash,
        setPataform,
        errorListClient,
        setClient,
        loadingShowClientId, errorShowClientId, dataShowClientId

    } = useContext(ClientContext)
    const location = useLocation()
    
    useEffect(() => {
        if(location) {
            if(location.hash!=='') {
                setHash(parseInt(location.hash.replace('#','')))
            } else {
                setHash(null)
            }
        }        
    }, [location])

    useEffect(() => {
        if(dataShowClientId){
            if(dataShowClientId.getClientData){
                setClient(dataShowClientId.getClientData.data)
            }
        }        
    }, [dataShowClientId])

    if(loadingShowClientId) return <Loader height='100%' />

    // if(errorShowClientId) return <Error />
    
    // if(errorListClient) return <Error />

    return Hash 
    ? (<Conter  title={`${t('clients.title')}`} icon={faUsers} buttonLeft={
            <Link to='/client' className={Button.default} onClick={()=> setPataform(null)} ><FontAwesomeIcon icon={faChevronLeft} /></Link>
        } styleHeader={{padding: '18px 36px'}} >
            <ShowClient />
        </Conter>) 
        : (<Conter title={t('clients.title')} icon={faUsers} styleHeader={{padding: '18px 36px'}} >
        <button 
            style={{display: ViewFrom ? 'flex' : 'none'}}
            className={`${Button.primary} ${floatButton}`}
            onClick={()=>{setViewFrom(!ViewFrom); formPlay(!ViewFrom)}}
        >
            <div className='tooltip'>{t('clients.seeForm')}</div>
            <FontAwesomeIcon icon={faClipboard} />
        </button>
        <div className={contList} >
            <div className='from' style={formStyle} >
                <From />
            </div>
            <div className='list' >
                <List />
            </div>
        </div>
    </Conter>)
}

export default withTranslation()(Client)
