import {style} from "typestyle"

const Color = {
    white: '#fff',
    text: '#888',
    textDark: '#777',
    border: '#ccc',
    disabled: '#eee',
    dark: '#1b262c',
    black: '#000',
};

export const select = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    
    $nest: {
        '&.disabled': {
            $nest:{
                '& .select': {
                    cursor: 'no-drop',
                    backgroundColor: Color.disabled,
                    $nest: {
                        '& .item': {
                            cursor: 'no-drop',
                        }
                    }
                }
            }
        },
        '& .select': {
            width: 'calc(100% - 22px)',
            borderColor: Color.border,
            borderWidth: '1px',
            borderStyle: 'solid',
            padding: '7px 10px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'row',
            color: Color.dark,
            cursor: 'pointer',
            
            $nest: {
                '& .item': {
                    cursor: 'pointer',
                    flex: '1 1 auto',
                    $nest: {
                        '&.place': {
                            color: Color.border
                        }
                    }
                },
                '& .contSelects': {
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    
                    $nest: {
                        '& label':{
                            backgroundColor: Color.border,
                            padding: '2px 4px',
                            borderRadius: '20px'
                        }
                    }
                },
                '& .close': {
                    cursor: 'pointer',
                    color: Color.border,
                    marginLeft: '2px',
                    marginRight: '2px'
                },
                '& .icon': {
                    color: Color.border
                },
                
            }
        },
        '& .contOptions': {
            height: '0px',
            display: 'none',
            marginBottom: '5px',
            zIndex: 2,

            $nest: {
                '& .options': {
                    borderColor: Color.border,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    color: Color.dark,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: Color.white,
                    borderRadius: '5px',
                    marginTop: '2px',
                    overflow: 'auto',
                    maxHeight: '200px',

                    $nest: {
                        '& .contSearch':{
                            display: 'flex',
                            flexDirection: 'row',
                            borderBottomColor: Color.border,
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            padding: '3px',

                            $nest:{
                                '& .search': {
                                    flex: '1 1 auto',
                                    borderWidth: '0px',
                                    borderStyle: 'solid',
                                    
                                    $nest: {
                                        '&:focus':{
                                            outline: 'none'
                                        }
                                    }
                                },
                                '& .icon': {
                                    color: Color.textDark
                                }
                            },
                        },
                        '& .option': {
                            padding: '5px 10px',
                            cursor: 'pointer',

                            $nest: {
                                '&:hover': {
                                    backgroundColor: Color.border
                                }
                            }
                        }
                    }            
                }
            }
        }
    }
})
