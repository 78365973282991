/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from 'react'
import { AES } from 'crypto-js'
// context
import { AuthContext } from '../../../Context/Auth'
// style
import { Cont } from '../../../Style/custom'
const ConversationFlow = () => {
    const { autoAuth, lang, botUser  } = useContext(AuthContext)
    const  hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser,location: '/intents'}), process.env.REACT_APP_KEY_CRYPTO_PATH).toString()
    console.log(`${process.env.REACT_APP_FRONT_OLD}authRemote#${hast}`);
    
    return( <div className={Cont.infoPanel}>
        <iframe src={`${process.env.REACT_APP_FRONT_OLD}authRemote#${hast}`} /> 
    </div>)
}
export default ConversationFlow