import React, { createContext, useState, useContext } from 'react'
import { useAnimate } from 'react-simple-animate'

const CustomBarContext = createContext()
const { Consumer, Provider } = CustomBarContext

const CustomBarProvider = ({children}) =>{
    
    const [templateId, setTemplateId] = useState(null)
    const [ViewShortCut, setViewShortCut] = useState(false)

    return(<Provider value={{
        templateId, setTemplateId,
        ViewShortCut, setViewShortCut
    }} >
        {children}
    </Provider>)
}

export { CustomBarProvider, Consumer as CustomBarConsumer, CustomBarContext }