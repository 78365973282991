/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { withTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
// function
import { toastMessage } from '../../../Recurent/ValidateGlobal'
// component
import Loader from '../../../Recurent/Loader'
import EventCalendar from './EventCalendar'
// context
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
// query
import { SHOW_DIARY_AGENT } from '../../../../Query/Messages'
// style
import { Button, Color } from '../../../../Style/custom'
import { messages } from '../../../../Style/messages'

const NewCalendar = ({t, createDiary, creatDiaryData}) => {
    const { lang, tokenSession, userAuth } = useContext(AuthContext)
    const { time, setTime, Events, setEvents, Client, ViewButtonsFrom, ViewCalendarFrom, setViewCalendarFrom, calendarPlay, calendarStyle } = useContext(MessagesContext)
    // query
    const { loading: loadingShowDiary, error: errorShowDiary, data: dataShowDiary, refetch: refetchShowDiary } = useQuery(SHOW_DIARY_AGENT, 
        { variables: { token: tokenSession , id_agent: userAuth.id_user}, errorPolicy: 'all' })

    useEffect(() => {
        if(dataShowDiary) {
            if(dataShowDiary.showSchedulingByAgentId){
                if(dataShowDiary.showSchedulingByAgentId.data){                    
                    setEvents(dataShowDiary.showSchedulingByAgentId.data.map(diary=> ({ ...diary, title: `${t('calendar.name')}: ${diary.client_name}`,
                    start: moment(diary.date_scheduling).format('YYYY-MM-DD HH:mm:ss')})))
                }
            }
        }
    }, [dataShowDiary])

    useEffect(() => {
        if(creatDiaryData){
            if(creatDiaryData.createScheduling){
                refetchShowDiary()
                setTimeout(() => {
                    setTime(null)
                }, 100);
            }
        }
    }, [creatDiaryData])

    useEffect(() => {
        if(errorShowDiary) {
            const ErrorLocal = errorShowDiary.graphQLErrors.shift()
            if(ErrorLocal){
                if(ErrorLocal.message==='Request failed with status code 404'){
                    toastMessage({message: t('messages.error.404')})
                } else {
                    // toastMessage({message: t('messages.error.default')})
                    return
                }
            }    
        }
    }, [errorShowDiary])

    if(loadingShowDiary) return <Loader height='100%' />

    return (
        <div className={messages.calendar}>
            <button 
                className={`${Button.primarySmall} justify between`}
                onClick={() => {                
                    setViewCalendarFrom(!ViewCalendarFrom)
                    calendarPlay(!ViewCalendarFrom)
                }}
                disabled={ViewButtonsFrom}
            >
                <FontAwesomeIcon  icon={faCalendar} /> 
                <div>
                    { time ? time : t('messages.from.scheduling') }
                </div>
                
            </button>
            <div className='contCaledar' style={calendarStyle}>
                <FullCalendar  
                    defaultView="timeGridWeek" 
                    plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin ]} 
                    header={{
                        left: 'prev,next,today',
                        center: 'title',
                        right: 'dayGridMonth, timeGridMonth, timeGridDay, timeGridWeek, save, close'
                    }}
                    firstDay={1}
                    locale={lang}
                    locales={[esLocale]}
                    slotLabelFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short'
                    }}
                    scrollTime={'8:00:00'}
                    columnHeaderText={date => `${t(`calendar.${moment(date).format('ddd')}`)}/${moment(date).format('DD')}`}
                    dateClick={ info => {
                        if(Events.find(e => e.start===moment(info.date).format('YYYY-MM-DD HH:mm:ss'))===undefined) {
                            if(moment().format('YYYY-MM-DD HH:mm:ss') <= moment(info.date).format('YYYY-MM-DD HH:mm:ss')) {
                                setTime(moment(info.date).format('YYYY-MM-DD HH:mm:ss'))
                            }
                        }
                    }}
                    customButtons={{
                        close: {
                            click: () => {
                                setViewCalendarFrom(!ViewCalendarFrom)
                                calendarPlay(!ViewCalendarFrom)  
                            }
                        },
                        save: {
                            text: t('calendar.save'),
                            click: () =>{ createDiary()}
                        } 
                    }}
                    eventClick={ info => {
                        console.log(info)                        
                    }}
                    events={[...Events, time ? {title: `${t('calendar.name')}: ${Client.client_name}`, ...Client,  start: time, color: Color.messageBot} : {}]}
                    eventRender={ ({ event, el }) => {
                        ReactDOM.render(<EventCalendar event={event}/>, el);
                        return el;
                    }}
                />
            </div>
        </div>    
    )
}

export default withTranslation()(NewCalendar)