import {style} from 'typestyle'

import { Color } from './custom'

export const conter = {
    cont: style({
        flex: '1 1 40%',
        height: 'calc(100% - 51px)',
        overflow: 'auto',
        display: 'flex',
        backgroundColor: Color.white,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'baseline',
        alignItems: 'flex-start'
    }),
    header: style({
        flex: '1 1 100%',
        padding: '36px',
        fontSize: '1.8em',
        display: 'grid',
        color: Color.dark,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: Color.hoverPrimary,
        fontWeight: 600,
        gridTemplateColumns: '50px auto 50px',
        gridGap: '5px',
        

        $nest:{
            '& .Left':{
                gridColumn: '1 / 2',
                display: 'flex',
            },
            '& .Right':{
                gridColumn: '3 / 4',
                display: 'flex',
                justifyContent: 'flex-end'
            },
            '& .title':{
                display: 'flex',
                gridColumn: '1 / 3',
                alignItems: 'center',
                $nest:{
                    '&.center':{
                        gridColumn: '2 / 3',
                    },
                    '& .icon':{
                        color: Color.text,
                        paddingRight: '5px'
                    }
                }
            },
        }
    }),

    body: style({
        flex: '1 1 auto',
        padding: '36px',
        width: 'calc(100% - 72px)'
    })
} 