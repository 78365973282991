/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, {useContext, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDatabase } from 'reactfire'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobot, faAsterisk, faCube, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { faTelegram, faFacebookMessenger, faWhatsappSquare,faWhatsapp, faSkype } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'
import emoji from 'react-easy-emoji'
import { getEmojiDataFromNative, Emoji } from 'emoji-mart'
import dataEmoji from 'emoji-mart/data/all.json'
import SentimentIcon from '../../../Recurent/Graphics/SentimentIcon';

// context
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
import { MessagesHistoryContext } from '../../../../Context/MessagesHistory'

// style
import { messages, userSelected } from '../../../../Style/messages'
import { Button, Color } from '../../../../Style/custom'


const ItemList = ({t, data, showTapsBot, tapBotRefetch, userSelected}) => {

    
    const { tokenSession, userAuth, botUser } = useContext(AuthContext)
    const { 
        PataformList
    } = useContext(MessagesContext)
    const {selectedRoom, setSelectedRoom, conversationRef,
           loadingConversations, setLoadingConversations,
           setLoadingChat} = useContext(MessagesHistoryContext)    
    // function   

    const selectConversation = (data) => {
        if(!loadingConversations && data.room_id != selectedRoom.room_id){
            setLoadingChat(true)
            setSelectedRoom(data)
            setTimeout(() => {
                setTitle()
                if(conversationRef) {
                    if(conversationRef.current) {                                
                        //chatRef.current.scrollIntoView(false);
                        conversationRef.current.scrollIntoView({block: 'end', behavior: "smooth" })                
                    }
                }
            }, 100);
        }
    }
    
    const getChannel = (data) => {
        const myChannel = PataformList ? PataformList.find(e => e.id === parseInt(data.platform_id)) ?  PataformList.find(e => e.id === parseInt(data.platform_id))  : { name: '' }: { name: '' }                    
        switch (data.platform_id) {
            case 7:
            let widgetChannel = myChannel
            return <>{(widgetChannel.icon!=='' && widgetChannel.icon!==' ') ? <img className='foto' src={widgetChannel.icon} /> : <FontAwesomeIcon className='iconchannel' icon={faRobot} />} <div className='name' >{widgetChannel.name}</div> </>                
            case 2:
                return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel asterisk' icon={faAsterisk} />} <div className='name' >{myChannel.name}</div> </>
            case 3:
                return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel skype' icon={faSkype} />} <div className='name' >{myChannel.name}</div> </>
            case 6:
                return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel telegram' icon={faTelegram} />} <div className='name' >{myChannel.name}</div> </>
            case 1:
                //return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel messager' icon={faFacebookMessenger} />} <div className='name' >{myChannel.name}</div> </>
                return <><FontAwesomeIcon className='iconchannel messager' icon={faFacebookMessenger} /> <div className='name' >{myChannel.name}</div> </>
            case 4:
            case 4:
                //return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel whatsapp' icon={faWhatsappSquare} />} <div className='name' > {myChannel.name}</div> </>
                return <> <FontAwesomeIcon className='iconchannel whatsapp' icon={faWhatsapp} /> <div className='name' > {myChannel.name}</div> </>
            case 8:
        return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel' icon={faCube} />} <div className='name' >{myChannel.name}</div> </>
            default:
        return <>{(myChannel.icon!=='' && myChannel.icon!==' ') ? <img className='foto' src={myChannel.icon} /> : <FontAwesomeIcon className='iconchannel' icon={faRobot} />} <div className='name' >{myChannel.name}</div> </>
        }
    }

    const setTitle = () => {        
        document.title = t('messages.info.title')
    }
    let lastMessagetemp = "";

    if (data.fk_channel_out == "1") {
      lastMessagetemp = "agent";

    } else if (data.fk_channel_out == "2") {
      lastMessagetemp = "client";

    } else if (data.fk_channel_out == "3") {
      lastMessagetemp = "bot";
    }
  
    const client_name = (data.client_name !== '') ? data.client_name : data.client_phone
    const showItem = true
    const client_phone = data.client_phone ? data.client_phone.trim() : ''
    const agent_name = data.agent_name? data.agent_name : t('messagesHistory.info.noAgent')
    const lastMessage = lastMessagetemp ? lastMessagetemp : t("messagesHistory.info.lastMessage");
    
    return(        
       
        <>{ showItem && 

        <div onClick={(e)=>selectConversation(data)} className={(userSelected && data.room_id==userSelected.room_id)?messages.userSelected:messages.itemList} style={{marginTop:'5px', borderColor: (userSelected && data.room_id==userSelected.room_id) ? Color.keos : 'lightgray'}}>

            <div className='contInfo'>


                <div className='contFoto' >
                    {data.img?<img className='foto' src={data.img} />: getChannel(data)}
                </div>

                <div className='info'>
                    {data.agent_sentiment != null ?
                        <div className='contSentiment'>
                            <SentimentIcon satisfactionLevel={data.agent_sentiment} size={15} />
                        </div>
                    : ''}

                    {client_name && <div><b>{`${t('messagesHistory.info.clientName')}:`}&nbsp;</b> { `${client_name}` }</div> }
                    {client_phone && <div><b>{`${t('messagesHistory.info.clientPhone')}:`}&nbsp;</b> { `${client_phone}` }</div> }
                    {agent_name && <div><b>{`${t('messagesHistory.info.agentName')}:`}&nbsp;</b> { `${agent_name}` }</div> }
                    {data.last_date && <div><b>{`${t('messagesHistory.info.time')}:`}&nbsp;</b><small>{` ${moment(data.last_date).format('YYYY-MM-DD')} ${moment(data.last_date).format('LT')}`}</small></div>}
                    {lastMessage &&  <div><b>{`${t("messagesHistory.info.lastMessage")}:`}&nbsp;</b>{" "}{`${lastMessage}`}</div>}
                   
                    <div>
                        {data.tag_name !== '' 
                        ? <div className='tag' style={{backgroundColor: '#e7e6e7', color:data.tag_color !== '' ? data.tag_color : Color.primary, borderColor:data.tag_color !== '' ? data.tag_color : Color.primary, borderStyle:'solid', borderWidth:'1px', fontSize:'8px' }} >{data.tag_name}</div> 
                        : ''}
                    </div>
                </div>
                
            </div>
        </div>
        }
      </>
       
    )
}

export default withTranslation()(ItemList)
