/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react'
import { withTranslation } from 'react-i18next'
import {faCog} from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation  } from '@apollo/react-hooks'
import Select from 'react-select'              
// context
import { BotConfigContext } from '../../../Context/BotConfig'
import { AuthContext } from '../../../Context/Auth'
// query and mutation
import { GET_CLOSE_BOT, GET_BOT_SETTING, SAVE_BOT_SETTING } from '../../../Query/BotConfig' 
// function
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
// componets
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import Conter from '../../Recurent/Conter'
// style
import {formElements , Button} from '../../../Style/custom'
import {topic} from '../../../Style/topic'
// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const BotConfig = ({t}) => {
    // context
    const {
        intent, setIntent,
        listIntents, setListIntents,
        sessionValidation, setSessionValidation,
        sessionCloseMinutes, setSessionCloseMinutes,
        sessionCloseMessage, setSessionCloseMessage,
        sessionCloseIntentId, setSessionCloseIntentId
    } = useContext(BotConfigContext)
    const { tokenSession, botUser } = useContext(AuthContext)    
    // query and mutation
    const { loading: botConfigLoading, error: botConfigError, data: botConfigData, refetch: botConfigRefetch} = useQuery(GET_BOT_SETTING,{variables:{token: tokenSession, bot_id: botUser.bot_id}})
    const [saveBotConfig, { loading: newBotConfigLoading, error: newBotConfigError, data: newBotConfigData }] = useMutation(SAVE_BOT_SETTING)
    const {loading: loadingIntents, error:errorShowIntents, data: dataShowIntents} = useQuery(GET_CLOSE_BOT,{variables:{token: tokenSession, bot_id: botUser.bot_id}})
    
    const saveBotConf = () => {

        if(intent === null){
            toastMessage({message: t('botConfig.error.default'),error:true});
            return;
        }

        const variables = {
            token: tokenSession,
            bot_id: botUser.bot_id,
            session_close_validity : (sessionValidation) === true ? 1 : 0,
            session_close_minutes_inactivity : sessionCloseMinutes,
            session_close_message: sessionCloseMessage,
            session_close_intent_id: intent.value
        }
                
        const [validate] = validateGlobal({
            token: 'String|Require',
            bot_id: 'Int|Require',
            session_close_validity: 'Int|Require',
            session_close_minutes_inactivity: 'Int|Require',
            session_close_message: 'String|Require',
            session_close_intent_id: 'Int|Require',
        },variables,{
            message: {
                ErrorDefault: t('botConfig.error.default'),
                CorrectValidate: t('botConfig.error.correct'),
                BotSessionValidity: t('botConfig.error.botSessionValidity'),
            },
            position: 'bottom-right',
            correctValidate: false
        })

        if(validate === true){ 
            saveBotConfig({ variables, errorPolicy: 'all'})
        }   

    }

    const changeIsBotSessionValidity = (e) => {
        setSessionValidation(e.target.checked)
        setSessionCloseMinutes(0)

    }


    useEffect(() => {
        if(botConfigData) {
            if(botConfigData.getBotSetting) {
                if(botConfigData.getBotSetting.data && botConfigData.getBotSetting.data.length > 0) {
                    const { 
                        session_close_validation,
                        session_close_minutes,
                        session_close_message,
                        session_close_intent_id
                    } = botConfigData.getBotSetting.data[0];
                    if(session_close_validation) {
                        setSessionValidation(session_close_validation === 1 ? true : false)
                    }
                    if(session_close_minutes) {
                        setSessionCloseMinutes(session_close_minutes)
                    }
                    if(session_close_message) {
                        setSessionCloseMessage(session_close_message)
                    }
                    if(session_close_intent_id != null) {
                        
                        setSessionCloseIntentId(session_close_intent_id);
                        if(listIntents){
                            let asignado = false
                            listIntents.filter(item => {
                                if(item.intent_id === session_close_intent_id) {
                                    setIntent({value:item.intent_id, label:item.intent_name});
                                    asignado = true
                                }
                            })
                            if(!asignado) {
                                setIntent({value:0, label:t('botConfig.noIntent')})
                            }
                        }
                    }
                } 
            } else {
                setSessionValidation(false)
                setSessionCloseMinutes(0)
            }
        }
    }, [botConfigData])

    useEffect(() => {
        if(newBotConfigData){
            if(newBotConfigData.alterBotSetting) {
                botConfigRefetch()
            }
        }
    }, [newBotConfigData])

    useEffect(() => {
        if(dataShowIntents){

            if(dataShowIntents.getCloseIntentsByBot){
                if(dataShowIntents.getCloseIntentsByBot.data){
                    const listIntentsAux = dataShowIntents.getCloseIntentsByBot.data; 
                    listIntentsAux.push({intent_id: 0, intent_name: t('botConfig.noIntent')})
                    setListIntents(listIntentsAux)    
                    if(sessionCloseIntentId != null) {                               
                        dataShowIntents.getCloseIntentsByBot.data.filter(item => {
                            if(item.intent_id === sessionCloseIntentId) {
                                setIntent({value:item.intent_id, label:item.intent_name});
                            }
                        })
                    } else {
                        setIntent({value:0, label:t('botConfig.noIntent')})
                    }
                }
            }
        }
    }, [dataShowIntents])

    if(botConfigLoading || loadingIntents) return <Loader height='100%' />

    if(botConfigError) return <Error />

    if(errorShowIntents) return <Error />

    if(newBotConfigError) return <Error />

    return (
        <Conter title={t('botConfig.title')} icon={faCog}>
            <div className={topic.cont}>
                {newBotConfigLoading ? <Loader height='100%' /> : <div className={topic.form}>
                    {/*SECCION DE VALIDEZ DE SESION DEL BOT*/}
                    <div className={topic.label}>{t('botConfig.bot_session')}</div>
                    <div className="field">

                          <FormControlLabel
                            control={<Switch checked={sessionValidation} onChange={ changeIsBotSessionValidity } color="primary"  />}
                            label={t('botConfig.bot_session_enable')}
                            //labelPlacement="start"                          
                          />                                                

                    </div>       
                    <div className="field">
                        <label className={formElements.formLabel}>{t('botConfig.bot_session_validity')}</label>
                        <div className="control">
                            <input 
                                type='number'
                                name="bot_session_validity"
                                min="0"
                                className={formElements.formInput} 
                                value={sessionCloseMinutes} 
                                onChange = {(e) =>{
                                    setSessionCloseMinutes(parseInt(e.target.value,10))}}
                                    />
                        </div>
                    </div>
                    <div className="field">
                        <label className={formElements.formLabel}>{t('botConfig.bot_session_message')}</label>
                        <div className="control">
                            
                            <textarea 
                                type='text'
                                name="session_close_message"
                                className={formElements.formInput} 
                                value={sessionCloseMessage} 
                                onChange = {(e) =>{
                                    setSessionCloseMessage(e.target.value)}}
                                />
                        </div>
                        <div className="grupItem">
                            <label className={formElements.formLabel}>{t('botConfig.bot_session_intent')}</label>
                           
                            <Select 
                                search={false}
                                value={intent}
                                onChange={e => setIntent(e)}
                                className='selectFlag'
                                marginTopOption='0px'
                                options={(Array.isArray(listIntents) && listIntents.length != 0 )  ? listIntents.map(intent => ({value:intent.intent_id, label:`${intent.intent_name}` })):[]}
                                />
                        
                        </div>
                    </div>

                    <div className={formElements.formSave}>
                        <button className={`${Button.primary}`} onClick={() => {saveBotConf()}}>{t('save')}</button>
                    </div>

                </div>}
            </div>
        </Conter>
    )
}

export default withTranslation()(BotConfig)
