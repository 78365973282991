import React, { createContext, useState, useContext, useRef } from 'react'
import { useQuery, useLazyQuery, useMutation  } from '@apollo/react-hooks'
// context
import { AuthContext } from '../../Context/Auth'
// query
import { SHOW_TAPS_BOT_ID, CREATE_PROCTION } from '../../Query/Messages'
import { SEARCH_CLIENT_BOT_ID } from '../../Query/Report'


const ReportContext = createContext()
const { Provider, Consumer } = ReportContext

const ReportProvider = ({children}) => {
    const {tokenSession, botUser} = useContext(AuthContext)
    const Boart = useRef(null)
    const [StartDate, setStartDate] = useState(null)
    const [EndDate, setEndDate] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null) 
    const [warpList, setWarpList] = useState([])
    //  query
    const { loading: loadingShowTap, error: errorShowTap, data: dataShowTap, refetch: refetchShowTap } = useQuery(SHOW_TAPS_BOT_ID, 
        {variables:{token: tokenSession, bot_id: botUser ? botUser.bot_id : null, type_tag_id: 1}})
    const [ SearchClientBotId, { loading: loadingSearchClientBotId, error: errorSearchClientBotId, 
            data: dataSearchClientBotId, refetch: refetchSearchClientBotId  } ] = useLazyQuery(SEARCH_CLIENT_BOT_ID)
    const [creatProction , {loading: creatProctionLoading, error: creatProctionError, data: creatProctionData }] = useMutation(CREATE_PROCTION)
    return (
        <Provider 
            value= {{
                Boart,
                StartDate, setStartDate,
                EndDate, setEndDate,
                focusedInput, setFocusedInput,
                warpList, setWarpList,
                loadingShowTap, errorShowTap, dataShowTap, refetchShowTap,
                SearchClientBotId, loadingSearchClientBotId, errorSearchClientBotId, dataSearchClientBotId, refetchSearchClientBotId,
                creatProction, creatProctionLoading, creatProctionError, creatProctionData
            }}
        >
            {children}
        </Provider>
    )
}

export { ReportProvider, Consumer as ReportConsumer, ReportContext }