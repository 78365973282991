/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useRef, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { faUser, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation  } from '@apollo/react-hooks'
// context
import { AuthContext } from '../../../Context/Auth'
// query
import { LOGIN_AUTH } from '../../../Query/Auth'
// component
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
// style
import { Button } from '../../../Style/custom'
import { login } from '../../../Style/auth'
// img
import icon from '../../../Asset/img/login/icon.png'

const LoginPage = ({t}) => {
    // graphql
    const [getLogin, { loading, data, error }] = useMutation (LOGIN_AUTH);
    // context
    const { viewPass, setViewPass, tokenAuth, setAuth, setAutoAuth, userAuth, setUserAuth, setTokenSession } = useContext(AuthContext)
    // ref
    const userRef = useRef(null)
    const passRef = useRef(null)
    // state
    // storage
    const token = tokenAuth
    // function
    const Login = () => {
        const variables = {
            token,
            user_name: userRef.current.value||'',
            password: passRef.current.value||''
        }

        //se especifica el tipo de variables que se envian y si son requeridas
        const [validate] = validateGlobal({
            token: 'String|Require', 
            user_name: 'String|Require',
            password: 'String|Require' 
        },
        variables,
        {
            message: {
                ErrorDefault: t('auth.error.userPass'),
                CorrectValidate: t('auth.error.correct')
            },
            correctValidate: false,
            position: 'bottom-right'
        }
        )
        // console.log('-->',validate);
        if(validate) {
            setAutoAuth({user_name:variables.user_name, password: variables.password})           
            getLogin({variables})
        }
    }
    
    useEffect(() => {    
        if(userAuth!==null){
            setAuth(true)    
            setTimeout(() => {

            }, 100);
        }
    })

    useEffect(() => {
        if(data){
            if(data.auth_login_back){
                if(data.auth_login_back.session){
                    setUserAuth(data.auth_login_back.session)
                    setTokenSession(data.auth_login_back.token)
                    localStorage.setItem('inicioSesion', new Date())
                } else {
                    if(data.auth_login_back.message){
                        toastMessage({message:  t(`auth.error.${data.auth_login_back.code}`) , error: true })
                    }
                }
            }
        }
    },[data])    

    if(error) return 
    // Error />

    if(loading) return <Loader />        

        
        return ( 
            <div className={login.cont}>
                <img className={login.logo} src={icon} />
                <div className={login.form}>
                    <div className={login.title}>{t('auth.login.title')}</div>
                    <div className={login.contInput}>
                        <FontAwesomeIcon className={login.icon} icon={faUser} />
                        <input ref={userRef} className={login.input} placeholder={t('auth.login.username')} />
                    </div>
                    <div className={login.contInput}>
                        <FontAwesomeIcon className={login.icon} icon={faLock} />
                        <input ref={passRef} className={login.input} type={viewPass?'text':'password'} placeholder={t('auth.login.password')} />
                        <FontAwesomeIcon 
                            className={login.icon} icon={viewPass?faEye:faEyeSlash} 
                            onClick={() => setViewPass(!viewPass)} 
                            style={{cursor: 'pointer'}} 
                        />
                    </div>
                    <button 
                        className={`${Button.primary} justify ${login.button}`}
                        onClick={()=>Login()}
                    >{t('auth.login.button')}</button>
                </div>
            </div>
        )
}
export default withTranslation()(LoginPage)