import {style} from 'typestyle'

import { Color } from './custom'

export const messages = {


    divArrow:style({
        float: 'right',
        display: 'flex',
        flexDirection : 'column',
        flex: '1 2 auto',
        position: 'relative',
        background: 'lightblue',
        borderRadius: '50%',
        width: '10px',
        height: '30px',
        maxWidth: '30px',
        textAlign: 'center',
        padding: '8px 10px 10px 10px',
        boxShadow: '3px 3px 5px 3px #d6d7d7',
        marginTop: '30%',
        marginRight: '5px'}),

    divNoArrow:style({
            float: 'right',
            width: '1%',
            display: 'flex',
            flexDirection : 'column',
            flex: '1 2 auto',
            position: 'relative',
            borderRadius: '50%',
            width: '10px',
            height: '30px',
            maxWidth: '30px',
            textAlign: 'center',
            padding: '8px 10px 10px 10px',
            marginTop: '40%',
            marginRight: '5px'}),

    divChat: style({
        width: '93%',
        maxHeight: '74vh',
        height: '74vh',
        '@media screen and (max-height: 768px)':  { 
            maxHeight: '58vh',
            height: '58vh'
        }
    }),

    columnCustom: style({
        width : '20%'
    }),

    cont: style({
        display: 'flex',
        flex: '1 1 40%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch '
    }),

    linkSelect: style({
        marginLeft: '10px',
        marginRight: '10px',
        //display: 'flex',
        justifyContent: 'center',
        
        $nest: {
            '&.success':{
                $nest:{
                    '& .name': {
                        borderColor: Color.primary,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '20px',                        
                        color: Color.primary
                    }
                }

            },

            '& .name': {
                padding: '5px 7px',
                color: Color.secon,
                cursor: 'pointer'
            },
            '& .option': {
                backgroundColor: Color.white,
                borderRadius: '5px',
                color: Color.text,
                display: 'none',
                flexDirection: 'column',
                position: 'absolute',
                marginTop: '30px',
                borderColor: Color.border,
                borderStyle: 'solid',
                borderWidth: '1px',
                zIndex: 2,
                //height: '200px',
                $nest: {
                    '& .title':{
                        width: 'calc(100% - 40px)',
                        padding: '10px 20px',
                        fontSize: '10px',
                        fontWeight: '700'
                    },
                    '& .item':{
                        //width: 'calc(100% - 40px)',
                        padding: '5px 20px',
                        borderBottomColor: Color.border,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        cursor: 'pointer',
                        color: Color.text,
                        textDecoration: 'none',

                        $nest: {
                            '& .icon':{
                                color: Color.primary
                            },
                            '&:hover':{
                                backgroundColor: Color.border
                            }
                        }
                    }
                }
            }
        }
    }),

    listChat: style({
        backgroundColor: Color.white,
        borderRightColor: Color.border,
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        padding: '10px',
        height: 'calc(100vh - 71px)',
        display: 'flex',
        maxWidth: '20vw',
        flexDirection: 'column',
        '@media screen and (max-width: 1378px)':  { 
            maxWidth: '24vw'
        },
        $nest: {
            '& .title': {
                color: Color.secon,
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 600,
                padding: '5px',
                borderBottomColor: Color.hoverPrimary,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                marginBottom: '15px',
                alignItems: 'center',

                $nest: {
                    '& .icon': {
                        paddingRight: '5px'
                    },
                    '& div': {
                        display:'inline-flex'
                    }
                }
            },
            '& .subtitle': {
                color: Color.text,
                fontWeight: 600,
                fontSize: '10px',
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            },
            '& .list': {
                flex: '1 1 auto',
                overflow: 'auto',
                textAlign: 'center'
            },
            '& .filter': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                $nest: {
                    '& .filterColumn': {
                        display: 'grid',
                        flexDirection: 'column',
                        flexBasis: 'auto',
                        flex: '1',
                        placeItems: 'center',
                        alignItems: 'center',
                        marginBottom:"5px"
                        
                    }
                }
            }
        }
    }),

    itemList: style({
        backgroundColor: Color.messagesBox,
        padding: '10px',
        marginBottom: '10px',
        //display: 'flex',
        display: 'inline-block',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderRadius: '5px',
        cursor: 'pointer',
        borderWidth: '2px',
        borderStyle: 'double',
        borderColor: Color.keos,
        boxShadow: `3px 3px 5px 3px #d6d7d7`,
        $nest: {

            '& .contAlert': {
                flex: '1 1 100%',
                display: 'flex',
                justifyContent: 'flex-end',
                //position: 'fixed',
                //left: '18vw',
                width: 0,
                height: 0,
                marginLeft: '-10px',
                $nest: {                    
                    '& .new': {
                       // position: 'absolute',
                        backgroundColor: Color.messageNew,
                        color: Color.white,
                        width: 'auto',
                        height: '30px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems: 'center',
                        padding: '5px',
                        transform: 'translate(16px, -16px)'
                    },
                    '& .transfer': {
                       // position: 'absolute',
                        backgroundColor: Color.green,
                        color: Color.white,
                        width: 'auto',
                        height: '30px',
                        borderRadius: '50%',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems: 'center',
                        padding: '10px',
                        transform: 'translate(16px, -16px)'
                    }

                }
            },
            '& .contInfo': {
                flex: '1 1 auto',
                display: 'grid',
                gridTemplateColumns: '45px 218px',
                gridGap: '5px',                
                $nest: {
                    '& .contFoto': {
                        gridArea: '1 / 1 / 2 / 2',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '5px',
                        $nest: {
                        '& .iconchannel': {
                            fontSize: '40px',
                            marginRight: '3px',
                            color: Color.hoverPrimary,

                            $nest: {
                                '&.skype': {
                                    color: Color.skype
                                },
                                '&.asterisk': {
                                    color: Color.asterisk
                                },
                                '&.telegram': {
                                    color: Color.telegram
                                },
                                '&.messager': {
                                    color: Color.messager
                                },
                                '&.whatsapp': {
                                    color: Color.whatsapp
                                },
                            }
                        },
                        '& .name': {
                            color: Color.Black,
                            fontWeight: "700",
                            fontSize: '10px',
                            marginTop: '5px',
                            textAlign: 'center'
                        },
                        '& .foto': {
                            backgroundColor: Color.white,
                            borderColor: Color.secon,
                            borderStyle: 'solid',
                            borderWidth: '0px',
                            width: '45px',
                            height: '45px',
                            borderRadius: '50%',
                            marginRight: '5px'

                        },
                        '& .iconFoto': {
                                borderColor: Color.secon,
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderRadius: '50%',
                                color: Color.textDark,
                                fontSize: '50px',
                                marginRight: '10px'
                        },
                    }
                    },
                    '& .info': {
                        color: Color.messageInfoColor,
                        display: 'flex',
                        flexDirection: 'column',
                        gridArea: '1 / 2 / 2 / 3',
                        borderLeftColor: '#b4b4b4',
                        borderLeftStyle: 'solid',
                        borderLeftWidth: 3,
                        paddingLeft: 10,
                        $nest: {
                            '& b':{
                                color:Color.black
                            },
                            '& div': {

                                fontSize: '13px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',

                                $nest: {
                                    '& small': {
                                        fontSize: '12px'
                                    }
                                }
                            },
                            '& .tag': {
                                color: Color.white,
                                padding: '3px 5px',
                                borderRadius: '10px',
                                fontSize: '11px',
                                marginTop: '5px'
                            },
                            '& button': {
                                marginTop: '5px'
                            },
                            '& .contAgentAlert':{
                                gridArea: '1 / 3 / 1 / 4',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end !important',
                                alignItems: 'flex-end',
                                $nest: {
                                    '& .iconAlert': {
                                        fontSize: '10px !important',
                                        marginRight: '3px',
                                        color: Color.hoverPrimary,
                                        height: 15,
                                        width: 15
                                    }
                                }
                            },
                            '& .contSentiment': {
                                gridArea: '1 / 3 / 1 / 1',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end !important',
                                alignItems: 'flex-end',
                                $nest: {
                                    '& .iconSentiment': {
                                        fontSize: '40px',
                                        marginRight: '3px',
                                        color: Color.hoverPrimary,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }),

    userSelected: style({
        backgroundColor: Color.messagesBoxSelected,
        padding: '10px',
        marginBottom: '10px',
        //display: 'flex',
        display: 'inline-block',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderRadius: '5px',
        cursor: 'pointer',
        borderWidth: '2px',
        borderStyle: 'double',
        borderColor: Color.keos,
        boxShadow: `3px 3px 5px 3px #d6d7d7`,
        $nest: {

            '& .contAlert': {
                flex: '1 1 100%',
                display: 'flex',
                justifyContent: 'flex-end',
                //position: 'fixed',
                //left: '18vw',
                width: 0,
                height: 0,
                marginLeft: '-10px',
                $nest: {                    
                    '& .new': {
                       // position: 'absolute',
                        backgroundColor: Color.messageNew,
                        color: Color.white,
                        width: 'auto',
                        height: '30px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems: 'center',
                        padding: '5px',
                        transform: 'translate(16px, -16px)'
                    },
                    '& .transfer': {
                       // position: 'absolute',
                        backgroundColor: Color.green,
                        color: Color.white,
                        width: 'auto',
                        height: '30px',
                        borderRadius: '50%',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems: 'center',
                        padding: '10px',
                        transform: 'translate(16px, -16px)'
                    }

                }
            },
            '& .contInfo': {
                flex: '1 1 auto',
                display: 'grid',
                gridTemplateColumns: '45px 218px',
                gridGap: '5px',                
                $nest: {
                    '& .contFoto': {
                        gridArea: '1 / 1 / 2 / 2',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '5px',
                        $nest: {
                        '& .iconchannel': {
                            fontSize: '40px',
                            marginRight: '3px',
                            color: Color.hoverPrimary,

                            $nest: {
                                '&.skype': {
                                    color: Color.skype
                                },
                                '&.asterisk': {
                                    color: Color.asterisk
                                },
                                '&.telegram': {
                                    color: Color.telegram
                                },
                                '&.messager': {
                                    color: Color.messager
                                },
                                '&.whatsapp': {
                                    color: Color.white
                                },
                            }
                        },
                        '& .name': {
                            color: Color.white,
                            fontWeight: "700",
                            fontSize: '10px',
                            marginTop: '5px',
                            textAlign: 'center'
                        },
                        '& .foto': {
                            backgroundColor: Color.white,
                            borderColor: Color.secon,
                            borderStyle: 'solid',
                            borderWidth: '0px',
                            width: '45px',
                            height: '45px',
                            borderRadius: '50%',
                            marginRight: '5px'

                        },
                        '& .iconFoto': {
                                borderColor: Color.secon,
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderRadius: '50%',
                                color: Color.textDark,
                                fontSize: '50px',
                                marginRight: '10px'
                        },
                    }
                    },
                    '& .info': {
                        color: Color.white,
                        display: 'flex',
                        flexDirection: 'column',
                        gridArea: '1 / 2 / 2 / 3',
                        borderLeftColor: Color.white,
                        borderLeftStyle: 'solid',
                        borderLeftWidth: 3,
                        paddingLeft: 10,
                        $nest: {
                            '& b':{
                                color:Color.white
                            },
                            '& div': {
                                fontSize: '13px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',

                                $nest: {
                                    '& small': {
                                        fontSize: '12px'
                                    }
                                }
                            },
                            '& .tag': {
                                color: Color.white+' !important',
                                borderColor: Color.white+' !important',
                                backgroundColor: Color.messagesBoxSelected+ ' !important' ,
                                padding: '3px 5px',
                                borderRadius: '10px',
                                fontSize: '11px',
                                marginTop: '5px'
                            },
                            '& button': {
                                marginTop: '5px'
                            },
                            '& .contAgentAlert':{
                                gridArea: '1 / 3 / 1 / 4',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end !important',
                                alignItems: 'flex-end',
                                $nest: {
                                    '& .iconAlert': {
                                        fontSize: '10px !important',
                                        marginRight: '3px',
                                        color: Color.hoverPrimary,
                                        height: 15,
                                        width: 15
                                    }
                                }
                            },
                            '& .contSentiment': {
                                gridArea: '1 / 3 / 1 / 1',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end !important',
                                alignItems: 'flex-end',
                                $nest: {
                                    '& .iconSentiment': {
                                        fontSize: '40px',
                                        marginRight: '3px',
                                        color: Color.hoverPrimary,
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }),

    buttonSelected: style({
        backgroundColor: Color.messageButtonSelected,
        color: Color.white,
        borderColor: Color.messageButtonSelected,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '4px',
        borderRadius: '3px',
        fontSize: '12px',
        cursor: 'pointer',
        width: '90%',

        $nest: {
            '&:hover': {
                backgroundColor: Color.messageButtonSelectedHover,
                borderColor: Color.messageButtonSelectedHover
            },
            '&:disabled': {
                backgroundColor: Color.messageButtonSelectedHover,
                borderColor: Color.messageButtonSelectedHover,
                cursor: 'no-drop'
            },
            '&.justify': {
                width: '100%'
            },
            '&.between': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',

                $nest: {
                    '& div':{
                        flex: '1 1 auto'
                    }
                }
            }
        }
    }),


    chat: style({
        backgroundColor: Color.white,
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 51px)',

        $nest:{
            '& .header': {
                textAlign: 'right',
                //flexDirection: 'row',
                justifyContent: 'space-between',                
                color: Color.keos,
                fontWeight: 600,
                padding: '15px',
                maxHeight: '68px',
                //alignItems: 'center',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: Color.keos,
                $nest: {
                    '& .viewFrom':{
                        display: 'inline-block',
                        float: 'right'
                    }
                }
            },
            '& .chat': {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                padding: '30px',
                backgroundColor: Color.white,
                color: Color.text,
                overflow: 'auto',

                $nest: {
                    '& .covertation': {
                        //display: 'contents',
                        display: 'flex',
                        flexDirection: 'column',
                        //height: '20vw',
                        //overflowY: 'auto',


                        $nest: {
                            '& .message': {
                                backgroundColor: Color.hoverPrimary,
                                color: Color.white,
                                borderRadius: '30px',
                                marginBottom: '10px',
                                padding: '10px 20px',
                                //flex: '1 1 auto',
                                display: 'inherit',
                                flexDirection: 'column',
                                alignSelf: 'flex-start',
                                marginRight: '150px',  
                                paddingBottom: '0px',
                                wordBreak: 'break-all', //Evitar que se salga                              

                                $nest: {
                                    '&.Agente':{
                                        backgroundColor: Color.messageBot,
                                        alignSelf: 'flex-end',
                                        marginLeft: '150px',
                                        marginRight: '0px',

                                    },
                                    '&.Bot':{
                                        backgroundColor: Color.messageBot,
                                        alignSelf: 'flex-end',
                                        marginLeft: '150px',
                                        marginRight: '0px',
                                    },
                                    '& .text': {
                                        marginBottom: '10px',
                                        
                                        $nest: {
                                            '& .imgUrl': {
                                                width: '100%',
                                                maxWidth: '350px',
                                                borderRadius: '10px'
                                            },
                                            '& .videoUrl': {
                                                width: '100%',
                                                maxWidth: '350px',
                                                borderRadius: '10px'
                                            }
                                        }
                                    },
                                    '& .time': {
                                        alignSelf: 'flex-end',
                                        fontSize: '8px'
                                    },
                                    '& .contBlock': {
                                        width: '100%',
                                        display: 'grid',
                                        gridTemplateColumns: '1fr',
                                        gridGap: '10px',
                                        marginBottom: '10px',

                                        $nest: {
                                            '& .video': {
                                                width: '100%'
                                            },
                                            '& .contButton': {
                                                width: '100%',
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(6, 1fr)',
                                                gridGap: '10px',
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            '& .contInput':{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                maxHeight: '47px',
                zIndex: 2,
                borderTopStyle:'solid',
                borderTopWidth: '1px',
                borderTopColor: Color.border,
                backgroundColor:Color.white,
                
                $nest: {
                    '& .emoji': {
                        fontSize: '28px',
                        color: Color.text,
                        alignSelf: 'center',
                        marginLeft: '5px',
                        marginRight: '7px',
                        cursor: 'pointer',

                        $nest: {
                            '&.active':{
                                color: Color.primary
                            }
                        }
                    },
                    '& .inputEmoji':{
                        flex: '1 1 auto',
                        borderWidth: '0px',
                        $nest: {
                            '&::placeholder': {
                                color: Color.border
                            },
                        }
                    },
                    '& .icon': {
                        padding: '0px 10px',
                        fontSize: '25px'
                    }
                }
            }
        }
    }),

    from: style({
        backgroundColor: Color.white,
        borderLeftColor: Color.border,
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        
        position: 'fixed',
        right: '0px',
        top: '120px',
        borderTopColor: Color.border,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderBottomColor: Color.border,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        maxWidth: '58%',
        maxHeight: 'calc(100vh - 190px)',
        overflow: 'auto',

        $nest: {


            '& .items': {
                //flex: '1 1 auto',
                $nest: {
                    '& .title': {
                        fontSize: '14px',
                        fontWeight: 600,
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: Color.primary,
                        borderBottomColor: Color.primary,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        textAlign: 'center'
                    },
                    '& .conTag': {
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gridGap: '4px',

                        $nest: {
                            '& .tag': {
                                borderRadius: '10px',
                                color: Color.white,
                                padding: ' 3px 10px',
                                marginBottom: '5px',
                                fontSize: '10px'
                            }
                        }
                    },

                    '& .contSelGrup':{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10px',
                        $nest: {
                            '& .label': {
                                color: Color.secon,
                                fontSize: '12px',
                                marginBottom: '8px',
                                fontWeight: 600                                
                            },
                            '& .input': {
                                borderColor: Color.border,
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                padding: '7px 10px',
                                borderRadius: '5px'
                            },
                            '& .selectFlag': {
                                backgroundColor:Color.gray,
                                $nest:{
                                    '& .select': {
                                        borderTopWidth: '0px',
                                        borderLeftWidth: '0px',
                                        borderBottomWidth: '0px',
                                        borderRadius: '0px',
                                        borderStyle: 'unset',
                                        height: '100%',
                                        $nest: {
                                                '& .item':{
                                                paddingRight: '10px',
                                                width: '5vw'
                                            },
                                        }
                                    },
                                    '& .contOptions':{
                                        position: 'fixed',
                                        
                                        $nest: {
                                            '& .option': {                                      
                                                $nest: {
                                                    '& img':{
                                                        width: '30px'
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            '& .grupItem': {
                                borderColor: Color.border,
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                overflow: 'hidden',

                                $nest: {
                                    '& .input': {
                                        borderWidth: '0px',
                                        flex: '1 1 auto'
                                    },
                                    '& button': {
                                        borderTopWidth: '0px',
                                        borderBottomWidth: '0px',
                                        borderRightWidth: '0px',
                                        borderRadius: '0px',
                                    }
                                }
                            },
                            '& .timePicker': {
                                
                                $nest: {
                                    '& .react-datetime-picker__wrapper': {
                                        borderRadius: '5px',
                                        padding: '2px 5px',
                                        borderColor: Color.border
                                    },
                                }
                            }
                        }
                    }
                }
            }
        }
    }), 




    calendar: style({
        marginBottom: '10px',
        
        $nest: {
            '& .contCaledar':{
                position: 'fixed',
                right: '-77vw',
                top: '120px',                
                backgroundColor: Color.white,
                padding: '5px',
                borderLeftColor: Color.border,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderTopColor: Color.border,
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderBottomColor: Color.border,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                maxWidth: '76%',
                maxHeight: 'calc(100vh - 187px)',
                overflow: 'auto',
                $nest: {
                    '@media screen and (max-width: 1024px)':{
                        maxWidth: '100%',
                        maxHeight: 'calc(100vh - 51px)',
                        top: '51px'
                    },
                    '& .event': {
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap:'wrap',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        height: '100%',
                        cursor: 'pointer',

                        $nest:{
                            '& .titleEvente': {
                                fontSize: '12px',
                                width: 'calc(100% - 5px)'
                            },
                            '& .timeEvente': {
                                width: 'calc(100% - 5px)',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                fontSize: '8px'
                            },
                            '& .tooltip': {
                                position: 'absolute',
                                backgroundColor: Color.hoverPrimary,
                                borderRadius: '5px',
                                color: Color.white,
                                padding: '5px 15px',
                                display: 'none',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                minWidth: '200px',
                                minHeight: '75px',

                                $nest: {
                                    '& .icon': {
                                        position: 'absolute',
                                        bottom: '-8px',
                                        color: Color.hoverPrimary
                                    },
                                    '& .itemsTooltip': {
                                        width: '100%',
                                        fontSize: '12px'
                                    }
                                }
                            },
                            '&:hover': {
                                $nest: {
                                    '& .tooltip': {
                                        display: 'flex'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}