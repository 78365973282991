import {style} from 'typestyle'

const Color = {
    white: '#fff',
    text: '#888',
    border: '#ccc',
    allhover: 'rgb(0,0,0,0.2)',
}

export const contLink = style({
    width: 'calc(100% - 20px)',
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textDecoration: 'none',
    backgroundColor: Color.allhover,
    borderRadius: '10px',
    marginBottom: '10px',
    
    $nest: {
        '& .img': {
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
        '& .contInfo': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            $nest: {
                '& .title': {
                    color: Color.white,
                    textDecoration: 'none',
                },
                '& .description': {
                    color: Color.white,
                    fontSize: '10px',
                    textDecoration: 'none',
                }
            }
        }
    }
})

export const img = style({
    width: '100%',
    maxWidth: '350px',
    borderRadius: '10px'
})