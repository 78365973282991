import React, {useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

const EventCalendar = ({ event, t }) => {
    const eventRef = useRef(null)
    // console.log(event.extendedProps)    
    const {client_name, client_email, client_phone} = event.extendedProps  

    return(
        <div className='event' ref={eventRef}>
            <div className='tooltip' style={{top: `-80px`}}>
                <div className='itemsTooltip'>{`${t('calendar.name')}: ${client_name}`}</div>
                <div className='itemsTooltip'>{`${t('calendar.mail')}: ${client_email}`}</div>
                <div className='itemsTooltip'>{`${t('calendar.phone')}: ${client_phone}`}</div>
                <FontAwesomeIcon className='icon' icon={faCaretDown} />
            </div>
            <div className='titleEvente'>{event.title}</div>
            <div className='timeEvente'>{moment(event.start).format('LT')}</div>
        </div>
    );
}

export default withTranslation()(EventCalendar)