import React, { createContext, useState } from 'react'

const BotConfigContext = createContext()
const { Consumer, Provider } = BotConfigContext

const BotConfigProvider = ({children}) =>{
    const [sessionCloseInactivity, setSessionCloseInactivity] = useState(false)
    const [intent, setIntent] = useState(null)
    const [listIntents, setListIntents] = useState(null)
    const [sessionValidation,setSessionValidation ] = useState(false)
    const [isSessionCloseValidity, setIsSessionCloseValidity] = useState(false)
    const [sessionCloseMinutes, setSessionCloseMinutes] = useState(0)
    const [sessionCloseMessage, setSessionCloseMessage] = useState("")
    const [ sessionCloseIntentId, setSessionCloseIntentId ] = useState("")
    return(<Provider value={{
        sessionCloseInactivity, setSessionCloseInactivity,
        intent, setIntent,
        listIntents, setListIntents,
        sessionValidation, setSessionValidation,
        isSessionCloseValidity, setIsSessionCloseValidity,
        sessionCloseMinutes, setSessionCloseMinutes,
        sessionCloseMessage, setSessionCloseMessage,
        sessionCloseIntentId, setSessionCloseIntentId
    }}>
        {children}
    </Provider>)
}

export { BotConfigProvider, Consumer as BotConfigConsumer, BotConfigContext }
