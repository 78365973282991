import {gql} from 'apollo-boost'

export const GET_DASHBOARD_METRICS = gql`
query getDashboardMetrics($token:String, $id_bot:Int){
  getDashboardMetrics(inputDashboardMetrics:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        allConversations
        tmo
        conversationsChannel{
          platform
          icon
          total
        }
        assignedConversations
        activeAgents
        answeredConversations
        unansweredConversations
        agentPendingConversations
        returnedConversations
        botServed
        agentServed
        busyAgents
        freeAgents
        agentSentiment
        botSentiment
        agentsPerformance{
            agentName
            active
            assignedConversations
            answeredConversations
            unansweredConversations
            totalConversations
            tmo
            returnedConversations
            alertedConversations
            agentSentiment
            positiveConversations
            negativeConversations
            neutralConversations
        }
    }
  }
}`

export const GET_CONVERSATIONS_ALL = gql`
query getAllConversations($token:String, $id_bot:Int){
  getAllConversations(inputConversation:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`

export const GET_TMO = gql`
query getTMO($token:String, $id_bot:Int){
  getTMO(inputTMO:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        tmo
    }
  }
}`

export const GET_CONVERSATIONS_CHANNEL = gql`
query getConversationsChannel($token:String, $id_bot:Int){
  getConversationsChannel(inputConversationChannel:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        platform,
        total
    }
  }
}`

export const GET_ASSIGNED_CONVERSATIONS = gql`
query getAssignedConversations($token:String, $id_bot:Int){
  getAssignedConversations(inputAssignedConversation:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`

export const GET_ACTIVE_AGENTS = gql`
query getActiveAgents($token:String, $id_bot:Int){
  getActiveAgents(inputActiveAgent:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`

export const GET_ANSWERED_CONVERSATIONS = gql`
query getAnsweredConversations($token:String, $id_bot:Int){
  getAnsweredConversations(inputAnsweredConversation:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`

export const GET_UNANSWERED_CONVERSATIONS = gql`
query getUnansweredConversations($token:String, $id_bot:Int){
  getUnansweredConversations(inputUnansweredConversation:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`

export const GET_AGENT_PENDING_CONVERSATIONS = gql`
query getAgentPendingConversations($token:String, $id_bot:Int){
  getAgentPendingConversations(inputAgentPendingConversation:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`

export const GET_RETURNED_CONVERSATIONS = gql`
query getReturnedConversations($token:String, $id_bot:Int){
  getReturnedConversations(inputReturnedConversation:{
    token:$token,
    id_bot:$id_bot
  }){
  code
    message
    data{
        total
    }
  }
}`