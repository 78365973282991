import React, {useContext, useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faChevronDown, faRobot, faUsers, faAngleDown} from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation  } from '@apollo/react-hooks'
import { useDatabase } from 'reactfire'
import { withTranslation } from 'react-i18next'
import profile from '../../../../Asset/img/profile/agent.png'
import moment from 'moment'
import { CHANGE_AGENT } from '../../../../Query/Derivate' 
import { GET_CONVERSATION, TRANSFER_AGENT} from '../../../../Query/MessagesHistory' 

// import emoji from 'react-easy-emoji'
// import dataAllEmoji from 'emoji-mart/data/all.json'
// context
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
import { MessagesHistoryContext } from '../../../../Context/MessagesHistory'
import { AgentContext } from '../../../../Context/Agent'
// componet
import CustomBar from '../../../Recurent/Mylibrery/CustomBar'
// function
import { validateGlobal, toastMessage } from '../../../Recurent/ValidateGlobal'
// style
import { messagesHistory } from '../../../../Style/messagesHistory'
import { Button, Color } from '../../../../Style/custom'

const Chat = ({children, selectedRoom, t, refetchChats}) => {    
    const { tokenSession, botUser, userAuth } = useContext(AuthContext)
    const scrollRef = useRef();
    const endElement = useRef();
    const {
        conversation, setConversation,
        setSelectedRoom,
        loadingChat,setLoadingChat
     } = useContext(MessagesHistoryContext)
    
    const { loading: conversationLoading, error: conversationError, data: conversationData, refetch: converRefetch} = useQuery(GET_CONVERSATION,{variables:{token: tokenSession, room_id: selectedRoom.room_id}})
    const [transferAgent, { loading: transferAgentLoading, error: transferAgentError, data: transferAgentData }] = useMutation(TRANSFER_AGENT)    
       
    const { 
        viewOption, setViewOption,
        loadingListAgents, 
        errorListAgents, 
        dataListAgent, 
        refetchListAgent
    } = useContext(AgentContext)

    // function        
    const [agents, setAgents] = useState(false)
    const [scrollPos, setScrollPos] = useState(null)
    const [bottom, setBottom] = useState(false)
    const agentRef = useRef(null)

    useEffect(() => {
        
        scrollRef.current.addEventListener('scroll',function(e) {
            
            setScrollPos(e.target.scrollTop)
        })
        scrollDown()
    }, [])

    useEffect(() => {
        
        if( Math.abs((scrollRef.current.scrollHeight - scrollRef.current.clientHeight) - scrollRef.current.scrollTop) <=2 ) {
            setBottom(true)
        } else {
            setBottom(false)
        }
    }, [scrollPos])

    useEffect(() => {
        if (dataListAgent){
            setAgents(dataListAgent.getAllAgentsOcupation.data)
            if (agentRef.current !== null){
                agentRef.current.scrollIntoView({block: 'end', behavior: "smooth" })
            }                    
        }
    }, [dataListAgent])

    useEffect(() => {
        if(conversationData && conversationData.getDetailMessages.code == 200){
          let messArray = conversationData.getDetailMessages.data.map((msj, pos) =>{
            return msj
          })
          setConversation(messArray)
        }
        setLoadingChat(false)
    }, [conversationData])


    const scrollDown = () =>{
        
        scrollRef.current.scroll({top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
            left: 0, 
            behavior: 'smooth',});
        
    }

    return(

        <div className={messagesHistory.chat}  >
            
                
                {selectedRoom.room_id != '' && (Array.isArray(agents) && agents.length > 0) &&
                
                    <div className={messagesHistory.linkSelect}>                            
                        <div style={{maxHeight: '200px'}}>
                        <div ref={agentRef} className="option" style={viewOption?({display:'flex'}):({display:'none'})}>                              
                        </div>
                        </div>
                    </div>

                    
                    }
                
            

            <c-wiz className={`bzJiD WQKmIb eO2Zfd lEX7Ob ${messagesHistory.cwiz}`} style={{display:"flex", opacity: loadingChat?'0.2':'1'}}>
                <div className={`Bl2pUd krjOGe chat ${messagesHistory.divChat}`} tabIndex={"-1"} ref={scrollRef}> 
                    {children}
                    <div ref={endElement}></div>
                </div>            
                {!bottom &&
                    <div className={`arrow ${messagesHistory.divArrow}`} onClick={() => scrollDown()}>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                }
                {bottom &&
                    <div className={`arrow ${messagesHistory.divNoArrow}`} onClick={() => scrollDown()}>
                        
                    </div>
                }
            </c-wiz>                
        </div>    
        

    )
}




export default  withTranslation()(Chat)