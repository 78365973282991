import { style } from 'typestyle'
// color
import { Color, Shadow } from './custom'

export const contList = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    $nest: {        
        '& .from': {
            padding: '10px',
            backgroundColor: Color.white,
            borderRadius: '5px',
            marginRight: '15px',
            width: 'auto',
            position: 'relative',
            overflow: 'hidden',
            
            $nest: {
                '& .buttonClose': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: Color.text,

                    $nest: {
                        '& .close':{
                            cursor: 'pointer'
                        }
                    }
                },
                '& .fromGrup': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    $nest: {
                        '& .grupItem': {
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '10px',
                            
                            $nest: {
                                '& .label': {
                                    fontWeight: 600,
                                    color: Color.text,
                                    marginBottom: '5px'
                                },
                                '& .date': {
                                    borderColor: Color.border,
                                    borderRadius: '5px',

                                    
                                    $nest: {
                                        '& .DateRangePickerInput__withBorder': {
                                            borderRadius: '5px',
                                            borderColor: 'transparent'
                                        },
                                        '& .DateInput_input': {
                                            width: 'calc(100% - 22px)'
                                        },
                                        '& .DateInput__small': {
                                            width: '110px'
                                        },
                                        '& .close': {
                                            color: Color.text
                                        }
                                    }
                                },
                                '& .input': {
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '5px',
                                    padding: '7px 5px'
                                },

                                '& .selectFlag': {
                                    backgroundColor:Color.gray,
                                    $nest:{
                                        '& .select': {
                                            
                                            borderTopWidth: '0px',
                                            borderLeftWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRadius: '0px',
                                            borderStyle: 'unset',
                                            height: '100%',
                                            $nest: {
                                                    '& .item':{
                                                    paddingRight: '10px',
                                                    width: '5vw'
                                                },
                                            }
                                        },
                                        '& .contOptions':{
                                            position: 'fixed',
                                            
                                            $nest: {
                                                '& .option': {                                      
                                                    $nest: {
                                                        '& img':{
                                                            width: '30px'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },                                
                                '& .grupItem': {
                                    borderColor: Color.border,
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflow: 'hidden',
    
                                    $nest: {
                                        '& .input': {
                                            borderWidth: '0px',
                                            flex: '1 1 auto'
                                        },
                                        '& button': {
                                            borderTopWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRightWidth: '0px',
                                            borderRadius: '0px',
                                        }
                                    }
                                },
                            }
                        }
                    }
                }
            }
        },
        '& .list': {
            flex: '1 1 40%',
            padding: '5px',
            backgroundColor: Color.white,
            borderRadius: '5px',
            height: '100%',
            overflow: 'hidden',

            $nest: {
                '& .listItems': {
                    height: 'auto',
                    maxHeight: '100%',
                    overflow: 'auto',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '5px',
                    width: '100%',

                    $nest: {
                        '& .item': {
                            borderRadius: '3px',
                            borderColor: Color.primary,
                            padding: '7px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            minHeight: '70px',
                            flexGrow: 0,
                            textDecoration: 'none',
                            color: Color.textDark,

                            $nest: {
                                '&.loader': {
                                    borderWidth: '0px',
                                },
                                '& .contInfo': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',

                                    $nest: {
                                        '& .info': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            fontSize: '11px',
                                            flexWrap: 'wrap',
                                            borderColor: Color.border,
                                            borderWidth: '0px',
                                            borderStyle: 'solid',
                                            padding: '6px',
                                            borderRadius: '5px',
                                            marginTop: '5px',
                                            marginBottom: '5px',

                                            $nest: {
                                                '& b': {
                                                    color: Color.primary,
                                                    fontSize: '13px',
                                                    marginRight: '5px'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
})

export const containerList = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'calc(40vh - 195px)',
    alignItems: 'flex-start',
    $nest: {        
        '& .from': {
            padding: '10px',
            backgroundColor: Color.white,
            borderRadius: '5px',
            marginRight: '15px',
            width: 'auto',
            position: 'relative',
            overflow: 'hidden',
            
            $nest: {
                '& .buttonClose': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: Color.text,

                    $nest: {
                        '& .close':{
                            cursor: 'pointer'
                        }
                    }
                },
                '& .fromGrup': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    $nest: {
                        '& .grupItem': {
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '10px',
                            
                            $nest: {
                                '& .label': {
                                    fontWeight: 600,
                                    color: Color.text,
                                    marginBottom: '5px'
                                },
                                '& .date': {
                                    borderColor: Color.border,
                                    borderRadius: '5px',

                                    
                                    $nest: {
                                        '& .DateRangePickerInput__withBorder': {
                                            borderRadius: '5px',
                                            borderColor: 'transparent'
                                        },
                                        '& .DateInput_input': {
                                            width: 'calc(100% - 22px)'
                                        },
                                        '& .DateInput__small': {
                                            width: '110px'
                                        },
                                        '& .close': {
                                            color: Color.text
                                        }
                                    }
                                },
                                '& .input': {
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '5px',
                                    padding: '7px 5px'
                                },

                                '& .selectFlag': {
                                    backgroundColor:Color.gray,
                                    $nest:{
                                        '& .select': {
                                            
                                            borderTopWidth: '0px',
                                            borderLeftWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRadius: '0px',
                                            borderStyle: 'unset',
                                            height: '100%',
                                            $nest: {
                                                    '& .item':{
                                                    paddingRight: '10px',
                                                    width: '5vw'
                                                },
                                            }
                                        },
                                        '& .contOptions':{
                                            position: 'fixed',
                                            
                                            $nest: {
                                                '& .option': {                                      
                                                    $nest: {
                                                        '& img':{
                                                            width: '30px'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },                                
                                '& .grupItem': {
                                    borderColor: Color.border,
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflow: 'hidden',
    
                                    $nest: {
                                        '& .input': {
                                            borderWidth: '0px',
                                            flex: '1 1 auto'
                                        },
                                        '& button': {
                                            borderTopWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRightWidth: '0px',
                                            borderRadius: '0px',
                                        }
                                    }
                                },
                            }
                        }
                    }
                }
            }
        },
        '& .list': {
            flex: '1 1 40%',
            padding: '5px',
            backgroundColor: Color.white,
            borderRadius: '5px',
            height: '100%',
            overflow: 'hidden',

            $nest: {
                '& .listItems': {
                    height: 'auto',
                    maxHeight: '100%',
                    overflow: 'auto',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '5px',
                    width: '100%',

                    $nest: {
                        '& .item': {
                            borderRadius: '3px',
                            borderColor: Color.primary,
                            padding: '7px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            minHeight: '70px',
                            flexGrow: 0,
                            textDecoration: 'none',
                            color: Color.textDark,

                            $nest: {
                                '&.loader': {
                                    borderWidth: '0px',
                                },
                                '& .contInfo': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',

                                    $nest: {
                                        '& .info': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            fontSize: '11px',
                                            flexWrap: 'wrap',
                                            borderColor: Color.border,
                                            borderWidth: '0px',
                                            borderStyle: 'solid',
                                            padding: '6px',
                                            borderRadius: '5px',
                                            marginTop: '5px',
                                            marginBottom: '5px',

                                            $nest: {
                                                '& b': {
                                                    color: Color.primary,
                                                    fontSize: '13px',
                                                    marginRight: '5px'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
})

export const containerListGraphics = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'calc(80vh - 195px)',
    alignItems: 'flex-start',
    $nest: {        
        '& .from': {
            padding: '10px',
            backgroundColor: Color.white,
            borderRadius: '5px',
            marginRight: '15px',
            width: 'auto',
            position: 'relative',
            overflow: 'hidden',
            
            $nest: {
                '& .buttonClose': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: Color.text,

                    $nest: {
                        '& .close':{
                            cursor: 'pointer'
                        }
                    }
                },
                '& .fromGrup': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    $nest: {
                        '& .grupItem': {
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '10px',
                            
                            $nest: {
                                '& .label': {
                                    fontWeight: 600,
                                    color: Color.text,
                                    marginBottom: '5px'
                                },
                                '& .date': {
                                    borderColor: Color.border,
                                    borderRadius: '5px',

                                    
                                    $nest: {
                                        '& .DateRangePickerInput__withBorder': {
                                            borderRadius: '5px',
                                            borderColor: 'transparent'
                                        },
                                        '& .DateInput_input': {
                                            width: 'calc(100% - 22px)'
                                        },
                                        '& .DateInput__small': {
                                            width: '110px'
                                        },
                                        '& .close': {
                                            color: Color.text
                                        }
                                    }
                                },
                                '& .input': {
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '5px',
                                    padding: '7px 5px'
                                },

                                '& .selectFlag': {
                                    backgroundColor:Color.gray,
                                    $nest:{
                                        '& .select': {
                                            
                                            borderTopWidth: '0px',
                                            borderLeftWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRadius: '0px',
                                            borderStyle: 'unset',
                                            height: '100%',
                                            $nest: {
                                                    '& .item':{
                                                    paddingRight: '10px',
                                                    width: '5vw'
                                                },
                                            }
                                        },
                                        '& .contOptions':{
                                            position: 'fixed',
                                            
                                            $nest: {
                                                '& .option': {                                      
                                                    $nest: {
                                                        '& img':{
                                                            width: '30px'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },                                
                                '& .grupItem': {
                                    borderColor: Color.border,
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflow: 'hidden',
    
                                    $nest: {
                                        '& .input': {
                                            borderWidth: '0px',
                                            flex: '1 1 auto'
                                        },
                                        '& button': {
                                            borderTopWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRightWidth: '0px',
                                            borderRadius: '0px',
                                        }
                                    }
                                },
                            }
                        }
                    }
                }
            }
        },
        '& .list': {
            flex: '1 1 40%',
            padding: '5px',
            backgroundColor: Color.white,
            borderRadius: '5px',
            height: '100%',
            overflow: 'hidden',

            $nest: {
                '& .listItems': {
                    height: 'auto',
                    maxHeight: '100%',
                    overflow: 'auto',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '5px',
                    width: '100%',

                    $nest: {
                        '& .item': {
                            borderRadius: '3px',
                            borderColor: Color.primary,
                            padding: '7px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            minHeight: '70px',
                            flexGrow: 0,
                            textDecoration: 'none',
                            color: Color.textDark,

                            $nest: {
                                '&.loader': {
                                    borderWidth: '0px',
                                },
                                '& .contInfo': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',

                                    $nest: {
                                        '& .info': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            fontSize: '11px',
                                            flexWrap: 'wrap',
                                            borderColor: Color.border,
                                            borderWidth: '0px',
                                            borderStyle: 'solid',
                                            padding: '6px',
                                            borderRadius: '5px',
                                            marginTop: '5px',
                                            marginBottom: '5px',

                                            $nest: {
                                                '& b': {
                                                    color: Color.primary,
                                                    fontSize: '13px',
                                                    marginRight: '5px'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
})