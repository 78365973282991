import {style} from "typestyle"

import {Color} from './custom'

export const headerTop = {
    contHeaderTop: style({
        width: 'calc(100% - 20px)',
        backgroundColor: Color.tree,
        color: Color.primary,
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
    }),

    optionLeft: style({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: '1 1 auto'
    }),
    
    optionRight: style({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '1 1 auto'
    }),

    logo: style({
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',

        $nest: {
            '& img': {
                width: '30px',
                height: '30px',
                marginRight: '5px'
            },
            '& label': {
                fontWeight: 700
            }
        }
    }),

    linkSelect: style({
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'center',
        
        $nest: {
            '&.success':{
                $nest:{
                    '& .name': {
                        borderColor: Color.primary,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '20px',                        
                        color: Color.primary
                    }
                }

            },

            '& .name': {
                padding: '5px 7px',
                color: Color.white,
                cursor: 'pointer'
            },
            '& .option': {
                backgroundColor: Color.white,
                borderRadius: '5px',
                color: Color.text,
                display: 'none',
                flexDirection: 'column',
                position: 'absolute',
                marginTop: '30px',
                borderColor: Color.border,
                borderStyle: 'solid',
                borderWidth: '1px',
                zIndex: 2,

                $nest: {
                    '& .title':{
                        width: 'calc(100% - 40px)',
                        padding: '10px 20px',
                        fontSize: '10px',
                        fontWeight: '700'
                    },
                    '& .item':{
                        width: 'calc(100% - 40px)',
                        padding: '5px 20px',
                        borderBottomColor: Color.border,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        cursor: 'pointer',
                        color: Color.text,
                        textDecoration: 'none',

                        $nest: {
                            '& .icon':{
                                color: Color.primary
                            },
                            '&:hover':{
                                backgroundColor: Color.border
                            }
                        }
                    }
                }
            }
        }
    }),

    buttonBot: style({
        borderColor: Color.primary,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '20px',
        padding: '5px 10px',
        cursor: 'pointer',

        $nest: {
            '&:hover':{
                backgroundColor: Color.primary,
                color: Color.white
            }
        }
    }),

    close: style({
        color: Color.dangertwo,
        marginRight: '10px',
        fontWeight: 700,
        padding: '5px 10px',
        borderRadius: '5px',
        cursor: 'pointer',

        $nest:{
            '&:hover':{
                color: Color.white,
                backgroundColor: Color.dangertwo
            }
        }
    }),

    buttonTestBotOff: style({
        backgroundColor: Color.white,
        marginRight: '10px',
        borderRadius: '5px',
        padding: '5px 10px',
        fontSize: '14px',
        cursor: 'pointer',

        $nest:{
            '&:hover':{
                color: Color.secon
            }
        }
    }),

    buttonTestBotOn: style({
        backgroundColor: Color.whatsapp,
        marginRight: '10px',
        borderRadius: '5px',
        padding: '5px 10px',
        fontSize: '14px',
        cursor: 'pointer',

        $nest:{
            '&:hover':{
                color: Color.secon
            }
        }
    })
}