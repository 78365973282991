import React, { createContext, useState, useRef } from 'react'

const MessagesHistoryContext = createContext()
const { Consumer, Provider } = MessagesHistoryContext

const MessagesHistoryProvider = ({children}) =>{
    const [messagesList, setMessagesList] = useState([])
    const [filteredMessagesList, setFilteredMessagesList] = useState([])
    const [selectedRoom, setSelectedRoom] = useState({room_id:''})
    const [conversation, setConversation] = useState([])
    const [conversationsRefect, setconversationsRefect] = useState(null)
    const [loadingConversations, setLoadingConversations] = useState(false)
    const [loadingChat, setLoadingChat] = useState(false)
    const conversationRef = useRef(null)
    return(<Provider value={{
        messagesList, setMessagesList,
        filteredMessagesList, setFilteredMessagesList,
        conversation, setConversation,
        conversationRef,
        selectedRoom, setSelectedRoom,
        loadingConversations, setLoadingConversations,
        loadingChat, setLoadingChat
    }}>
        {children}
    </Provider>)
}

export { MessagesHistoryProvider, Consumer as MessagesHistoryConsumer, MessagesHistoryContext }
