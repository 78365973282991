import React, { createContext, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
// query
import { SHOW_TAP_TOPIC, CREAR_TAP_TOPIC, DELETE_TAP_TOPIC, EDIT_TAP_TOPIC } from '../../Query/Topic'
// style
import {Color} from '../../Style/custom'

const TopicContext = createContext()
const { Consumer, Provider } = TopicContext

const TopicProvider = ({children}) =>{
    const [typeTopic, setTypeTopic] = useState(null)
    const [listTypeTopic, setListTypeTopic] = useState([])
    const [listTopic, setListTopic] = useState([])
    const [editTopic, setEditTopic] = useState(null)
    const [InputColor, setInputColor] = useState(Color.primary)
    const [Order, setOrder] = useState(listTopic ? listTopic.length+1 : 1)
    const [ViewColor, setViewColor] = useState(false)
    const [RefechKanban, setRefechKanban] = useState(false)
    // query and mutation
    const [getTap, { loading: tapLoading, error: tapError, data: tapData, refetch: tapRefetch }] = useLazyQuery(SHOW_TAP_TOPIC)
    const [creatTap, { loading: newTapLoading, error: newTapError, data: newTapData }] = useMutation(CREAR_TAP_TOPIC)
    const [deleteTap, { loading: deleteTapLoading, error: deleteTapError, data: deteleTapData }] = useMutation(DELETE_TAP_TOPIC)
    const [editTap, { loading: editTapLoading, error: editTapError, data: editTapData }] = useMutation(EDIT_TAP_TOPIC)

    return(<Provider value={{
        typeTopic, setTypeTopic,
        listTypeTopic, setListTypeTopic,
        listTopic, setListTopic,
        editTopic, setEditTopic,
        InputColor, setInputColor,
        Order, setOrder,
        ViewColor, setViewColor,
        RefechKanban, setRefechKanban,
        getTap, tapLoading, tapError, tapData, tapRefetch,
        creatTap, newTapLoading, newTapError, newTapData,
        deleteTap, deleteTapLoading, deleteTapError, deteleTapData,
        editTap, editTapLoading, editTapError, editTapData,
    }}>
        {children}
    </Provider>)
}

export { TopicProvider, Consumer as TopicConsumer, TopicContext }