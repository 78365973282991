import React from 'react'

import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import PieChart from '../../../Recurent/Graphics/PieChart'
import SentimentIcon from '../../../Recurent/Graphics/SentimentIcon';

import ic_h_bar_pink from '../../../../Asset/img/profile/ic_h_bar_pink.png'
import ic_h_bar_blue from '../../../../Asset/img/profile/ic_h_bar_blue.png'
import ic_bot from '../../../../Asset/img/profile/ic_bot.png'
import ic_headphones from '../../../../Asset/img/profile/ic_headphones.png'
import ic_user_check from '../../../../Asset/img/profile/ic_user_check.png'
import ic_user_download from '../../../../Asset/img/profile/ic_user_download.png'

import { contList } from '../../../../Style/dashboard'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      height: 600,
      padding: 30,
    },
    title: {
        fontSize: 40,
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    subTitle: {
        fontSize: 20,
        textAlign: 'center',
        padding: 5,
    },
    cardIcon: {
        float: 'left',
        height: 40,
        width: 80,
        padding: 10,
        paddingLeft: 30,
    },
    panel: {
        margin: 5,
    },
    cardText: {
        float: 'left',
        alignContent: 'center',
        height: 350,
        width: 600,
    },
    cardIcons: {
        float: 'left',
    },
    cardIconsBot: {
      float: 'left',
    },
    icons: {
        float: 'left',
        width: 600,
        paddingTop: 15,
        paddingLeft: 55
    },
    containerIconBot: {
      float: 'left',
      width: 300,
      paddingTop: 10,
      paddingLeft: 10,
  },
    iconoSize: {
      width: 100,
      height: 15,
      paddingTop: 2,
    },
    divIcon: {
      width: '22%',
      float: "left",
    },
    divIconBot: {
      width: '22%',
      float: "left",
    },
    divIconUser: {
      width: '15%',
      float: "left",
    },
    divText: {
      width: '80%',
      fontWeight: 'bold',
      float: "left",
      fontSize: 20,
    },
    divTextUser: {
      width: '80%',
      float: "left",
      fontSize: 15,
    },
    linear: {
        width: 1,
        backgroundColor: 'black',
        height: 440, 
        float: 'left'
    },
    divValue:{
      fontSize: 80,
      height: 80,
      paddingLeft: 85,
      float: 'left'
    },
    iconBot: {
      width: 70,
      height: 60,
      paddingTop: 2,
    },
    iconUser: {
      width: 50,
      height: 30,
      paddingTop: 2,
    },
  }));

const CardAttentionToConvertations = ({t,title, botServed, agentServed, answeredConversations, unansweredConversations, agentPendingConversations, botSatisfactionLevel, agentSatisfactionLevel})=> {

    const classes = useStyles();

    return (
        <Card className={classes.root} style={{margin:'10px'}}>
            <Typography className={classes.title} color="textPrimary" gutterBottom>
                {title || "Title"}
            </Typography>

            <div style={{width: '42%', float: 'left'}}>
              <PieChart t={t} botServed={botServed} agentServed={agentServed}/>
            </div>

            <div className={classes.linear}></div>
            
            <div style={{ width: '45%',  float: 'left'}}>

            <div className={contList} >
                <div className='list' >
                <div className={classes.cardIcons}>
                    <div className={classes.icons}>
                      <div className={classes.divIcon}>
                        <img className={classes.iconoSize} src={ic_h_bar_pink} alt="icon_header_card"/>
                      </div>
                      <div className={classes.divText}>
                        <h2><b>{t('dashboard.automaticBotLabel')}</b></h2>
                      </div>
                    </div>
                </div> 

                <div className={classes.cardIconsBot}>
                  <div className={classes.divValue}>
                    <h2>{botServed}</h2>
                  </div>
                  <div className={classes.containerIconBot}>
                    <div className={classes.divIconBot}>
                      <img className={classes.iconBot} src={ic_bot} alt="icon_header_card"/>
                    </div>
                  </div>
                </div>
                <div className={classes.cardIcons}>
                    <div className={classes.icons}>
                      <div className={classes.divIcon}>
                        <img className={classes.iconoSize} src={ic_h_bar_blue} alt="icon_header_card"/>
                      </div>
                      <div className={classes.divText}>
                        <h2><b>{t('dashboard.agentLabel')}</b></h2>
                      </div>
                    </div>
                </div> 
                <div className={classes.cardIconsBot}>
                  <div className={classes.divValue}>
                    <h2>{agentServed}</h2>
                  </div>
                  <div className={classes.containerIconBot}>
                    <div className={classes.divIconBot}>
                      <img className={classes.iconBot} src={ic_headphones} alt="icon_header_card"/>
                    </div>
                  </div>
                </div>

                <div className={classes.cardIcons}>
                    <div className={classes.icons}>
                        <h2>{t('dashboard.separatosLabel')}:</h2>
                    </div>
                </div>

                <div className={classes.cardIcons}>
                    <div className={classes.icons}>
                      <div className={classes.divIconUser}>
                        <img className={classes.iconUser} src={ic_user_check} alt="icon_header_card"/>
                      </div>
                      <div className={classes.divTextUser}>
                        <h2>{answeredConversations}: {t('dashboard.drivenAndAnswerLabel')}</h2>
                      </div>
                    </div>
                </div>

                <div className={classes.cardIcons}>
                    <div className={classes.icons}>
                      <div className={classes.divIconUser}>
                        <img className={classes.iconUser} src={ic_user_download} alt="icon_header_card"/>
                      </div>
                      <div className={classes.divTextUser}>
                        <h2>{unansweredConversations}: {t('dashboard.drivenLabel')}</h2>
                      </div>
                    </div>
                </div>

                <div className={classes.cardIcons}>
                    <div className={classes.icons}>
                      <div className={classes.divIconUser}>
                        <img className={classes.iconUser} src={ic_user_check} alt="icon_header_card"/>
                      </div>
                      <div className={classes.divTextUser}>
                        <h2>{agentPendingConversations}: {t('dashboard.searchAgentLabel')}</h2>
                      </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* <div style={{width: '12%', float: 'left'}}>
            <div className={contList} >
                <div className='list' >
                  <div className={classes.divText}>
                    <h2><b>{t('dashboard.botSatisfactionLevel')}</b></h2>
                  </div>

                <div className={classes.cardIconsBot}>
                  <div className={classes.containerIconBot}>
                    <div className={classes.divIconBot}>
                      <SentimentIcon satisfactionLevel={botSatisfactionLevel} />
                    </div>
                  </div>
                </div>
                <div className={classes.divText}>
                  <h2><b>{t('dashboard.agentSatisfactionLevel')}</b></h2>
                </div>
                <div className={classes.cardIconsBot}>
                  <div className={classes.containerIconBot}>
                    <div className={classes.divIconBot}>
                      <SentimentIcon satisfactionLevel={agentSatisfactionLevel} />
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div> */}
        </Card>
      )
}

export default withTranslation()(CardAttentionToConvertations)