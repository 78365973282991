/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react'
import { withTranslation } from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faEdit, faTrashAlt, faPlus, faFileAlt, faSave, faClock} from '@fortawesome/free-solid-svg-icons'

import { useQuery, useMutation  } from '@apollo/react-hooks'
import Select from 'react-select'              

// context

import { SchedulesBySkillContext } from '../../../Context/SchedulesBySkill'
import { AuthContext } from '../../../Context/Auth'
// query and mutation
import { SHOW_TAGS, SHOW_TAG_SCHEDULES, CREATE_TAG_SCHEDULE, UPDATE_TAG_SCHEDULE, DELETE_TAG_SCHEDULE} from '../../../Query/SchedulesBySkill' 

// function
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
// components
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import Conter from '../../Recurent/Conter'

import paginationFactory from 'react-bootstrap-table2-paginator';  
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';  


///IMPORTS FOR DIALOGS

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CodeIcon from '@material-ui/icons/Code';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
//import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

//import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// style
import 'bootstrap/dist/css/bootstrap.min.css';
import {formElements , Button as ButtonSt} from '../../../Style/custom'

import {topic} from '../../../Style/topic'
const SchedulesBySkill = ({t}) => {
    const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignContent: 'baseline',
            alignItems: 'flex-start',
        },
        avatar: {
          backgroundColor: blue[100],
          color: blue[600],
        },
        dialogPaper: {
          minHeight: '80vh',
          maxHeight: '80vh',
        },
        divColumn:{
            flexGrow: 1,
        },
        divButton:{
            textAlign: 'right',
        },
        selectTime:{
            width: '100%',
            backgroundColor: '#fff',
            borderColor: '#d9d9d9 #ccc #b3b3b3',
            borderRadius: '4px',
            border: '1px solid #ccc',
            color: '#6f6f6f',
            cursor: 'default',
            height: '36px',
            outline: 0,
        }
      });
    const classes = useStyles();

    //context
    const {
        deleteScheduleId, setDeleteScheduleId,
        editScheduleId, setEditScheduleId,
        editing, setEditing,
        schedules, setSchedules,
        tags, setTags,
        scheduleTag, setScheduleTag,
        scheduleDays, setScheduleDays,
        scheduleHours, setScheduleHours,
        scheduleMessage, setScheduleMessage,
        createEditDialog, setCreateEditDialog,
        typeDialog, setTypeDialog,
        dialog, setDialog,
        deleteDialog, setDeleteDialog,
        variable, setVariable,
        variables, setVariables
    } = useContext(SchedulesBySkillContext)

    const days = [
        {
          label: t('schedules.daysOfWeek.monday'),
          value: 0
        },
        {
          label: t('schedules.daysOfWeek.tuesday'),
          value: 1
        },
        {
          label: t('schedules.daysOfWeek.wednesday'),
          value: 2
        },
        {
          label: t('schedules.daysOfWeek.thursday'),
          value: 3
        },
        {
          label: t('schedules.daysOfWeek.friday'),
          value: 4
        },
        {
          label: t('schedules.daysOfWeek.saturday'),
          value: 5
        },
        {
          label: t('schedules.daysOfWeek.sunday'),
          value: 6
        }
    ];

    const { tokenSession, botUser, userAuth } = useContext(AuthContext)    
    // query and mutation
    const { loading: schedulesLoading, error: schedulesError, data: schedulesData, refetch: schedulesRefetch} = useQuery(SHOW_TAG_SCHEDULES,{variables:{token: tokenSession, bot_id: botUser.bot_id}})
    const { loading: tagsLoading, error: tagsError, data: tagsData, refetch: tagsRefetch} = useQuery(SHOW_TAGS,{variables:{token: tokenSession, bot_id: botUser.bot_id, type_tag_id: 2}})
    const [createSchedule, { loading: createScheduleLoading, error: createScheduleError, data: createScheduleData }] = useMutation(CREATE_TAG_SCHEDULE)
    const [delSchedule, { loading: delScheduleLoading, error: delScheduleError, data: delScheduleData }] = useMutation(DELETE_TAG_SCHEDULE)



    const editSchedule = (cell, row) => {
        let daysEdit = [];
        row.days.map(d => daysEdit.push({value: days[d].value, label: days[d].label}));
        let hoursEdit = [];
        row.hours.map(h => hoursEdit.push({startTime: h.start_time, endTime: h.end_time}));
        setScheduleDays(daysEdit)
        setScheduleHours(hoursEdit)
        setScheduleMessage(row.message)
        setScheduleTag({value: row.tag_id, label: row.tag_name})
        setEditing(true)
        setCreateEditDialog(true)
    }


    const deleteSchedule = (cell, row) => {
        let daysEdit = [];
        row.days.map(d => daysEdit.push({value: days[d].value, label: days[d].label}));
        let hoursEdit = [];
        row.hours.map(h => hoursEdit.push({startTime: h.start_time, endTime: h.end_time}));
        setScheduleDays(daysEdit)
        setScheduleHours(hoursEdit)
        setScheduleTag({value: row.tag_id, label: row.tag_name})
        setDeleteDialog(true)
    } 

    const GetActionFormat = (cell, row) => {
        return (
            <div>
                <button className={`${ButtonSt.table_edit} button`}  onClick={editSchedule.bind(this,cell,row)} >
                            <FontAwesomeIcon icon={faEdit} /> 
                </button>
                <button className={`${ButtonSt.table_delete} button`}  onClick={deleteSchedule.bind(this,cell,row)} >
                                <FontAwesomeIcon icon={faTrashAlt} /> 
                    </button>
            </div>
        );
    }

    // function to close variables and type dialog
    const handleClose = () => {
        //console.log("Days: ", scheduleDays);
        //console.log("Hours: ", scheduleHours);
        //console.log("Skill: ", scheduleTag);
        setCreateEditDialog(false);
        setScheduleDays([]);
        setScheduleHours([{startTime: '', endTime: ''}]);
        setScheduleMessage("");
        setScheduleTag("");
        setEditing(false);
      };

    // function to close variables and type dialog
    const handleSave = () => {
        // Validaciones previas:
        // Días:
        let daysValidated;
        if (scheduleDays && scheduleDays.length > 0){
            daysValidated = true;
        }
        // Horas:
        let hoursValidated;
        let validHours = true;
        if (scheduleHours && scheduleHours.length > 0){
            for (let i = 0; i < scheduleHours.length; i++) {
                if (!(scheduleHours[i].startTime.length > 0
                    && scheduleHours[i].endTime.length > 0
                    && validateTimeSlot(scheduleHours[i].startTime, scheduleHours[i].endTime))) {
                    validHours = false;
                    break;
                }
            }
        }
        if (validHours) hoursValidated = true;
        const variables = {
            daysValidated: daysValidated,
            hoursValidated: hoursValidated
        }
        const [validate] = validateGlobal({
            daysValidated: 'Boolean|Require',
            hoursValidated: 'Boolean|Require'
        },variables,{
            message: {
                daysValidated: t('schedules.error.days'),
                hoursValidated: t('schedules.error.hours'),
                ErrorDefault: t('schedules.error.default')
            },
            position: 'bottom-right',
            correctValidate: false
        })

        // Guardar cambios:
        if (validate) addSchedule();
      };

    // function to close variables dialog and delete entry
    const handleCloseDelete = () => {
        setDeleteDialog(false);
        setScheduleDays([]);
        setScheduleHours([{startTime: '', endTime: ''}]);
        setScheduleTag("");
    };

    // Function to delete an entry
    const handleConfirmDelete = () => {
        let daysSave = [];
        scheduleDays.map(d => daysSave.push(d.value));
        let hoursSave = [];
        scheduleHours.map(h => hoursSave.push({start_time: h.startTime, end_time: h.endTime}));
        const variables = {
            token: tokenSession,
            tag_id: scheduleTag.value,
            days: daysSave,
            hours: hoursSave,
        }

        const [validate] = validateGlobal({
            token: 'String|Require',
            tag_id: 'Int|Require',
            days: 'Require',
            hours: 'Require'
        },variables,{
            message: {
                ErrorDefault: t('schedules.error.default'),
                CorrectValidate: t('schedules.error.correct')
            },
            position: 'bottom-right',
            correctValidate: false
        })
        if(validate) {
            delSchedule({ variables, errorPolicy: 'all'})
        }
        handleCloseDelete();
    };

    // Function to select the types of templates for the select filter
    const selectOptionsTemplateTypes = {
        1: t("templates.template_type.push"),
        2: t("templates.template_type.response")
    };

    // Columns of the template data
    const columns = [
        {
          dataField: 'tag_name',  
          text: t('schedules.skill'),  
          sort:true  ,
          style:{'width' : '270px'}
         
        }, {  
          dataField: 'days',  
          text: t('schedules.days'),
          sort: true,
          formatter: (cell, row) => {
            let dayColumn = [];
            cell.map((day) => {
                dayColumn.push(days[day].label);
            })
            return(dayColumn.join(', '));
          }
        }, {
          dataField: 'hours',
          text: t('schedules.hours'),
          sort: true,
          formatter: (cell, row) => {
            let hourColumn = [];
            cell.map((hour) => {
                hourColumn.push(hour.start_time + ' - ' + hour.end_time);
            })
            return(hourColumn.join(', '));
          }
        }, {
          dataField: 'message',
          text: t('schedules.message'),
          sort: true
        },
        {
            text: t('templates.action'),
            dataField: "",
            formatter: GetActionFormat,
            classes: "p-1",
            style:{'width' : '60px'}
            
        }
    ]

    // Config of the columns
    const options = {  
        page: 2,   
        sizePerPageList: [ {  
                text: '5', value: 5 
            }, {  
                text: '10', value: 10  
            }, {  
                text: 'All', value: schedules.length  
            } ],   
        sizePerPage: 5,   
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: 'Prev',  
        nextPage: 'Next', 
        firstPage: 'First',   
        lastPage: 'Last',   
        paginationPosition: 'top'    
    };

    // To add a new entry, verify variables and write with mutation gql. WRITE AND READ FROM DB.
    const addSchedule = () => {
        let daysSave = [];
        scheduleDays.map(d => daysSave.push(d.value));
        let hoursSave = [];
        scheduleHours.map(h => hoursSave.push({start_time: h.startTime, end_time: h.endTime}));
        const variables = {
            token: tokenSession,
            tag_id: scheduleTag.value,
            days: daysSave,
            hours: hoursSave,
            message: scheduleMessage
        }

        const [validate] = validateGlobal({
            token: 'String|Require',
            tag_id: 'Int|Require',
            days: 'Require',
            hours: 'Require',
            message: 'String|Require'
        },variables,{
            message: {
                tag_id: t('schedules.error.skill'),
                message: t('schedules.error.message'),
                ErrorDefault: t('schedules.error.default')
            },
            position: 'bottom-right',
            correctValidate: false
        })

        //if template input from the user is valid, then save template
        if(validate) {
            createSchedule({ variables, errorPolicy: 'all'})
            // Cerrar diálogo
            handleClose();
        }
    }


    const validateTimeSlot = (startTime, endTime) => {
        let sTime = startTime.split(":");
        let eTime = endTime.split(":");
        let startMinutes = (sTime[0] * 60) + (sTime[1] * 1);
        let endMinutes = (eTime[0] * 60) + (eTime[1] * 1);
        return endMinutes > startMinutes;
    }

    // In variable 'typeArr' will be stored all types of templates in the project

    useEffect(()=>{
        if(schedulesData && schedulesData.showTagSchedules.data){
            if(schedulesData.showTagSchedules.data.length > 0){
                if(schedulesData.showTagSchedules.data[0].tag_id == null){
                    setSchedules([]);
                } else {
                    setSchedules(schedulesData.showTagSchedules.data);
                }
            } else {
                setSchedules([]);
            }           
        } else {
            setSchedules([]);
        }
    },[schedulesData])

    useEffect(()=>{
        if(tagsData && tagsData.showTagsByBotId.data){
            let tags = [];
            tagsData.showTagsByBotId.data.map((tag) => {
                tags.push({value: tag.tag_id, label: tag.tag_name})
            })
            setTags(tags);
        }
    },[tagsData])

    useEffect(() => {
        if(createScheduleData){
            if(createScheduleData.createTagSchedule){
                schedulesRefetch()
                handleClose()
            }
        }
    }, [createScheduleData])
    
    useEffect(() => {
        if(delScheduleData){
            if(delScheduleData.deleteTagSchedule){
                schedulesRefetch()
                handleCloseDelete()
            }
        }
    }, [delScheduleData])

    if(schedulesLoading) return <Loader height='100%' />

    // if(schedulesError) return <Error />

    if(createScheduleError) return <Error />
    
    return (
        <Conter title={t('schedules.title')} icon={faClock}>
            {<>
                <p className="py-4">{t('schedules.use')}</p>

                <button className={`${ButtonSt.primary}`}  onClick={()=> setCreateEditDialog(true)} >
                       <FontAwesomeIcon icon={faPlus} /> {t('schedules.create_schedule')}
                </button>
                {/*Dialog for the create and edit window*/}
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={createEditDialog} fullWidth={true} classes={{paper: classes.dialogPaper}}>
                    <DialogTitle id="simple-dialog-title">{t(editing?'schedules.edit_schedule':'schedules.create_schedule')}</DialogTitle>
                    <DialogContent dividers={true}>
                    <label className={`${topic.label} small name`}>{t('schedules.dayDays')}</label>
                    <Select
                        value={scheduleDays?scheduleDays:[]}
                        onChange={e => setScheduleDays(e)}
                        options={days}
                        isMulti={true}
                        isSearchable={true}

                    />
                    {scheduleHours.map((hours, iHour) => (
                    <>
                    <div className={classes.root}>
                      <div className={classes.divColumn}>
                        {iHour > 0 ? '' : <label className={`${topic.label} small name`}>{t('schedules.startTime')}</label>}
                        <input
                          type="time"
                          className="form-control time-picker"
                          name="scheduleStartTime"
                          value={hours.startTime}
                          onChange={e => {
                            let val = e.target.value;
                            setScheduleHours(h => {
                                let arr = [...h];
                                arr[iHour].startTime = val;
                                return arr;
                            })
                          }}
                          step="60"
                          min="00:00"
                          max="23:59"
                        />
                      </div>

                      <div className={classes.divColumn}>
                        {iHour > 0 ? '' : <label className={`${topic.label} small name`}>{t('schedules.endTime')}</label>}
                        <input
                          type="time"
                          className="form-control time-picker"
                          name="scheduleEndTime"
                          value={hours.endTime}
                          onChange={e => {
                            let val = e.target.value;
                            setScheduleHours(h => {
                                let arr = [...h];
                                arr[iHour].endTime = val;
                                return arr;
                            })
                          }}
                          step="60"
                          min="00:00"
                          max="23:59"
                        />
                      </div>
                    </div>
                    </>
                    ))}
                    <div className={classes.divButton}>
                        <button className={`${ButtonSt.primary}`}  onClick={()=> setScheduleHours([...scheduleHours, {startTime: '', endTime: ''}])} >
                               <FontAwesomeIcon icon={faPlus} /> {t('schedules.add_time_slot')}
                        </button>
                    </div>
                    <div>
                      <label className={`${topic.label} small name`}>{t('schedules.message')}</label>
                      <textarea
                        type="text"
                        rows={10}
                        className="form-control"
                        value={scheduleMessage}
                        name="scheduleMessage"
                        onChange={e => setScheduleMessage(e.target.value)}
                        max="100"
                        placeholder={t('schedules.messagePlaceholder')}
                      />
                    </div>
                    <div>
                        <label className={`${topic.label} small name`}>{t('schedules.skill')}</label>
                        <Select
                            value={scheduleTag?scheduleTag:''}
                            onChange={e => setScheduleTag(e)}
                            options={tags}
                        />
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancelar
                      </Button>
                      <Button onClick={handleSave} color="primary">
                        Guardar
                      </Button>
                    </DialogActions>
                </Dialog>
            </>}

            <Dialog
                open={deleteDialog}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('schedules.confirm_delete')}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('schedules.delete_message')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        {t('schedules.disagree')}
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        {t('schedules.agree')}
                    </Button>
                </DialogActions>
            </Dialog>
            
            <BootstrapTable   
                        striped  
                        hover  
                        keyField='id'   
                        data={ schedules }   
                        columns={ columns }
                        pagination={ paginationFactory() }  
                        filter={ filterFactory() } >
                            
            </BootstrapTable>  
            
        </Conter>
    )
    
}

export default withTranslation()(SchedulesBySkill)
