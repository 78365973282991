import React, {useContext, useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faChevronDown, faRobot, faUsers, faAngleDown} from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation  } from '@apollo/react-hooks'
import { useDatabase } from 'reactfire'
import { withTranslation } from 'react-i18next'
import profile from '../../../../Asset/img/profile/agent.png'
import moment from 'moment'
import { CHANGE_AGENT } from '../../../../Query/Derivate' 
import { ADD_MESSAGES} from '../../../../Query/Messages' 
import{ LIST_AGENTS} from '../../../../Query/Agent'
//CHANGE JESSE
import { useAnimate } from 'react-simple-animate'
import FormDiary from './FormDiary'
import Loader from '../../../Recurent/Loader'
import Error from '../../../Recurent/Error'
// import emoji from 'react-easy-emoji'
// import dataAllEmoji from 'emoji-mart/data/all.json'
// context
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
import { AgentContext } from '../../../../Context/Agent'
// componet
import CustomBar from '../../../Recurent/Mylibrery/CustomBar'
// function
import { validateGlobal, toastMessage } from '../../../Recurent/ValidateGlobal'
// style
import { messages } from '../../../../Style/messages'
import { Button, Color, divScroll } from '../../../../Style/custom'


const Chat = ({children, user, selectedUser, t, removeRoomById}) => {    
    const { tokenSession, botUser, userAuth } = useContext(AuthContext)
    //const scrollRef = useRef();
    const endElement = useRef();
    const { 
        isTransfer, setTransfer,
        setViewFrom, viewFrom,        
        formPlay,
        formStyle, tapBotLoading, tapBotError, tapBotRefetch,
        typeSelect,
        User,setUser,
        Message, setMessage,
        ListTap,
        PataformList,
        chatRef, 
        scrollRef,
        setClient,
        setName,
        setMail,
        setPhone, phone,
        setMyTaps,
        setClientPhone,
        setTime,
        setCountry,
        setTypeSelect,
        setViewWhatsapp,
        setViewWhatsappRender,        
        setViewButtonsClient,
        setViewButtonsFrom,
        setRefechIdSearch,
        setRefechPhoneSearch,
        showClientId, showClientIdData, showClientIdRefetch,
        showClientPhone, showClientPhoneData, showClientPhoneRefetch
    } = useContext(MessagesContext)
    
    const { 
        viewOption, setViewOption,
        getListAgents,
        loadingListAgents, 
        errorListAgents, 
        dataListAgent, 
        refetchListAgent        
    } = useContext(AgentContext)

    const [formPlaySt, setFormPlaySt] = useState(false)
    const [viewFromSt, setViewFromSt] = useState(false)
    const [scrollPos, setScrollPos] = useState(null)
    const [bottom, setBottom] = useState(false)

    // animate
    const { play: formPlayAn , style: formStyleAn } = useAnimate({
        start: { right: '0px' },
        end: { right: '-60vw' },
        duration: 1
    });

    const [changeAgent, { loading: changeAgentLoading, error: changeAgentError, data: changeAgentData }] = useMutation(CHANGE_AGENT)
    const [loadMessages, { loading: loadMessagesLoading, error:loadMessagesError, data: loadMesagesData }] = useMutation(ADD_MESSAGES)
    
    const db = useDatabase()
    // function        
    const [agents, setAgents] = useState([])
    const agentRef = useRef(null)

    useEffect(() => {
        scrollRef.current.addEventListener('scroll',function(e) {
            setScrollPos(e.target.scrollTop)
        })
        scrollDown()
        //lista de agentes se repliega
        if(viewOption){
            setViewOption(false)
        }
        //lista de agentes se repliega
        let handleClickOutside = (event) => {
            if (agentRef.current && !agentRef.current.contains(event.target)) {
                setViewOption(false)
            }
        }
        document.querySelector('body').addEventListener('click', handleClickOutside)
       
       
   
    }, [])

    useEffect(() => {
        
        if(User && User.msj_total && User.msj_showing && scrollPos == 0 && User.msj_total > User.msj_showing){
            
            let variables = {
                token: tokenSession,
                room_id: User.roomId,
                bot_id: parseInt(User.botId),
                quantity: parseInt(User.msj_showing),
                platform_id: parseInt(User.platform),
                session_id: User.session_id,
                agent_id: userAuth.id_user
            }

            loadMessages({ variables, errorPolicy: 'all'}).then((payload) => {
                console.log("MESSAGES WERE ADDED")
            }).catch((e) => console.log("ERROR ADDING MESSAGES")) 
        } 
        if( Math.abs((scrollRef.current.scrollHeight - scrollRef.current.clientHeight) - scrollRef.current.scrollTop) <=2 ) {
            setBottom(true)
        } else {
            setBottom(false)
        }
    }, [scrollPos])

    useEffect(() => {
        if (dataListAgent){
            if(dataListAgent.getAllAgentsOcupation.data != null){
                setAgents(dataListAgent.getAllAgentsOcupation.data)
            }else{
                setAgents([])    
                    
            }         
            if (agentRef.current !== null){
                agentRef.current.scrollIntoView({block: 'end', behavior: "smooth" })
            }             
        }
    }, [dataListAgent])

    const onClickTransfer = () => {
   
        /**
         * Fix para carga de agentes al hacer click
         */
        const  variables = {token:tokenSession, id_bot: botUser.bot_id,  id_user: userAuth ? parseInt(userAuth.id_user) : 0}
        

        if(refetchListAgent ){            
            refetchListAgent({variables, fetchPolicy: 'network-only'})

        }else{            
            getListAgents({variables, fetchPolicy: 'network-only'})
        }
        if (agentRef.current !== null){
            agentRef.current.scrollIntoView({block: 'end', behavior: "smooth" })
        }  
        // if(agents && agents.length == 0){
        //         toastMessage({message: t('messages.error.noAgents'), error: true})
        
        // }
        setViewOption(!viewOption)
    }
    


    const scrollDown = () =>{
        
        scrollRef.current.scroll({top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
            left: 0, 
            behavior: 'smooth',});
        
    }

    const returnBot = () => {
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({ 'return_to_bot': true}).then(()=>{
            db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).remove()   
        })           
        removeRoomById(User.roomId)
        clearUser(User)        
        setMessage('')
        setTimeout(() => {
            if(chatRef) {
                if(chatRef.current) {                                
                    chatRef.current.scrollIntoView({block: 'end', behavior: "smooth" })                
                }
            }        
        }, 100);
    }
    

    const changeUser = (new_agent) => {
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({'newMessage': 't'})                                
        const platforms = [12,13,14,15,16,17,18,19,20];
        var _platform = User.platform
        if (platforms.includes(parseInt(_platform))){
            _platform = "7";
        }    

        const variables = {
            token: tokenSession,
            botId: parseInt(User.botId),
            roomId: User.roomId,
            agentId: parseInt(new_agent),
            oldAgentId: parseInt(userAuth.id_user),
            platform: _platform 
        }

            changeAgent({ variables, errorPolicy: 'all'}).then((payload) => {
                    
                if (payload.data.changeClientFromAgent.code == 102) {                    
                    toastMessage({message: t('messages.error.transfer'), error: true})
                    // console.log(t('messages.error.transfer'))

                }else {
                    // console.log(t('messages.transfer'))                    
                    setTransfer(true)                    
                    toastMessage({message: t('messages.transfer'), error: false})            
                }    
            }).catch((e) => toastMessage({message: t('messages.transfer'), error: false}))          
            setViewOption(false) 

        setViewFrom(true)
        formPlay(true)


    }
    
    const clearUser = (User) => {
        console.log("clearUser")
        setUser(null)
        setClient(null)
        setName('')
        setMail('')
        setPhone('')
        setMyTaps([])
        setTime(null)
        setCountry(null)
        setTypeSelect(null)
        setViewButtonsClient(true)
        setViewButtonsFrom(true)
        setViewWhatsapp(false)
        setClientPhone(null)        
    } 


    const scrollToBottom = () => {
  
          setTimeout(() => {
            //chatRef.current.scrollIntoView(false);
            chatRef.current.scrollIntoView({block: 'end', behavior: "smooth" })                
          }, 300);

          setTimeout(() => {
            //chatRef.current.scrollIntoView(false);
            chatRef.current.scrollIntoView({block: 'end', behavior: "smooth" })                
          }, 1000);

      }
      
    /**
     * 
     * @param {*} url url del archivo incluido en el mensaje
     * @param {*} content_type tipo de archivo enviado en el mensaje
     * @param {*} mensaje contenido del mensaje
     * @returns 
     */
    const newMessage = (url, content_type, mensaje) => {   


        const chat = Object.keys(User.chat)
        //const Meta = ('metadata' in User.chat[chat[0]]) ? JSON.parse(User.chat[chat[0]].metadata) : null                        
        const Meta = ('metadata' in User) ? JSON.parse(User.metadata) : null 

        const widget_session = ('widget_session' in Meta) ? Meta.widget_session : ""
        let metadata = {url_file:url,contentType:content_type, widget_session: widget_session}                       
        var metadata_aux = "{}";
        try{
            Object.values(selectedUser.chat).some((elemento, index) => {
                return elemento['channel'] === "2"? ((metadata_aux = elemento.metadata), true) : false
            })
        }catch(e){
            console.log("error al obtener metadata del cliente")
        }
        metadata_aux = JSON.parse(metadata_aux)

        delete metadata_aux['url_file'];
        delete metadata_aux['contentType'];
        delete metadata_aux['title'];
        delete metadata_aux['custom'];

        metadata = Object.assign({}, metadata_aux, metadata);
        
        let msj = (url.length > 0)?url:mensaje
        
        let _platform = User.platform
        const platforms = [12,13,14,15,16,17,18,19,20];
        if (platforms.includes(parseInt(_platform))){
            _platform = "7";
        }    

        if(User) {
            const puhsChat = {
                name: '',
                message: msj,
                channel: '1',
                action: 'message',
                roomId: User.roomId,
                time: moment().format('YYYY-MM-DD HH:mm:ss') ,
                //platform: User.platform,
                platform: _platform,
                token: User.token,
                botId: User.botId,
                agentId: JSON.stringify(userAuth.id_user),
                tagsId: typeSelect ? JSON.stringify(typeSelect.value) : '',
                metadata: metadata ? JSON.stringify(metadata) : ''
            }
            const [validate] = validateGlobal({
                name: 'String',
                message: 'String|Require',
                channel: 'String|Require',
                action: 'String|Require',
                roomId: 'String|Require',
                time: 'String|Require',
                platform: 'String|Require',
                token: 'String|Require',
                botId: 'String|Require',
                agentId: 'String', 
                tagsId: 'String', 
                metadata: 'String'
            },
            puhsChat,
            {
                message:{
                    message: t('messages.error.message'),
                    ErrorDefault: t('messages.error.default'),
                    CorrectValidate: t('messages.error.correct')
                },
                correctValidate: false,
                position: 'bottom-right'
            })

            if(validate){
                db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/chat/`).push(puhsChat)           
                db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${User.roomId}/`).update({'alerted': false})       
                setMessage('')                        
                scrollToBottom() 
                scrollDown()
            }
            return validate
        } else {
            toastMessage({message: t('messages.error.select')})
            return false
        }
    }

    
    return(

        <div className={messages.chat} >
            <div className='header' >
                
                {User &&
                    <div className={messages.linkSelect}>                            
                        <button id="change-agent" name="change-agent" className={`${Button.primary}`} style={{float:'left'}} type='button'  onClick={onClickTransfer}><FontAwesomeIcon icon={faUsers} /> {t('messages.agents.changeagent')} <FontAwesomeIcon icon={faChevronDown} /> </button>
                        <div style={{maxHeight: '200px'}}>
                            <div id="agentList" ref={agentRef} className={`${divScroll.scrollable} option`} style={viewOption?({display:'flex'}):({display:'none'}) }>                              
                                {agents.map((agent, i)=>
                                <div key={i} className="item">
                                    <div className={messages.itemList} style={{backgroundColor: Color.white }}>                                    
                                        
                                        <div className='contInfo'>
                                            <div className='contFoto' >                                       
                                                <img className='foto' src={profile} />
                                            </div>
                                            <div className='info' id="chat-item" nameid="chat-item">
                                                {agent.name && <div><b>{`${t('messages.agent')}:`}&nbsp;</b> {` ${agent.name}`}</div>} 
                                                {<div><b>{`${t('messages.agents.availability')}:`}&nbsp;</b> {agent.ocupation < 90 ? `${t('messages.agents.free')}` : `${t('messages.agents.full')}`}</div>} 
                                                <button id={agent.name} name="button_derive" className={`${Button.primarySmall}`} type='button' disabled={agent.ocupation > 90} onClick={() => {changeUser(agent.id)}}>{t('messages.agents.derive')}</button>  
                                            </div>   
                                        </div>
                                        
                                    </div>
                                </div>    
                                    )}
                            </div>
                        </div>
                    </div>
                    }
                
                {User &&
                    <button type='button' id="return-bot" name="return-bot" className={`${Button.primary} `} onClick={() => returnBot()} ><FontAwesomeIcon icon={faRobot} /> {t('messages.info.returnBot')}</button>    
                }
                

                {User && <button id="view-from" name="view-from" className={`${Button.primary} viewFrom`} onClick={()=> { 
                        //setViewFrom(!viewFrom)
                        //formPlay(!viewFrom)
                        setViewFromSt(!viewFromSt)
                        formPlayAn(!viewFromSt)
                    }}>
                    <FontAwesomeIcon icon={faReceipt} />                    
                    {` ${viewFromSt ? t('messages.viewFrom') : t('messages.noViewFrom')}`}
                </button>}
            </div>

            <c-wiz className="bzJiD WQKmIb eO2Zfd lEX7Ob" style={{display:"flex"}}>
                <div className={`Bl2pUd krjOGe chat ${messages.divChat}`} tabIndex={"-1"} ref={scrollRef}> 
                    {children}
                    <div ref={endElement}></div>
                </div>            
                {!bottom &&
                    <div className={`${messages.divArrow}`} onClick={() => scrollDown()}>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                }
                {bottom &&
                    <div className={`${messages.divNoArrow}`} onClick={() => scrollDown()}>
                        
                    </div>
                }
                    { User &&           
                            <CustomBar 
                                className='inputEmoji' 
                                value={Message} 
                                onChange={e => setMessage(e)} 
                                Color={Color.primary}
                                onButton={(url, content_type, message) => newMessage(url, content_type, message)}
                                User = {User}
                                disabled = {false}
                                emoji = {true}
                                typeTemplate = {2} //Response
                                customMaxWidth = {'86%'}
                                
                            />            
            }
            {User && 
            <div className={`${messages.from}`} style={formStyleAn} >
                { tapBotLoading 
                    ? <Loader height='100%' /> 
                    : tapBotError   
                        // ? <Error 
                        //     error={t('messages.error.tapBotError') } 
                        //     action={()=> tapBotRefetch() } /> 
                        ? (<FormDiary /> )
                        : ( <FormDiary /> )}
            </div>
            }         
            </c-wiz>                
        </div>    
        

    )
}




export default  withTranslation()(Chat)
