import { style } from 'typestyle'
// color
import { Color, Shadow } from './custom'

export const divInputCustom = style({
    bottom: 0,
    position: 'absolute',
    width: '80%'

})

export const inputCustom = style({

    width : '100%',
    maxHeight: '8vh',
    overflowY: 'auto',
    display:  'block',
    padding: 0

})
