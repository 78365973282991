import React, { createContext, useState } from 'react'

const SchedulesBySkillContext = createContext()
const { Consumer, Provider } = SchedulesBySkillContext

const SchedulesBySkillProvider = ({children}) =>{
    const [deleteScheduleId, setDeleteScheduleId] = useState(0)
    const [editScheduleId, setEditScheduleId] = useState(0)
    const [editing, setEditing] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [tags, setTags] = useState([])
    const [scheduleTag, setScheduleTag] = useState("")
    const [scheduleDays, setScheduleDays] = useState([])
    const [scheduleHours, setScheduleHours] = useState([{startTime: '', endTime: ''}])
    const [scheduleMessage, setScheduleMessage] = useState("")
    const [createEditDialog, setCreateEditDialog] = useState(false)
    const [typeDialog, setTypeDialog] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [variable, setVariable] = useState("")
    const [variables, setVariables] = useState([])

    return(<Provider value={{
        deleteScheduleId, setDeleteScheduleId,
        editScheduleId, setEditScheduleId,
        editing, setEditing,
        schedules, setSchedules,
        tags, setTags,
        scheduleTag, setScheduleTag,
        scheduleDays, setScheduleDays,
        scheduleHours, setScheduleHours,
        scheduleMessage, setScheduleMessage,
        createEditDialog, setCreateEditDialog,
        typeDialog, setTypeDialog,
        dialog, setDialog,
        deleteDialog, setDeleteDialog,
        variable, setVariable,
        variables, setVariables
    }}>
        {children}
    </Provider>)
}

export { SchedulesBySkillProvider, Consumer as SchedulesBySkillConsumer, SchedulesBySkillContext }