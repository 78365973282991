import { style } from 'typestyle'
// color
import { Color, Shadow } from './custom'

export const floatButton = style({
    position: 'fixed',
    zIndex: 1,
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    bottom: '20px',
    right: '10px',
    boxShadow: Shadow.default,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    $nest: {
        '&:hover': {
            $nest: {
                '& .tooltip': {
                    display: 'block'
                }
            }
        },
        '& .tooltip': {
            position: 'absolute',
            backgroundColor: Color.white,
            borderColor: Color.border,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '10px',
            color: Color.text,
            right: '50px',
            padding: '3px 5px',
            width: '100px',
            display: 'none'
        }
    }
})

export const contList = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'calc(100vh - 195px)',
    alignItems: 'flex-start',
    $nest: {        
        '& .from': {
            padding: '10px',
            backgroundColor: Color.white,
            borderColor: Color.border,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '5px',
            marginRight: '15px',
            width: 'auto',
            position: 'relative',
            overflow: 'hidden',
            
            $nest: {
                '& .buttonClose': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: Color.text,

                    $nest: {
                        '& .close':{
                            cursor: 'pointer'
                        }
                    }
                },
                '& .fromGrup': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    $nest: {
                        '& .grupItem': {
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '10px',
                            
                            $nest: {
                                '& .label': {
                                    fontWeight: 600,
                                    color: Color.text,
                                    marginBottom: '5px'
                                },
                                '& .date': {
                                    borderColor: Color.border,
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '5px',

                                    
                                    $nest: {
                                        '& .DateRangePickerInput__withBorder': {
                                            borderRadius: '5px',
                                            borderColor: 'transparent'
                                        },
                                        '& .DateInput_input': {
                                            width: 'calc(100% - 22px)'
                                        },
                                        '& .DateInput__small': {
                                            width: '110px'
                                        },
                                        '& .close': {
                                            color: Color.text
                                        }
                                    }
                                },
                                '& .input': {
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '5px',
                                    padding: '7px 5px'
                                },

                                '& .selectFlag': {
                                    backgroundColor:Color.gray,
                                    $nest:{
                                        '& .select': {
                                            
                                            borderTopWidth: '0px',
                                            borderLeftWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRadius: '0px',
                                            borderStyle: 'unset',
                                            height: '100%',
                                            $nest: {
                                                    '& .item':{
                                                    paddingRight: '10px',
                                                    width: '5vw'
                                                },
                                            }
                                        },
                                        '& .contOptions':{
                                            position: 'fixed',
                                            
                                            $nest: {
                                                '& .option': {                                      
                                                    $nest: {
                                                        '& img':{
                                                            width: '30px'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },                                
                                '& .grupItem': {
                                    borderColor: Color.border,
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflow: 'hidden',
    
                                    $nest: {
                                        '& .input': {
                                            borderWidth: '0px',
                                            flex: '1 1 auto'
                                        },
                                        '& button': {
                                            borderTopWidth: '0px',
                                            borderBottomWidth: '0px',
                                            borderRightWidth: '0px',
                                            borderRadius: '0px',
                                        }
                                    }
                                },
                            }
                        }
                    }
                }
            }
        },
        '& .list': {
            flex: '1 1 40%',
            padding: '5px',
            backgroundColor: Color.white,
            borderColor: Color.border,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '5px',
            height: '100%',
            overflow: 'hidden',

            $nest: {
                '& .listItems': {
                    height: 'auto',
                    maxHeight: '100%',
                    overflow: 'auto',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '5px',
                    width: '100%',

                    $nest: {
                        '& .item': {
                            borderRadius: '3px',
                            borderColor: Color.primary,
                            padding: '7px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            minHeight: '70px',
                            flexGrow: 0,
                            textDecoration: 'none',
                            color: Color.textDark,

                            $nest: {
                                '&.loader': {
                                    borderWidth: '0px',
                                },
                                '& .contInfo': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',

                                    $nest: {
                                        '& .info': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            fontSize: '11px',
                                            flexWrap: 'wrap',
                                            borderColor: Color.border,
                                            borderWidth: '0px',
                                            borderStyle: 'solid',
                                            padding: '6px',
                                            borderRadius: '5px',
                                            marginTop: '5px',
                                            marginBottom: '5px',

                                            $nest: {
                                                '& b': {
                                                    color: Color.primary,
                                                    fontSize: '13px',
                                                    marginRight: '5px'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
})

export const conEdit = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 'calc(100vh - 195px)',
    alignItems: 'flex-start',

    $nest: {
        '& .header': {
            flex: '1 1 100%',
            padding: '7px 0px',
            display: 'flex'
        },
        '& .info': {
            flex: '1 1 100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            gridGap: '10px',
            height: 'calc(100% - 0px)',
            $nest:{
                '& .calendar': {
                    backgroundColor: Color.white,
                    borderColor: Color.border,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    gridTemplateRows: 'repeat(15, 1fr)',

                    $nest: {
                        '& .float': {
                            gridArea: '1 / 12 / 2 / 13',
                            textAlign: 'right'
                        },
                        '& .cont': {
                            gridArea: '2 / 1 / 16 / 16',
                            overflow: 'auto',
                            $nest: {
                                '& .event': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap:'wrap',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    height: '100%',
                                    cursor: 'pointer',
            
                                    $nest:{
                                        '& .titleEvente': {
                                            fontSize: '12px',
                                            width: 'calc(100% - 5px)'
                                        },
                                        '& .timeEvente': {
                                            width: 'calc(100% - 5px)',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            fontSize: '8px'
                                        },
                                    }
                                }
                            }
                        }
                    }
                },
                '& .user': {
                    backgroundColor: Color.white,
                    borderColor: Color.border,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',

                    $nest: {
                        '& .float': {
                            position: 'absolute',
                            alignSelf: 'flex-end'
                        },
                        '& .cont':{
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',

                            $nest: {
                                '& .item':{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    padding: '6px',
                                    borderRadius: '5px',
                                    marginBottom: '10px',

                                    $nest: {
                                        '& b': {
                                            color: Color.primary,
                                            marginRight: '5px'
                                        }
                                    }
                                },
                                '& .listTag': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    marginTop: '10px',
                                    $nest: {
                                        '& .tag': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '2px 5px',
                                            backgroundColor: Color.primary,
                                            color: Color.white,
                                            borderRadius: '15px',
                                            margin: '3px',
                                            alignItems: 'center',
                                        }
                                    }
                                }    
                            }    
                        }    
                    }
                },
                '& .linetime':{
                    backgroundColor: Color.white,
                    borderColor: Color.border,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',


                    $nest: {
                        '& .float': {
                            position: 'absolute',
                            alignSelf: 'flex-end',
                        },
                        '& .cont': {
                            padding: '10px',
                            marginTop: '35px',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gridGap: '10px',
                            overflow: 'auto',
                            width: 'calc(100% - 20px)',

                            $nest: {
                                '& .tag': {
                                    padding: '5px',
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '10px',
                                    fontWeight: 700,
                                    color: Color.primary,
                                    cursor: 'pointer',

                                    $nest: {
                                        '&.active':{
                                            backgroundColor: Color.tree,
                                            color: Color.white
                                        },
                                        '& .foto':{
                                            width: '35px',
                                            height: '35px',
                                            marginBottom: '10px'
                                        },
                                        '& .iconchannel': {
                                            fontSize: '35px',
                                            marginRight: '3px',
                                            color: Color.hoverPrimary,
                                            marginBottom: '10px',
                                            
                                            $nest: {
                                                '&.skype': {
                                                    color: Color.skype
                                                },
                                                '&.asterisk': {
                                                    color: Color.asterisk
                                                },
                                                '&.telegram': {
                                                    color: Color.telegram
                                                },
                                                '&.messager': {
                                                    color: Color.messager
                                                },
                                                '&.whatsapp': {
                                                    color: Color.whatsapp
                                                },
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        '& .chats': {
                            padding: '5px',
                            borderColor: Color.border,
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            height: 'calc(100% - 140px)',
                            overflow: 'auto',
                            marginLeft: '10px',
                            marginRight: '10px',
                            display: 'none',
                            flexDirection: 'column',
                            width: 'calc(100% - 30px)',

                            $nest: {
                                '& .noChatMessage': {
                                    alignSelf: 'center',
                                    marginTop: '25px',
                                    padding: '3px 5px',
                                    borderColor: Color.border,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '10px',
                                    backgroundColor: Color.white,
                                    color: Color.text
                                },
                                '& .contDate': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    $nest: {
                                        '& .date': {
                                            padding: '5px',
                                            borderColor: Color.border,
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            borderRadius: '10px',
                                            marginBottom: '10px',
                                            color: Color.text,
                                            fontSize: '10px',
                                        }
                                    }
                                },
                                '& .message': {
                                    backgroundColor: Color.hoverPrimary,
                                    color: Color.white,
                                    borderRadius: '30px',
                                    marginBottom: '10px',
                                    padding: '10px 20px',
                                    flex: '1 1 auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignSelf: 'flex-start',
                                    marginRight: '150px',

                                    $nest: {
                                        '&.Agente':{
                                            backgroundColor: Color.messageBot,
                                            alignSelf: 'flex-end',
                                            marginLeft: '150px',
                                            marginRight: '0px',
                                        },
                                        '&.Bot':{
                                            backgroundColor: Color.messageBot,
                                            alignSelf: 'flex-end',
                                            marginLeft: '150px',
                                            marginRight: '0px',
                                        },
                                        '& .text': {
                                            marginBottom: '10px'
                                        },
                                        '& .time': {
                                            alignSelf: 'flex-end',
                                            fontSize: '8px'
                                        }
                                    }
                                },
                                '&.focus':{
                                    display: 'flex'
                                }
                            }
                        },
                        '&.focus': {
                            $nest: {
                                '& .cont': {
                                    gridTemplateColumns: 'repeat(12, 1fr)',
                                    $nest:{
                                        '& .tag': {
                                            fontSize: '8px',
                                            $nest: {
                                                '& .foto':{
                                                    width: '30px',
                                                    height: '30px',
                                                    marginBottom: '10px'
                                                },
                                                '& .iconchannel': {
                                                    fontSize: '30px',
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
})

