import React from 'react'

import { DashboardPanel } from '../../../../Style/custom'

const HeaderCard = ({text, value, icon, subText})=> {
    return(
        <div className={`${DashboardPanel.headerCard}`}>
            <div className={`${DashboardPanel.divTitle}`}>
                <h1>{text || "TextHeaderCard"}</h1>
                <h2>{subText || ""}</h2>
            </div>
            <div className={`${DashboardPanel.divContent}`}>
                <div className={DashboardPanel.divLeft}>
                    <img className={DashboardPanel.iconoSize} src={icon} alt="icon_header_card"/>
                </div>
                <div className={`${DashboardPanel.divValue}`}>
                    <h3>{value || "00"}</h3>
                </div>
            </div>
        </div>
    )
}

export default HeaderCard