import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
// syle
import { Button } from '../../../../Style/custom'

const Blocks = ({message}) => {
    const { type , data, text } = message
    switch (type) {
        case  'quick-buttons':
            return (<div className='contBlock' >
                <div className='texto'>{data.text}</div>
                <div className='contButton'>{data.buttons.map((info, i) => 
                    <button key={i} className={Button.primary} style={{backgroundColor: info.color}} disabled={true}>{info.value}</button>)}
                </div>
            </div>)
        case  'video':
            return (<div className='contBlock'>
                <div className='texto'>{data.text}</div>
                <video className='video' controls={true} src={data.video.src}></video>
            </div>)
        default:
            return <div>{data ? data.text : text}</div>
    }
}

const MessagesChat = ({chat, index , chats}) => {
    const Oldchat = chats[index-1] 
    
    return(
        <div>
            {Oldchat 
            ? moment(chat.created_at).format('DD/MM/YYYY') !== moment(Oldchat.created_at).format('DD/MM/YYYY') 
            ? <div className='contDate'><div className='date'>{moment(chat.created_at).format('ddd - DD/MM/YYYY')}</div></div> : '' 
            : <div className='contDate'><div className='date'>{moment(chat.created_at).format('ddd - DD/MM/YYYY')}</div></div>}
            {chat.text_in ? chat.text_in!=='' ? <div className={`message`}>
                <div className='text'>{chat.text_in}</div>
                <div className='time'>{moment(chat.created_at).format('LT')}</div>
            </div>: '':''}
            <div className={`message ${chat.channel_name}`} >
                <div className='text'>{chat.text_out.indexOf('"message') > -1 
                    ? JSON.parse(chat.text_out).message.map((message, i) => <Blocks key={i} message={message} />)
                    : chat.text_out}
                </div>
                <div className='time'>{moment(chat.created_at).format('LT')}</div>
            </div>
        </div>
    )
}

export default MessagesChat