import {style} from "typestyle"

export const minimap = style({
    width: '150px',
    height: '100px',
    padding: '5px',
    position: 'fixed',
    bottom: '75px',
    zIndex: '100',
    right: '25px',
    boxShadow: '0px 1px 3px 2px #989696',
    cursor: 'all-scroll',
    backgroundColor: '#fff',

    $nest: {
        '& .section':{
            borderColor: '#529de8',
            borderWidth: '2px',
            borderStyle: 'solid',
            width: '100%',
            height: '100%',
            position: 'relative',
            top: 0,
            left: 0
        }
    }
})