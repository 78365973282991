import {style} from 'typestyle'

import {Color, Shadow} from './custom'

export const topic =  {
    cont: style({
        display: 'flex',
        justifyContent: 'center' 
    }),


    MuiTypography: style ({
        fontSize: '12px'
    }),    

    form: style({
        width: '80%',
        borderColor: Color.border,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '15px',
        boxShadow: Shadow.default,
        $nest: {
            '& .field':{
                display: 'flex',
                flexDirection: 'column',
                $nest: {
                    '& .control':{
                        flex:'1 1 auto',
                        display: 'flex'
                    }
                }
            }
        }
    }),

    contLabel: style({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }),

    label: style({
        fontSize: '20px',
        fontWeight: 600,
        color: Color.text,
        marginBottom: '4px',
        $nest:{
            '&.small': {
                fontSize: '12px'
            },
            '&.order': {
                width: '42px'
            },
            '&.name': {
                flex: '1 1 auto'
            },
            '&.body': {
                flex: '2 1 auto'
            },
            '&.color': {
                width: '77px'
            },
        }
    }),


    select: style({
        marginBottom: '10px'
    }),

    contInputButton: style({
        display: 'flex',
        flexDirection: 'row',
        borderColor: Color.border,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        marginBottom: '10px',

        $nest:{
            '& .button':{
                borderWidth: '0px'
            },
            '& .order':{
                // flex: '1 1 auto',
                borderTopWidth: '0px',
                borderLeftWidth: '0px',
                borderBottomWidth: '0px',
                borderRightWidth: '1px',
                borderRightColor: Color.border,
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                maxWidth: '40px',
                textAlign: 'center',

                $nest: {
                    '&:disabled':{
                        backgroundColor: Color.lint,
                        cursor: 'no-drop'
                    }
                }
            },
            '& .input_name':{
                flex: '1 1 auto',
                borderTopWidth: '0px',
                borderLeftWidth: '0px',
                borderBottomWidth: '0px',
                borderRightWidth: '1px',
                borderRightColor: Color.border,
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                paddingLeft: '5px',

                $nest: {
                    '&:disabled':{
                        backgroundColor: Color.lint,
                        cursor: 'no-drop'
                    }
                }
            },
            '& .input':{
                flex: '5 1 auto',
                borderTopWidth: '0px',
                borderLeftWidth: '0px',
                borderBottomWidth: '0px',
                borderRightWidth: '1px',
                borderRightColor: Color.border,
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                paddingLeft: '5px',

                $nest: {
                    '&:disabled':{
                        backgroundColor: Color.lint,
                        cursor: 'no-drop'
                    }
                }
            },
            '& .inputColor': {
                display: 'flex',
                flexDirection: 'column',

                $nest: {
                    '& .color': {
                        color: Color.white,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '3px',
                        cursor: 'pointer',

                        
                    },
                    '& .select': {
                        position: 'absolute',
                        alignSelf: 'flex-end',
                    },
                    '&.disabled':{
                        $nest: {
                            '& .color': {
                                cursor: 'no-drop'
                            }
                        }
                    }
                }
            }
        }
    }),
    
    listTap: style({
        width: 'calc(100% - 20px)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '10px',
        backgroundColor: Color.lint,
        borderRadius: '5px',
        minHeight: '230px',
        alignItems: 'flex-start',
        alignContent: 'baseline'
    }),

    Tap: style({
        display: 'flex',
        flexDirection: 'row',
        padding: '2px 5px',
        backgroundColor: Color.primary,
        color: Color.white,
        borderRadius: '15px',
        margin: '3px',
        alignItems: 'center',
        
        $nest:{
            '& .icon':{
                marginLeft: '2px',
                marginRight: '2px',
                padding: '4px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                fontSize: '12px',
                cursor: 'pointer',

                $nest:{
                    '&:hover':{
                        backgroundColor: Color.allhover,
                    }
                }
            },
            '& .small':{
                marginLeft: '10px',
                fontSize: '10px',
            }
        }
    })
}