import React, { useContext, useEffect } from 'react'

import { withTranslation } from 'react-i18next'

import 'bootstrap/dist/css/bootstrap.min.css'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import SentimentIcon from '../../../Recurent/Graphics/SentimentIcon';

import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      height: 700,
      padding: 30,
      overflow: 'scroll'
    },
    title: {
        fontSize: 40,
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    cardIcon: {
        float: 'left',
        height: 40,
        width: 80,
        padding: 10,
        paddingLeft: 30,
    },
    panel: {
        margin: 5,
        fontSize: 20,
    },
    divActive:{
        float: "left",
        width:'33%',
        height: 200,
        textAlign: 'center',
        paddingTop: 30
    },
    divConversations:{
        float: "left",
        width:'33%',
        height: 200,
        textAlign: 'center',
        paddingTop: 30
    },
    divNoConversations:{
        float: "left",
        width:'33%',
        height: 200,
        textAlign: 'center',
        paddingTop: 30
    },
    iconSize: {
        width: 100,
        height: 70,
        paddingTop: 2,
      },
    text: {
        padding: 10
    },
    value: {
        fontSize: 35,
        padding: 10
    }
  }));


const CardPerformancePerAgent = ({t, title, dataRows})=> {

    const classes = useStyles();

    const data = {
        columns: [
        {
            label: t('dashboard.tableRowName'),
            field: 'agentName',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowState'),
            field: 'active',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowAssinged'),
            field: 'assignedConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowAnswered'),
            field: 'answeredConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowUnanswered'),
            field: 'unansweredConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowAgentMessages'),
            field: 'totalConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowtmo'),
            field: 'tmo',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowFinished'),
            field: 'returnedConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.tableRowAlerted'),
            field: 'alertedConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.agentSentiment'),
            field: 'agentSentiment',
            sort: 'asc',
            Cell: row => <div>{row.agentSentiment}</div>
        },
        {
            label: t('dashboard.positiveConversations'),
            field: 'positiveConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.negativeConversations'),
            field: 'negativeConversations',
            sort: 'asc'
        },
        {
            label: t('dashboard.neutralConversations'),
            field: 'neutralConversations',
            sort: 'asc'
        },
        ]};

    let rowsData = [];
    for (var i = 0; i < dataRows.length; i++) {
        let rowItem = {};
        let key;
        for (key in dataRows[i]){
            rowItem[key] = dataRows[i][key];
        }
        
        if (rowItem.agentSentiment != null){
            rowItem.agentSentiment = <SentimentIcon satisfactionLevel={dataRows[i].agentSentiment} />
        }
        rowsData.push(rowItem)
    }

    return (           
        <Card className={classes.root} style={{margin:'10px'}}>
            <Typography className={classes.title} color="textPrimary" gutterBottom>
                {title || "Title"}
            </Typography>
            <div className={classes.panel}>
                <MDBTable responsive>
                    <MDBTableHead columns={data.columns} />
                    <MDBTableBody rows={rowsData} />
                </MDBTable>
            </div>
        </Card>
      )
}

export default withTranslation()(CardPerformancePerAgent)