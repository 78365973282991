import React, { createContext, useState, useRef, useContext } from 'react'
import { useAnimate } from 'react-simple-animate'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
// query
import { SHOW_CLIENT_ID, SHOW_CLIENT_PHONE, SHOW_CLIENT_MAIL, SHOW_TAPS_BOT_ID, VALIDATE_PHONE,CHANNEL_PATAFORM_LIST_MESSAGES } from '../../Query/Messages'
//  context
import {AuthContext} from '../Auth'

const MessagesContext = createContext()
const { Consumer, Provider } = MessagesContext

const MessagesProvider = ({children}) =>{
    const {tokenSession, userAuth} = useContext(AuthContext)
    const [User, setUser] = useState(null)
    const [typeSelect, setTypeSelect] = useState(null)
    const [time, setTime] = useState(null)
    const [Client, setClient] = useState(null)
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [ClientPhone, setClientPhone] = useState(null)
    const [ChannelList, setChannelList] = useState(null)
    const [PataformList, setPataformList] = useState(null)
    const [ListTap, setListTap] = useState(null)
    const [listChat, setListChat] = useState([])
    const [Message, setMessage] = useState('')
    const [viewFrom, setViewFrom] = useState(false)
    const [Scheduling, setScheduling] = useState(null)
    const chatRef = useRef(null)
    const scrollRef = useRef(null)
    const [isTransfer, setTransfer] = useState(null)
    const [ViewButtonsClient, setViewButtonsClient] = useState(true)
    const [ViewButtonsFrom, setViewButtonsFrom] = useState(true)
    const [ViewCalendarFrom, setViewCalendarFrom] = useState(false)
    const [ListCountry, setListCountry] = useState(null)
    const [Country, setCountry] = useState(null)
    const [MyTaps, setMyTaps] = useState([])
    const [Events, setEvents] = useState([{
        title: 'All Day Event',
        start: '2020-05-25'
    }])
    const [RefechIdSearch, setRefechIdSearch] = useState(false)
    const [RefechPhoneSearch, setRefechPhoneSearch] = useState(false)
    const [RefechMailSearch, setRefechMailSearch] = useState(false)
    const [ViewWhatsapp, setViewWhatsapp] = useState(true)
    const [ViewWhatsappRender, setViewWhatsappRender] = useState(false)
    // query
    const { loading: channelPataformLoading, error: channelPataformError, data: channelPataformData } = useQuery(CHANNEL_PATAFORM_LIST_MESSAGES, {variables:{token: tokenSession}}, {fetchPolicy: 'network-only'})
    const [showClientId , {loading: showClientIdLoading, error: showClientIdError, data: showClientIdData, refetch: showClientIdRefetch }] = useLazyQuery(SHOW_CLIENT_ID, {fetchPolicy: 'network-only'})
    //const [showClientId , {loading: showClientIdLoading, error: showClientIdError, data: showClientIdData, refetch: showClientIdRefetch }] = useQuery(SHOW_CLIENT_ID, {fetchPolicy: 'network-only'})
    const [showClientPhone , {loading: showClientPhoneLoading, error: showClientPhoneError, data: showClientPhoneData, refetch: showClientPhoneRefetch }] = useLazyQuery(SHOW_CLIENT_PHONE, { fetchPolicy: 'network-only' })
    const [showClientMail , {loading: showClientMailLoading, error: showClientMailError, data: showClientMailData, refetch: showClientMailRefetch }] = useLazyQuery(SHOW_CLIENT_MAIL, {fetchPolicy: 'network-only'})
    const [showTapsBot ,{loading: tapBotLoading, error: tapBotError, data: tapBotData, refetch: tapBotRefetch}] = useLazyQuery(SHOW_TAPS_BOT_ID)

    // animate
    const { play: formPlay , style: formStyle } = useAnimate({
        start: { right: '0px' },
        end: { right: '-60vw' },
        duration: 1
    });
    const { play: calendarPlay , style: calendarStyle } = useAnimate({
        start: { right: '-105vw' },
        end: { right: '0px' },
        duration: 1
    });
    //sound
    const [sound, setSound] = useState(false)
    return(<Provider value={{
        User, setUser,
        typeSelect, setTypeSelect,
        time, setTime,
        Client, setClient,
        name, setName,
        mail, setMail,
        phone, setPhone,
        ClientPhone, setClientPhone,
        ChannelList, setChannelList,
        sound, setSound,
        PataformList, setPataformList,
        ListTap, setListTap,
        listChat, setListChat,
        Message, setMessage,
        viewFrom, setViewFrom,
        chatRef,
        scrollRef,
        formPlay, formStyle,
        ViewButtonsClient, setViewButtonsClient,
        ViewButtonsFrom, setViewButtonsFrom,
        Scheduling, setScheduling,
        calendarPlay, calendarStyle,
        ViewCalendarFrom, setViewCalendarFrom,
        Events, setEvents,
        ListCountry, setListCountry,
        Country, setCountry,
        MyTaps, setMyTaps,
        RefechIdSearch, setRefechIdSearch,
        RefechPhoneSearch, setRefechPhoneSearch,
        RefechMailSearch, setRefechMailSearch,
        ViewWhatsapp, setViewWhatsapp,
        ViewWhatsappRender, setViewWhatsappRender,
        channelPataformLoading, channelPataformError, channelPataformData,
        showClientId, showClientIdLoading, showClientIdError, showClientIdData, showClientIdRefetch,
        showClientPhone, showClientPhoneLoading, showClientPhoneError, showClientPhoneData, showClientPhoneRefetch,
        showClientMail, showClientMailLoading, showClientMailError, showClientMailData, showClientMailRefetch,
        showTapsBot, tapBotLoading, tapBotError, tapBotData, tapBotRefetch,isTransfer,setTransfer
    }} >
        {children}
    </Provider>)
}

export { MessagesProvider, Consumer as MessagesConsumer, MessagesContext }