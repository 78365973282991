/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { AES, enc, format, mode, pad } from 'crypto-js'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
// context
import { AuthContext } from '../../../Context/Auth'
import { DashboardContext } from '../../../Context/Dashboard'
// style
import { PanelDiv } from '../../../Style/custom'
//Components
import Conter from '../../Recurent/Conter'
import HeaderCard from '../Dashboard/items/HeaderCard'

import CardGraphics from '../Dashboard/items/CardGraphics'
import CardAttentionToConvertations from '../Dashboard/items/CardAttentionToConvertations'
import CardAgentAvailability from '../Dashboard/items/CardAgentAvailability'
import CardPerformancePerAgent from '../Dashboard/items/CardPerformancePerAgent'

import ic_chat_cloud from '../../../Asset/img/profile/ic_chat_cloud.png'
import ic_clock from '../../../Asset/img/profile/ic_clock.png'


import { contList } from '../../../Style/dashboard'

const Admin = ({t}) => {

    const { autoAuth, lang, botUser  } = useContext(AuthContext)
    const {
        allConversations, setAllConversations,
        tmo, setTMO,
        conversationsChannel, setConversationsChannel,
        setConversationsChannelGD,
        activeAgents, setActiveAgents,
        answeredConversations, setAnsweredConversations,
        unansweredConversations, setUnansweredConversations,
        agentPendingConversations, setAgentPendingConversations,
        setReturnedConversations,
        // agentSentiment, setAgentSentiment,
        // botSentiment, setBotSentiment,
        botServed, setBotServed, agentServed, setAgentServed,
        busyAgents, setBusyAgents, freeAgents, setFreeAgents,
        agentsPerformance, setAgentsPerformance, dataDashboardMetrics,
        refetchDashboardMetrics
    } = useContext(DashboardContext)

    const Key = process.env.REACT_APP_KEY_CRYPTO_PATH
    const IV = enc.Utf8.parse("0123456789ABCDEF")
    const hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser, location: '/dashboard'}),         
    Key, {
        iv: IV,
        mode: mode.CBC,
        padding: pad.Pkcs7  
    }).toString(format.Base64)

   useEffect(() => {
    //   console.log("adminComponent", hast)   
      refetchDashboardMetrics()     
    })

    useEffect(() => {
        if(dataDashboardMetrics){
            if(dataDashboardMetrics.getDashboardMetrics){
                let metrics = dataDashboardMetrics.getDashboardMetrics.data;
                if (!(metrics === null)){
                    setAllConversations(metrics.allConversations)
                    setTMO(metrics.tmo)
                    setConversationsChannel(metrics.conversationsChannel)
                    setConversationsChannelGD({labels: ['Telegram', 'Facebook', 'WhatsApp', 'Widget'], data:[12, 10, 18, 5]})
                    setActiveAgents(metrics.activeAgents)
                    setAnsweredConversations(metrics.answeredConversations)
                    setUnansweredConversations(metrics.unansweredConversations)
                    setAgentPendingConversations(metrics.agentPendingConversations)
                    setReturnedConversations(metrics.returnedConversations)
                    // setAgentSentiment(metrics.agentSentiment)
                    // setBotSentiment(metrics.botSentiment)
                    setBotServed(metrics.botServed)
                    setAgentServed(metrics.agentServed)
                    setBusyAgents(metrics.busyAgents)
                    setFreeAgents(metrics.freeAgents)
                    setAgentsPerformance(metrics.agentsPerformance)
                }
            }
        }        
    }, [dataDashboardMetrics])

// console.log("agentsPerformance: ", agentsPerformance)
    return( 
        <Conter title= {`${t('dashboard.title')}`} icon={faTachometerAlt} styleHeader={{padding: '18px 36px'}} >

            <div className={`${PanelDiv.panel}`}>
                <div className={`${PanelDiv.panelDiv}`}>
                    <HeaderCard text={t('dashboard.titleHead')} icon={ic_chat_cloud} value={allConversations || "--"}/>
                </div> 
                <div className={`${PanelDiv.panelDiv2}`}>
                    <HeaderCard text={t('dashboard.titleHeadTMO')} subText={t('dashboard.subTitleHeadTMO')} icon={ic_clock} value={tmo || "--"}/>
                </div> 
            </div>
            <div className='list' >
                <CardGraphics title={t('dashboard.tileConversationsGraphics')} graphicsData={conversationsChannel}/>
            </div>
            
            <div className={`${PanelDiv.atentionPanel}`}>
                <CardAttentionToConvertations title={t('dashboard.titleAttetionGraphics')}
                botServed={botServed} agentServed={agentServed} answeredConversations={answeredConversations}
                unansweredConversations={unansweredConversations} agentPendingConversations={agentPendingConversations}
                // botSatisfactionLevel={botSentiment} agentSatisfactionLevel={agentSentiment} 
                />
            </div>

            <div className={contList} >
                <div className='list' >
                    <CardAgentAvailability title={t('dashboard.titleAvailabilityByAgent')}
                        activeAgents={activeAgents}
                        busyAgents={busyAgents}
                        freeAgents={freeAgents} />
                </div>
            </div>

            <div className={`${PanelDiv.panel}`}>
                <CardPerformancePerAgent title={t('dashboard.titlePerformancePerAgent')} dataRows={agentsPerformance}/>
            </div>
            <div className={`${PanelDiv.graphicsPanel}`}>
                
            </div>
        </Conter>)
}
export default withTranslation()(Admin)
