/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, {useContext, useState, useEffect} from 'react'
import { useQuery  } from '@apollo/react-hooks'
import axios from 'axios'
// style
import {contLink, img} from './style'
import { AuthContext } from '../../../../Context/Auth'
import { GET_CONTENT_TYPE } from '../../../../Query/Common' 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileWord, faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons'
import GoogleMapReact from 'google-map-react';




const PreViewLink = ({url, KeyLP, Loader}) => {

    const [loader, setloader] = useState(true)
    const [Img, setImg] = useState(null)
    const [Title, setTitle] = useState(null)
    const [Description, setDescription] = useState(null)
    const [Url, setUrl] = useState(null)
    const [contentType, setContentType] = useState("")
    const [ErrorUrl, setErrorUrl] = useState(false)
    const { tokenSession } = useContext(AuthContext)    

    const {loading: loadingGetContentType, error: errorGetContentType, data: dataGetContentType, refetch: refetchGetContentType} = useQuery(GET_CONTENT_TYPE, 
            {variables:{token: tokenSession, url: url },  errorPolicy: 'all', fetchPolicy: 'network-only' })        
    
    
    //console.log(dataGetContentType)

    useEffect(() =>{
        if (dataGetContentType && dataGetContentType.getContentType && dataGetContentType.getContentType.code == 200){
            setContentType(dataGetContentType.getContentType.data)
        }

    },[dataGetContentType])

    
    const renderMarkers = (map, maps, coordenadas, label) => {
            let marker = new maps.Marker({
                              position: coordenadas,
                              map,
                              title: label
                           });
    };

    const ParceType = (url) => { 
    //console.log("ParceType2")   
    switch (contentType) {
        case 'image':
        case 'photo':        
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp':
        case 'image/gif':        
            return <img className='imgUrl' src={url} />;
        case 'voice':
        case 'audio':
        case 'audio/mpeg':
        case 'audio/ogg; codecs=opus':
        case 'audio/ogg':
        case 'audio/mp3':
        case 'audio/wav':
            return (<audio className='audioUrl' src={url} controls={true} >
                Tu navegador no implementa el elemento audio.
            </audio>);
        case 'video':
        case 'video/mp4':
        case 'video/3gpp':
            return (<video className='videoUrl' src={url} controls={true} >
                Tu navegador no implementa el elemento <code>video</code>.
            </video>);
        case 'application/msword':
        case 'application/doc':    
        case 'application/docx':    
            return(
                <div className='previewFile' >
                    <div className='file'>
                        <a href={url}>
                        <FontAwesomeIcon icon={faFileWord} className='fa-5x icon' />
                        </a>
                    </div>
                </div>) 
        case 'application/vnd.ms-excel':
        case 'application/xls':    
        case 'application/xlsx':    
        case 'application/csv':    
            return(
                <div className='previewFile' >
                    <div className='file'>
                        <a href={url}>
                        <FontAwesomeIcon icon={faFileExcel} className='fa-5x icon' />
                        </a>
                    </div>
                </div>) 
        case 'application/pdf':
            return(
                <div className='previewFile' >
                    <iframe src={url} />
                </div>)
        case 'location':
            const ubication = JSON.parse(url)
            const label = ubication.label
            const coordenadas = {lat: parseFloat(ubication.latitude),lng: parseFloat(ubication.longitude)}
            return (                 
                  <div style={{ height: '30vh', width: '30vw' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS  }}
                      center={coordenadas}
                      onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps, coordenadas, label)}                                                          
                      defaultZoom={15}                                
                    >
                    </GoogleMapReact>
                  </div>
                )                      
        default:
            return <a href={url} target="_blank">{`${url ? url.substr(0,35) : ''} ${url ? url.length >= 35 ? '...' : '' : ''}`}</a>
    }
}



     if(loadingGetContentType)  return <FontAwesomeIcon  icon={faSpinner} spin  style={{width:"24px",height:"24px"}} /> 

    return contentType == '' ? (<div >{url}</div>) :  ParceType(url) 
}

export default PreViewLink