/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
// style
import { minimap } from './style'

const Minimap = ({referent}) => {
    const PageMap = useRef(null)
    const viewPageMap = useRef(null)
    const defaultStyle = { width: '150px', height: '100px'}
    const [Width, setWidth] = useState('100%')
    const [Height, setHeight] = useState('100%')
    const [Top, setTop] = useState('0px')
    const [Left, setLeft] = useState('0px')
    const [Init, setInit] = useState(true)
    const [InitScroll, setInitScroll] = useState(false)
    // function
    const tam = (eje, dime) => (eje.replace('px','')*(dime.replace('%','')/100));

    const calStylePage = element => {
        const height = element ? `${element.offsetHeight*100/element.scrollHeight}%` : '100%'
        const width = element ? `${element.offsetWidth*100/element.scrollWidth}%` : '100%'
        setWidth(width)
        setHeight(height)
        setInit(false)
        setInitScroll(true)
    }


    useEffect(() => {
        if(referent) {
            if(referent.current){
                referent.current.addEventListener('scroll', (e)=> {
                    // console.log(e)                        
                    const top = `${e.target.scrollTop*100/(e.target.scrollHeight)}px`
                    const left = `${e.target.scrollLeft*100/(e.target.scrollWidth)}px`                        
                    if(top!==Top) setTop(top)
                    if(left!==Left) setLeft(left) 
                })
            }
        }
    }, [referent])
    useEffect(() => {
        if(referent) {
            if(referent.current) {
                if(InitScroll) {
                    PageMap.current.addEventListener('click', e => {
                        const top = `${e.offsetY-tam(defaultStyle.height, Height) > 0 ? e.offsetY-(tam(defaultStyle.height, Height)): 0.1}px`
                        const left = `${e.offsetX-tam(defaultStyle.width, Width) > 0  ? e.offsetX-(tam(defaultStyle.width, Width)): 0.1}px`
                        referent.current.scrollTop = (top.replace('px','')*referent.current.scrollHeight)/defaultStyle.height.replace('px','')
                        referent.current.scrollLeft = (left.replace('px','')*referent.current.scrollWidth)/defaultStyle.width.replace('px','')
                        if(top!==Top) setTop(top)
                        if(left!==Left) setLeft(left) 
                    })
                }
            }
        }
    }, [referent, InitScroll])

    useEffect(() => {
        if(referent) {
            if(referent.current) {
                if(Init ) {
                    setTimeout(() => {
                        calStylePage(referent.current)
                    }, 100);
                }
                //if(defaultStylePage.top!==Top || defaultStylePage.left!==Left) calStylePage(referent.current)
            }
        }
    }, [referent, Init])
    
    return (
        <div style={defaultStyle} className={minimap} ref={PageMap}>
            <div style={{top: Top, left: Left, width: Width, height: Height}} className='section' draggable="true" ref={viewPageMap} />
        </div>
    )
}

export default Minimap 