/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { useQuery, useMutation  } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
import { AES } from 'crypto-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// context
import { AuthContext } from '../../../Context/Auth'
import { MessagesContext } from '../../../Context/Messages'
import { MessagesHistoryContext } from '../../../Context/MessagesHistory'
// componet
import ListChat from './items/ListChat'
import ItemList from './items/ItemList'
import Chat from './items/Chat'

import MessagesChat from './items/MessagesChat'
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import { GET_MESSAGES, ADD_MESSAGES} from '../../../Query/MessagesHistory' 
// style
import { Cont } from '../../../Style/custom'
import { messages } from '../../../Style/messages'

const MessagesHistory = ({t}) => {
    // context
    const { autoAuth, userAuth, lang, botUser, tokenSession } = useContext(AuthContext)   
    
    const {
        
        setChannelList,
        setPataformList,
        setListTap,
        channelPataformLoading, channelPataformError, channelPataformData,
        showTapsBot, tapBotLoading, tapBotError, tapBotData, tapBotRefetch
    } = useContext(MessagesContext)

    const {
      messagesList, setMessagesList, 
      filteredMessagesList, setFilteredMessagesList,
      conversationRef, 
      selectedRoom, 
      conversation,
      loadingChat
         } = useContext(MessagesHistoryContext)
    
    
    const [addRooms , { loading: addRoomsLoading, error: addRoomsError, data: addRoomsData, refetch: addRoomsRefetch}] = useMutation(ADD_MESSAGES)
    // switch
    const  hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser ,location: '/messages'}), process.env.REACT_APP_KEY_CRYPTO_PATH).toString()
   // function

    useEffect(() => {
        let variables = {
            token: tokenSession,
            bot_id: parseInt(botUser.bot_id),
            limit:  100,
            offset: 0,
            user_filter: ''
        }
        addRooms({ variables, errorPolicy: 'all'}).then((payload) => {
            // console.log("ROOMS OBTAINED",payload)
            
            if(payload && payload.data.getMessagesListPaged.code == 200){
                let messArray = []
                payload.data.getMessagesListPaged.data.map((conv) =>{
                    let roomId = conv.room_id;
                    
                    if(findRoom(messArray, roomId)){
                        messArray.push(conv)
                    }
                })
                // console.log("MESSAGES ADDED", messArray)
                setMessagesList(messArray)
                setFilteredMessagesList(messArray)
                }
        }).catch((e) => {
            
            console.log("ERROR OBTAINING ROOMS", e)
        })     
    }, [])

    const findRoom = (arr, roomId) => {
        
        var result  = arr.filter(function(o){return o.room_id == roomId;} );
        return !(result && result.length > 0)
      
    }

    useEffect(() => {           
        if(channelPataformData){
            if(channelPataformData.showChannel){
                setChannelList(channelPataformData.showChannel.data)
            }
            if(channelPataformData.showPlatform){
                setPataformList(channelPataformData.showPlatform.data)
            }
        }
    }, [channelPataformData])

    useEffect(() => {
        if(tapBotData){
            if(tapBotData.showTagsByBotId){
                setListTap(tapBotData.showTagsByBotId.data)
            }
        }
    }, [tapBotData])

/*
  useEffect(() => {
      if(messagesData && messagesData.getMessagesList.code == 200){
        let messArray = messagesData.getMessagesList.data.map((msj, pos) =>{
          return msj
        })
        setMessagesList(messArray)
        setFilteredMessagesList(messArray)
      }
  }, [messagesData])
*/
    



    if(addRoomsLoading) return <Loader height='100%' />
    if(addRoomsError) return <Error />

    if(channelPataformLoading) return <Loader height='100%' />

    if(channelPataformError) return <Error />

    
    
    return process.env.REACT_APP_FRONT_ENV ? (<div className={messages.cont}>
            <ListChat>
                {filteredMessagesList.map((data, i)=>(
                    <ItemList key={`room_${i}`} data={data} showTapsBot={showTapsBot} tapBotRefetch={tapBotRefetch} userSelected={selectedRoom}/>
                ))}
            </ListChat>            
            
            <Chat selectedRoom={selectedRoom} refetchChats={addRoomsRefetch}  >   
                {loadingChat &&
                    <div className='message' style={{position:'absolute', marginTop:'25%', marginLeft:'40%', fontSize:'100px'}}><FontAwesomeIcon className='icon fa-spin' icon={faSpinner} /></div>
                }
                {selectedRoom && selectedRoom.room_id !='' ? <div ref={conversationRef} className='covertation'>{conversation.map((chat,pos) =>{
                    return (
                    <>
                    {chat.text_in.text && <MessagesChat key={`chat_in_${pos}`} pos={pos} User={selectedRoom} chat={chat.text_in} chatType ="Cliente"/>}
                    {chat.text_out.text && <MessagesChat key={`chat_out_${pos}`} pos={pos} User={selectedRoom} chat={chat.text_out} chatType ="Agente"/>}
                    </>
                            )})}
                </div> : t('messagesHistory.selectChat')}
            
            </Chat>
        </div>) : (<div className={Cont.infoPanel}>
        <iframe src={`${process.env.REACT_APP_FRONT_OLD}authRemote#${hast}`} /> 
    </div>)
}
export default withTranslation()(MessagesHistory)