import React, { createContext, useState } from 'react'

const TemplatesContext = createContext()
const { Consumer, Provider } = TemplatesContext

const TemplatesProvider = ({children}) =>{
    const [deleteTemplateId, setDeleteTemplateId] = useState(0)
    const [editTemplateId, setEditTemplateId] = useState(0)
    const [editing, setEditing] = useState(false)
    const [templates, setTemplates] = useState([])
    const [templateName, setTemplateName] = useState("")
    const [templateBody, setTemplateBody] = useState("")
    const [templateType, setTemplateType] = useState("")
    const [templateTypes, setTemplateTypes] = useState([])
    const [typeDialog, setTypeDialog] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [variable, setVariable] = useState("")
    const [variables, setVariables] = useState([])
    return(<Provider value={{
        deleteTemplateId, setDeleteTemplateId,
        editTemplateId, setEditTemplateId,
        editing, setEditing,
        templates, setTemplates,
        templateName, setTemplateName,
        templateBody, setTemplateBody,
        templateType, setTemplateType,
        templateTypes, setTemplateTypes,
        typeDialog, setTypeDialog,
        dialog, setDialog,
        deleteDialog, setDeleteDialog,
        variable, setVariable,
        variables, setVariables
    }}>
        {children}
    </Provider>)
}

export { TemplatesProvider, Consumer as TemplatesConsumer, TemplatesContext }