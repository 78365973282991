/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, {useContext, useEffect} from 'react'
import moment from 'moment'
import emoji from 'react-easy-emoji'
import { getEmojiDataFromNative, Emoji } from 'emoji-mart'
import data from 'emoji-mart/data/all.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileWord, faFileExcel, faFilePowerpoint,faFileCsv, faFile,faFilePdf } from '@fortawesome/free-solid-svg-icons'
// context
import { MessagesContext } from '../../../../Context/Messages'
// componet
import PreViewLink from '../../../Recurent/Mylibrery/PreViewLink'
// style 
import { Button, divFlex, linkMaps} from '../../../../Style/custom'
import GoogleMapReact from 'google-map-react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import { MessageSharp } from '@material-ui/icons'


const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const Blocks = ({message}) => {
    // console.log("Blocks ",message)
    let { type , data, content, options } = message

    if(message.hasOwnProperty("text")){
        data = message
    }
    // Fix para que soporte el formato de texto
    if(type === "text" &&  message.hasOwnProperty("message")){
        data = message
    }
    if(type === "file" &&  message.hasOwnProperty("filename")){
        data = message
    }
    if(type === "video" &&  message.hasOwnProperty("caption")){
        data = message
    }
    if(type === "audio" &&  message.hasOwnProperty("url")){
        data = message
    }
    switch (type) {
        case  'quick_reply':
            return (<div className='contBlock' >

                    <div className='texto'>{renderHTML(content.text)}</div>
                    <div className='contButton'>{options.map((info, i) => 
                        <button key={i} className={Button.primary} style={{backgroundColor: info.color}} disabled={true}>{info.title}</button>)}
                    </div>
                </div>)
        case  'quick-buttons':
            return (<div className='contBlock' >
                    <div className='texto'>{renderHTML(data.text)}</div>
                    <div className='contButton'>{data.buttons.map((info, i) => 
                        <button key={i} className={Button.primary} style={{backgroundColor: info.color}} disabled={true}>{info.value}</button>)}
                    </div>
                </div>)
        case  'video':
            if(message.hasOwnProperty("caption")){
                return (<div className='contBlock'>
                        <video className='video' controls={true} src={data.url}></video>
                        <div className='texto'>{data.caption}</div>
                    </div>)
            }else{
            return (<div className='contBlock'>
                    <div className='texto'>{data.text}</div>
                    <video className='video' controls={true} src={data.video.src}></video>
                </div>)
            }
        case 'text':
            if(typeof data === 'object' && data.hasOwnProperty("message")){
                return(
                    <div>{ renderHTML(data.message)  }</div>
                )
            }
            return(
                <div>{ renderHTML(data.text)  }</div>
            )
        case 'image':
            let texto = (data.hasOwnProperty("text") && data.text != "")?data.text:""
            return(
                <>   
                    <img className='imgUrl' src={data.img.src} />
                    <div>{texto}</div>
                </>
            )   
        case 'audio':
            return(
                <div className='contBlock'>
                    <audio className='audio' controls={true} src={data.url}></audio>
                </div>
            )
        case 'file':
            return(
                <div className='contBlock'>
                   <div className='file'>
                        <a href={data.url}>
                        <FontAwesomeIcon icon={faFilePdf} className='fa-5x icon' />
                        </a>
                    </div>
                    <div className='texto'>{data.filename}</div>
                </div>
            )
        default:            
                if(data){
                    return <div>{validateUrl(data.text) ? '' : renderHTML(data.text)  }</div>
                } else {
                    if(message.hasOwnProperty("attachment")){
                        return <div>{renderTemplates(message)}</div>
                    }
                }
                return "Texto no serializable"
                //return (data) ? <div>{validateUrl(data.text) ? '' : renderHTML(data.text)  }</div> : "Texto no serializable"       
        
    }
}

const renderTemplates = (message) =>{
    const type = message.attachment.type;
    if(type == "template"){
        const tempType = message.attachment.payload.template_type
        switch (tempType) {
            case "button":
                const payload = message.attachment.payload
                return (
                        <div className='contBlock' >
                            <div className='texto'>{renderHTML(payload.text)}</div>
                                <div className='contButton'>{payload.buttons.map((info, i) => 
                                    <button key={i} className={Button.primary}  disabled={true}>{info.title}</button>)}
                                </div>
                        </div>
                )
            default:
                return "Plantilla no serializable"
        }
    }
    return "Adjunto no serializable"
}

const renderMarkers = (map, maps, coordenadas, label) => {
        let marker = new maps.Marker({
                          position: coordenadas,
                          map,
                          title: label
                       });
};

const validateUrl = url => /^(ftp|http|https):\/\/[^ "]+$/.test(url)

const ParceMeta = Meta => {
    // console.log("PARCE META", Meta)
    switch (Meta.contentType) {
        case 'image':
        case 'photo':        
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp':
        case 'image/gif':        
            return (             
                <>   
                    <img className='imgUrl' src={Meta.url_file} />
                    {Meta.title != "" && 
                    <>
                    <br/>
                    <span>{Meta.title}</span>
                    </>
                }
                </>    
                )
        case 'voice':
        case 'audio':
        case 'audio/mpeg':
        case 'audio/ogg; codecs=opus':
        case 'audio/ogg':
        case 'audio/mp3':
        case 'audio/wav':
            return (
                <>
                    <audio className='audioUrl' src={Meta.url_file} controls={true} >
                        Tu navegador no implementa el elemento audio.
                    </audio>
                    {Meta.hasOwnProperty("text") && typeof Meta.text === 'string' && Meta.text !== "" && 
                        <div>{Meta.text}</div>
                    }
                </>
            );
        case 'video':
        case 'video/mp4':
        case 'video/3gpp':
        case 'video/webm':
        case 'video/quicktime':
        // case 'video/x-msvideo':
        // case 'video/x-flv':
        // case 'video/mpeg':
        // case 'video/x-ms-wmv':
            return (
            <>
            <video className='videoUrl' src={Meta.url_file} controls={true} >
                Tu navegador no implementa el elemento <code>video</code>.
            </video>
            {Meta.title != "" && 
                <>
                <br/>
                <span>{Meta.title}</span>
                </>
            }
            </>    
            );
        case 'application/msword':
        case 'application/doc':    
        case 'application/docx':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':    
            return(
                <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url_file}>
                        <FontAwesomeIcon icon={faFileWord} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
                    {Meta.title != "" && 
                        <>
                        <br/>
                        <span>{Meta.title}</span>
                        </>
                    }            
                </>
                ) 
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':        
        case 'application/pptx':
            return(
                <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url_file}>
                        <FontAwesomeIcon icon={faFilePowerpoint} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
                {Meta.title != "" && 
                    <>
                    <br/>
                    <span>{Meta.title}</span>
                    </>
                }            
                </>
                ) 

        case 'application/csv':
        case 'text/csv':
            return(
                <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url_file}>
                        <FontAwesomeIcon icon={faFileCsv} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
                {Meta.title != "" && 
                    <>
                    <br/>
                    <span>{Meta.title}</span>
                    </>
                    }            
                </>
                ) 

        case 'application/zip':
            return(
                <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url_file}>
                        <FontAwesomeIcon icon={faFile} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
                    {Meta.title != "" && 
                        <>
                        <br/>
                        <span>{Meta.title}</span>
                        </>
                    }            
                </>
                ) 


        case 'application/vnd.ms-excel':
        case 'application/xls':    
        case 'application/xlsx':    
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':    
            return(
                <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url_file}>
                        <FontAwesomeIcon icon={faFileExcel} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
                    {Meta.title != "" && 
                        <>
                        <br/>
                        <span>{Meta.title}</span>
                        </>
                    }            
                </>
                ) 

        case 'application/pdf':        
            return(
                <>
                <div className='previewFile' >
                    <iframe src={Meta.url_file} />
                </div>
                    {Meta.title != "" && 
                        <>
                        <br/>
                        <span>{Meta.title}</span>
                        </>
                    }            
                </>
                ) 

        case 'Contact':            
            const contacto = Meta.custom.contacts[0]        
            const phone_show = (Array.isArray(contacto.phones) && contacto.phones.length != 0 ) ? `${contacto.phones[0].type} | ${contacto.phones[0].phone}` : ""   
            const first_name = (typeof contacto.name.first_name !== 'undefined' && contacto.name.first_name !== null) ? contacto.name.first_name : ""    
            const last_name  = (typeof contacto.name.last_name !== 'undefined' && contacto.name.last_name !== null) ? contacto.name.last_name : ""    

            var iniciales = ""
            if (first_name !== "" && last_name !== ""){
                iniciales = `${first_name.charAt(0)}${last_name.charAt(0)}`
            }else if(first_name !== "" && last_name == ""){
                iniciales = `${first_name.charAt(0)}`
            }else if(first_name == "" && last_name !== ""){
                iniciales = `${last_name.charAt(0)}`
            }    
            return (   

               <Card >
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" style={{backgroundColor: "#22a7cc"}}>
                        {iniciales}
                      </Avatar>
                    }
                    action={
                        <PhoneIcon style={{color: "#22a7cc"}}/>
                    }
                    title= {`${first_name} ${last_name}`}
                    subheader={phone_show}
                  />
               </Card>                           
            )                      
        case 'location':
            let ubication = null
            if(typeof Meta.url_file === 'object'){
                ubication = Meta.url_file
            } else if(typeof Meta.url_file === 'string') {
                ubication = JSON.parse(Meta.url_file)
            }
            
            const label = ubication.label
            const coordenadas = {lat: parseFloat(ubication.latitude),lng: parseFloat(ubication.longitude)}
            const urlmap= "https://maps.google.com/?q="+coordenadas.lat+","+coordenadas.lng
            return (                 
                  <div style={{ height: '30vh', width: '30vw' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS  }}
                      center={coordenadas}
                      onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps, coordenadas, label)}                                                          
                      defaultZoom={15}                                
                    >
                    </GoogleMapReact>

                    <a href= {urlmap} className={`${linkMaps.link}`} target="_blank">Ir a Maps</a>
    
                  </div>
                )                      

        default:
            
            return <a href={Meta.url_file} target="_blank">{`${Meta.url_file ? Meta.url_file.substr(0,35) : ''} ${Meta.url_file ? Meta.url_file.length >= 35 ? '...' : '' : ''}`}</a>
    }
}
const parserText = (text) => {
    
    if(typeof text === 'string' && text.length > 0){
        return text.replace(
            /🫡|🫣|🫢|🥸|🥹|🥲|🫥|🫤|🫠|🫶🏼|🫰🏻|🫰|🪢|🪡|🩴|🪖|🫃🏽|🫄🏽|🧌|🥷🏽|🫅🏻|🫂|🫁|🫀|🫦|🫵🏽|🫱🏾|🫲🏽|🫴🏻|🫳|🤌🏾|🫶🏽|🛝|🛻|🪆|🥲|🪰|🪲|🪳|🦟|🦗|🕷️|🕸️|🦭|🦣|🦬|🪶|🐓|🦃|🦤|🦚|🦜|🦢|🦫|🪵|🪴|🪺|🪺|🪹|🍄|🐚|🪸|🪨|💐|🌹|🌷|💐|🌹|🥀|🪷|🌺|🌸|🌻|🌞|🌝|🫧|🫓|🫔|🫕|🫕|🫙|🫗|🫖|🫖|🧋|🫐|🫑|🫒|🛼|🛷|⛸️|🛹|🥌|🎿|⛷️|🏂|🪂|🪘|🪗|🩼|🛞|🛞|🛟|🪝|⛽|🚧|🚦|🚥|🚏|🗺️|🛖|🪫|🪙|🪪|🪜|🧰|🪛|🔧|🔨|⚒️|🪚|🪤|🪦|⚱️|🏺|🔮|📿|🧿|🪬|💈|🔭|🔬|🕳️|🩻|🪠|🪥|🪥|🪞|🪟|🪄|🪅|🪩|🪩|✉️|📩|📨|📧|💌|🪧|🛗|🟰|♾️|💲|🪣/g, '😀')
    }else{
        return text;
    }
        
} ;

// funcion para procesar las urls de los mensajes
const formatText = (text) => {
    console.log("textp",text)
    let link= text;
    console.log("text", text)
    let arrText = text.replace(/\n/g," ").split(' ');
    arrText.forEach(element => {
        if(element.includes("https:")){
            link = link.replace(element,`<a href="${element}" target="_blank">${element}</a>`);
        }else if(element.includes("http:")){
            link = link.replace(element,`<a href="${element}" target="_blank">${element}</a>`);
        } else if(element.includes("www.")){
            link = link.replace(element,`<a href="//${element}" target="_blank">${element}</a>`);
        }
    });
    return link;
}

const MessagesChat = ({User, chat}) => {
    const { ChannelList, scrollRef } = useContext(MessagesContext)
    
    let Meta;
    let processMetadata = false
    try{
        Meta = User.chat[chat].metadata ? User.chat[chat].metadata !== "" ? JSON.parse(User.chat[chat].metadata) : User.chat[chat].metadata : ""           
        
    } catch (error){
        // console.log("ERROR PARSEANDO METADATA DEL USER CHAT")
        // console.log(Meta)
        processMetadata = true;
    }

    if(processMetadata){
        try{
            Meta = User.chat[chat].metadata
            Meta = Meta.replace(':"{"',':{"').replace('"}",','"},').replace(/(?:\r\n|\r|\n)/g, '');
            Meta = JSON.parse(Meta)
        } catch(error){
            // console.log("ERROR PARSEANDO METADATA DEL USER CHAT")
            // console.log(Meta)
            processMetadata = true;
        }
    }
    const Contact =  (Meta.custom  && Meta.custom.contacts ) ? true : false
    if (Contact){
        Meta.url_file = 'Contact'
        Meta.contentType = 'Contact'
    }


    useEffect(() => {
        scrollDown()
      });

    const scrollDown = () =>{
        scrollRef.current.scroll({top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
            left: 0, 
            behavior: 'smooth',});
    }
    
    let message = User.chat[chat].message
    try{
        message = message.replace(':"{"',':{"').replace('"}",','"},').replace(/(?:\r\n|\r|\n)/g, '');
        message = JSON.parse(message)
        
        /** Fix para que soporte bloques con estrutura de array */
        if(Array.isArray(message)){
            let messageAux = {message: message};
            message = messageAux;
        }
    }
    catch (error){
        // console.log("Message Not JSON", message)
    }
    if(typeof message === 'object' && message.hasOwnProperty("latitude")){
        Meta.contentType = 'location'
        Meta.url_file = JSON.stringify(message)
    }
    // console.log("User.chat[chat]",User.chat[chat])
    // console.log("typeof message", typeof message)

    // console.log("RENDER Meta", Meta)
    // console.log("RENDER Message Type", typeof message)

    let compositeMedia = false;
    if(typeof message === 'string' && Meta.hasOwnProperty("contentType") && Meta.contentType.includes("audio") && User.chat[chat].channel == 2){
        if(!message.includes('https:')){
            Meta.text = message
        }
        compositeMedia = true
    }else if(typeof message === 'string' && Meta.hasOwnProperty("contentType") && Meta.contentType.includes("application") ){
        Meta.text = message
        compositeMedia = true
    }else if(typeof message === 'string' && Meta.hasOwnProperty("contentType") && Meta.contentType.includes("image") && User.chat[chat].channel == 2){
        Meta.text = message
        compositeMedia = true
    }
    let render = true
    /*
    let keys = Object.keys(message)
    if(keys.length == 1 && keys[0] == "message"){
        if(Array.isArray(message.message) && message.message.length == 1 ){
            let objMsj = message.message[0]
            
            let msjKeys = Object.keys(objMsj)
            if(msjKeys.length == 1 && msjKeys[0] == "chat_id"){
                render = false
                return (<></>)
            }
        }
    }*/
    // console.log("MESSAGE CHAT",message)
    if(render){
        return(
            <div className={`message ${ChannelList.find(e => e.id === parseInt(User.chat[chat].channel)).name}`}>
                <div className='text'> 
                    { typeof message === 'object' && message !== null && Array.isArray(message.message) ?
                        message.message.map((message, i) =>{                             
                            return (<Blocks key={i} message={message} />)
                        }) 
                        : validateUrl(User.chat[chat].message) || Meta.contentType == 'location' || Contact || compositeMedia
                            ? Meta.url_file !== ''  
                                ? ParceMeta(Meta) 
                                : <PreViewLink url={User.chat[chat].message} /> 
                            :
                                emoji(User.chat[chat].message, (_, string, offset) => {
                                    const emojiData = getEmojiDataFromNative(string, 'google', data)                                           
                    
                                if (typeof emojiData === 'object' && emojiData !== null ){

                                    return <Emoji  key={offset} emoji={emojiData} set={'google'} skin={emojiData ? emojiData.skin || 1 : 1} size={28} />    
                                }
                                
                            })? 
                            <div  className={`${divFlex.flex}`}>{renderHTML(parserText(formatText(User.chat[chat].message)))}</div>  : <div>{renderHTML(User.chat[chat].message.split("🥲").join("😀"))}</div>
                    }
                </div>
                
                <div className='time'>{`${moment(User.chat[chat].time).format('YYYY-MM-DD')} ${moment(User.chat[chat].time).format('LT')}`}</div>
            </div>
        )
    }
}

export default MessagesChat
