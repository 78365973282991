/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useRef, useEffect} from 'react'
import { withTranslation } from 'react-i18next'
import {faBiohazard, faPlus, faSave, faTimes, faPencilAlt, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import { useQuery } from '@apollo/react-hooks'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { SketchPicker } from 'react-color'
// import Select from 'react-select'              
// context
import { TopicContext } from '../../../Context/Topic'
import { AuthContext } from '../../../Context/Auth'
// query and mutation
import { TYPE_TAP_TOPIC } from '../../../Query/Topic' 
// function
import { validateGlobal, toastMessage } from '../../Recurent/ValidateGlobal'
// componets
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
import Conter from '../../Recurent/Conter'
import Select from '../../Recurent/Mylibrery/Select'
// style
import {Button} from '../../../Style/custom'
import {topic} from '../../../Style/topic'
const Topics = ({t}) => {
    // context
    const { 
        typeTopic, setTypeTopic,
        listTypeTopic, setListTypeTopic,
        listTopic, setListTopic,
        editTopic, setEditTopic,
        InputColor, setInputColor,
        Order, setOrder,
        ViewColor, setViewColor,
        setRefechKanban,
        getTap, tapLoading, tapError, tapData, tapRefetch,
        creatTap, newTapLoading, newTapError, newTapData,
        deleteTap, deleteTapLoading, deleteTapError, deteleTapData,
        editTap, editTapLoading, editTapError, editTapData
    } = useContext(TopicContext)
    const { tokenSession, userAuth, botUser } = useContext(AuthContext)    
    // query and mutation
    const { loading: typeTapLoading, error: typeTapError, data: typeTapData, } = useQuery(TYPE_TAP_TOPIC,{variables:{token: tokenSession}})
    // ref
    const nameTopicRef = useRef(null)
    const inputColorRef = useRef(null)
    const selectColorRef = useRef(null)
    // funtion
    const handleClickOutside = (event) => {
        if (selectColorRef && !selectColorRef.current.contains(event.target)) {
            setViewColor(false);
        }
    }
    const addTopic = () => {
        const variables = {
            token: tokenSession,
            name: nameTopicRef.current.value||'',
            type_tag_id: typeTopic.value,
            bot_id: botUser.bot_id,
            user_id: userAuth.id_user,
            order: parseInt(Order),
            color: InputColor
        }
        const [validate] = validateGlobal({
            token: 'String|Require',
            name: 'String|Require',
            type_tag_id: 'Int|Require',
            bot_id: 'Int|Require',
            user_id: 'Int|Require',
            order: 'Int|Require',
            color: 'String|Require'
        },variables,{
            message: {
                name: t('topics.error.name'),
                ErrorDefault: t('topics.error.default'),
                CorrectValidate: t('topics.error.correct')
            },
            position: 'bottom-right',
            correctValidate: false
        })

        if(validate) {
            creatTap({variables, errorPolicy: 'all' })
        }
    }

    const limitNumber = num => {
        if (num > 1) {
            if(editTopic) {
                return num > listTopic.length ?  listTopic.length : num
            } else {
                return num > listTopic.length+1 ?  listTopic.length+1 : num
            }
        } else  {
            return 1
        }
    }
    
    const editsTopic = () => {
        // console.log('test---->',editTopic);
        
        const variables = {
            token: tokenSession,
            tag_id: editTopic.tag_id,
            name: nameTopicRef.current.value||'',
            type_tag_id: typeTopic.value,
            status_tag_id: editTopic.status_tag_id,
            bot_id: botUser.bot_id,
            order: parseInt(Order),
            color: InputColor
        }
        const [validate] = validateGlobal({
            token: 'String|Require',
            tag_id: 'Int|Require',
            name: 'String|Require',
            type_tag_id: 'Int|Require',
            status_tag_id: 'Int|Require',
            bot_id: 'Int|Require',
            order: 'Int|Require',
            color: 'String|Require'
        },variables,{
            message: {
                name: t('topics.error.name'),
                ErrorDefault: t('topics.error.default'),
                CorrectValidate: t('topics.error.correct')
            },
            position: 'bottom-right',
            correctValidate: false
        })

        if(validate) {
            editTap({variables, errorPolicy: 'all'})
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    })
    
    useEffect(() => {
        // console.log('--->',newTapData)
        if(newTapData){
            if(newTapData.createTag){
                tapRefetch()    
            }
        }      
    }, [newTapData])

    useEffect(() => {
        if(editTapData){
            if(editTapData.editTag){
                // console.log('===> test');
                nameTopicRef.current.value=''
                tapRefetch()
                setEditTopic(null) 
            }
        }      
    }, [editTapData])
    
    useEffect(() => {
        if(deteleTapData){
            if(deteleTapData.deleteTag){
                setOrder(1)
                setEditTopic(null)
                nameTopicRef.current.value=''
                tapRefetch()  
                if(deteleTapData.deleteTag.code===204){
                    toastMessage({message: t('topics.error.408'), error: true})
                }
            }
        }      
    }, [deteleTapData])

    useEffect(() => {
        if(tapData) {
            if(tapData.showTagsByBotId){
                if(tapData.showTagsByBotId.data!==listTopic){
                    setListTopic(tapData.showTagsByBotId.data)
                    setRefechKanban(true)
                }
            }
        }
    }, [tapData])

    useEffect(() => {
        if(typeTapData) {
            // console.log(typeTapData)
            if(typeTapData.showTagTypes){
                if(typeTapData.showTagTypes.data!==listTypeTopic){
                    setListTypeTopic(typeTapData.showTagTypes.data)
                }
            }
        }
    }, [typeTapData])

    useEffect(() => {
        if(newTapError) {
            toastMessage({message: t('topics.error.409'), error: true})
        }
    }, [newTapError])

    useEffect(() => {
        if(editTapError) {
            nameTopicRef.current.value=editTopic.tag_name
            toastMessage({message: t('topics.error.409'), error: true})
        }
    }, [editTapError])

    useEffect(() => {
        if(deleteTapError) {
            toastMessage({message: t('topics.error.408'), error: true})
        }
    }, [deleteTapError])

    if(typeTapLoading) return <Loader height='100%' />

    // if(typeTapError) return <Error />

    return (
        <Conter title={t('topics.title')} icon={faBiohazard}>
            <div className={topic.cont}>
                {newTapLoading || editTapLoading ? <Loader height='100%' /> : <div className={topic.form}>
                    <div className={topic.label}>{t('topics.type')}</div>
                    <Select 
                        placeholder={t('topics.type')}
                        className={topic.select}
                        value={typeTopic}
                        search={true}
                        marginTopOption='0px'
                        onChange={(e) =>{
                            getTap({variables:{token:tokenSession, type_tag_id:e.value, bot_id: botUser.bot_id }})
                            setTypeTopic(e)
                            setOrder(1)
                        }}
                        options={listTypeTopic.map(item => ({value:item.type_tag_id, label:item.name}))}
                    />
                    {<>
                    <p>{t('topics.use')}</p>
                    <div className={topic.contLabel}>
                        <div className={`${topic.label} small order`}>{t('topics.order')}</div>
                        <div className={`${topic.label} small name`}>{t('topics.name')}</div>
                        <div className={`${topic.label} small color`}>{t('topics.color')}</div>
                        <div className={`${topic.label} small`}>{t('topics.action')}</div>
                    </div>
                    <div className={topic.contInputButton}>
                        <input 
                            className='order' 
                            type='number' 
                            disabled={typeTopic===null} 
                            min={1} 
                            max={listTopic ? listTopic.length+1 : 1} 
                            value={Order} 
                            onChange={e => setOrder(limitNumber(e.target.value)) } 
                        />
                        <input className='input' ref={nameTopicRef} disabled={typeTopic===null} maxLength={20} 
                        onKeyPress={e =>(e.which===13) ? editTopic ? editsTopic() : addTopic() : '' } />
                        <div className={`inputColor ${typeTopic===null ? 'disabled' : ''}`} >
                            <div ref={inputColorRef} className='color' style={{backgroundColor: InputColor}} 
                            onClick={()=> typeTopic!==null && setViewColor(!ViewColor)} >{InputColor}</div>
                            <div
                                ref={selectColorRef} 
                                className='select' 
                                style={{
                                    marginTop: `${inputColorRef.current ? inputColorRef.current.offsetHeight+5 : 10}px`,
                                    display: ViewColor ? 'block' : 'none'
                                }}
                                >
                                <SketchPicker color={InputColor} onChange={color => setInputColor(color.hex)} />
                            </div>
                        </div>
                        {editTopic && <button className={`${Button.default} button`} onClick={() =>{setEditTopic(null); nameTopicRef.current.value=''}} >
                            <FontAwesomeIcon icon={faTimes } /> 
                        </button>}
                        <button className={`${Button.default} button`} disabled={typeTopic===null} onClick={() =>editTopic ? editsTopic() : addTopic()} >
                            <FontAwesomeIcon icon={editTopic ? faSave : faPlus } /> 
                        </button>
                    </div>
                    </>}
                    <div className={topic.listTap}>
                        { typeTopic ? tapLoading || deleteTapLoading ? <Loader height='100%' size={100} /> 
                        : tapError ? [] : listTopic ? listTopic.map((item, i) =>(
                            <div className={topic.Tap} key={i} style={{backgroundColor: item.color}} >
                                <div>&nbsp;{`${item.order} - ${item.tag_name}`}</div>
                                <FontAwesomeIcon className='icon small' icon={faPencilAlt} 
                                onClick={()=>{setEditTopic(item); nameTopicRef.current.value=item.tag_name; setInputColor(item.color); setOrder(item.order) }} />
                                <FontAwesomeIcon className='icon' icon={faTimesCircle} 
                                onClick={()=>deleteTap({variables:{token:tokenSession, tag_id:item.tag_id, bot_id: botUser.bot_id, type_tag_id: typeTopic.value}, errorPolicy: 'all'}) }  />
                            </div>
                        )):'':''}
                    </div>
                </div>}
            </div>
        </Conter>
    )
}

export default withTranslation()(Topics)
