import React, {useContext, useState, useEffect} from 'react'
import { useQuery  } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
//Imagen
import favorito from '../../../../Asset/img/customBar/favorito.svg'
//Query
import { GET_TEMPLATES } from '../../../../Query/Templates' 
//Context
import { AuthContext } from '../../../../Context/Auth'
//List
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import InputBase from '@material-ui/core/InputBase';
// context
import { CustomBarContext } from '../../../../Context/CustomBar'




const useStyles = makeStyles((theme) => ({
  root: {
    width: '99%',
    maxHeight: '24vh',
    position: 'relative',
    overflow: 'auto',    
    borderRadius: '20px',
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
   borderRadius: '0' 
  },
  title:{
    color: '#0095ae',
    borderRadius: '10px',
    fontSize: '1.10rem'
  },
  inline: {
    display: 'inline',
  },

  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: '10px',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    borderRadius: '10px',
    borderStyle: 'double',
    borderWidth: '1px',
    borderColor:'#EDEDED',
    color: '#263238'

  }

}));




const ShortCut = ({roomId, onButton, title, typeTemplate}) => {
    const { tokenSession, botUser, userAuth } = useContext(AuthContext)    
    const { setTemplateId } = useContext(CustomBarContext)  
    const classes = useStyles();
    const {loading: loadingListTemplate, error: errorListTemplate, data: dataListTemplate, refetch: refetchListTemplate} = useQuery(GET_TEMPLATES, 
        {variables:{token: tokenSession, session_id: roomId, template_type : typeTemplate, bot_id: botUser.bot_id, agent_id: userAuth.id_user },  errorPolicy: 'all', fetchPolicy: 'network-only' })        
    const [dataShortcut, setDataShortcut] = useState(null)
    const [arrShortcuts, setSrrShortcuts] = useState(null)
    const _title = title


    const handleListItemClick = (e, text, id) => {
        //setTemplateId(id) 
        onButton(text)
    }


    useEffect(() => {
      try {
        if(dataListTemplate.getTemplates){
            let templates = []
            if (dataListTemplate.getTemplates.data.templates){                
                templates = dataListTemplate.getTemplates.data.templates
                if (Array.isArray(templates) && templates.length != 0 ){                    
                    setDataShortcut(templates) 
                    setSrrShortcuts(templates)
                }else{
                    setDataShortcut([])
                    setSrrShortcuts([])
                }                
            }else{
                setDataShortcut([])
                setSrrShortcuts([])
            }
        }

      } catch (error) {
        setDataShortcut([])
        setSrrShortcuts([])        
      }  
    }, [dataListTemplate])



    const searchText = (e) => {      
      let character = e.target.value
      let text = character.toLowerCase()
      let trucks = arrShortcuts//dataShortcut
      let filteredName=[]
      if (Array.isArray(trucks) && trucks.length > 0){
          filteredName = trucks.filter((item) => {
          return item.name.toLowerCase().match(text)
        })        
      }
      if (!text || text === '') {
        setDataShortcut(arrShortcuts)
      } else if (!Array.isArray(filteredName) && !filteredName.length) {
        // set no data flag to true so as to render flatlist conditionally
        setDataShortcut([])
      } else if (Array.isArray(filteredName)) {
        setDataShortcut(filteredName)
    }
  }

    return(
      <>



        <List className={classes.root} subheader={<ListSubheader className={classes.title}>{title}   <InputBase placeholder="Search…" classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              name="search-templates"
              id="search-templates"
              onChange={searchText}/>              
          </ListSubheader>}>
        {(Array.isArray(dataShortcut) && dataShortcut.length != 0 )  ? dataShortcut.map(shortcut => {
            return (
            <>
            <ListItem alignItems="flex-start" onClick={(event) => handleListItemClick(event, shortcut.msj, shortcut.id)}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar} src={favorito} />
                </ListItemAvatar>            
                <ListItemText
                   primary={shortcut.name}
                   secondary={
                     <React.Fragment>
                       {shortcut.msj}
                     </React.Fragment>
                   }
                 />            
            </ListItem>              
            <Divider variant="inset" component="li" />
            </>
        )      

        }) : <ListItem>No hay plantillas con ese nombre</ListItem>
        }
        </List>

        </>
    )
}




export default  withTranslation()(ShortCut)