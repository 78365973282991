import { gql } from "apollo-boost"

export const GET_MESSAGES = gql`
query getMessagesList(
  $token: String!
  $bot_id: Int!  
) {
  getMessagesList(
    inputMessagesList: { token: $token, bot_id: $bot_id}
  ) {
    code    
    message
    data{platform_id,client_phone,client_name,agent_name,last_date,tag_color,tag_name,room_id,agent_sentiment}
  }
}
`

export const GET_CONVERSATION = gql`
query getDetailMessages(
  $token: String!
  $room_id: String!
) {
  getDetailMessages(
    inputDefaultChannel: { token: $token, room_id: $room_id}
  ) {
    code    
    message
    data {text_in{text,date_time,content_type,url,title},text_out{text,date_time,content_type,url}}
  }
}
`

export const TRANSFER_AGENT = gql`
mutation pushDerivation(
	$token: String! 
  $client_phone: String! 
  $agent_id: Int!
  $bot_id: Int!
  $client_name: String
  $room_id: String !
  
) {
  pushDerivation(
    inputPush: { token: $token, client_phone: $client_phone, agent_id: $agent_id, bot_id: $bot_id, client_name: $client_name, room_id: $room_id }
  ) {
    code
    message    
  }
}
`

export const ADD_MESSAGES = gql`
mutation getMessagesListPaged($token: String!, $bot_id: Int!,$limit: Int!,$offset: Int!, $user_filter: String) {
  getMessagesListPaged(inputMessagesListPaged: {token: $token, bot_id: $bot_id,limit:$limit,offset:$offset, user_filter:$user_filter}) {
      code
      message
      data {
          platform_id
          client_phone
          client_name
          agent_name
          last_date
          fk_channel_out
          tag_color
          tag_name
          room_id
          agent_sentiment
      }
  }
}
`
