import { gql } from "apollo-boost";

// export const GET_SENTIMENT_AGENT = gql`
// query getAvgSentimentAnalysisByAgent($token: String!, $agent_id: Int!) {
//   getAvgSentimentAnalysisByAgent(inputAvgSentimentAnalysisByAgent: {token: $token, agent_id: $agent_id}) {
//     code
//     message
//     data {
//         avg_sentiment_analysis
//     }
//   }
// }
// `

export const CHANNEL_PATAFORM_LIST_MESSAGES = gql`
query getDataChannel($token:String){
    showChannel(inputDefaultChannel:{token:$token}){
      data{
        id
        name
      }
    }
    showPlatform(inputDefaultPlatform:{token:$token}){
        data{
          id
          name
          icon
        }
    }
  }
`

export const SHOW_TAPS_BOT = gql`
query showTagsByUserId($token:String,$user_id:Int){
  showTagsByUserId(inputByUserId:{
    token:$token,
    user_id:$user_id
  }){
    code
    message
    data{
      tag_id
      tag_name
      status_tag_id
      type_tag_id
      type_tag_name
    }
  }
}
`
export const SHOW_TAPS_BOT_ID = gql`
query showTagsByBotId($token:String,$bot_id:Int,$type_tag_id:Int){
  showTagsByBotId(inputByBotTypeId:{
    token:$token,
    bot_id:$bot_id,
    type_tag_id:$type_tag_id
  }){
    code
    message
    data{
      tag_id
      tag_name
      order
      color
    }
  }
}
`
export const SHOW_TAPS_TYPE_BOT = gql`
query showTag($token:String,$type_tag_id:Int){
  showTag(inputDefaultTag:{
    token:$token,
    type_tag_id:$type_tag_id
  }){
    code
    message
    data{
      tag_id
      name
      status_tag_id
      create_date
    }
  }
}
`
export const SHOW_CLIENT_MAIL  = gql`
query getClientDataByEmail($token:String, $client_email:String, $id_bot:Int, $user_id:Int){
  getClientDataByEmail(inputClientByEmail:{
    token:$token,
    client_email:$client_email,
    id_bot:$id_bot,
    user_id:$user_id
  }){
    code
    message
    data{
      infoClient{
        id_client
        client_name
        client_email
        client_phone_indicative
        client_phone
        json_keys
        telegram_id
        facebook_id
        company
      }
      tagsClient{
        tag_id
        tag_name
        order
        color
        status_tag_id
      }
    }
  }
}
`
export const SHOW_CLIENT_PHONE  = gql`
query getClientDataByPhone($token:String, $client_phone:String, $id_bot:Int, $user_id:Int){
  getClientDataByPhone(inputClientByPhone:{
    token:$token,
    client_phone:$client_phone,
    id_bot:$id_bot,
    user_id:$user_id
  }){
    code
    message
    data{
      infoClient{
        id_client
        client_name
        client_email
        client_phone_indicative
        client_phone
        json_keys
        telegram_id
        facebook_id
        company
      }
      tagsClient{
        tag_id
        tag_name
        order
        color
        status_tag_id
      }
    }
  }
}
`

export const SHOW_CLIENT_ID = gql`
query	getClientData($token:String, $id_client:Int, $id_bot: Int, $start_date: String, $end_date:String){
  getClientData(inputClientDefault:{
    token:$token,
    id_client:$id_client,
    id_bot:$id_bot,
    start_date:$start_date,
    end_date:$end_date
  }){
    code
    message
    data{
      infoClient{
        id_client
        client_name
        client_email
        client_phone_indicative
        client_phone
        json_keys
        telegram_id
        facebook_id
        company
      }
      datesClient{
        id_schedulin
        date_scheduling
        id_agent
      }
      tagsClient{
        tag_id
        tag_name
        order
        color
        status_tag_id
      }
    }
  }
}
`

export const CREATE_CLIENT = gql`
mutation newClient (
  $token: String
  $client_name: String
  $client_email: String
  $client_phone: String
  $client_phone_indicative: String
  $facebook_id: String
  $platform_id: Int
  $session_id: String
  $user_id: Int
){
  createClient(inputClient:{
    token: $token
		client_name: $client_name
    client_email: $client_email
    client_phone: $client_phone
    client_phone_indicative: $client_phone_indicative
    user_id: $user_id
    json_keys: {
      facebook_id: $facebook_id
    }
    platform_id: $platform_id
    session_id: $session_id
  }){
    code
    message
    id_client
  }
}
`
export const EDIT_CLIENT = gql`
mutation editCliente(
  $token: String
  $id_client: Int
  $client_name: String
  $client_email: String
  $client_phone: String
  $client_phone_indicative: String
  $facebook_id: String
  $fk_state: Int
  $platform_id: Int
  $session_id: String
  $user_id: Int
) {
    editClient(
    inputEditClient: {
      token: $token
      id_client:$id_client
      client_name: $client_name
      client_email: $client_email
      client_phone: $client_phone
      client_phone_indicative: $client_phone_indicative
      json_keys: {
        facebook_id: $facebook_id
      }
      fk_state: $fk_state
      platform_id: $platform_id
      session_id: $session_id
      user_id: $user_id
    }
  ) {
    code
    message
  }
}
`

export const CREATE_DIATY_USER = gql`
mutation newDiary(
  $token: String
  $date_scheduling: String
  $id_agent: Int
  $id_client: Int
  $scheduling_description: String
){
  createScheduling(inputCreateScheduling:{
    token: $token
    date_scheduling: $date_scheduling
    id_agent: $id_agent
    id_client: $id_client
    scheduling_description: $scheduling_description
  }){
    code
    message
    id_scheduling
  }
}
`

export const EDIT_DIARY_USER = gql`
mutation editdiary(
  $token: String
  $id_scheduling: Int
  $date_scheduling: String
  $id_agent: Int
  $id_client: Int
  $scheduling_description: String
  $fk_state: Int
) {
  editScheduling(
    inputEditScheduling: {
      token: $token
      id_scheduling: $id_scheduling
      date_scheduling: $date_scheduling
      id_agent: $id_agent
      id_client: $id_client
      scheduling_description: $scheduling_description
      fk_state: $fk_state
    }
  ) {
    code
    message
  }
}
`

export const CREATE_PROCTION = gql`
mutation createPropetion(
  $token: String,
	$bot_id: Int,
  $client_id: Int,
  $tag_id: Int,
  $agent_id: Int
) {
  createProspecting(inputCreateProspecting: {
    token: $token
    bot_id: $bot_id
    client_id: $client_id
    tag_id: $tag_id
    agent_id: $agent_id
  }) {
    code
    message
    id_prospecting
  }
}
`

export const SHOW_DIARY_AGENT = gql`
query showDiary($token: String, $id_agent: Int){
  showSchedulingByAgentId(inputTokIdAgent:{
    token: $token,
    id_agent: $id_agent
  }){
    code
    message
    data{
      id_scheduling
      date_created
      date_scheduling
      id_agent
      scheduling_description
      fk_state
      id_client
      client_name
      client_email
      client_phone
    }
  }
}
`

export const SHOP_COUTRY = gql`
query getCoutry($token: String){
  getCountriesData(inputCountryDefault:{
    token: $token
  }){
    code
    message
    data{
      id_country
      name
      initials
      code
      flag
    }
  }
}
`

export const VALIDATE_PHONE = gql`
query validatePhone (
  $token: String
  $phone: String
) {
  validCountryMobilePhone(inputCountryPhone:{
    token: $token
    phone: $phone
  }){
    code
    message
    data{
      name
      code
      flag
      dial_code
      phone_number
      phone
    }
  }
}
`

export const ADD_MESSAGES = gql`
mutation createMessage(
  $token:String!,
  $room_id:String!,
  $bot_id:Int!,
  $quantity:Int!,
  $platform_id:Int!,
  $session_id:String!,
  $agent_id:Int!){
  createMessage(
    inputCreateMessage:{
      token:$token,
      room_id:$room_id,
      bot_id:$bot_id,
      quantity:$quantity,
      platform_id:$platform_id,
      session_id:$session_id,
      agent_id:$agent_id}
      ){
      code
      message
  }
}`