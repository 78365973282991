import React, { createContext, useState } from 'react'
import { useSessionStorage, useLocalStorage, useLocalStorageNoEcrypt } from '../../Hook/sessionStorage'
import { useAnimate } from 'react-simple-animate'

const AuthContext = createContext()
const { Consumer, Provider } = AuthContext
  
const AuthProvider = ({children}) =>{
      
    const [auth, setAuth] = useLocalStorage('auth', false)
    const [autoAuth, setAutoAuth] = useLocalStorage('autoAuth', false)
    const [userAuth, setUserAuth] = useLocalStorage('userAuth', null)
    const [lang, setLang] = useLocalStorageNoEcrypt('lang', 'en')
    const [tokenFirebase, setTokenFirebase] = useSessionStorage('tokenFirebase', null)
    const [tokenAuth, setTokenAuth] = useSessionStorage('tokenAuth', null)
    const [tokenSession, setTokenSession] = useLocalStorage('tokenSession', null)
    const [botsUser, setBotsUser] = useLocalStorage('botsUser', null)
    const [botUser, setBotUser] = useLocalStorage('botUser', null)
    const [viewPass, setViewPass] = useState(false)
    const [viewMenu, setviewMenu] = useState(false)
    const [ErrorBot, setErrorBot] = useState(false)

    const [viewOption, setViewOption] = useState(false)
    const [viewOptionLang, setViewOptionLang] = useState(false)
    // animate
    const { play, style } = useAnimate({
        start: { width: '250px' },
        end: { width: '50px' }
    });
    const { play: playButton, style: styleButton } = useAnimate({
        start: { left: 'calc(250px - 14px)' },
        end: { left: 'calc(50px - 14px)' }
    });
    
    return(<Provider value={{
        tokenAuth, setTokenAuth,
        tokenFirebase, setTokenFirebase,
        tokenSession, setTokenSession,
        auth, setAuth,
        autoAuth, setAutoAuth,
        userAuth, setUserAuth,
        viewPass, setViewPass,
        lang, setLang,
        botsUser, setBotsUser,
        botUser, setBotUser,
        viewMenu, setviewMenu,
        ErrorBot, setErrorBot,
        viewOption, setViewOption,
        viewOptionLang, setViewOptionLang,
        // animate
        play, style,
        playButton, styleButton
    }}>
        {children}
    </Provider>)
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext }