import React, {useContext} from 'react'
import { useQuery, useMutation  } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
import {style} from 'typestyle'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//style
import './style/style.css';


const iconStyle = style({
    width: '24px',
    height: '24px',
    color: '#A0A0A0'
});





const Icon = ({title, icon, setViewContEmoji, contEmojiPlay, setViewContFile, contFilePlay, setViewContAudio, contShortCutPlay, setViewShortCut, onButton}) => {
    const _title = title
    const _icon = icon
    const _setViewContEmoji = setViewContEmoji
    const _contEmojiPlay    = contEmojiPlay
    const _setViewContFile  = setViewContFile
    const _contFilePlay     = contFilePlay
    const _setViewContAudio = setViewContAudio
    const _contShortCutPlay = contShortCutPlay
    const _setViewShortCut  = setViewShortCut


    const setValue = (setViewContEmoji, contEmojiPlay, setViewContFile, contFilePlay, setViewContAudio, contShortCutPlay, setViewShortCut) => { 
        onButton(setViewContEmoji, contEmojiPlay, setViewContFile, contFilePlay, setViewContAudio, contShortCutPlay, setViewShortCut)
    }

    return(

      <div className="fml9Yb">
          <div className="zgMAae">
              <div role="button" className="U26fgb mUbCce fKz7Od OniOhf zS8jHe wAZd3e" tabIndex={0} title={_title}>
                  <span className="xjKiLb">
                      <span className="Ce1Y1c" style={{top: "-12px"}}>                                                        
                          <FontAwesomeIcon className={iconStyle} icon={_icon} onClick={() => setValue(_setViewContEmoji,
                                                                                                      _contEmojiPlay,
                                                                                                      _setViewContFile,
                                                                                                      _contFilePlay,
                                                                                                      _setViewContAudio,
                                                                                                      _contShortCutPlay,
                                                                                                      _setViewShortCut)}                                                            
                          />
                      </span>
                  </span>
              </div>
          </div>
      </div>
    )
}


export default  withTranslation()(Icon)