/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect, useCallback, useContext} from 'react'
import { useQuery, useMutation  } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
import {useAnimate} from 'react-simple-animate'
import {contInput} from './style'
import { inputCustom, divInputCustom, textbox } from '../../../../Style/customBar'
import ContentEditable from 'react-contenteditable'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faLaughBeam, faAngleRight, faAngleDoubleRight,
     faPaperclip, faMicrophone, faTimes,
    faFile, faFileWord, faFileExcel, faFileUpload, faComment, faSpinner } from '@fortawesome/free-solid-svg-icons'
import {useDropzone} from 'react-dropzone'
import { validateGlobal, toastMessage } from '../../../Recurent/ValidateGlobal'
import { GET_FILE_URL } from '../../../../Query/Derivate' 
import { AuthContext } from '../../../../Context/Auth'
import { NimblePicker, 
    // Emoji, getEmojiDataFromNative     
} from 'emoji-mart'
//Context
import { CustomBarContext } from '../../../../Context/CustomBar'

//Component
import  ShortCut  from './shortcut'
import  Icon  from './icon'

import data from 'emoji-mart/data/google.json'
import 'emoji-mart/css/emoji-mart.css'
import { ReactMic } from '@cleandersonlobo/react-mic';
import axios from 'axios';
import './style/style.css';
import {maxlengthContentEditable} from 'maxlength-contenteditable';

//Voice
import MicRecorder from 'mic-recorder-to-mp3';


/**
 * 
 * @param {*} onButton  funcion para efectuar el envio del mensaje
 * 
 * @returns 
 */
const CustomBar = ({placehobler, value, onChange, className, Color, onButton, User, t, disabled, emoji, typeTemplate, customMaxWidth}) => {
    const { tokenSession, botUser } = useContext(AuthContext)    
    const { ViewShortCut, setViewShortCut } = useContext(CustomBarContext)  
    const [getFileUrl, { loading: newFileUrlLoading, error: newFileUrlError, data: getFileUrlData }] = useMutation(GET_FILE_URL)
    const [Value, setValue] = useState(value||'')
    const [files, setFiles] = useState([])
    const [ViewContEmoji, setViewContEmoji] = useState(false)
    const [ViewContFile, setViewContFile]   = useState(false)    
    const [ViewContAudio, setViewContAudio] = useState(false)
    const [audioInterval, setAudioInterval] = useState(false)
    const [loadingContent, setLoadingContent] = useState(false)
    const [textToSend, setTextToSend] = useState("")
    const [recorder, setRecorder] = useState(new MicRecorder({
      bitRate: 128
    }))
    const [firstRender, setfirstRender] = useState(true)
    const [audioFile, setAudioFile] = useState(false)
    let startAudio = new Date()
    const onDrop = useCallback(acceptedFiles  => {

        let arrayTypes = [
        'image/jpeg', 'image/webp', 'image/png',
        'audio/ogg', 'audio/mpeg', 'audio/mp3', 'audio/wav',
        'video/mp4', 'video/3gpp',
        'application/msword',
        'application/doc',    
        'application/docx',
        'application/pptx',  
        'application/vnd.ms-excel',
        'application/xls',    
        'application/xlsx',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ]

        
        if(arrayTypes.includes(acceptedFiles[0].type)){
            if(acceptedFiles.length > 1){
                toastMessage({message: t('messages.error.files'), error: true})
            } else {
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
            }
        } else {
            toastMessage({message: t('messages.error.invalid_file'), error: true})
        }
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    // const [ViewFocus, setViewFocus] = useState(false)
    const refInput = useRef(null)
    const refContFile = useRef(null)
    
    const { play: contEmojiPlay , style: contEmojiStyle } = useAnimate({
        start: {  position: 'fixed', bottom: '-550px',width: '25%', margin: '0px 5px'},
        end: {  position: 'inherit', width: '25%', margin: '0px 5px'},
        duration: 0.8
    })
    const { play: contFilePlay , style: contFileStyle } = useAnimate({
        start: {  position: 'fixed', bottom: '-650px', margin: '0px 10px', borderStyle: 'double', borderWidth: '1px',borderColor: '#0095ae',borderRadius: '10px'},
        end: {  position: 'inherit', margin: '0px 10px', borderStyle: 'double', borderWidth: '1px',borderColor: '#0095ae',borderRadius: '10px'},
        duration: 0.8
    })

    const { play: contShortCutPlay , style: contShortCutStyle } = useAnimate({
        start: {  position: 'fixed', bottom: '-550px', margin: '0px 50px 15px' , borderStyle: 'double', borderWidth: '1px',borderColor: '#0095ae',borderRadius: '10px', maxHeight:'26vh'},
        end: {  position: 'inherit', maxWidth:'86%', margin: '0px 50px 15px' , borderStyle: 'double', borderWidth: '1px',borderColor: '#0095ae',borderRadius: '10px', backgroundColor:'white', maxHeight:'26vh'},
        duration: 0.8

    })

    const ambiente = process.env.REACT_APP_FRONT_ENV


    const removeFile = () => {
        clearInterval(audioInterval);
        setAudioFile(false)
        setAudioInterval(false)
        setViewContAudio(false)
        setViewContFile(false)
        setViewShortCut(false)
        setFiles([])
        setValue("")
    }

    const switchRecord = (e) => {
        
        if(!ViewContAudio){
            startAudio = new Date();
            let inter = setInterval(() => {
                    let endTime =new Date();
                    let diff = endTime - startAudio
                    diff  = Math.floor(diff/1000)
                    let mins = Math.floor( diff /60)
                    mins = (mins.toString().length == 1)?"0"+mins:mins
                    let secs = diff%60
                    secs = (secs.toString().length == 1)?"0"+secs:secs
                    let removeAudio = ""
                    setValue(removeAudio + mins+":"+secs)
            }, 1000);
            setAudioInterval(inter)
            
        } else {
            clearInterval(audioInterval);
        }
    }

    const onData = () => {
    }

    const setStateValue =(_setViewContEmoji, _contEmojiPlay, _setViewContFile, _contFilePlay,_setViewContAudio, _contShortCutPlay, _setViewShortCut) => {

        console.log("_contEmojiPlay",_contEmojiPlay)
        setViewContFile(_setViewContFile); 
        contFilePlay(_contFilePlay); 
        setViewContEmoji(_setViewContEmoji); 
        contShortCutPlay(_contShortCutPlay); 
        contEmojiPlay(_contEmojiPlay); 
        setViewContAudio(_setViewContAudio);
        setViewShortCut(_setViewShortCut)   

    }
    
    const onStop = (recordedBlob) => {
 // se genera el nombre del archivo de audio
        let name = "Voice_message_"+(Math.floor(Math.random() * 1000000 )+ 100000)+".mp3"
        recordedBlob.name = name      
        let file = new File([recordedBlob], name, {type:recordedBlob.type, lastModified:new Date()})
        setAudioFile(file)
    }


    const change = (e) => {
        
        if(e.nativeEvent.inputType !== 'insertParagraph')  {      
            const s = e.currentTarget.textContent
            const htmlObject = document.createElement('div');
            htmlObject.setAttribute('id', 'setChage'); 
            htmlObject.innerHTML = s;

            const children = []            
            //Ojo
            htmlObject.childNodes.forEach(item => {
                if(item.attributes) {
                    if(item.attributes.item('aria-label')) {               
                        const ariaLabel = item.attributes.item('aria-label').value.split(',')
                        children.push(ariaLabel[0])
                    }
                } else {
                    children.push(item.data)

                }
            })
            setTextToSend(children.join(''))
            //onChange(children.join(''))
            setValue(`${e.target.value}`)
        }else{
            eviar()
        }
    }

    const handleOnPaste = (e) => {
        let cbPayload = [...(e.clipboardData || e.originalEvent.clipboardData).items];
        let cbFiles = cbPayload.filter(item => item.kind == "file");
        console.log("payload: ", cbPayload);
        console.log("files: ", cbFiles);
        if (cbFiles.length > 0){
            let arrayTypes = [
            'image/jpeg', 'image/webp', 'image/png',
            'audio/ogg', 'audio/mpeg', 'audio/mp3', 'audio/wav',
            'video/mp4', 'video/3gpp',
            'application/msword',
            'application/doc',    
            'application/docx',
            'application/pptx',  
            'application/vnd.ms-excel',
            'application/xls',    
            'application/xlsx',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
            ]

            
            if(arrayTypes.includes(cbFiles[0].type)){
                if(cbFiles.length > 1){
                    toastMessage({message: t('messages.error.files'), error: true})
                } else {
                    let file = cbPayload[0].getAsFile();
                    setFiles([Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })]);
                    setViewContEmoji(false)
                    contEmojiPlay(false)
                    setViewContFile(true)
                    setViewContAudio(false)
                    setAudioInterval(false)
                    setAudioFile(false)
                    contFilePlay(true)
                    contShortCutPlay(false)
                    setViewShortCut(false)
                    e.preventDefault();
                    return false;
                }
            } else {
                toastMessage({message: t('messages.error.invalid_file'), error: true})
            }            
        }
    }


    const getURL = (file) => {
        const _authorization = "JWT " + tokenSession;
        const formData = new FormData();
        formData.append('file',file);
        return axios.post(`${process.env.REACT_APP_PENGIBOT_BACK}common/getUrlFromFile/`, formData , {
            headers: {
                "Authorization": _authorization,
                "Content-Type": 'multipart/form-data'
            },
        });
    }

    const eviar = async () => {
        let mensaje = textToSend
        if(!loadingContent){
            let url = ""
            let contentType = ""

            if(audioFile){                            
                setLoadingContent(true)
                disabled = true
                await getURL(audioFile).then((res)=>{
                    setLoadingContent(false)
                    disabled = false
                    console.warn(res)
                    url = res.data.media_uri
                    contentType = res.data.content_type
                    envioFire(url,contentType,mensaje)
                })
            } else if(files){
                setLoadingContent(true)
                disabled = true
                await getURL(files[0]).then((res)=>{
                    setLoadingContent(false)
                    disabled = false
                    console.warn(res)
                    url = res.data.media_uri
                    contentType = res.data.content_type
                    envioFire(url,contentType,mensaje)
                })                
            } else {
                envioFire("","",mensaje)
            }            
        }
    }


    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files])


    const envioFire = (url, contentType, mensaje) => {

        const enviado = onButton(url, contentType, mensaje)
            if(enviado) {
                onChange('')
                setValue('')
                setTextToSend('')
                setViewContEmoji(false)
                contEmojiPlay(false)
                setViewContFile(false)                 
                setViewContAudio(false)
                setAudioInterval(false)
                setAudioFile(false)
                setFiles([])
                contFilePlay(false)
                contShortCutPlay(false)
                setViewShortCut(false)

        }
    }

    

    const setShortCut = (text) => {
        setValue(text)
        //onChange(text)
        setTextToSend(text)
    }


    //Voice

    function startRecording() {        
      recorder.start().then(() => {
        console.warn("startRecording");
      }).catch((e) => {
        console.error(e);        
      });
    }

    function stopRecording() {
      recorder.stop().getMp3().then(([buffer, blob]) => {
        console.warn("stopRecording");
        onStop(blob)
      }).catch((e) => {
        console.error(e);
      });
    }


    useEffect(() => {
        if (firstRender){
            setfirstRender(false)            
        }else{
            if(ViewContAudio){
                startRecording()
            } else {
                stopRecording()
            }            
        }
    }, [ViewContAudio])


    // upload files
    const uploadFiles = (e) => {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            console.log(e.target.result);
        }
    }



    return (   

         

        <>


        <div className={`byY7Yb cFc9ae ${divInputCustom}`} >

        <div className='contEmojis'>
            <NimblePicker 
                set='google' 
                data={data} 
                style={contEmojiStyle} 
                color={Color || '#222'} 
                emoji='grinning'
                onClick={(emoji, event) => {         
                    //onChange(`${value}${emoji.native}`)
                    //setValue(`${Value}${event.currentTarget.outerHTML}&nbsp;`)
                    setValue(`${Value}${emoji.native}`)
                    setTextToSend(`${Value}${emoji.native}`)
                    refInput.current.focus()
                }}
                />
        </div>


        <div className='contFile' ref={refContFile} style={{marginLeft: "10px", backgroundColor:"white", width:"30%"}} >
            <div 
                className='cont' {...getRootProps()} 
                style={{...contFileStyle,                    
                    gridTemplateRows: files.length === 0 ? '485px 10px' : '145px 350px',}}>
                <div className='contInputFile' style={{textAlign:'center'}}>
                    <input {...getInputProps()}/>
                    {
                        isDragActive ?                        
                        <p className='message' >Drop the files here ...</p> :
                        <div style={{padding:"10px",color:"#0095ae"}}> 
                            <FontAwesomeIcon icon={faFileUpload} size={"3x"} className='icon' /> 
                            <p style={{padding:"10px"}}>{t('customBar.uploadFile')}</p>  
                            <p style={{padding:"10px", fontSize: "10px" }}>{t('customBar.supportedTypes')}</p>  
                        </div>
                        
                    }
                </div>
                <div className='contPreviewFile'>
                    {files.map(file => {
                        switch (file.type) {
                            case 'image/png':
                            return(
                                <div className='previewFile' key={file.name}  style={{textAlign:"center"}}>
                                    <img src={file.preview} style={{maxWidth:"45vh"}}/>
                                </div>)
                            case 'video/mp4':
                            return(
                                <div className='previewFile' key={file.name}  style={{textAlign:"center"}}>
                                    <video controls={true} src={file.preview} style={{maxWidth:"45vh"}} ></video>
                                </div>)    
                            case 'audio/mp3':
                            case 'audio/ogg':
                            return(
                                <div className='previewFile' key={file.name}  style={{textAlign:"center"}}>
                                    <audio controls={true} src={file.preview} style={{maxWidth:"45vh"}} ></audio>
                                </div>)    
                            case 'application/pdf':
                            return(
                                <div className='previewFile' key={file.name} style={{textAlign:"center"}}>
                                    <iframe src={file.preview} style={{maxWidth:"45vh"}}/>
                                </div>)  
                            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            return(
                                <div className='previewFile' key={file.name}  style={{textAlign:"center"}}>
                                    <div className='file' style={{padding:"10px",color:"#0095ae", borderTopStyle:"dotted", borderTopWidth:"1px"}}>
                                        <FontAwesomeIcon icon={faFileWord} className='icon' size={"6x"} />
                                        <label>{`${file.name.substr(0,15)} ${file.name.length>=15 ? '...' : ''}`}</label> 
                                    </div>
                                </div>) 
                            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            return(
                                <div className='previewFile' key={file.name}  style={{textAlign:"center"}}>
                                    <div className='file' style={{padding:"10px",color:"#0095ae", borderTopStyle:"dotted", borderTopWidth:"1px"}}>
                                        <FontAwesomeIcon icon={faFileExcel} className='icon' size={"6x"} />
                                        <label>{`${file.name.substr(0,15)} ${file.name.length>=15 ? '...' : ''}`}</label> 
                                    </div>
                                </div>) 
                            default:
                            return(
                                <div className='previewFile' key={file.name}  style={{textAlign:"center"}}>
                                    <div className='file' style={{padding:"10px",color:"#0095ae", borderTopStyle:"dotted", borderTopWidth:"1px"}}>
                                        <FontAwesomeIcon icon={faFile} className='icon' size={"6x"} />
                                        <label>{`${file.name.substr(0,15)} ${file.name.length>=15 ? '...' : ''}`}</label> 
                                    </div>
                                </div>) 
                        }
                        
                })}
                </div>
            </div>
        </div>





                <div className="IEIJqd" style={{paddingTop:"0px"}}>
                    <div className="XganBc eLNT1d"style={{display:"flex",boxShadow:"none", paddingTop: "0"}}  data-show-otr-toggle="true" data-include-non-members-in-autocomplete="false" data-is-off-the-record="false" data-is-room="false" data-is-compact="false">
                        <c-wiz className="PhFuQe yXgmRe qs41qe">
                            <div className="I0LFzc mS1Rod">


                            <div className='contAudioDelete' style={{position:'relative', padding:'10px', color: '#A0A0A0'}} title={t('customBar.cancel')}>
                                {(audioFile && !loadingContent) && <FontAwesomeIcon  icon={faTimes}  style={{width:"24px",height:"24px"}} onClick={() =>{ removeFile()}} /> }
                                {(files.length > 0 && !loadingContent) && <FontAwesomeIcon  icon={faTimes}  style={{width:"24px",height:"24px"}} onClick={() =>{ removeFile()}} /> }                                
                                {(loadingContent) && <FontAwesomeIcon  icon={faSpinner} spin  style={{width:"24px",height:"24px"}} onClick={() =>{ removeFile()}} /> }
                            </div>



                                <div className="dJ9vNe" style={{ border : "solid 1px #0095ae",backgroundColor: "white", maxWidth: customMaxWidth }}>
                                    <div className="BScnzc">
                                        <div className="AYGmkd">
                                            <div className="igYHTd">
                                                <div className="Kqyxvc qBALNe">
                                                    

                                                    
                                                            <ContentEditable 
                                                                innerRef={refInput}
                                                                name="message-content"
                                                                id="message-content"
                                                                html={ Value}
                                                                disabled={loadingContent}       // use true to disable editing
                                                                onChange={change} // handle innerHTML change
                                                                tagName='div'
                                                                maxLength={512}
                                                                className={`oAzRtb krjOGe ${inputCustom}`}
                                                                spellCheck={true}
                                                                onPaste={handleOnPaste}
                                                                placeholder={t('customBar.placeholder')}
                                                                data-max-length="10"
                                                                style={{border:0,maxHeight:"8vh",width:"100%", margin:"10px", lineHeight:"2",resize: "none",outlineWidth: 0 ,pointerEvents: (disabled||loadingContent) ? 'none' : 'all'}}
                                                            ></ContentEditable>
                                                </div>
                                    </div>
                                </div>
                            </div>
                            <div className="IwyqYb" jsname="o1L2mb"></div>
                            <div className="EFNTcf" jsaction="JIbuQc:rqwjd(JT7Im)">
                                <div className="CfUpN">
                                  

                                    {  
                                         (User.platform == 1 || User.platform == 4 || User.platform == 7) && <div className="fml9Yb">
                                        <div className="zgMAae">
                                            <div role="button" id="send_audio" name="send_audio" className="U26fgb mUbCce fKz7Od OniOhf zS8jHe wAZd3e"tabIndex="0" title={t('customBar.send.audio')}>
                                                <span className="xjKiLb">
                                                    <span className="Ce1Y1c" style={{top: "-12px"}}>                                                        
                                                        <FontAwesomeIcon style={{color:ViewContAudio?"#ff0000":"#A0A0A0",height:"24px"}} icon={faMicrophone} disabled={loadingContent}
                                                        onClick={() =>{ if(!loadingContent){switchRecord(); 
                                                                        setViewContAudio(!ViewContAudio); 
                                                                        setViewContFile(false); 
                                                                        contFilePlay(false); 
                                                                        setViewContEmoji(false); 
                                                                        contEmojiPlay(false);
                                                                        contShortCutPlay(false);
                                                                        setViewShortCut(false)}}} />
                                                        <div style={{display:"none"}} id="wave_container">

                                                                {/*<ReactMic
                                                                record={ViewContAudio}
                                                                className="sound-wave"
                                                                onStop={onStop}
                                                                onData={onData}
                                                                strokeColor="#000000"
                                                                backgroundColor="#22a7cc"
                                                                echoCancellation={true} // defaults -> false
                                                                autoGainControl={true}  // defaults -> false
                                                                noiseSuppression={true} // defaults -> false
                                                                 />*/}
                                                            </div>



                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {/* UPLOAD_FILES */}
                                    {/* <label htmlFor="file_upload">
                                        <div className="fml9Yb">
                                            <div className="zgMAae">
                                                <div role="button" className="U26fgb mUbCce fKz7Od OniOhf zS8jHe wAZd3e" tabIndex={0} >
                                                    <span className="xjKiLb">
                                                        <span className="Ce1Y1c" style={{top: "-8px"}}>                                                        
                                                            <FontAwesomeIcon style={{color:"#A0A0A0"}} icon={faPaperclip} />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>

                                    <input
                                        type="file"
                                        id="file_upload"
                                        onChange={(e) => uploadFiles(e)}
                                        style={{display: "none"}}
                                    /> */}

                                    {(emoji) && <Icon title={t('customBar.send.emoji')} 
                                          icon={faLaughBeam}
                                          onButton={(setViewContEmoji, contEmojiPlay, 
                                                     setViewContFile, contFilePlay, 
                                                     setViewContAudio, contShortCutPlay, 
                                                     setViewShortCut) => {if(!loadingContent){setStateValue(!ViewContEmoji, !ViewContEmoji, false, false, false, false, false)}}}
                                     />}


                                    { (User.platform == 1 || User.platform == 4 || User.platform == 7) && <Icon title={t('customBar.send.file')} 
                                          icon={faPaperclip}
                                          onButton={(setViewContEmoji, contEmojiPlay, 
                                                     setViewContFile, contFilePlay, 
                                                     setViewContAudio, contShortCutPlay, 
                                                     setViewShortCut) => {if(!loadingContent){setStateValue(false, false, !ViewContFile, !ViewContFile, false, false, false)}}}
                                     />}


                                    <Icon title= {t('customBar.selectmessage')} 
                                          icon={faComment}
                                          onButton={(setViewContEmoji, contEmojiPlay, 
                                                     setViewContFile, contFilePlay, 
                                                     setViewContAudio, contShortCutPlay, 
                                                     setViewShortCut) => {if(!loadingContent){setStateValue(false, false, false, false, false, !ViewShortCut, !ViewShortCut)}}}
                                     />


                                        <div className="zgMAae">
                                            <div role="button" id="send_button" name="send_button"className="U26fgb mUbCce fKz7Od OniOhf zS8jHe wAZd3e"tabIndex="0" title={t('customBar.send.message')}>
                                                <span className="xjKiLb">
                                                    <span className="Ce1Y1c" style={{top: "-16px"}}>                                                        
                                                        <FontAwesomeIcon style={{color:"#22a7cc", width:"32px",height:"32px"}} icon={faAngleDoubleRight} 
                                                        onClick={() => eviar()} />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                </div>
                            </div>
                        </div>
                        {/*<div className="XT3Vq N5XGq">
                            <div role="button" className="U26fgb mUbCce fKz7Od zFe2Ef m7Rhac" style={{position:'relative', padding:'5px', paddingTop:'5px', color: '#A0A0A0'}} onClick={() => eviar()}  tabIndex={"0"} title={t('customBar.send.message')}>
                                <FontAwesomeIcon style={{width:"32px",height:"32px", color:"#A0A0A0", paddingTop:'5px'}} icon={faAngleDoubleRight} />
                            </div>
                        </div>*/}
                    </div>
                    </c-wiz>
                </div>
            </div>


            {ViewShortCut && <div className='contShortCuts'>
                <div style={contShortCutStyle}>

                    <ShortCut 
                        roomId={User.roomId}
                        onButton={(text) => setShortCut(text)}
                        title={t('customBar.shortcuts')} 
                        key={User.roomId}
                        typeTemplate = {typeTemplate}
                    />
                </div>
            </div>}
        </div>
    </>    
    )
}

export default withTranslation()(CustomBar)