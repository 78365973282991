import { gql } from "apollo-boost"

export const TYPE_TAP_TOPIC = gql`
query getTypeTap($token: String){
  showTagTypes(inputDefaultTag:{
    token: $token
  }){
    data{
      type_tag_id
      name
    }
  }
}
`

export const SHOW_TAP_TOPIC = gql`
query getTag($token: String, $type_tag_id: Int, $bot_id: Int ){
  showTagsByBotId(inputByBotTypeId:{
    token: $token
    type_tag_id: $type_tag_id
    bot_id: $bot_id
  }) {
    data{
      tag_id
      tag_name
      order
      color
      status_tag_id
    }
  }
}
`

export const CREAR_TAP_TOPIC = gql`
mutation crearTap(
  $token: String, 
  $type_tag_id: Int, 
  $name: String, 
  $bot_id: Int, 
  $user_id: Int,
  $order: Int,
  $color: String,
){
createTag(inputNewTag:{
  token: $token
  type_tag_id: $type_tag_id
  name: $name
  bot_id: $bot_id
  user_id: $user_id
  order: $order
  color: $color
}){
  code
  message
  tag_id
}
}
`
export const EDIT_TAP_TOPIC = gql`
mutation editTap($token: String, $tag_id: Int, $type_tag_id: Int, $name: String, 
  $status_tag_id: Int, $bot_id: Int, $order: Int, $color: String){
  editTag(inputEditTag:{
    token: $token,
    tag_id: $tag_id,
    type_tag_id: $type_tag_id,
    name: $name,
    status_tag_id: $status_tag_id
    bot_id: $bot_id,
    order: $order,
    color: $color
  }){
    code
    message
  }
}
`
export const DELETE_TAP_TOPIC = gql`
mutation deleteTap($token: String, $tag_id: Int, $bot_id: Int, $type_tag_id: Int){
  deleteTag(inputDeleteTag:{
    token: $token
    tag_id: $tag_id
    bot_id: $bot_id
    type_tag_id: $type_tag_id
  }){
    code
    message
  }
}
`