import React from 'react'
import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import BarChart from '../../../Recurent/Graphics/BarCharts'


const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      height: 500,
      padding: 30,
    },
    title: {
        fontSize: 40,
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    subTitle: {
        fontSize: 20,
        textAlign: 'center',
        padding: 5,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: '#0095ae',
    },
    cardIcon: {
        float: 'left',
        height: 40,
        width: 80,
        padding: 10,
        paddingLeft: 30,
    },
    panel: {
        margin: 5,
    },
    cardText: {
        float: 'left',
        alignContent: 'center',
        height: 350,
        width: 800,
    },
    cardIcons: {
        float: 'left',
        width: '100%',
        padding: 20,
    },
    icons: {
        float: 'left',
        height: 80,
        width: '90%',
        paddingTop: 15,
        paddingLeft: 120
    },
    iconoSize: {
      width: 45,
      height: 45,
      float: 'right'
    },
    divIcon: {
      width: '25%',
      height: '50%',
      float: "left",
    },
    divText: {
      height: '60%',
      float: "left",
      paddingTop: 15,
      fontSize: 20,
    },
    divCaneles: {
      float: 'left', 
      padding: 15
    }
  }));

const CardGraphics = ({t, title, graphicsData})=> {

    const classes = useStyles();
    
    var labels = [];
    var data = [];
    graphicsData.map(row => {
      labels.push(row.platform)
      data.push(row.total)
    });
    var graphData = {labels: labels, data: data}

    return (
      <Card className={classes.root} style={{margin:'10px'}}>
          <Typography className={classes.title} color="textPrimary" gutterBottom>
              {title || "Title"}
          </Typography>

          <div className={classes.divCaneles} style={{width: '65%'}}>
            <BarChart t={t} graphicsData={graphData}/>
          </div>
          
          <div className={classes.divCaneles} style={{width: '35%'}}>
          {graphicsData.map(row => (
              <div className={classes.cardIcons} key={row.platform}>

                  <div className={classes.divIcon}>
                    <img className={classes.iconoSize} src={row.icon} alt={row.platform}/>
                  </div>
                  <div className={classes.divText}>
                    <h2>{row.platform}: {row.total}</h2>
                  </div>
                </div>
              ))}  
          </div>
      </Card>
    )
}

export default withTranslation()(CardGraphics)