import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from '../../../Context/Auth'

const PrivateRoute = ({ children, ...rest }) => {
    const { auth } = useContext(AuthContext)
    return(
        <Route
            {...rest}
            render={({ location }) =>
                auth ? (
                    children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }}
                            />
                    )
            }
        />
    )
};

export default PrivateRoute;