/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { withTranslation } from 'react-i18next'
import { RingLoader } from 'react-spinners'
// style
import { Color, Cont } from '../../../Style/custom'
import { login } from '../../../Style/auth'
// img
import icon from '../../../Asset/img/login/icon.png'

const Loader = ({t, size, height, styleImg}) => (
    <div className={Cont.loader} style={{height}}>
        <img className={login.logo} src={icon} style={styleImg} />
        <h1 style={{color: Color.primary, marginBottom: 0}}>{t('title')}</h1>
        <h3 style={{color: Color.primary, marginTop: 0}}>{t('loader')}</h3>
        <div style={{marginBottom: '30px'}}>
            <RingLoader
                size={size||150}
                color={Color.primary}
                loading={true}
            />
        </div>
    </div>
)

export default withTranslation()(Loader)