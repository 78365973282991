/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, {useContext} from 'react'
import moment from 'moment'
import emoji from 'react-easy-emoji'
import { getEmojiDataFromNative, Emoji } from 'emoji-mart'
import data from 'emoji-mart/data/all.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileWord, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
// context
import { MessagesContext } from '../../../../Context/Messages'
// componet
import PreViewLink from '../../../Recurent/Mylibrery/PreViewLink'
// style 
import { Button, divFlex, linkMaps } from '../../../../Style/custom'
import GoogleMapReact from 'google-map-react';
import { ContactsOutlined } from '@material-ui/icons'
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone'


const renderHTML = (rawHTML,key) => {
    
    try {
        let obj = JSON.parse(rawHTML) 
        
            if (obj.hasOwnProperty("message")){
                let message = obj.message[0]
                if (message.type == "text"){
                     return React.createElement("div", { dangerouslySetInnerHTML: { __html: message.data.message }})            
                }
                return (<Blocks key={`blocks_${key}`} message={message} />)
            } else if (obj.hasOwnProperty("latitude")){
                const label = obj.label
                const coordenadas = {lat: parseFloat(obj.latitude),lng: parseFloat(obj.longitude)}
                const urlmap= "https://maps.google.com/?q="+coordenadas.lat+","+coordenadas.lng
                return (                 
                    <div style={{ height: '30vh', width: '30vw' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS  }}
                        center={coordenadas}
                        onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps, coordenadas, label)}                                                          
                        defaultZoom={15}                                
                        >
                        </GoogleMapReact>
                        <a href= {urlmap} className={`${linkMaps.link}`} target="_blank">Ir a Maps</a>
                    </div>
                    )    
            } else if (obj.hasOwnProperty("custom") && obj.custom.hasOwnProperty("contacts")){
                
                const contacto = obj.custom.contacts[0]        
                
                const phone_show = (Array.isArray(contacto.phones) && contacto.phones.length != 0 ) ? `${contacto.phones[0].type} | ${contacto.phones[0].phone}` : ""   
                const first_name = contacto.name.last_name && contacto.name.first_name !== null ? contacto.name.first_name : ""    
                const last_name  = contacto.name.last_name && contacto.name.last_name !== null ? contacto.name.last_name : ""    
                
                var iniciales = ""
                if (first_name !== "" && last_name !== ""){
                    iniciales = `${first_name.charAt(0)}${last_name.charAt(0)}`
                }else if(first_name !== "" && last_name == ""){
                    iniciales = `${first_name.charAt(0)}`
                }else if(first_name == "" && last_name !== ""){
                    iniciales = `${last_name.charAt(0)}`
                }    
                return (   
                <Card >
                    <CardHeader
                        avatar={
                        <Avatar aria-label="recipe" style={{backgroundColor: "#22a7cc"}}>
                            {iniciales}
                        </Avatar>
                        }
                        action={
                            <PhoneIcon style={{color: "#22a7cc"}}/>
                        }
                        title= {`${first_name} ${last_name}`}
                        subheader={phone_show}
                    />
                </Card>                           
                )  
            }
            return React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML }})            
    }
    catch (error){
        //console.log("CATCH renderHTML", rawHTML)
        return React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML }})            
    }
};

const Blocks = ({message}) => {

    let { type , data,content, options } = message
    if(message.hasOwnProperty("text")){
        data = message
    }
    // Fix para que soporte el formato de texto
    if(type === "text" &&  message.hasOwnProperty("message")){
        data = message
    }
    if(type === "file" &&  message.hasOwnProperty("filename")){
        data = message
    }
    if(type === "video" &&  message.hasOwnProperty("caption")){
        data = message
    }
    if(type === "audio" &&  message.hasOwnProperty("url")){
        data = message
    }
    switch (type) {
        // Nuevo caso para manejo de botones de whatsapp
        case  'quick_reply':           
        return (<div className='contBlock' >
        <div className='texto'>{renderHTML(content.text)} </div>
        <div className='contButton'>{options.map((info, i) =>
            <button className={Button.primary}  disabled={true}>{info.title}</button>
        )}
        </div>     
      </div>)
        case  'quick-buttons':
        return (<div className='contBlock' >
                <div className='texto'>{renderHTML(data.text,0)}</div>
                <div className='contButton'>{data.buttons.map((info, i) => 
                    <button key={i} className={Button.primary} style={{backgroundColor: info.color}} disabled={true}>{info.value}</button>)}
                </div>
            </div>)
        case  'video':
            //case para video de canal whatsapp
            if(message.hasOwnProperty("caption")){
                return (<div className='contBlock'>
                        <video className='video' controls={true} src={data.url}></video>
                        <div className='texto'>{data.caption}</div>
                    </div>)
            }else{
                return (<div className='contBlock'>
                        <div className='texto'>{data.text}</div>
                        <video className='video' controls={true} src={data.video.src}></video>
                    </div>)
            }

        case 'text':
            if(typeof data === 'object' && data.hasOwnProperty("message")){
                return(
                    <div>{ renderHTML(data.message)  }</div>
                )
            }
            return(
                <div>{ renderHTML(data.text)  }</div>
            )
        case 'image':
            return(
                <>   
                    <img className='imgUrl' src={data.img.src} />
                    <div>{data.text}</div>
                </>
            )   
        case 'audio':
            return(
                <div className='contBlock'>
                    <audio className='audio' controls={true} src={data.url}></audio>
                </div>
            )

        case 'file':
            return(
                <div className='contBlock'>
                   <div className='file'>
                        <a href={data.url}>
                        <FontAwesomeIcon icon={faFilePdf} className='fa-5x icon' />
                        </a>
                    </div>
                    <div className='texto'>{data.filename}</div>
                </div>
            )
        default:            
                return (data) ? <div>{validateUrl(data.text) ? '' : renderHTML(data.text)  }</div> : renderHTML(message)  
    }
}



const renderMarkers = (map, maps, coordenadas, label) => {
        let marker = new maps.Marker({
                          position: coordenadas,
                          map,
                          title: label
                       });
};

const validateUrl = url => /^(ftp|http|https):\/\/[^ "]+$/.test(url)

const ParceMeta = Meta => {

    switch (Meta.content_type) {
        case 'image':
        case 'photo':        
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp':
        case 'image/gif':        
            return(                 
                <>   
                    <img className='imgUrl' src={Meta.url} />
                    {Meta.title != "" && 
                    <>
                    <br/>
                    <span>{Meta.title}</span>
                    </>
                }
                </>)    

        case 'voice':
        case 'audio':
        case 'audio/mpeg':
        case 'audio/ogg; codecs=opus':
        case 'audio/ogg':
        case 'audio/mp3':
        case 'audio/wav':
            return (<audio className='audioUrl' src={Meta.url} controls={true} >
                Tu navegador no implementa el elemento audio.
            </audio>);
        case 'video':
        case 'video/mp4':
        case 'video/3gpp':
            return (
            <>
            <video className='videoUrl' src={Meta.url} controls={true} >
                Tu navegador no implementa el elemento <code>video</code>.
            </video>
            {Meta.title != "" && 
                <>
                <br/>
                <span>{Meta.title}</span>
                </>
            }
            </>    
            );
        case 'application/msword':
        case 'application/doc':    
        case 'application/docx':    
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return(
            <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url}>
                        <FontAwesomeIcon icon={faFileWord} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
            {Meta.title != "" && 
                <>
                <br/>
                <span>{Meta.title}</span>
                </>
            }
            </>    
            );

        case 'application/vnd.ms-excel':
        case 'application/xls':    
        case 'application/xlsx':    
        case 'application/csv':    
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return(
            <>
                <div className='previewFile' >
                    <div className='file'>
                        <a href={Meta.url}>
                        <FontAwesomeIcon icon={faFileExcel} className='fa-5x icon' />
                        </a>
                    </div>
                </div>
                {Meta.title != "" && 
                <>
                <br/>
                <span>{Meta.title}</span>
                </>
            }
            </>    
        ); 
        case 'application/pdf':
            return(
            <>
                <div className='previewFile' >
                    <iframe src={Meta.url} />
                </div>
            {Meta.title != "" && 
                <>
                <br/>
                <span>{Meta.title}</span>
                </>
            }
            </>    
            );
        case 'location':
            const ubication = JSON.parse(Meta.url)
            const label = ubication.label
            const coordenadas = {lat: parseFloat(ubication.latitude),lng: parseFloat(ubication.longitude)}
            const urlmap= "https://maps.google.com/?q="+coordenadas.lat+","+coordenadas.lng
            return (                 
                  <div style={{ height: '30vh', width: '30vw' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS  }}
                      center={coordenadas}
                      onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps, coordenadas, label)}                                                          
                      defaultZoom={15}                                
                    >
                    </GoogleMapReact>
                    <a href= {urlmap} className={`${linkMaps.link}`}  target="_blank">Ir a Maps</a>
                  </div>
                  
                )                      
        default:
            return <a href={Meta.url} target="_blank">{`${Meta.url ? Meta.url.substr(0,35) : ''} ${Meta.url ? Meta.url.length >= 35 ? '...' : '' : ''}`}</a>
    }
}

const parserText = (text) => {
    
    if(typeof text === 'string' && text.length > 0){
        return text.replace(
            /🫡|🫣|🫢|🥸|🥹|🥲|🫥|🫤|🫠|🫶🏼|🫰🏻|🫰|🪢|🪡|🩴|🪖|🫃🏽|🫄🏽|🧌|🥷🏽|🫅🏻|🫂|🫁|🫀|🫦|🫵🏽|🫱🏾|🫲🏽|🫴🏻|🫳|🤌🏾|🫶🏽|🛝|🛻|🪆|🪱|🪰|🪲|🪳|🦟|🦗|🕷️|🕸️|🦭|🦣|🦬|🪶|🐓|🦃|🦤|🦚|🦜|🦢|🦫|🪵|🪴|🪺|🪺|🪹|🍄|🐚|🪸|🪨|💐|🌹|🌷|💐|🌹|🥀|🪷|🌺|🌸|🌻|🌞|🌝|🫧|🫓|🫔|🫕|🫕|🫙|🫗|🫖|🫖|🧋|🫐|🫑|🫒|🛼|🛷|⛸️|🛹|🥌|🎿|⛷️|🏂|🪂|🪘|🪗|🩼|🛞|🛞|🛟|🪝|⛽|🚧|🚦|🚥|🚏|🗺️|🛖|🪫|🪙|🪪|🪜|🧰|🪛|🔧|🔨|⚒️|🪚|🪤|🪦|⚱️|🏺|🔮|📿|🧿|🪬|💈|🔭|🔬|🕳️|🩻|🪠|🪥|🪥|🪞|🪟|🪄|🪅|🪩|🪩|✉️|📩|📨|📧|💌|🪧|🛗|🟰|♾️|💲|🪣/g, '😀')
    }else{
        return text;
    }
        
} ;

// funcion para procesar las urls de los mensajes
const formatText = (text) => {
    console.log("textp",text)
    let link= text;
    console.log("text", text)
    let arrText = text.replace(/\n/g," ").split(' ');
    arrText.forEach(element => {
        if(element.includes("https:")){
            link = link.replace(element,`<a href="${element}" target="_blank">${element}</a>`);
        }else if(element.includes("http:")){
            link = link.replace(element,`<a href="${element}" target="_blank">${element}</a>`);
        } else if(element.includes("www.")){
            link = link.replace(element,`<a href="//${element}" target="_blank">${element}</a>`);
        }
    });
    return link;
}

const MessagesChat = ({ User, chat, chatType, pos}) => {
    
    const { ChannelList } = useContext(MessagesContext)

    let channel = ChannelList.find(e => e.name === chatType).name
    
    let message = chat
    try{
        let strText = message.text.replace(/(?:\r\n|\r|\n)/g, '').replace(':"{"',':{"').replace('"}",','"},');
        let objText = JSON.parse(strText)
        message.message = objText.message
    }
    catch (error){
        // console.log("Message Not JSON", message)
        
    }

    return(
        <div key={`${channel}_${pos}`} className={`message ${ChannelList.find(e => e.name === chatType).name}`}>
            <div className='text'> 
                { typeof message === 'object' && message !== null && Array.isArray(message.message) ?
                    message.message.map((message, i) =>{                             
                        return (<Blocks key={i} message={message} />)
                    }) 
                    : validateUrl(message.url) || message.contentType == 'location'  
                        ? message.url_file !== ''  
                            ? ParceMeta(message) 
                            : <PreViewLink url={message} /> 
                        :
                            emoji(message.text, (_, string, offset) => {
                                const emojiData = getEmojiDataFromNative(string, 'google', data)                                           
                  
                            if (typeof emojiData === 'object' && emojiData !== null ){

                                return <Emoji  key={offset} emoji={emojiData} set={'google'} skin={emojiData ? emojiData.skin || 1 : 1} size={28} />    
                            }
                             
                        })? <div className={`${divFlex.flex}`}>{renderHTML(parserText(formatText(message.text)),pos)}</div>  : <div>{renderHTML(parserText(formatText(message.text)),pos)}</div>
                }
            </div>
            <div className='time'>{`${moment(message.date_time).format('YYYY-MM-DD')} ${moment(message.date_time).format('LT')}`}</div>
            
        </div>
    )
    /*
    return(
        <div key={`${channel}_${pos}`} className={`message ${ChannelList.find(e => e.name === chatType).name}`}>
            <div className='text'>
            {chat.hasOwnProperty('messageArray')  
            ? chat.messageArr.map((message, i) =>{ 
                return (<Blocks key={i} message={message} />)}) 
            : validateUrl(chat.url) || chat.content_type == 'location' 
                ? ParceMeta(chat) 
                : emoji(chat.text, (_, string, offset) => {
                    const emojiData = getEmojiDataFromNative(string, 'google', data)
                    if (typeof emojiData === 'object' && emojiData !== null ){
                        return <Emoji  key={offset} emoji={emojiData} set={'google'} skin={emojiData ? emojiData.skin || 1 : 1} size={28} />
                    }    
                    }) ? <>{renderHTML(chat.text,pos)}</> : <>{renderHTML(chat.text,pos)}</>  }</div>
            <div className='time'>{moment(chat.date_time).format('LT')}</div>
        </div>
    )*/
}

export default MessagesChat


