/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMutation  } from '@apollo/react-hooks'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt ,faAsterisk ,faCube ,faRobot} from '@fortawesome/free-solid-svg-icons'
import { faFacebookMessenger, faSkype, faTelegram, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
// context
import { ClientContext } from '../../../../Context/Client'
import { AuthContext } from '../../../../Context/Auth'
import { MessagesContext } from '../../../../Context/Messages'
import { CustomBarContext } from '../../../../Context/CustomBar'

// component
import EventCalendar from './EventCalendar'
import MessagesChat from './MessagesChat'
import CustomBar from '../../../Recurent/Mylibrery/CustomBar'
// style
import { Button } from '../../../../Style/custom'
import { conEdit } from '../../../../Style/client'
import { Color } from '../../../../Style/custom'
//Query
import { SEND_PUSH } from '../../../../Query/Client' 
//Functions
import {  toastMessage } from '../../../Recurent/ValidateGlobal'

const ShowClient = ({t}) => {
    const {
        Client,
        phone,
        FocusCalendar, setFocusCalendar,
        FocusTimeLine, setFocusTimeLine,
        Pataform, setPataform,
        FocusCalendarPlay, FocusCalendarStyle,
        FocusUserPlay, FocusUserStyle,
        FocusLineTimePlay, FocusLineTimeStyle,
    } = useContext(ClientContext)
    const {
        channelPataformData, 
        PataformList, setPataformList
    } = useContext(MessagesContext)    
    const { lang, tokenSession, botUser } = useContext(AuthContext)
    const { templateId, setViewShortCut } = useContext(CustomBarContext)  
    const [sendPush, {loading: loadingSendPush, error: errorSendPush, data: dataSendPush}] = useMutation(SEND_PUSH, 
        {variables:{},  errorPolicy: 'all' },{fetchPolicy: 'network-only'})        


    const focusCalendar = () => {
        setFocusCalendar(true)
        FocusCalendarPlay(false)
        FocusUserPlay(false)
        FocusLineTimePlay(false)
        setFocusTimeLine(false)
    }
    const focusLineTime = () => {
        setFocusCalendar(false)
        FocusCalendarPlay(true)
        FocusUserPlay(false)
        FocusLineTimePlay(true)
        setFocusTimeLine(true)
    }

    const [Message, setMessage] = useState("")   
    const ambiente = process.env.REACT_APP_FRONT_ENV

    const getIcon = pataform => { 
        switch (pataform.name) {
            case 'Asterisk':
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel asterisk' icon={faAsterisk} />
            case 'Skype':
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel skype' icon={faSkype} />
            case 'Telegram':
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel telegram' icon={faTelegram} />
            case 'Facebook':
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel messager' icon={faFacebookMessenger} />
            case 'Whatsapp':
            case 'WhatsApp':
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel whatsapp' icon={faWhatsappSquare} />
            case 'API':
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel' icon={faCube} />
            default:
                return (pataform.icon!=='' && pataform.icon!==' ') ? <img className='foto' src={pataform.icon} /> :  <FontAwesomeIcon className='iconchannel' icon={faRobot} />
        }
    }

    useEffect(() => {
        if(channelPataformData){
            if(channelPataformData.showPlatform){
                setPataformList(channelPataformData.showPlatform.data)
            }
        }
    }, [channelPataformData])



    const newMessage = (text) =>{
        sendPush({variables:{token: tokenSession, phone: Client.infoClient.client_phone, template_id : templateId, bot_id: botUser.bot_id}})
        setViewShortCut(false)        

    }

    useEffect(() => {        
        if (dataSendPush){
            setMessage("")
            toastMessage({message: t('messages.error.sendPush')})
        }
    }, [dataSendPush])

    const FakeUser = {roomId:""}
    
    return Client ? (
        <div className={conEdit} >
            <div className='info' id="show-client" name="show-client">
                <div className='user' style={FocusUserStyle} >
                    {/* <button 
                        className={`${Button.primary} float`} 
                        onClick={() => focusUser()} 
                    ><FontAwesomeIcon icon={faExpandAlt} /></button> */}
                    <div className='cont'>
                        <div className='item'><b>{`${t('clients.from.name')}: `}</b>{Client.infoClient.client_name}</div>
                        <div className='item'><b>{`${t('clients.from.mail')}: `}</b>{Client.infoClient.client_email}</div>
                        <div className='item'><b>{`${t('clients.from.phone')}: `}</b>{Client.infoClient.client_phone}</div>
                        <div className='listTag'>
                            {Client.tagsClient ? Client.tagsClient.map( (item, i) => (
                                <div className='tag' key={i} style={{backgroundColor: item.color}} >
                                    <div>&nbsp;{`${item.tag_name}`}</div>
                                </div>
                            )) : []}
                        </div>
                    </div>
                </div>
                <div className='calendar' style={FocusCalendarStyle} >
                    <div className='float' >
                        <button 
                            className={`${Button.primary}`} 
                            onClick={() => focusCalendar()} 
                        ><FontAwesomeIcon icon={faExpandAlt} /></button>
                    </div>
                    <div className='cont'>
                        <FullCalendar  
                            defaultView="dayGridMonth" 
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin ]} 
                            header={{
                                left: 'prev,next,today',
                                center: 'title',
                                right: FocusCalendar ? 'dayGridMonth, timeGridMonth, timeGridDay, timeGridWeek' :''
                            }}
                            firstDay={1}
                            locale={lang}
                            locales={[esLocale]}
                            slotLabelFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'short'
                            }}
                            scrollTime={'8:00:00'}
                            columnHeaderText={date => `${moment(date).locale(lang).format('ddd')}`}
                            customButtons={{
                                save: {
                                    text: t('calendar.save'),
                                    click: () =>{ console.log('ok')}
                                }
                            }}
                            eventClick={ info => {
                                console.log(info)                        
                            }}
                            events={Client.datesClient ? Client.datesClient.map(event => ({ ...event, title: `${t('calendar.name')}: ${Client.infoClient.client_name}`,
                            start: moment(event.date_scheduling).format('YYYY-MM-DD HH:mm:ss')})) : [] }
                            eventRender={ ({ event, el }) => {
                                ReactDOM.render(<EventCalendar event={event}/>, el);
                                return el;
                            }}
                        />
                    </div>
                </div>
                <div className={`linetime ${FocusTimeLine ? 'focus' :''}`} style={FocusLineTimeStyle} >
                    <button 
                        className={`${Button.primary} float`} 
                        onClick={() => focusLineTime()} 
                    ><FontAwesomeIcon icon={faExpandAlt} /></button>
                    <div className='cont'>
                            {PataformList ? PataformList.map((pataform, i) => (
                            <div 
                                onClick={()=> { focusLineTime(); setPataform(pataform) }}
                                className={`tag ${Pataform ? Pataform.id===pataform.id ? 'active' : '' : ''}`} 
                                key={i}>
                                {getIcon(pataform)}
                                <div>{pataform.name} </div>                                
                            </div>)): []}
                    </div>
                    <div className={`chats ${FocusTimeLine ? 'focus' :''}`} style={{position: 'absolute', marginTop: '130px', height: '20vw'}}>
                        {Client.messagesClient 
                            ? Pataform 
                                ? Client.messagesClient.find(chat => chat.platform_id === Pataform.id ) 
                                    ? Client.messagesClient.map(
                                        (chat, i, chats) => Pataform 
                                            ? chat.platform_id === Pataform.id ?<MessagesChat key={`chat-${i}`} chat={chat} index={i} chats={chats} /> : '' 
                                            : '')
                                    : <div className='noChatMessage'>{t('clients.thereRecords')}</div> 
                                : ''
                            : <div className='noChatMessage'>{t('clients.thereRecords')}</div>}
                    </div>
                    {  Pataform ? Pataform.id == 4 ?
                        <CustomBar 
                        style={{width: '80vw'}} 
                        className='inputEmoji' 
                        value={Message} 
                        onChange={e => setMessage(e)} 
                        Color={Color.primary}
                        onButton={(Message) => newMessage(Message)}
                        User = {FakeUser}
                        disabled = {true}
                        emoji = {false}
                        typeTemplate = {1} //Push
                    />:'':''}
                </div>
            </div>
        </div>
    ) : <div>test</div>
}

export default withTranslation()(ShowClient)