import React, {createContext, useState, useRef, useContext} from 'react'
import {useAnimate} from 'react-simple-animate'
import {useQuery, useLazyQuery} from '@apollo/react-hooks'
// context
import {AuthContext} from '../Auth'
// query
import { LIST_AGENTS } from '../../Query/Agent'

const AgentContext = createContext()
const { Provider, Consumer } = AgentContext

const AgentProvider = ({children}) => {    
    const {tokenSession, botUser, userAuth} = useContext(AuthContext)
    /**
     * Se cambia la definicion del query de LIST_AGENTS, a LazyQuery para que pueda ser invocada desde cualquier lugar
     */
    const [getListAgents,{loading: loadingListAgents, error: errorListAgents, data: dataListAgent, refetch: refetchListAgent}] = useLazyQuery(LIST_AGENTS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null, id_user: userAuth ? parseInt(userAuth.id_user) : 0 },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})    
    const [viewOption, setViewOption] = useState(false)


    return (<Provider
        value={{
            viewOption, setViewOption,
            getListAgents,loadingListAgents, errorListAgents, dataListAgent, refetchListAgent
        }}
    >
        {children}
    </Provider>)
}

export {AgentProvider, Consumer as AgentConsumer, AgentContext} 