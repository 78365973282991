import React, { useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { faChevronCircleLeft, faChevronCircleRight, faTachometerAlt, faCogs, faRecycle, 
    faUserCheck, faRobot, faCommentMedical, faProjectDiagram, faSyncAlt, faHammer, faBuilding,
    faUsers, faComment, faEye, faBiohazard, faCog, faClipboard, faFileAlt, faBook, faClock } from '@fortawesome/free-solid-svg-icons'
import { faStumbleuponCircle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// context
import { AuthContext } from '../../../Context/Auth'
// funtion
import { capitalize } from '../../../Hook/sessionStorage'
// style
import { menuVertical } from '../../../Style/menuVertical'

const MenuVertical = ({t}) => {
    // context
    const { userAuth, viewMenu, setviewMenu, play, style, playButton, styleButton, botUser } = useContext(AuthContext)
    // router
    const location = useLocation();
    // const 
    const menu = [
        { 
            setion: 'general',
            rol: ['Administrador'],
            bot_types: [1, 2, 3],
            option: [
                { 
                    icon: faTachometerAlt,
                    name: 'dashboard',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faCogs,
                    name: 'settings',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faRecycle,
                    name: 'backups',
                    bot_types: [1],
                }
            ]
        },
        { 
            setion: 'negocio',
            rol: ['Administrador'],
            bot_types: [1, 2, 3],
            option: [
                { 
                    icon: faBiohazard,
                    name: 'topics',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faUsers,
                    name: 'agents',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faClock,
                    name: 'schedulesBySkill',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faFileAlt,
                    name: 'templates',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faCog,
                    name: 'personalization',
                    bot_types: [1, 2, 3],
                }
            ]
        },
        { 
            setion: 'bots',
            rol: ['Administrador'],
            bot_types: [1, 2, 3],
            option: [
                { 
                    icon: faUserCheck,
                    name: 'assignBots',
                    bot_types: [1, 2, 3],
                },
                {
                    icon: faCogs,
                    name: 'botConfig',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faRobot,
                    name: 'bots',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faCommentMedical,
                    name: 'conversationFlow',
                    bot_types: [1],
                },
                { 
                    icon: faProjectDiagram,
                    name: 'flowTree',
                    bot_types: [1],
                },
                { 
                    icon: faSyncAlt,
                    name: 'integrations',
                    bot_types: [1, 2, 3],
                },
                { 
                    icon: faStumbleuponCircle,
                    name: 'jarvisHooks',
                    bot_types: [1],
                },
                { 
                    icon: faHammer,
                    name: 'phrasesRefactoring',
                    bot_types: [1],
                },
                { 
                    icon: faBuilding,
                    name: 'entities',
                    bot_types: [1],
                }
            ]
        },
        { 
            setion: 'messages',
            bot_types: [1],
            rol: ['Agente', 'Administrador'],
            option: [
                { 
                    icon: faComment,
                    name: 'messages',
                    bot_types: [1],
                },
                { 
                    icon: faClipboard,
                    name: 'report',
                    bot_types: [1],
                },
                { 
                    icon: faUsers,
                    name: 'client',
                    bot_types: [1],
                }

            ]
        },        
        { 
            setion: 'history',
            bot_types: [1],
            rol: ['Administrador'],
            option: [
                /*{ 
                    icon: faEye,
                    name: 'monitoring',
                },*/
                { 
                    icon: faBook,
                    name: 'messages_history',
                    bot_types: [1],
                }
                /*,
                {
                    icon: faClipboard,
                    name: 'reportCustomer',

                }*/
            ]
        }
    ]
    
    let history = useHistory();

    const rol =  userAuth.name_rol
    const bot_type = botUser === null ? 1 : botUser.type_bot; //Detecta tipo de bot actual
    //console.log('Hola Bot: ', botUser);
    //console.log('Location. ', location);
    //Revisar si al cambiar de bot, la opción actual sigue estando disponible.
    //Si ya no lo está, redireccionar a la primera opción disponible según
    //el rol del usuario logueado y el tipo de bot seleccionado
    let currentOption = location.pathname.substring(1);
    var menuItem;
    menu.every((i) => {
        menuItem = i.option.find(o => o.name === currentOption);
        return !menuItem;
    });
    //console.log('menuItem: ', menuItem);
    if (menuItem){
        let bt = menuItem.bot_types.find(e => e === bot_type);
        if (!bt){
            var availMenuItem;
            menu.every((i) => {
                let mi = i.rol.find(r => r === rol);
                if (mi) availMenuItem = i;
                return !mi;
            });
            //console.log('Avail menu item: ', availMenuItem);
            if (availMenuItem){
                var availOption;
                availMenuItem.option.every((am) => {
                    let abt = am.bot_types.find(e => e === bot_type);
                    if (abt) availOption = am;
                    return !abt;
                });
                if (availOption){
                    //console.log('Avail Option: ', availOption);
                    history.push('/' + availOption.name);
                }
            }
        }
    }
    return(
        <div className={menuVertical.cont} style={style} >
            <FontAwesomeIcon 
                className={menuVertical.iconclose} 
                onClick={()=> {
                    // console.log('test--->', viewMenu);
                    play(!viewMenu)
                    playButton(!viewMenu)
                    setviewMenu(!viewMenu)
                }} 
                style={styleButton}
                icon={viewMenu ? faChevronCircleRight : faChevronCircleLeft} />
            {menu.map((item, i) => item.rol.find(e => e === rol) && item.bot_types.find(e => e === bot_type) ? (
                <div key={`item${i}`} className={menuVertical.contOption} >
                    <div className={`${menuVertical.title} ${viewMenu ? 'noview' : ''}`} >{t(`menu.titles.${item.setion}`).toUpperCase()}</div>
                    {item.option.map((link, j) => link.bot_types.find(e => e === bot_type) ? (
                        <Link key={`link${j}`} to={`/${link.name}`} className={`${menuVertical.link} ${viewMenu ? 'noview' : ''} ${location.pathname===`/${link.name}`? 'active' : ''}`}>
                            <FontAwesomeIcon className="icon" icon={link.icon} />
                            <label className="label">{capitalize(t(`menu.items.${link.name}`))}</label>
                        </Link>
                    ) : '' )}
                </div>
            ) : '' )} 
        </div>
    )
}

export default withTranslation()(MenuVertical)
