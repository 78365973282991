/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from 'react'
import { AES, enc, format, mode, pad } from 'crypto-js'
// context
import { AuthContext } from '../../../Context/Auth'
// style
import { Cont } from '../../../Style/custom'
const Restore = () => {
    const { autoAuth, lang, botUser  } = useContext(AuthContext)
    // const base58 = encoder('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz');
    const Key = process.env.REACT_APP_KEY_CRYPTO_PATH
    const IV = enc.Utf8.parse("0123456789ABCDEF")
    const  hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser, location: '/restore'}), 
    Key, {
        iv: IV,
        mode: mode.CBC,
        padding: pad.Pkcs7  
    }).toString(format.Base64)
    
    return( <div className={Cont.infoPanel}>
        <iframe src={`${process.env.REACT_APP_FRONT_OLD}authRemote#${hast}`} /> 
    </div>)
}
export default Restore