import React, { createContext, useState, useRef, useContext } from 'react'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
// query
import { GET_DASHBOARD_METRICS, GET_CONVERSATIONS_ALL, GET_TMO, GET_CONVERSATIONS_CHANNEL, GET_ASSIGNED_CONVERSATIONS, GET_ACTIVE_AGENTS, GET_ANSWERED_CONVERSATIONS,
GET_UNANSWERED_CONVERSATIONS, GET_AGENT_PENDING_CONVERSATIONS, GET_RETURNED_CONVERSATIONS } from '../../Query/Dashboard'
//  context
import {AuthContext} from '../Auth'

const DashboardContext = createContext()
const { Consumer, Provider } = DashboardContext

const DashboardProvider = ({children}) =>{
    const {tokenSession, botUser, userAuth} = useContext(AuthContext)

    const [allConversations, setAllConversations] = useState('-')
    const [tmo, setTMO] = useState('-')
    const [conversationsChannel, setConversationsChannel] = useState([])
    const [conversationsChannelGD, setConversationsChannelGD] = useState([])
    const [assignedConversations, setAssignedConversations] = useState('-')
    const [activeAgents, setActiveAgents] = useState('-')
    const [answeredConversations, setAnsweredConversations] = useState('-')
    const [unansweredConversations, setUnansweredConversations] = useState('-')
    const [agentPendingConversations, setAgentPendingConversations] = useState('-')
    const [returnedConversations, setReturnedConversations] = useState('-')
    const [agentSentiment, setAgentSentiment] = useState('-')
    const [botSentiment, setBotSentiment] = useState('-')
    const [botServed, setBotServed] = useState('-')
    const [agentServed, setAgentServed] = useState('-')
    const [busyAgents, setBusyAgents] = useState('-')
    const [freeAgents, setFreeAgents] = useState('-')
    const [agentsPerformance, setAgentsPerformance] = useState([])

    // query
    const {loading: loadingDashboardMetrics, error: errorDashboardMetrics, data: dataDashboardMetrics, refetch: refetchDashboardMetrics} = useQuery(GET_DASHBOARD_METRICS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    /*const {loading: loadingAllConversations, error: errorAllConversations, data: dataAllConversations, refetch: refetchAllConversations} = useQuery(GET_CONVERSATIONS_ALL, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingAllConversations, error: errorAllConversations, data: dataAllConversations, refetch: refetchAllConversations} = useQuery(GET_CONVERSATIONS_ALL, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingTMO, error: errorTMO, data: dataTMO, refetch: refetchTMO} = useQuery(GET_TMO, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingConversationsChannel, error: errorConversationsChannel, data: dataConversationsChannel, refetch: refetchConversationsChannel} = useQuery(GET_CONVERSATIONS_CHANNEL, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingAssignedConversations, error: errorAssignedConversations, data: dataAssignedConversations, refetch: refetchAssignedConversations} = useQuery(GET_ASSIGNED_CONVERSATIONS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingActiveAgents, error: errorActiveAgents, data: dataActiveAgents, refetch: refetchActiveAgents} = useQuery(GET_ACTIVE_AGENTS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingAnsweredConversations, error: errorAnsweredConversations, data: dataAnsweredConversations, refetch: refetchAnsweredConversations} = useQuery(GET_ANSWERED_CONVERSATIONS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingUnansweredConversations, error: errorUnansweredConversations, data: dataUnansweredConversations, refetch: refetchUnansweredConversations} = useQuery(GET_UNANSWERED_CONVERSATIONS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingAgentPendingConversations, error: errorAgentPendingConversations, data: dataAgentPendingConversations, refetch: refetchAgentPendingConversations} = useQuery(GET_AGENT_PENDING_CONVERSATIONS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})
    const {loading: loadingReturnedConversations, error: errorReturnedConversations, data: dataReturnedConversations, refetch: refetchReturnedConversations} = useQuery(GET_RETURNED_CONVERSATIONS, 
        {variables:{token: tokenSession, id_bot: botUser ? botUser.bot_id : null },  errorPolicy: 'all'},{fetchPolicy: 'network-only'})*/

    return(<Provider value={{
        allConversations, setAllConversations,
        tmo, setTMO,
        conversationsChannel, setConversationsChannel,
        conversationsChannelGD, setConversationsChannelGD,
        assignedConversations, setAssignedConversations,
        activeAgents, setActiveAgents,
        answeredConversations, setAnsweredConversations,
        unansweredConversations, setUnansweredConversations,
        agentPendingConversations, setAgentPendingConversations,
        returnedConversations, setReturnedConversations,
        agentSentiment, setAgentSentiment,
        botSentiment, setBotSentiment,
        botServed, setBotServed, agentServed, setAgentServed,
        busyAgents, setBusyAgents, freeAgents, setFreeAgents,
        agentsPerformance, setAgentsPerformance,
        loadingDashboardMetrics, errorDashboardMetrics, dataDashboardMetrics, refetchDashboardMetrics
        /*loadingAllConversations, errorAllConversations, dataAllConversations, refetchAllConversations,
        loadingTMO, errorTMO, dataTMO, refetchTMO,
        loadingConversationsChannel, errorConversationsChannel, dataConversationsChannel, refetchConversationsChannel,
        loadingAssignedConversations, errorAssignedConversations, dataAssignedConversations, refetchAssignedConversations,
        loadingActiveAgents, errorActiveAgents, dataActiveAgents, refetchActiveAgents,
        loadingAnsweredConversations, errorAnsweredConversations, dataAnsweredConversations, refetchAnsweredConversations,
        loadingUnansweredConversations, errorUnansweredConversations, dataUnansweredConversations, refetchUnansweredConversations,
        loadingAgentPendingConversations, errorAgentPendingConversations, dataAgentPendingConversations, refetchAgentPendingConversations,
        loadingReturnedConversations, errorReturnedConversations, dataReturnedConversations, refetchReturnedConversations*/
    }} >
        {children}
    </Provider>)
}

export { DashboardProvider, Consumer as DashboardConsumer, DashboardContext }