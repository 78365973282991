import {style} from 'typestyle'
// style
import { Color } from './custom'

export const report = style({
    width: '100%',
    minHeight: 'calc(100vh - 322px)',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    paddingBottom: '12px',
    $nest: {
        '& .droppable': {
            backgroundColor: Color.border,
            marginLeft: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            width: '250px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,


            $nest:{
                '& .title':{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: Color.primary,
                    color: Color.white,
                    padding: '5px',
                },
                '& .cont':{
                    padding: '5px',
                    flex: '1 1 auto',
                    overflow: 'auto',
                    $nest: {
                        '& .item': {
                            backgroundColor: Color.hoverPrimary,
                            borderRadius: '5px',
                            padding: '5px',
                            marginBottom: '5px',
                            color: Color.white 
                        }
                    }
                }
            }
        }
    }
})

export const form = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '10px',

    $nest: {
        '& .contGrup': {
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            justifyContent: 'flex-end',

            $nest: {
                '& .label': {
                    marginBottom: '5px',
                    color: Color.primary,
                    fontWeight: 600
                },
                '& .date': {
                    borderColor: Color.border,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    
                    $nest: {
                        '& .DateRangePickerInput__withBorder': {
                            borderRadius: '5px',
                            borderColor: 'transparent'
                        },
                        '& .DateInput_input': {
                            width: 'calc(100% - 22px)'
                        },
                        '& .DateInput__small': {
                            width: '110px'
                        }

                    }
                },
                '& button': {
                    padding: '9px 12px',
                    alignSelf: 'flex-end'
                }
            }
        }
    }
})

export const contInfo = style({
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    $nest: {
        '& .info': {
            fontSize: '12px',
            color: Color.text,
            marginTop: '0px',
            marginBottom: '10px'
        }
    }
})