import {gql} from 'apollo-boost'

export const LIST_CLIENT = gql`
query getClientDataByLike($token:String, $min_count:Int, $max_count:Int, $id_bot:Int,
    $client_name:String, $client_email:String, $client_phone:String, $id_tags:String, $user_id:Int,
    $start_date:String, $end_date:String){
    getClientDataByLike(inputClientByLike:{
        token:$token,
        min_count:$min_count,
        max_count:$max_count,
        id_bot:$id_bot,
        client_name:$client_name,
        client_email:$client_email,
        client_phone:$client_phone,
        id_tags:$id_tags,
        user_id:$user_id,
        start_date:$start_date,
        end_date:$end_date
    }){
    code
    message
    data{
        availableData
        dataClient{
            id_client
            client_name
            client_email
            client_phone_indicative
            client_phone
            json_keys
            telegram_id
            facebook_id
            company
        }
    }
}
}
`

export const SHOW_CLIENT_ID = gql`
query	getClientData($token:String, $id_client:Int, $id_bot: Int, $start_date: String, $end_date:String){
    getClientData(inputClientDefault:{
        token:$token,
        id_client:$id_client,
        id_bot:$id_bot,
        start_date:$start_date,
        end_date:$end_date
    }){
        code
        message
        data{
            infoClient{
                id_client
                client_name
                client_email
                client_phone_indicative
                client_phone
                json_keys
                telegram_id
                facebook_id
                company
            }
            datesClient{
                id_schedulin
                date_scheduling
                id_agent
            }
            tagsClient{
                tag_id
                tag_name
                order
                color
                status_tag_id
            }
            messagesClient{
                msg_id
                text_in
                text_out
                platform_id
                platform_name
                channel_id
                channel_name
            }
        }
    }
}
`

export const SEND_PUSH = gql`
mutation sendPush (
  $token: String
  $phone: String
  $template_id: Int
  $bot_id: Int
){
  sendPush(inputMessage:{
    token: $token
    phone: $phone
    template_id: $template_id
    bot_id: $bot_id
  }){
    message
  }
}
`