import { gql } from "apollo-boost"

export const GET_PERSONALIZATION = gql`
query getConfigurationByBotId($token:String, $bot_id:Int){
  getConfigurationByBotId(inputBotId:{
    token:$token,
    bot_id:$bot_id
  }){
	  code
    message
    data{
        id_company
        configuration{
            max_bot
            max_user
            max_agent_occupation
            max_derivation_queue_validity
            max_derivation_agent_validity
            derivations_adm
            alert_time
            analize_sentiment
            monthly_total
            sample_percentage
            is_bot_session_validity
            bot_session_validity
            bot_session_message
        }
    }
  }
}
`

export const SAVE_PERSONALIZATION = gql`
mutation setConfigurationByBotId(
  $token:String, 
  $bot_id: Int, 
  $max_bot: Int,
  $max_user: Int,
  $max_agent_occupation: Int,
  $max_derivation_queue_validity: Int,
  $max_derivation_agent_validity: Int,
  $derivations_adm: Boolean,
  $alert_time: Int,
  $analize_sentiment: Boolean,
  $monthly_total: Int,
  $sample_percentage: Int,
  $is_bot_session_validity: Boolean,
  $bot_session_validity: Int,
  $bot_session_message: String
){
 setConfigurationByBotId(inputCompanyConfigByBotId:{
    token:$token,
    bot_id:$bot_id,
    configuration:{
      max_bot: $max_bot,
      max_user: $max_user,
      max_agent_occupation: $max_agent_occupation,
      max_derivation_queue_validity: $max_derivation_queue_validity,
      max_derivation_agent_validity: $max_derivation_agent_validity,
      derivations_adm: $derivations_adm,
      alert_time: $alert_time,
      analize_sentiment: $analize_sentiment,
      monthly_total: $monthly_total,
      sample_percentage: $sample_percentage,
      is_bot_session_validity: $is_bot_session_validity,
      bot_session_validity: $bot_session_validity,
      bot_session_message: $bot_session_message
    }
  }){
		code
    message
  }
}
`

