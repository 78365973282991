/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { AES } from 'crypto-js'
import { useDatabase, useDatabaseObjectData } from 'reactfire'
import moment from 'moment'
// context
import { AuthContext } from '../../../Context/Auth'
import { MessagesContext } from '../../../Context/Messages'
// componet
import ListChat from './items/ListChat'
import ItemList from './items/ItemList'
import Chat from './items/Chat'
import FormDiary from './items/FormDiary'
import MessagesChat from './items/MessagesChat'
import Loader from '../../Recurent/Loader'
import Error from '../../Recurent/Error'
// style
import { Cont } from '../../../Style/custom'
import { messages } from '../../../Style/messages'
import { RemoveRedEye } from '@material-ui/icons'
import { dateFilter } from 'react-bootstrap-table2-filter'

const Messages = ({t}) => {
    // context
    const { autoAuth, userAuth, lang, botUser } = useContext(AuthContext)   
    const {
        User, setUser,
        setChannelList,
        setPataformList,
        setListTap,
        listChat, setListChat,
        chatRef,
        scrollRef,
        formStyle,
        channelPataformLoading, channelPataformError, channelPataformData,
        showTapsBot, tapBotLoading, tapBotError, tapBotData, tapBotRefetch,
        sound
    } = useContext(MessagesContext)
    // state
    const [dataFire, setDataFire] = useState(null)
    const [child, setChild] = useState(null)
    const [roomToDelete, setRoomToDelete] = useState(null)
    const [roomToModify, setRoomToModify] = useState(null)
    const [newMessage, setNewMessage] = useState(null)
    const [loading, setLoading] = useState(true)
    const [roomAddMessages, setRoomAddMessages] = useState(null)
    // firebase 
    const db = useDatabase()
    // eslint-disable-next-line   
    const url = "https://storage.googleapis.com/images_keos-bot/config/notificacion.mp3"
    const audio = new Audio(url)
    

    const rooms =    useDatabaseObjectData(db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}`))
    useEffect(() => {
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}`).on('child_added', (childSnapshot, prevChildKey) => {

            let newChild = childSnapshot.val()
            setChild(newChild)

        })    

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}`).on('child_removed', (oldChildSnapshot) => {
            let removedRoom = oldChildSnapshot.val()
            if(removedRoom.hasOwnProperty("roomId")){
                setRoomToDelete(removedRoom)
            }
        }) 
        for(const roomId in rooms){
            addListeners(roomId)
        }
        arrayListChat(rooms)
        setDataFire(rooms)
        setLoading(false)
    }, [])

    useEffect(()=>{
        if(!loading){
            let newChild = child
            if(newChild != null){
                if(newChild.hasOwnProperty("roomId")){
                    let newData = dataFire;
                    if(dataFire== null){
                        newData={}
                        newData[newChild.roomId] = newChild
                        setDataFire(newData)
                    }else if(!dataFire.hasOwnProperty(newChild.roomId)){
                        newData[newChild.roomId] = newChild
                        if (sound){
                            audio.play()
                        }   

                    }
                    addListeners(newChild.roomId)
                    arrayListChat(newData)
                    
                    
                    if (chatRef.current !== null){
                        setTimeout(() => {
                            chatRef.current.scroll({top: chatRef.current.scrollHeight - chatRef.current.clientHeight,
                                left: 0, 
                                behavior: 'smooth',});               
                        }, 100);         
        
                    }
                    setDataFire(newData)
                }
            } 
        }
    }, [child])

    useEffect(()=>{
        if(!loading){
            if(roomToDelete != null){
                let newData = dataFire
                delete newData[roomToDelete.roomId]
                arrayListChat(newData)
                if(User && User.roomId == roomToDelete.roomId ){
                    // console.log("roomToDelete clear user")
                    setUser(null)
                }
                setDataFire(newData)
            }
        }
    },[roomToDelete])

    useEffect(()=>{
        if(!loading){
            let newData = dataFire
            if(roomToModify != null && newData[roomToModify.roomId]){
                newData[roomToModify.roomId][roomToModify.key] = roomToModify.value
                arrayListChat(newData)
                setDataFire(newData)
            }
        }
    },[roomToModify])

    useEffect(()=>{
        
        if(!loading){
            let newData = dataFire
            if(newMessage != null && newData[newMessage.value.roomId] && newData[newMessage.value.roomId].chat ){
                let chat = newData[newMessage.value.roomId].chat
                let chatKeys = Object.keys(chat)
                if(chatKeys[chatKeys.length-1] == newMessage.prevKey){
                    chat[newMessage.key]=newMessage.value
                    newData[newMessage.value.roomId].chat = chat
                    arrayListChat(newData)
                    setDataFire(newData)
                    if (sound){
                        if (newMessage.value.channel =='2' ){                    
                            audio.play()
                        }                         
                    }
                    
                    
                    if (User && newMessage.value.roomId == User.roomId){
                        
                       setTimeout(() => {
                            if(chatRef) {
                                if(chatRef.current) {                                
                                    //chatRef.current.scrollIntoView(false);
                                    chatRef.current.scroll({top: chatRef.current.scrollHeight - chatRef.current.clientHeight,
                                        left: 0, 
                                        behavior: 'smooth',}); 
                                }
                            }
                        }, 100);                        
                    }
                    
                }
            }
        }
    },[newMessage])


    useEffect(()=>{
        if(!loading){
            let listener = (dataSnapshot) => {
                let room = dataSnapshot.val()
                let newData = dataFire
                newData[roomAddMessages] = room
                //setUser(room)
                arrayListChat(newData)
                setDataFire(newData)
            }
            db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomAddMessages}`).on('value', listener)
            db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomAddMessages}`).off('value', listener)
        }
    },[roomAddMessages])
    
    const removeRoomById = (roomId) => {
        if(!loading){
         
                let newData = dataFire
                delete newData[roomId]
                arrayListChat(newData)
                if(User && User.roomId == roomId ){
                    // console.log("removeRoomById clear user")
                    setUser(null)
                }
                setDataFire(newData)
         
        }        
    }  

    const addListeners = roomId => {
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/newMessage`).on('value', (dataSnapshot) => {
            let data = dataSnapshot.val()
            if(data != null){
                let obj = {key:"newMessage",value:data, roomId:roomId}
                setRoomToModify(obj)
            }
        })    

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/lastMessage`).on('value', (dataSnapshot) => {
            let data = dataSnapshot.val()
            if(data != null){
                let obj = {key:"lastMessage",value:data, roomId:roomId}
                setRoomToModify(obj)
            }
        }) 

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/client_name`).on('value', (dataSnapshot) => {
            let data = dataSnapshot.val()
            if(data != null){
                let obj = {key:"client_name",value:data, roomId:roomId}
                setRoomToModify(obj)
            }
        })
        //se agrega escuchador firebase para la carga del client_id 
        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/client_id`).on('value', (dataSnapshot) => {
            let data = dataSnapshot.val()
            if(data != null){
                let obj = {key:"client_id",value:data, roomId:roomId}
                setRoomToModify(obj)
            }
        })   

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/alerted`).on('value', (dataSnapshot) => {
            let data = dataSnapshot.val()
            if(data != null){
                let obj = {key:"alerted",value:data, roomId:roomId}
                setRoomToModify(obj)
            }
        })    

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/tag_name`).on('value', (dataSnapshot) => {
            let data = dataSnapshot.val()
            if(data){
                let obj = {key:"tag_name",value:data, roomId:roomId}
                setRoomToModify(obj)
                let colorPromise = db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/tag_color`)
                                        .transaction(colorValue => {
                                            return colorValue
                                        })
                colorPromise.then(value =>{
                    let obj = {key:"tag_color",value:value.snapshot.val(), roomId:roomId}
                    setRoomToModify(obj)
                })
            }
        })    

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/chat`).on('child_added', (childSnapshot, prevChildKey) => {
            
            let data = childSnapshot.val()
            //console.log("CHILD ADDDED", data)
            if(data != null && (!data.recovered || data.recovered != 1)){
                let obj = {prevKey:prevChildKey,value:data,key:childSnapshot.key,roomId:roomId}
                setNewMessage(obj)
            }
        }) 

        db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${roomId}/msj_showing`).on('value', (childSnapshot, prevChildKey) => {
            let data = childSnapshot.val()
            //console.log("MESSAGE SHOWING", data)
            setRoomAddMessages(roomId)
        })
    }

    

    // switch
    const  hast= AES.encrypt( JSON.stringify({...autoAuth, lang,  botUser ,location: '/messages'}), process.env.REACT_APP_KEY_CRYPTO_PATH).toString()
   // function
    const arrayListChat = dataFire => { 
        //console.log("Array List CHAT USER",User)
        const list = Object.keys(dataFire)
        let newListChat = list.map(data=>{ 
            if(dataFire && dataFire[data] && dataFire[data].hasOwnProperty("lastMessage") && dataFire[data].lastMessage.hasOwnProperty("time") &&
               dataFire[data].hasOwnProperty("chat") && dataFire[data].hasOwnProperty("botId") && 
               dataFire[data].hasOwnProperty("roomId") && dataFire[data].hasOwnProperty("session_id")  ){
                if(User){
                    if(User.roomId===dataFire[data].roomId){
                            setUser({ ...dataFire[data]})
                    }
                }
                return { ...dataFire[data]}
            } else{
                db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${data}/`).update({ 'return_to_bot': true}).then(()=>{
                    db.ref(`bot/rooms/Integrations/${userAuth.uid}/${userAuth.id_user}/${data}/`).remove() 
                })           
                
            }
        })
        newListChat = newListChat.filter(obj => (true&&obj))
        
        let sorted = newListChat.sort((a, b)=>{
            if(moment(a.lastMessage.time).format('YYYY-MM-DD HH:mm:ss')<moment(b.lastMessage.time).format('YYYY-MM-DD HH:mm:ss')) return 1
            if(moment(a.lastMessage.time).format('YYYY-MM-DD HH:mm:ss')>moment(b.lastMessage.time).format('YYYY-MM-DD HH:mm:ss')) return -1
            return 0
        })
        
        setListChat(sorted)
    }

    useEffect(() => {               
        if(channelPataformData){
            if(channelPataformData.showChannel){
                setChannelList(channelPataformData.showChannel.data)
            }
            if(channelPataformData.showPlatform){
                setPataformList(channelPataformData.showPlatform.data)
            }
        }
    }, [channelPataformData])


    useEffect(() => {
        if(tapBotData){
            if(tapBotData.showTagsByBotId){
                setListTap(tapBotData.showTagsByBotId.data)
            }
        }
    }, [tapBotData])


    if(channelPataformLoading) return <Loader height='100%' />

    if(channelPataformError) return <Error />
    
    
    return process.env.REACT_APP_FRONT_ENV ? (<div className={messages.cont}>
            <ListChat>
                {listChat.map((data, i)=>(
                    <ItemList key={i} data={data} showTapsBot={showTapsBot} tapBotRefetch={tapBotRefetch} userSelected={User} removeRoomById={removeRoomById}/>
                ))}
            </ListChat>            
            
            <Chat user={userAuth.user_name} selectedUser={User} removeRoomById={removeRoomById}>            
                {User ? User.chat ? <div ref={chatRef} className='covertation'>{Object.keys(User.chat).map(chat =>{
                    //console.log("USER CHAT", chat)
                    return (<MessagesChat key={chat} User={User} chat={chat} />)})}
                </div> : '' : t('messages.selectChat')}
            
            </Chat>
        </div>) : (<div className={Cont.infoPanel}>
        <iframe src={`${process.env.REACT_APP_FRONT_OLD}authRemote#${hast}`} /> 
    </div>)
}
export default withTranslation()(Messages)
