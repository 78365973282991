import {style} from 'typestyle'

const Color = {
    white: '#fff',
    text: '#888',
    border: '#ccc',
    allhover: 'rgb(0,0,0,0.2)',
    fondo: '#cacccd'
}

export const contInput = style({
    display: 'grid',
    gridTemplateColumns: '60px 60px 60px 20px auto 60px',
    width:'100%',
    borderColor: Color.border,
    borderStyle: 'solid',
    borderWidth: '1px',
    height: '47px',
    backgroundColor: Color.fondo,

    $nest: {
        '& .contShortCuts': {
            width: '100%',
            gridArea: '1 / 1 / 2 / 6',
            zIndex: 1,
            display: 'flex',
            backgroundColor: Color.white
        },        
        '& .contEmojis': {
            width: '100%',
            gridArea: '1 / 1 / 2 / 6',
            zIndex: 1,
            display: 'flex',
        },
        '& .contFile': {
            width: '100%',
            gridArea: '1 / 1 / 2 / 6',
            zIndex: 1,
            display: 'flex',
            $nest: {
                '& .cont': {
                    backgroundColor: Color.white,
                    borderColor: Color.border,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridGap: '5px',
                    overflow: 'hidden',
                    minHeight: '500px',

                    $nest: {
                        '& .contInputFile': {
                            backgroundColor: Color.white,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            alignItems: 'center',
                            
                            $nest: {
                                '& .message': {
                                    width: '70%',
                                    borderColor: Color.border,
                                    borderWidth: '2px',
                                    borderStyle: 'dashed',
                                    borderRadius: '10px',
                                    padding: '25px',
                                    textAlign: 'center',
                                    color: Color.text,
                                    fontSize: '30px',
                                    fontWeight: 700
                                }
                            }
                        },
                        '& .contPreviewFile':{
                            display: 'grid',
                            gridGap: '5px',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            overflow: 'auto',
                            maxHeight: '400px',
                            margin:'0px 5px',

                            $nest: {
                                '& .previewFile': {
                                    width: '100%',
                                    $nest: {
                                        '& img': {
                                            width: '100%'
                                        },
                                        '& iframe': {
                                            width: '100%',
                                            height: '380px'
                                        },
                                        '& .file': {
                                            width: '100%',
                                            height: '380px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: Color.text,
                                            $nest: {
                                                '& .icon': {
                                                    fontSize: '200px',
                                                    marginBottom: '5px'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        '& .iconEmoji': {
            gridArea: '1 / 1 / 1 / 2',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: Color.text,
            zIndex: 2,
            backgroundColor: Color.fondo,
            fontSize: '22px',
            cursor: 'pointer'
        },
        '& .iconFile': {
            gridArea: '1 / 2 / 1 / 2',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: Color.text,
            zIndex: 2,
            backgroundColor: Color.fondo,
            fontSize: '22px',
            cursor: 'pointer'
        },
        '& .iconAudio': {
            gridArea: '1 / 3 / 1 / 3',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: Color.text,
            zIndex: 2,
            backgroundColor: Color.fondo,
            fontSize: '22px',
            cursor: 'pointer'
        },
        '& .contAudioDelete': {
            gridArea: '1 / 4 / 1 / 4',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: Color.text,
            zIndex: 2,
            backgroundColor: Color.fondo,
            fontSize: '22px',
            cursor: 'pointer'
        },
        '& .contInput': {
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridTemplateRows: 'repeat(5, 1fr)',
            gridArea: '1 / 5 / 2 / 6',
            backgroundColor: Color.fondo,

            $nest: {
                '& .placehobler': {
                    marginLeft: '10px',
                    zIndex: 3,
                    color: Color.text,
                    display: 'flex',
                    justifyContent: 'flex-start',

                },
                '& .input': {
                    gridArea: '2 / 1 / 5 / 6',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '25px',
                    backgroundColor: Color.white,
                    zIndex: 2,
                    paddingLeft: '5px',
                    
                    $nest: {
                        '&:focus': {
                            outline: 'none'
                        }
                    }
                },
            }
        },
        '& .contButton': {
            gridArea: '1 / 6 / 2 /6',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: Color.text,
            fontSize: '28px',
            zIndex: 2,
            backgroundColor: Color.fondo,


            $nest: {
                '& .button': {
                    borderRadius: '50%',
                    borderColor: Color.text,
                    borderStyle: 'solid',
                    borderWidth: '0px',
                    color: Color.text,
                    backgroundColor: Color.white,
                    width: '35px',
                    height: '35px',
                    fontSize: '25px',
                    fontWeight: 700,
                    cursor: 'pointer'
                }
            }
        }
    }
})