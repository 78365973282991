import { gql } from "apollo-boost";

export const TOKEN_AUTH = gql`
    {
        token_login{
            token
        }
    }
`

export const LOGIN_AUTH = gql`
mutation authLogin($token: String!, $user_name: String!, $password: String!){  
    auth_login_back(inputAuth:{
     token: $token
     user_name: $user_name
     password: $password
   }){
        code
        message
        token
        session{
            id_company
            id_user
            name_rol
            user_name
            uid
            bots{
                id_bot
                name_bot
                type_bot
            }
        }
    }
}
`
export const FIREBASE_AUTH = gql`
query loginToken($token: String){
  login_firebase(token: $token){
    token
  }
}
`
export const CHECK_BOT_USER = gql`
query showBot($token:String, $id_user: Int){
    checkBotsState(inputDefault:{
      token: $token
      id_user: $id_user
    }){
      code
      message
      bots{
        bot_id
        name
        type_bot
      }
    }
  }
`

export const ASINATE_BOT_USER = gql`
mutation asinateBot($token: String, $id_user: Int, $id_bot: Int, $id_company: Int, $activate: Boolean){
    asignbot(inputAsinetBot: {
        inputTokIdUser: {
         token: $token
        id_user: $id_user
      }
      id_bot: $id_bot
      id_company: $id_company
      activate: $activate 
    }){
      code
      message
    }
  }
`

export const VIEW_BOT_USER = gql`
query getBotViewed($token:String, $id_user:Int){
  getBotViewed(inputIdUser:{
    token:$token,
    id_user:$id_user
  }){
    code
    message
    data{
      bot_id
      bot_name
      type_bot
      integrations{
        configuration
        name_integration
        name_platform
      }
    }
  }
}
`
export const SELECT_BOT_USER = gql`
mutation setBotViewed($token:String, $id_user:Int, $id_bot:Int){
  setBotViewed(inputBotUserID:{
    token:$token,
    id_user:$id_user,
    id_bot:$id_bot
  }){
    code
    message
  }
}
`