import { useState } from 'react'
import { AES, enc } from 'crypto-js'

export const  useSessionStorage = (key, initialValue) => {
    const [storageValue, setStorageValue] = useState(() => {
        try {
            const item = AES.decrypt(sessionStorage.getItem(key),process.env.REACT_APP_KEY_CRYPTO).toString(enc.Utf8);
            return item ? JSON.parse(item) : initialValue;
        } catch (_) {
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            setStorageValue(value);
            sessionStorage.setItem(key, AES.encrypt(JSON.stringify(value), process.env.REACT_APP_KEY_CRYPTO));
        } catch (error) {
            console.error(error);
        }
    };

    return [storageValue, setValue];
};

export const  useLocalStorage = (key, initialValue) => {
    const [storageValue, setStorageValue] = useState(() => {
        try {
            const item = AES.decrypt(localStorage.getItem(key),process.env.REACT_APP_KEY_CRYPTO).toString(enc.Utf8);
            return item ? JSON.parse(item) : initialValue;
        } catch (_) {
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            setStorageValue(value);
            localStorage.setItem(key, AES.encrypt(JSON.stringify(value), process.env.REACT_APP_KEY_CRYPTO));
        } catch (error) {
            console.error(error);
        }
    };

    return [storageValue, setValue];
};

export const  useLocalStorageNoEcrypt = (key, initialValue) => {
    const [storageValue, setStorageValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (_) {
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            setStorageValue(value);
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    };

    return [storageValue, setValue];
};

export const capitalize = word =>  `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
